import styles from './PLP.module.scss';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import ProductContext from './ProductContext';
import { connect } from 'react-redux';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import Spinner from 'components/primitives/spinner/Spinner';
import { useProductRouteBuilder } from './hooks';
import { ProductThumbnail } from 'components/primitives/product';

const Thumbnail = ({ id, url, title, image, noImage }) => {
  const routeBuilder = useProductRouteBuilder(id);

  if (!image && !noImage)
    return null;

  return (
    <div className={styles.thumbnail} aria-hidden>
      <WithProductClickTracking>
        {handleClick => (
          <Link onClick={handleClick} url={url} to={routeBuilder} tabIndex="-1">
            <ProductThumbnail
              src={image}
              noImageSrc={noImage}
              title={title}
              className={styles.productImg}
              wrapperClassName={styles.productImgWrapper}
              placeholder={<Spinner />}
            />
          </Link>
        )}
      </WithProductClickTracking>
    </div>
  );
};

Thumbnail.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  noImage: PropTypes.string,
};

const ConnectedComponent = connect(mapStateToProps)(Thumbnail);

export default connectToContext(
  [ProductContext],
  ({ id, url, title, image }, { gridMode }) => ({
    id,
    url,
    title,
    image: image && (
      gridMode
        ? image.medium || image.small
        : image.small
    ),
  }),
)(ConnectedComponent);

function mapStateToProps(state, ownProps) {
  const { gridMode } = ownProps;
  const {
    settings: { product: productSettings },
  } = state;

  if (!productSettings)
    return { noImage: null };

  return {
    noImage: gridMode
      ? productSettings.noImage.medium
      : productSettings.noImage.small,
  };
}