import { stringifyQuery, parseQuery } from 'utils/url';

export function queryFacetUrlBuilder(options) {
  const { encode, encodeTitle, pathname, search, allFacets } = options;
  const { selectedFacets, query } = init();

  return {
    getSelectedFacets() {
      return selectedFacets;
    },
    addToUrl(field, value) {
      const fieldTitle = encodeTitle(field.title);
      const valueTitle = encode(value.textTitle || value.title);
      const { sort, 'view-mode': viewMode, ...resultQuery } = query;

      resultQuery[fieldTitle] = query[fieldTitle] ? `${query[fieldTitle]},${valueTitle}` : valueTitle;
      resultQuery.sort = sort;
      resultQuery['view-mode'] = viewMode;

      return pathname + stringifyQuery(resultQuery);
    },
    removeFromUrl(field, value) {
      const fieldTitle = encodeTitle(field.title);
      const { [fieldTitle]: selectedValues, ...resultQuery } = query;

      if (selectedValues) {
        const valueTitle = encode(value.textTitle || value.title);
        if (selectedValues.length !== valueTitle.length) {
          let value = selectedValues.replace(new RegExp(`${valueTitle},?`), '');
          if (value[value.length - 1] === ',')
            value = value.substring(0, value.length - 1);

          resultQuery[fieldTitle] = value;
        }
      }

      return pathname + stringifyQuery(resultQuery);
    },
    removeAllFromUrl() {
      const { q, sort, 'view-mode': viewMode } = query;
      const resultQuery = { q, sort, 'view-mode': viewMode };

      return pathname + stringifyQuery(resultQuery);
    },
  };

  function init() {
    const query = parseQuery(search);
    const selectedFacets = [];

    for (const facet of allFacets) {
      if (facet.anySelected) {
        const record = {
          name: facet.name,
          values: new Map(),
        };
        for (const value of facet.values) {
          if (value.selected) {
            record.values.set(value.value, encode(value.textTitle || value.title));
          }
        }
        selectedFacets.push(record);
      }
    }

    return { selectedFacets, query };
  }
}
