import { ACCURACY } from './constants';

export function normalizeBounds(value, minimum, maximum) {
  if (value < minimum)
    return minimum;

  if (value > maximum)
    return maximum;

  return value;
}

export function strip(number) {
  return parseFloat(number.toFixed(ACCURACY));
}