interface HTMLElementWithIESpecificMethods extends HTMLElement {
  setActive: () => void;
}

export const focusById = (id: string) => document.getElementById(id)?.focus();

export const setFocusWithoutScrolling = (element: HTMLElementWithIESpecificMethods | null | undefined) => {
  if (!(element instanceof HTMLElement))
    return;

  // To prevent scroll on focus in IE11 non-standard method setActive should be used.
  element.setActive ? element.setActive() : element.focus({ preventScroll: true });
};
