import { PRODUCT_SORTFIELDS_REQUESTED, sortableFieldsReceived } from './actions';
import { switchMap, map, takeUntil } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { sortableFieldsQuery } from './queries';
import { VIEWER_CHANGED } from 'behavior/events';

export default function epic(action$, _state$, { api }) {
  const viewerChanged$ = action$.pipe(ofType(VIEWER_CHANGED));

  return action$.pipe(
    ofType(PRODUCT_SORTFIELDS_REQUESTED),
    switchMap(_action => api.graphApi(sortableFieldsQuery).pipe(
      map(({ catalog: { sortableFields } }) => sortableFieldsReceived(sortableFields)),
      takeUntil(viewerChanged$),
    )),
  );
}