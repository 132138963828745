export const LASTVIEWED_PRODUCTS_PAGE_REQUESTED = 'LASTVIEWED_PRODUCTS_PAGE_REQUESTED';
export const requestPage = calculatedOnly => ({
  type: LASTVIEWED_PRODUCTS_PAGE_REQUESTED,
  payload: { calculatedOnly },
});

export const LASTVIEWED_PRODUCTS_PAGE_RECEIVED = 'LASTVIEWED_PRODUCTS_PAGE_RECEIVED';
export const pageReceived = products => ({
  type: LASTVIEWED_PRODUCTS_PAGE_RECEIVED,
  payload: products,
});
