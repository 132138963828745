import styles from './Error.module.scss';
import { memo } from 'react';
import { Container } from 'components/primitives/grid';
import { Link } from 'components/primitives/links';
import { Placeholder } from 'components/primitives/placeholders';
import { useSanaTexts } from 'components/sanaText';
import { DangerAlert, alertStyles } from 'components/primitives/alerts';
import { routesBuilder } from 'routes';
import { makeSimpleText, makeRichText } from 'utils/render';

const textKeys = [
  'ErrorPage_PageErrorTitle',
  'ErrorPage_ErrorText',
  'ErrorPage_PageErrorReload_Reload',
  'ErrorPage_PageErrorReload_Text',
  'ErrorPages_HomepageLink',
  'ErrorPages_HomepageLinkTemplate',
];

const ErrorBanner = () => {
  const { loaded, texts: [title, msg, reload, reloadTemplate, returnText, returnHomeTemplate] } = useSanaTexts(textKeys);

  const placeholder = loaded ? '' : <Placeholder className={styles.loading} />;

  const retryLoading = (
    <button className={alertStyles.link} onClick={_ => window.location.reload()}>
      {makeSimpleText(reload) || 'Reload'}
    </button>
  );

  const homePageLink = (
    <Link to={routesBuilder.forHome}>
      {makeSimpleText(returnText) || 'Return'}
    </Link>
  );

  return (
    <Container id="errorPage">
      <h1 className={styles.title}>
        {placeholder || makeSimpleText(title) || 'Requested page cannot be shown'}
      </h1>
      <DangerAlert className={styles.block}>
        <div>
          {placeholder || makeRichText(msg) || 'Sorry! We are having some trouble with retrieving the information for you. Please try the following:'}
        </div>
        <ul className={styles.actionList}>
          <li>
            {placeholder || makeSimpleText(reloadTemplate || '{0} the page', [retryLoading], true)}
          </li>
          <li>
            {placeholder || makeSimpleText(returnHomeTemplate || '{0} to homepage.', [homePageLink], true)}
          </li>
        </ul>
      </DangerAlert>
    </Container>
  );
};

export const preload = {
  routes: [routesBuilder.forHome()],
  texts: textKeys,
};

export default memo(ErrorBanner);
