//3.1.Econ � configuration functionality
import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { switchMap, map, pluck, groupBy, mergeMap } from 'rxjs/operators';
import { CREATE_USER_TOKEN, tokenRecordsReceived } from '../actions';
import { createUserTokenQuery } from '../queries';

export default (action$, _state$, { api }) => {
  //const createUserToken$ = action$.pipe(
  //  ofType(CREATE_USER_TOKEN),
  //  groupBy(action => action.payload.contentBlockId),
  //  mergeMap(group => group.pipe(
  //    switchMap(({ payload: { contentBlockId } }) => {
  //      return api.graphApi(createUserTokenQuery, { id: 'ECon' }).pipe(
  //        pluck('createUserToken'),
  //        map(records => tokenRecordsReceived(contentBlockId, records)),
  //      );
  //    }),
  //  )),

  //);
  const createUserToken$ = action$.pipe(
    ofType(CREATE_USER_TOKEN),
    mergeMap(({ payload: { } }) => {
      return api.graphApi(createUserTokenQuery).pipe(
        pluck('createUserToken'),
        map(records => tokenRecordsReceived(records)),
      );
    }),
  );
  return merge(createUserToken$);
};
