import { createContext } from 'react';
import { noop } from 'rxjs';

const CheckoutContext = createContext({
  activeStep: null,
  isCompleted: null,
  setLoading: noop,
  nextStep: noop,
  getPrevStepNavigationInfo: noop,
  submit: noop,
});

export default CheckoutContext;
