import { initSystemPageContent } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { requestDocuments } from './actions';
import { PageComponentNames } from '../componentNames';
import DateOnly from 'date-only';
import { loadSystemPageQuery } from '../system/queries';
import { getDocumentField } from 'behavior/documents';

export const size = 10;

export default function (documentType, componentName, query) {
  const documentsFieldName = getDocumentField(documentType);
  return function ({ params, options: pageOptions }, state$, { api }) {
    const filter = normalizeFilter(params && params.filter);

    if (params?.previewToken) {
      return api.graphApi(loadSystemPageQuery(documentsFieldName)).pipe(
        map(({ pages }) => {
          const page = pages[documentsFieldName];
          if (!page)
            return null;
  
          page.docs = { totalCount: 0 };
          page.component = componentName;
          page.size = size;
          page.filter = filter;

          return { page };
        }),
        initSystemPageContent(),
      );
    }

    const onlyItems = pageOptions && pageOptions.onlyItems;
    const backTo = pageOptions && pageOptions.backTo;
    const options = createOptions(params, filter, onlyItems);

    if (onlyItems)
      return of({
        action$: of(requestDocuments(options, documentType)),
        page: {
          ...state$.value.page,
          filter,
        },
      });

    return api.graphApi(query, { options }).pipe(
      map(({ pages, documents: pageDocuments }) => {
        const page = pages[documentsFieldName];
        if (!page)
          return null;

        const documents = pageDocuments.docs && pageDocuments.docs.list;
        if (!documents) {
          page.component = PageComponentNames.NotFound;
          return { page };
        }

        page.docs = documents;
        page.component = componentName;
        page.size = size;
        page.filter = filter;
        page.backTo = backTo;

        return { page };
      }),
      initSystemPageContent(),
    );
  };
}

export function createOptions(params, filter, onlyItems) {
  const index = (params && params.index) || 0;
  const page = onlyItems
    ? { index, size }
    : { index: 0, size: size + size * index };

  return { ...filter, page };
}

export function normalizeFilter(filter) {
  if (filter && filter.orderDate)
    return filter;

  const from = new Date();
  let fromDate = from.getDate();
  const fromMonth = from.getMonth() - 3;
  const fromMonthLastDay = getNumberOfDaysInMonth(fromMonth + 1, from.getFullYear());
  if (fromMonthLastDay < fromDate)
    fromDate = fromMonthLastDay;

  from.setMonth(fromMonth, fromDate);

  return { ...filter, orderDate: { from: DateOnly.toISOString(from) } };
}

function getNumberOfDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
