import styles from './Order.module.scss';
import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { CustomerTypes } from 'behavior/user';
import { AbilityTo } from 'behavior/user/constants';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { useSanaTexts, UseSanaTexts } from 'components/sanaText';
import { makeSimpleText, makeRichText } from 'utils/render';
import Attachments from './general/Attachments';
import ReorderButton from './general/ReorderButton';
import DownloadReportLink, { DownloadStates } from './general/DownloadReportLink';
import { PromoteButton } from './quote/promotable';
import { usePageTitle } from 'components/objects/hooks';
import { useHasAbilities } from 'components/objects/user';
import PrintPreviewLink from './PrintPreviewLink';
import LeftColumn from './general/LeftColumn';
import RightColumn from './general/RightColumn';
import DocumentAddresses from './address/DocumentAddresses';
import { DocumentLines } from './lines';
import Totals from './totals/Totals';
import QuoteMessages from './quote/QuoteMessages';
import { DocumentType, isDocumentCancelled } from 'behavior/documents';
import { canInvoiceBePaid } from 'behavior/pages/document';
import { PayInvoiceButton, ReturnOrderButton } from './invoice';
import { InfoAlert, DangerAlert } from 'components/primitives/alerts';
import { useOnChange } from 'utils/hooks';
import EditButton from './general/EditButton';
import DocumentNotifications from './DocumentNotifications';
import { AuthorizationInfo } from './authorization';
import { useIsPreview } from 'components/objects/preview';
import ConfigureButton from './general/ConfigureButton';

const downloadMessageId = 'dwnld-msg';
const initialNotificationState = {
  orderNotFound: false,
  orderHasNoProducts: false,
  cannotBePromoted: false,
  creditLimitExceeded: false,
  payInvoiceError: false,
};

const Document = ({
  document,
  documentType,
  isB2BCustomer,
  isPrintMode,
  setLoadingIndicator,
  unsetLoadingIndicator,
}) => {
  const [notificationState, setNotificationState] = useState(initialNotificationState);
  const documentNotifications = useMemo(() => ({
    orderNotFound() { updateNotificationState({ orderNotFound: true }); },
    orderHasNoProducts() { updateNotificationState({ orderHasNoProducts: true }); },
    cannotBePromoted() { updateNotificationState({ cannotBePromoted: true }); },
    creditLimitExceeded() { updateNotificationState({ creditLimitExceeded: true }); },
    payInvoiceError() { updateNotificationState({ payInvoiceError: true }); },
    hideOrderNotifications,
    hidePromoteNotifications,
    hidePayInvoiceNotifications,
  }), []);

  useEffect(() =>
    void (document
      ? unsetLoadingIndicator()
      : setLoadingIndicator()
    )
    , [document == null]);

  const {
    texts: {
      documentTypeText,
      quoteExpiredText,
      quoteInReviewText,
      downloadingMsg,
    },
    textsLoaded,
  } = useTexts(documentType);

  const pageTitle = document
    ? document.documentId + (textsLoaded ? ` ${documentTypeText}` : '')
    : '';

  const [canViewPrices, reorderAvailable, canPromoteQuote, canPayInvoice, canCreateReturnOrder] = useHasAbilities(
    AbilityTo.ViewPrices,
    AbilityTo.Reorder,
    AbilityTo.PromoteQuote,
    AbilityTo.PayInvoice,
    AbilityTo.CreateDocBasedReturnOrder,
  );

  const titleValue = usePageTitle(pageTitle);
  const title = <Helmet><title>{titleValue}</title></Helmet>;
  const header = <h1 className={styles.title}>{pageTitle}</h1>;

  let [reportLoadState, setReportLoadState] = useState();
  const isPreview = useIsPreview();

  useOnChange(() => {
    setReportLoadState();
    reportLoadState = undefined;
  }, [document && document.reportUrl || null], false);

  if (!document)
    return <>{title}{header}</>;

  const cancelled = isDocumentCancelled(document);
  const isQuoteDocument = documentType === DocumentType.Quote;
  const promoteQuoteAvailable = canPromoteQuote && isQuoteDocument && !cancelled;

  const payInvoiceAvailable = canPayInvoice
    && documentType === DocumentType.Invoice
    && canInvoiceBePaid(document);

  const returnOrderAvailable = canCreateReturnOrder
    && documentType === DocumentType.Invoice
    && document.canBeReturned !== false;

  const {
    currency: currencyInfo,
    addresses,
    totals,
    attachments,
    reportUrl,
    authorizationInfo,
    authorizationActionResultStatus,
    editable,
  } = document;

  const anyActionAvailable = !isPreview
    && (reportUrl
      || editable
      || promoteQuoteAvailable
      || reorderAvailable
      || returnOrderAvailable
      || payInvoiceAvailable);

  //3.1 Econ configuration
  const notConfigurable = !(document.reconfigurtionId);

  return (
    <>
      {title}
      <div className={styles.header}>
        {header}
        <PrintPreviewLink isPrintMode={isPrintMode} />
      </div>
      <DocumentNotifications notificationState={notificationState} />
      {textsLoaded && isQuoteDocument && !isPrintMode && !isPreview && (
        <QuoteMessages
          document={document}
          expiredText={quoteExpiredText}
          inReviewText={quoteInReviewText}
        />
      )}
      <div className={styles.orderWrapper}>
        <div className={`${styles.columnsGeneral} ${styles.generalInfo}`}>
          <LeftColumn isB2BCustomer={isB2BCustomer} document={document} documentType={documentType} />
          <RightColumn isB2BCustomer={isB2BCustomer} document={document} />
        </div>
        <DocumentAddresses isB2BCustomer={isB2BCustomer} addresses={addresses} />
        <div className={styles.actionBar}>
          {attachments && attachments.length > 0 && <Attachments attachments={attachments} />}
          {anyActionAvailable && (
            <div className={styles.actions}>
              {reportUrl &&
                <DownloadReportLink document={document}
                  documentType={documentType}
                  setReportLoading={setReportLoadState}
                  msgId={reportLoadState && reportLoadState !== DownloadStates.Success ? downloadMessageId : null}
                />}
              {editable && notConfigurable && <EditButton document={document} documentNotifications={documentNotifications} />}
              {promoteQuoteAvailable && <PromoteButton quote={document} documentNotifications={documentNotifications} />}
              {reorderAvailable && notConfigurable &&<ReorderButton document={document} documentNotifications={documentNotifications} />}
              {returnOrderAvailable && <ReturnOrderButton invoice={document} />}
              {payInvoiceAvailable && <PayInvoiceButton invoice={document} documentNotifications={documentNotifications} />}
              {/*3.1 Econ configuration*/}
              {documentType === DocumentType.Quote && !notConfigurable && <ConfigureButton configurationId={document.reconfigurtionId}/>}
            </div>
          )}
        </div>
        {reportLoadState === DownloadStates.Waiting &&
          <InfoAlert role="alert" className={styles.msgBlock}>
            <div className={styles.downloadMsg} id={downloadMessageId}>
              {downloadingMsg}
            </div>
          </InfoAlert>
        }
        {reportUrl && reportLoadState === DownloadStates.Failure &&
          <UseSanaTexts options={['ProblemGettingYourFile']}>
            {([msg]) => msg && (
              <DangerAlert role="alert" className={styles.msgBlock} scrollOnAppear>
                <div className={styles.downloadMsg} id={downloadMessageId}>
                  {makeRichText(msg)}
                </div>
              </DangerAlert>
            )}
          </UseSanaTexts>
        }
        <DocumentLines
          document={document}
          documentType={documentType}
          currencyInfo={currencyInfo}
          cancelled={cancelled}
        />
        {canViewPrices && totals && (
          <Totals totals={totals} currencyInfo={currencyInfo} />
        )}
        <AuthorizationInfo
          orderId={document.id}
          info={authorizationInfo}
          authorizationActionResultStatus={authorizationActionResultStatus}
        />
      </div>
    </>
  );

  function updateNotificationState(notificationsToUpdate) {
    setNotificationState(oldState => ({
      ...oldState,
      ...notificationsToUpdate,
    }));
  }

  function hideOrderNotifications() {
    updateNotificationState({
      orderNotFound: false,
      orderHasNoProducts: false,
    });
  }

  function hidePromoteNotifications() {
    updateNotificationState({
      cannotBePromoted: false,
      creditLimitExceeded: false,
    });
  }

  function hidePayInvoiceNotifications() {
    updateNotificationState({
      payInvoiceError: false,
    });
  }
};

Document.propTypes = {
  documentType: PropTypes.string,
  document: PropTypes.shape({
    documentId: PropTypes.string.isRequired,
    addresses: PropTypes.object.isRequired,
    attachments: PropTypes.array,
    totals: PropTypes.object,
    currency: PropTypes.object.isRequired,
    reportUrl: PropTypes.string,
  }),
  isB2BCustomer: PropTypes.bool,
  isPrintMode: PropTypes.bool.isRequired,
  setLoadingIndicator: PropTypes.func.isRequired,
  unsetLoadingIndicator: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  page: {
    document,
    documentType,
    isPrintMode,
  },
  user: { customerType },
}) => ({
  document,
  documentType,
  isB2BCustomer: customerType !== CustomerTypes.B2C, // sales agent also should see the fields
  isPrintMode,
});

export default connect(mapStateToProps, { setLoadingIndicator, unsetLoadingIndicator })(
  Document,
);

function useTexts(documentType) {
  const {
    texts: [
      documentTypeText,
      quoteExpiredText,
      quoteInReviewText,
      downloadingMsg,
    ],
    loaded: textsLoaded,
  } = useSanaTexts([
    `OrderType_${documentType}`,
    'ThisQuoteIsExpired',
    'ThisQuoteInReview',
    'TheReportPreparationTakesSeveralMinutes',
  ]);

  return {
    texts: {
      documentTypeText: makeSimpleText(documentTypeText),
      quoteExpiredText: makeRichText(quoteExpiredText),
      quoteInReviewText: makeRichText(quoteInReviewText),
      downloadingMsg: makeRichText(downloadingMsg),
    },
    textsLoaded,
  };
}