import 'css/Normalize.css';
import 'css/Fonts.scss';
import 'css/General.scss';
import 'css/General.print.scss';

import { memo } from 'react';
import { Layout } from 'layouts';
import { Page } from 'pages';
import { ToastProvider } from 'components/objects/toast';
import { Head, AlternateLinks, CanonicalLink } from 'components/objects/pages';
import {
  HtmlInjectionHead,
  HtmlInjectionBodyStart,
  HtmlInjectionBodyEnd,
} from 'components/objects/htmlInjections';
import { VdPageSwitcher, VdLayoutSwitcher } from 'components/visualDesigner';
import { PreloadErrorComponents } from 'components/objects/error';
import { MatchMediaProvider } from 'components/responsive';
import { MouseDetectionProvider } from 'components/detection';
import { ValidationTextsLoader } from 'components/objects/forms/validation';
import { SanaTextsProvider } from 'components/sanaText';

const App = () => (
  <SanaTextsProvider>
    <Head />
    <HtmlInjectionHead />
    <HtmlInjectionBodyStart />
    <CanonicalLink />
    <AlternateLinks />
    <MatchMediaProvider>
      <VdLayoutSwitcher>
        <MouseDetectionProvider>
          <Layout>
            <ToastProvider>
              <VdPageSwitcher>
                <Page />
              </VdPageSwitcher>
            </ToastProvider>
          </Layout>
        </MouseDetectionProvider>
        <PreloadErrorComponents />
        <ValidationTextsLoader />
      </VdLayoutSwitcher>
    </MatchMediaProvider>
    <HtmlInjectionBodyEnd />
  </SanaTextsProvider>
);

export default memo(App);
