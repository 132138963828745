import styles from '../SanaForm.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import FieldErrorCore from '../FieldError';

const FieldError = ({ fieldName, className = '' }) => (
  <div className={`${styles.validation} form-row-validation ${className}`} aria-live="polite">
    <FieldErrorCore fieldName={fieldName} />
  </div>
);

FieldError.propTypes = {
  fieldName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default memo(FieldError);
