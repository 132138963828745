import { useRef, useEffect, EffectCallback, DependencyList } from 'react';

/**
  * Executes imperative, possibly effectful code. Doesn't execute provided callback on initial render.
  *
  * @param {EffectCallback} effect Imperative function that can return a cleanup function.
  * @param {DependencyList?} dependencies If present, effect will only activate if the values in the list change.
*/
export default function useOnUpdate(effect: EffectCallback, dependencies?: DependencyList): void {
  const didMountRef = useRef<undefined | true>();

  useEffect(() => {
    if (didMountRef.current)
      return effect();
      
    didMountRef.current = true;
  }, dependencies);
}
