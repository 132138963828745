exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (min-width:var(--breakpoints_Small,600)px),print{.ContactUs_form-holder{max-width:46.75em}}@media (min-width:var(--breakpoints_Medium,960)px),print{.ContactUs_form-holder{max-width:none}}.ContactUs_toast{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"form-holder": "ContactUs_form-holder",
	"formHolder": "ContactUs_form-holder",
	"toast": "ContactUs_toast"
};