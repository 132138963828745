import animations from 'css/Animations.module.scss';

export const AnimationsMap = Object.freeze({
  NONE: null,
  FADE_IN: animations.fadeIn,
  ZOOM_IN: animations.zoomIn,
  MOVING_IN_FROM_TOP: animations.fadeInDown,
  MOVING_IN_FROM_BOTTOM: animations.fadeInUp,
  MOVING_IN_FROM_LEFT: animations.fadeInLeft,
  MOVING_IN_FROM_RIGHT: animations.fadeInRight,
});

export const VerticalAlignmentsMap = Object.freeze({
  Inherited: 'auto',
  TOP: 'start',
  MIDDLE: 'center',
  BOTTOM: 'end',
});

export const NoBorderStyleValue = 'NONE';