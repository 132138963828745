import { memo } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

const ReadonlyField = ({ fieldName }) => {
  const formik = useFormikContext();
  return getIn(formik.values, fieldName);
};

ReadonlyField.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default memo(ReadonlyField);
