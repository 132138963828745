import styles from './Header.module.scss';
import { memo, useRef, useEffect } from 'react';
import { Col, Container, Row } from 'components/primitives/grid';
import { MobileLoginInfo } from 'components/objects/loginInfo';
import { Logo } from 'components/primitives/logo';
import { Search } from 'components/objects/search';
import MobileTopMenu from './MobileTopMenu';
import OpenerContainer from './OpenerContainer';
import { BasketSummary } from 'components/objects/basket';
import ShoppingCartSuggestions from './ShoppingCartSuggestions';
import { Subject } from 'rxjs';
import { HEADER_SEARCH_BOX_ID } from './constants';

const MobileHeaderTemplate = () => {
  const menuOpenerRef = useRef();
  const loginInfoOpenerRef = useRef();
  const syncSubjectRef = useRef();

  if (!syncSubjectRef.current)
    syncSubjectRef.current = new Subject();

  useEffect(() => () => syncSubjectRef.current.complete(), []);

  return (
    <>
      <Container fluid className={`${styles.mobileTopContainer} ${styles.noGutters}`}>
        <Row crossAxisAlign="center" noGutters>
          <OpenerContainer className={styles.noGutters} ref={menuOpenerRef} />
          <Col className={`${styles.column} ${styles.logoWrapper}`}>
            <Logo className={styles.logo} small />
          </Col>
          <OpenerContainer ref={loginInfoOpenerRef} />
          <Col xs="auto">
            <BasketSummary hideLabel />
          </Col>
        </Row>
      </Container>
      <Container fluid className={`${styles.mobileMiddleContainer} ${styles.noGutters}`}>
        <MobileLoginInfo openerContainer={loginInfoOpenerRef} syncSubject={syncSubjectRef.current} />
        <Search className={styles.searchBox} id={HEADER_SEARCH_BOX_ID} />
        <ShoppingCartSuggestions />
      </Container>
      <Container fluid className={`${styles.topMenuContainer} ${styles.noGutters}`}>
        <MobileTopMenu openerContainer={menuOpenerRef} syncSubject={syncSubjectRef.current} />
      </Container>
    </>
  );
};

export default memo(MobileHeaderTemplate);
