import styles from './ChangePassword.module.scss';
import { memo, useEffect } from 'react';
import { RichText, useSimpleTexts } from 'components/sanaText';
import {
  SanaForm,
  FormGroup,
  NewPasswordFormGroup,
  useFormLockerOnSubmit,
} from 'components/objects/forms';
import { Col, Row } from 'components/primitives/grid';
import { PasswordField } from 'components/objects/forms/fields';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from 'behavior/pages/changePassword';
import { routesBuilder, RouteName } from 'routes';
import { navigateToPrevious } from 'behavior/routing';
import { toasts } from 'behavior/toasts';
import { FormLockKeys } from 'behavior/pages';
import { formatText } from 'utils/formatting';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const ChangePassword = () => {
  const [
    oldPasswordTitle,
    newPasswordTitle,
    repeatPasswordTitle,
    minPasswordPlaceholderTemplate,
    samePasswordError,
    comparisonErrorMessage,
  ] = useSimpleTexts([
    'ChangePassword_OldPassword',
    'ChangePassword_NewPassword',
    'ChangePassword_RetypePassword',
    'CreateAccount_MinPasswordLength',
    'Validation_SamePassword',
    'ChangePassword_CompareFailed',
    'PasswordSuccessfullyUpdated', // preload text
  ]).texts;

  const minLength = useSelector(({ settings: { profile } }) => profile && profile.minPasswordLength);
  const newPasswordPlaceholder = formatText(minPasswordPlaceholderTemplate, [minLength]);

  const dispatch = useDispatch();
  const passwordChanged = useSelector(state => state.page.passwordChanged);

  const { name, email } = useSelector(state => state.user);

  const onSubmit = useFormLockerOnSubmit(data => {
    dispatch(changePassword(data.old, data.new));
  }, FormLockKeys.ChangePassword, []);

  useEffect(() => {
    if (passwordChanged) {
      toasts.success(<RichText textKey="PasswordSuccessfullyUpdated" disableInsiteEditor />, { className: styles.toast });
      dispatch(
        navigateToPrevious(
          [RouteName.ChangePassword, RouteName.Login, RouteName.EditProfile],
          routesBuilder.forMyAccount(),
        ),
      );
    }
  }, [passwordChanged]);

  return (
    <>
      <div>
        <RichText textKey="ChangePassword_Description" />
      </div>
      <Row>
        <Col md={8} lg={7}>
          <SanaForm
            className={styles.form}
            name="ChangePassword"
            onSubmit={useHandlerLockerInPreview(onSubmit)}
          >
            <FormGroup
              fieldName="old"
              fieldTitle={oldPasswordTitle}
              fieldComponent={PasswordField}
              autoComplete="off"
              maxLength={30}
              required
              validation={{
                required: { allowWhitespace: true },
              }}
            />
            <NewPasswordFormGroup
              fieldName="new"
              fieldTitle={newPasswordTitle}
              placeholder={newPasswordPlaceholder}
              aria-describedby="pswd"
              additionalValidation={{
                notEqualsTo: { to: 'old', message: samePasswordError },
              }}
              userData={[name, email]}
            />
            <FormGroup
              fieldName="repeat"
              fieldTitle={repeatPasswordTitle}
              fieldComponent={PasswordField}
              autoComplete="off"
              maxLength={30}
              required
              validation={{
                required: { allowWhitespace: true },
                equalsTo: { to: 'new', message: comparisonErrorMessage },
              }}
            />
            <SanaForm.SubmitRow textKey="Save" />
          </SanaForm>
        </Col>
      </Row>
    </>
  );
};

export default memo(ChangePassword);
