import { useRef, useCallback } from 'react';
import useFormLocker from './useFormLocker';

export default function useCaptchaOnSubmit(onSubmit, formLockKey = null, dependencies) {
  const captchaRef = useRef();
  const isLocked = useFormLocker(formLockKey);

  return {
    onSubmit: useCallback((...args) => {
      const captcha = captchaRef.current;

      if (!captcha || captcha.getValue() && !isLocked())
        onSubmit(...args);
      else
        captchaRef.current.execute();
    }, dependencies),
    captchaRef,
  };
}