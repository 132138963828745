/**
 * Reusable helper to determine if navigation tree should be rendered
 * @param {array} items - navigation items
 * @returns {bool} - Returns whether the menu should be displayed
 */
export function shouldRenderNavigation(items) {
  return !!(items && items.length);
}

/**
 * Determines if event occurred on Right Arrow key press
 * @param {KeyboardEvent} e - keydown event
 * @returns {bool} - if event occurred on Right Arrow key press
 */
export function isRightArrowKeyPressed(e) {
  const { key, which } = e;
  return key === 'ArrowRight' || which === 39;
}

/**
 * Determines if event occurred on Left Arrow key press
 * @param {KeyboardEvent} e - keydown event
 * @returns {bool} - if event occurred on Left Arrow key press
 */
export function isLeftArrowKeyPressed(e) {
  const { key, which } = e;
  return key === 'ArrowLeft' || which === 37;
}