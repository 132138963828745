import { useSelector } from 'react-redux';

export default function useIsOffline() {
  return useSelector(({ app, routing }) => {
    if (routing.routeData?.params?.previewToken != null)
      return false;

    return app.offlineMode;
  });
}
