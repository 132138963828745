import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MouseDetectionContext } from './MouseDetectionContext';
import { useHasMultiplePointers, useIsTouchDevice } from 'utils/detections';

const MouseDetectionProvider = ({ children }) => {
  const [isMouse, setIsMouse] = useState(null);
  const isContextMenuTriggered = useRef(false);
  const hasMultiplePointers = useHasMultiplePointers();
  const isTouchDevice = useIsTouchDevice(); // Used for detection to work properly when switching to touch device emulation in browser devtools.

  useEffect(() => {
    const className = 'pointer-mouse';

    const handlePointerOver = e => {
      if (e.pointerType === 'mouse') {
        document.documentElement.classList.add(className);
        setIsMouse(true);
      } else {
        if (hasMultiplePointers && document.documentElement.classList.contains(className)) {
          document.addEventListener('pointermove', handlePointerMove);
          document.addEventListener('contextmenu', handleContextMenu);
        }
        document.documentElement.classList.remove(className);
        setIsMouse(false);
      }

      if (!hasMultiplePointers)
        document.removeEventListener('pointerover', handlePointerOver);
    };

    // If waiting for possible switch from non-mouse pointer to mouse pointer, mouse detection should be
    // performed faster - with pointermove event.
    const handlePointerMove = e => {
      if (e.pointerType !== 'mouse')
        return;

      if (isContextMenuTriggered.current) {
        isContextMenuTriggered.current = false;
        return;
      }

      setIsMouse(true);
      document.documentElement.classList.add(className);
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('contextmenu', handleContextMenu);
    };

    // Some browsers randomly trigger mousemove event on mouse pointer after context menu being triggered
    // with touch screen.
    const handleContextMenu = () => isContextMenuTriggered.current = true;

    document.addEventListener('pointerover', handlePointerOver);

    return () => {
      document.removeEventListener('pointerover', handlePointerOver);
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('contextmenu', handleContextMenu);
      document.documentElement.classList.remove(className);
      isContextMenuTriggered.current = false;
      setIsMouse(null);
    };
  }, [hasMultiplePointers, isTouchDevice]);

  return (
    <MouseDetectionContext.Provider value={isMouse}>
      {children}
    </MouseDetectionContext.Provider>
  );
};

MouseDetectionProvider.propTypes = {
  children: PropTypes.node,
};

export default MouseDetectionProvider;