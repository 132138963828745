import styles from './LoginInfo.module.scss';
import { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { VerticalSliding } from 'components/primitives/transitions';
import LoginInfoItems from './LoginInfoItems';
import Icon from './Icon';
import { useOnChange } from 'utils/hooks';
import { RouteName } from 'routes';
import { LOGIN_FORM_NAME } from 'components/objects/login';
import { REGISTRATION_FORM_NAME } from 'components/objects/registration';

const accordionId = 'mobileLoginInfo';
const slidingDuration = 200;

const MobileLoginInfo = ({ openerContainer, syncSubject, isLoading, routeName, isImpersonating }) => {
  const [shouldRender, setRenderState] = useState(!!openerContainer.current);
  const [opened, setOpened] = useState(false);

  const setClosed = useCallback(() => setOpened(false), []);
  const handleInfoItemsClick = useCallback(({ target: { tagName } }) => {
    if (tagName !== 'A' && tagName !== 'BUTTON')
      return;

    setOpened(false);
  }, []);

  useEffect(() => {
    setRenderState(!!openerContainer.current);
  }, [openerContainer.current]);

  useEffect(() => {
    const subscription = syncSubject.subscribe(id => {
      if (id === accordionId)
        return;

      setClosed();
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    // Perform close on Login Form submit to avoid visual appearance of changed user data.
    if (routeName !== RouteName.Login && routeName !== RouteName.Registration)
      return;

    const form = document.forms[LOGIN_FORM_NAME] || document.forms[REGISTRATION_FORM_NAME];
    form.addEventListener('submit', setClosed);

    return () => form.removeEventListener('submit', setClosed);
  }, [routeName]);

  useOnChange(() => {
    if (!isLoading)
      return;

    setClosed();
  }, [isLoading], false);

  if (!shouldRender)
    return null;

  const handleClick = () => {
    if (!opened)
      syncSubject.next(accordionId);

    setOpened(!opened);
  };

  const openerComponent = (
    <button
      className={`${styles.openerBtn}${opened ? ` ${styles.active}` : ''}`}
      onClick={handleClick}
      aria-controls={accordionId}
    >
      <Icon isImpersonating={isImpersonating} />
      <FontAwesomeIcon className={styles.caret} icon={faCaretDown} rotation={opened ? 180 : null} />
    </button>
  );

  return <>
    {ReactDOM.createPortal(openerComponent, openerContainer.current)}
    <VerticalSliding
      duration={slidingDuration}
      id={accordionId}
      expanded={opened}
      containerClass={styles.mobileContainer}
      onClick={handleInfoItemsClick}
    >
      <LoginInfoItems logoutDelay={slidingDuration} />
    </VerticalSliding>
  </>;
};

MobileLoginInfo.propTypes = {
  openerContainer: PropTypes.object.isRequired,
  syncSubject: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  routeName: PropTypes.string,
  isImpersonating: PropTypes.bool,
};

const mapStateToProps = ({
  isLoading,
  routing: { routeData },
  user: { isImpersonating },
}) => ({
  isLoading,
  routeName: routeData && routeData.routeName,
  isImpersonating,
});

export default connect(mapStateToProps)(MobileLoginInfo);
