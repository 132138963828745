import { noop } from 'rxjs';
import { removeInsiteEditorEncoding } from 'components/visualDesigner/insiteEditor';

let addToast = noop;

export const onAdd = callback => {
  addToast = callback;
};

export const toasts = {
  success(content, option, callback) {
    addToast(getToastContent(content), getToastOption(option, 'success'), callback);
  },
  error(content, option, callback) {
    addToast(getToastContent(content), getToastOption(option, 'error'), callback);
  },
  errorDialog(content, option, callback) {
    addToast(getToastContent(content), getToastOption(option, 'errorDialog'), callback);
  },
  warning(content, option, callback) {
    addToast(getToastContent(content), getToastOption(option, 'warning'), callback);
  },
  info(content, option, callback) {
    addToast(getToastContent(content), getToastOption(option, 'info'), callback);
  },
};

function getToastContent(content) {
  return typeof content === 'string'
    ? removeInsiteEditorEncoding(content)
    : content;
}

function getToastOption(option, appearance) {
  return { ...option, appearance };
}