import styles from './Lines.module.scss';
import { memo, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { QuantityTextBox, createQuantityModel } from 'components/primitives/product';
import { useCultureName } from 'utils/hooks';
import { getNumberDecimalsSeparator } from 'utils/format';

const QuantityBox = ({ id, initialQuantity, getCurrentQuantity, uom, updateQuantity }) => {
  const lineCurrentQuantity = getCurrentQuantity();
  const quantityValue = lineCurrentQuantity != null
    ? lineCurrentQuantity.value
    : initialQuantity;

  const [, forceUpdate] = useState();
  const handleQuantityChange = useCallback(quantity => {
    updateQuantity(quantity);
    forceUpdate(quantity);
  }, [updateQuantity]);

  const quantityModel = useMemo(() => createQuantityModel(uom, false), [uom]);
  const culture = useCultureName();
  const separator = getNumberDecimalsSeparator(culture);

  return (
    <div className={styles.quantity}>
      <QuantityTextBox
        id={id}
        quantity={quantityModel}
        value={quantityValue}
        onChange={handleQuantityChange}
        separator={separator}
        hideControlsOnBlur
        showControlsUnderInputMd
      />
    </div>
  );
};

QuantityBox.propTypes = {
  id: PropTypes.string.isRequired,
  initialQuantity: PropTypes.number,
  getCurrentQuantity: PropTypes.func.isRequired,
  uom: PropTypes.object,
  updateQuantity: PropTypes.func.isRequired,
};

export default memo(QuantityBox);