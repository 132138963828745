import btnStyles from 'components/primitives/buttons/Button.module.scss';
import styles from '../Order.module.scss';
import { useState, useCallback } from 'react';
import { useEffectOnChange } from 'utils/hooks';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { useSelector, useDispatch } from 'react-redux';
import { createInvoiceOrder } from 'behavior/pages/invoicePayment';

const PayInvoiceButton = ({ invoice, documentNotifications }) => {
  const [pending, setPending] = useState(false);
  const dispatch = useDispatch();

  const createInvoiceOrderResult = useSelector(({ page }) => page.createInvoiceOrderResult);

  useEffectOnChange(() => {
    if (!createInvoiceOrderResult)
      return;

    setPending(false);
    if (createInvoiceOrderResult.error)
      documentNotifications.payInvoiceError();
  }, [createInvoiceOrderResult]);

  const payInvoicesHandler = useCallback(() => {
    const { id, orderId } = invoice;
    documentNotifications.hidePayInvoiceNotifications();
    setPending(true);
    dispatch(createInvoiceOrder([{ id, orderId }]));
  }, [invoice]);

  return (
    <SanaButton
      className={`${btnStyles.btn} ${btnStyles.btnAction} ${btnStyles.btnSmall} ${styles.btnAction} ${styles.btnPay}`}
      textKey="PayInvoice"
      disabled={pending}
      onClick={payInvoicesHandler}
    />
  );
};

PayInvoiceButton.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    orderId: PropTypes.string,
  }).isRequired,
  documentNotifications: PropTypes.shape({
    payInvoiceError: PropTypes.func.isRequired,
    hidePayInvoiceNotifications: PropTypes.func.isRequired,
  }),
};

export default PayInvoiceButton;