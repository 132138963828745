import styles from '../Orders.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import InvoicesPaymentContext from './InvoicesPaymentContext';
import { FormattedPrice } from 'components/primitives/price';
import { Button } from 'components/primitives/buttons';
import { useSelector, useDispatch } from 'react-redux';
import { createInvoiceOrder } from 'behavior/pages/invoicePayment';

const PayInvoicesButton = ({ texts, showPrices }) => {
  const context = useContext(InvoicesPaymentContext);
  const { selectedInvoices, currency } = context;

  const [pending, setPending] = useState(false);
  const dispatch = useDispatch();
  const createInvoiceOrderResult = useSelector(({ page }) => page.createInvoiceOrderResult);

  useEffect(() => {
    if (createInvoiceOrderResult)
      setPending(false);
  }, [createInvoiceOrderResult]);

  const total = showPrices && selectedInvoices
    .reduce((total, { totals: { outstandingAmount } }) => total + outstandingAmount, 0);

  const payInvoicesHandler = useCallback(() => {
    const invoices = selectedInvoices.map(({ id, orderId }) => ({ id, orderId }));

    setPending(true);
    dispatch(createInvoiceOrder(invoices));
  }, [selectedInvoices]);

  return (
    <div className={`${styles.payContainer}${showPrices ? ` ${styles.withTotal}` : ''}`}>
      {showPrices &&
        <div className={styles.totalsContainer}>
          <span>{texts.totalText}</span>
          <span className={styles.totalPrice}>
            <FormattedPrice price={total} currencyInfo={currency} />
          </span>
        </div>
      }
      <Button
        className={`${btnStyles.btn} ${btnStyles.btnAction}`}
        disabled={pending}
        onClick={payInvoicesHandler}
      >
        {texts.payButtonText}
      </Button>
    </div>
  );
};

PayInvoicesButton.propTypes = {
  texts: PropTypes.shape({
    totalText: PropTypes.string,
    payButtonTitle: PropTypes.string,
    payButtonText: PropTypes.string,
  }).isRequired,
  showPrices: PropTypes.bool.isRequired,
};

export default PayInvoicesButton;