import styles from '../Checkout.module.scss';
import { useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import CheckoutContext from './CheckoutContext';
import { Steps, selectShippingMethod } from 'behavior/pages/checkout';
import { ShippingMethodStep as ShippingMethodStepBase, ShippingMethodStepBody } from '../base/shipping';

const ShippingMethodStep = ({ info, isCompleted }) => {
  const dispatch = useDispatch();
  const { setLoading, registerStep } = useContext(CheckoutContext);

  useEffect(() => {
    registerStep(Steps.Shipping, styles.shippingMethod, [Steps.Address, Steps.Shipping]);
  }, []);

  const onSelect = useCallback((id, locationId = null) => {
    if (id === info.shippingMethodId && info.pickupLocation?.id === locationId)
      return;

    if (!locationId && info.shippingMethods.find(m => m.id === id).hasLocations)
      return;

    dispatch(selectShippingMethod(id, locationId));
    setLoading(Steps.Shipping);
  }, [info.shippingMethods, info.shippingMethodId, info.pickupLocation]);

  return (
    <ShippingMethodStepBase
      className={styles.shippingMethod}
      isQuote={info.isQuote}
      isCompleted={isCompleted}
    >
      <ShippingMethodStepBody
        shippingMethods={info.shippingMethods}
        selectedMethodId={info.shippingMethodId}
        selectedLocationId={info.pickupLocation?.id}
        locations={info.pickupLocations}
        isQuote={info.isQuote}
        totalGrossWeight={info.totalGrossWeight}
        onSelect={onSelect}
      />
    </ShippingMethodStepBase>
  );
};

ShippingMethodStep.propTypes = {
  info: PropTypes.shape({
    isQuote: PropTypes.bool,
    totalGrossWeight: PropTypes.number,
    shippingMethods: PropTypes.array,
    selectedMethodId: PropTypes.string,
  }).isRequired,
  isCompleted: PropTypes.bool,
};

export default ShippingMethodStep;
