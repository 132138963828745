import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const UseVisualDesignerTexts = ({ textKeys, texts, children }) => {
  const resultTexts = textKeys.map(x => {
    if (x in texts)
      return texts[x];

    return null;
  });
  return children(resultTexts);
};

UseVisualDesignerTexts.propTypes = {
  textKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  texts: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
};

export default connect(
  ({ visualDesigner }) => ({
    texts: visualDesigner.texts,
  }),
)(UseVisualDesignerTexts);