import { NEWSLETTER_UPDATED, NEWSLETTER_SUBSCRIBE, NEWSLETTER_UNSUBSCRIBE } from './actions';
import { createReducer } from 'utils/redux';

const initialState = {
  lastUpdated: null,
  updatedById: null,
};

export default createReducer(initialState, {
  [NEWSLETTER_SUBSCRIBE]: onNewsletterChange,
  [NEWSLETTER_UNSUBSCRIBE]: onNewsletterChange,
  [NEWSLETTER_UPDATED]: onNewsletterUpdated,
});

function onNewsletterChange(state, _action) {
  return {
    ...state,
    updatedById: null,
  };
}

function onNewsletterUpdated(_state, action) {
  return {
    updatedById: action.payload.updatedById,
    lastUpdated: new Date(),
  };
}