exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ShoppingCartSuggestions_suggestions-btn{display:flex;justify-content:center;align-items:center;margin:0 var(--gutterWidth,16px) 15px;padding:7px 10px;min-width:auto;min-height:auto}.ShoppingCartSuggestions_suggestions-btn .ShoppingCartSuggestions_icon{max-width:.28em;max-height:.28em}.ShoppingCartSuggestions_suggestions-btn.ShoppingCartSuggestions_hide-label{padding:0;margin:0;width:44px;height:44px}.ShoppingCartSuggestions_suggestions-btn.ShoppingCartSuggestions_hide-label .ShoppingCartSuggestions_icon{max-width:20px;max-height:20px}.ShoppingCartSuggestions_suggestions-btn .btn-cnt{margin-left:.6em;padding-top:0}", ""]);

// exports
exports.locals = {
	"suggestions-btn": "ShoppingCartSuggestions_suggestions-btn",
	"suggestionsBtn": "ShoppingCartSuggestions_suggestions-btn",
	"icon": "ShoppingCartSuggestions_icon",
	"hide-label": "ShoppingCartSuggestions_hide-label",
	"hideLabel": "ShoppingCartSuggestions_hide-label"
};