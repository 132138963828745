import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { switchMap, map, mergeMap, startWith } from 'rxjs/operators';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import {
  AGREEMENT_APPLY,
  AGREEMENT_CANCEL,
  agreementApplied,
  agreementCanceled,
  AGREEMENT_APPLIED,
  AGREEMENT_CANCELED,
  appliedAgreementReceived,
} from './actions';
import {
  AGREEMENTS_REQUESTED,
} from 'behavior/pages/salesAgreements/actions';
import {
  applySalesAgreementMutation,
  cancelSalesAgreementMutation,
  appliedSalesAgreementQuery,
} from './queries';
import { retryWithToast } from 'behavior/errorHandling';
import { BASKET_UPDATED } from 'behavior/basket/actions';

export default function salesAgreementsEpic(action$, _state$, { api, logger }) {
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  const apply$ = action$.pipe(
    ofType(AGREEMENT_APPLY),
    switchMap(({ payload }) =>
      api.graphApi(applySalesAgreementMutation, { agreementId: payload.salesAgreementId }).pipe(
        map(({ salesAgreement }) => {
          if (salesAgreement.apply.success)
            return agreementApplied(payload.salesAgreementId);

          throw new Error(`Sales agreement with ID/Title: "${payload.salesAgreementId}" is not available anymore.`);
        }),
    )),
  );

  const cancel$ = action$.pipe(
    ofType(AGREEMENT_CANCEL),
    switchMap(() =>
      api.graphApi(cancelSalesAgreementMutation).pipe(
        map(() => agreementCanceled()),
    )),
  );

  const appliedAgreement$ = action$.pipe(
    ofType(AGREEMENTS_REQUESTED, AGREEMENT_CANCELED, AGREEMENT_APPLIED, BASKET_UPDATED),
    switchMap(() =>
      api.graphApi(appliedSalesAgreementQuery).pipe(
        mergeMap(({ basket }) => [unsetLoading, appliedAgreementReceived(basket.salesAgreementId)]),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
    )),
  );

  return merge(apply$, cancel$, appliedAgreement$);
}

