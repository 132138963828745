exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Spacer_body{height:100%;min-height:26px}.Spacer_body:before{content:\"\";position:absolute;background:url(\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo5RTQ4QTRFMTFFNUJFQjExOTQzMUU2QTI3RTNBNjFGNSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3QUUyQUY0ODVCMjYxMUVCQjQ5NENEMEY0QzY2N0UyNSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3QUUyQUY0NzVCMjYxMUVCQjQ5NENEMEY0QzY2N0UyNSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjIxRTYxNjc1MjY1QkVCMTE4NDg1QjFBRUQ4QzNCODQxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjlFNDhBNEUxMUU1QkVCMTE5NDMxRTZBMjdFM0E2MUY1Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+WLQ/BQAAADNJREFUeNpi/PDhw34GPICfn78RnzwTA4Vg1IDBYADj////HfAp+PjxY/1oIA57AwACDAA1fArrR+I0sQAAAABJRU5ErkJggg==\");width:100%;height:100%}", ""]);

// exports
exports.locals = {
	"body": "Spacer_body"
};