import styles from './RadioButtonGroup.module.scss';
import PropTypes from 'prop-types';
import Radio from './Radio';

const RadioButtonGroup = ({ id, value: selectedValue, items, onChange = null, ...attributes }) => {
  if (!items || !items.length)
    return null;

  return items.map(({ name, value }) => {
    const radioButtonId = `${id}_${value}`;

    return (
      <Radio
        key={radioButtonId}
        id={radioButtonId}
        value={value}
        onChange={onChange && (() => onChange(value))}
        {...attributes}
        checked={selectedValue === value}
        className={styles.item}
      >
        {name}
      </Radio>
    );
  });
};

RadioButtonGroup.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.node,
    value: PropTypes.any,
  })),
  onChange: PropTypes.func,
};

export default RadioButtonGroup;