import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useCallback } from 'react';
import { addonAction } from '../actions';

const memoizeWrapper = wrap => {
  let lastWrappable = undefined;
  let lastWrapper = undefined;

  return wrappable => {
    if (lastWrappable !== wrappable) {
      lastWrappable = wrappable;
      lastWrapper = wrap(wrappable);
    }

    return lastWrapper;
  };
};

const createMapStateWrapper = selectState => {
  function wrapMapState(mapStateToProps) {
    if (mapStateToProps) {
      return mapStateToProps.length > 1
        ? (state, ownProps) => {
          const result = mapStateToProps(selectState(state), ownProps);
          if (typeof result === 'function')
            return wrapMapState(result);

          return result;
        }
        : state => {
          const result = mapStateToProps(selectState(state));
          if (typeof result === 'function')
            return wrapMapState(result);

          return result;
        };
    }

    return mapStateToProps;
  }

  return wrapMapState;
};

const createMapDispatchWrapper = wrapDispatch => mapDispatchToProps => {
  if (!mapDispatchToProps) {
    return dispatch => ({ dispatch: wrapDispatch(dispatch) });
  }

  if (typeof mapDispatchToProps === 'object') {
    return dispatch => {
      const wrappedDispatch = wrapDispatch(dispatch);

      return ({
        ...bindActionCreators(mapDispatchToProps, wrappedDispatch),
        dispatch: wrappedDispatch,
      });
    };
  }

  return (dispatch, ownProps) => {
    const wrappedDispatch = wrapDispatch(dispatch);

    return {
      ...mapDispatchToProps(wrappedDispatch, ownProps),
      dispatch: wrappedDispatch,
    };
  };
};

function createAddonConnect(selectState, wrapDispatch) {
  const wrapMapState = createMapStateWrapper(selectState);
  const wrapMapDispatch = createMapDispatchWrapper(wrapDispatch);

  return (
    mapStateToProps = undefined,
    mapDispatchToProps = undefined,
    mergeProps = undefined,
    options = undefined,
  ) => connect(
    wrapMapState(mapStateToProps),
    wrapMapDispatch(mapDispatchToProps, wrapDispatch),
    mergeProps,
    options,
  );
}

const createUseSelectorHook = selectState => (selector, equalityFn = undefined) => {
  const scopeSelector = useCallback(state => selector(selectState(state)), [selector]);
  return useSelector(scopeSelector, equalityFn);
};

const createUseDispatchHook = wrapDispatch => () => wrapDispatch(useDispatch());

export default function (addonId) {
  const selectState = state => state.addons.scopes[addonId];
  const wrapDispatch = memoizeWrapper(dispatch => action => dispatch(addonAction(addonId, action)));

  return {
    connect: createAddonConnect(selectState, wrapDispatch),
    useDispatch: createUseDispatchHook(wrapDispatch),
    useSelector: createUseSelectorHook(selectState),
    shallowEqual,
  };
}