import styles from '../../Checkout.module.scss';
import PropTypes from 'prop-types';
import StepSummary from './StepSummary';
import { SimpleText, SanaTextContainer } from 'components/sanaText';
import { renderHTML } from 'utils/render';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { shallowEqual, useSelector } from 'react-redux';
import { CustomerTypes } from 'behavior/user';
import { useCurrentRouteAsBackTo, useIsOffline } from 'utils/hooks';
import { Steps } from 'behavior/pages/checkout';
import { toUrlHash } from 'utils/url';
import { makeSimpleText } from 'utils/render';

const SummaryInfo = ({
  info,
  showShippingAddress,
  showBillingAddress,
  showEmailAddress,
  showChangeLinks,
  showShipping,
  showPayment,
  showExtraPayment,
}) => {
  const backTo = useCurrentRouteAsBackTo();
  const editProfileRoute = routesBuilder.forEditProfile(backTo);
  const onlineMode = !useIsOffline();

  const { isB2C, customerEmail } = useSelector(({ user: { customerType, customer } }) => ({
    isB2C: customerType === CustomerTypes.B2C,
    customerEmail: customer && customer.email,
  }), shallowEqual);

  const email = info.isGuest ? info.email : customerEmail;

  const shippingMethodName =
    showShipping &&
    info.shippingMethodId &&
    info.shippingMethods.find(method => method.id === info.shippingMethodId)?.name;
  const pickupLocationAddress = info.pickupLocation && info.pickupLocation.address;

  const paymentMethodName =
    showPayment &&
    info.paymentMethodId &&
    info.paymentMethods.find(m => m.id === info.paymentMethodId)?.name;

  return (
    <dl className={styles.summary}>
      {showShippingAddress && (
        <StepSummary
          title={<SimpleText textKey="ShippingAddress" />}
          value={info.shippingAddress?.address ? renderHTML(info.shippingAddress.address.formatted) : <SimpleText textKey="EnterInformation" />}
          className={styles.shipping}
          link={showChangeLinks && renderEditLink('CheckoutSummary_ChangeShippingAddress', null)}
        />
      )}
      {showBillingAddress &&
        <StepSummary
          title={<SimpleText textKey="BillingAddress" />}
          value={info.billingAddress ? renderHTML(info.billingAddress.formatted) : <SimpleText textKey="EnterInformation" />}
          className={styles.billing}
          link={showChangeLinks && (info.canEditBilling && onlineMode || info.isGuest && showShippingAddress) && (
            <Link to={info.isGuest ? routesBuilder.forCheckout(false, null, true) : editProfileRoute}>
              <SimpleText textKey="CheckoutSummary_ChangeBillingAddress" />
            </Link>
          )}
        />
      }
      {showShipping &&
        <StepSummary
          title={<SimpleText textKey="CheckoutSummary_ShippingMethods" />}
          value={shippingMethodName
            ? <>{shippingMethodName}{pickupLocationAddress && <div>{pickupLocationAddress}</div>}</>
            : <SimpleText textKey="SelectShipping" />}
          className={styles.summaryShippingMethod}
          link={showChangeLinks && renderEditLink('CheckoutSummary_ChangeShipping', Steps.Shipping)}
        />
      }
      {showPayment &&
        <StepSummary
          title={<SimpleText textKey="CheckoutSummary_PaymentMethods" />}
          value={paymentMethodName ?? <SimpleText textKey="SelectPayment" />}
          className={styles.payment}
          link={showChangeLinks && renderEditLink('CheckoutSummary_ChangePayment', Steps.Payment)}
        />
      }
      {showExtraPayment &&
        <StepSummary
          title={(
            <SanaTextContainer textKey={info.extraPaymentStep.titleKey}>
              {makeSimpleText(info.extraPaymentStep.title)}
            </SanaTextContainer>
          )}
          value={info.extraPaymentStep.summaryLine}
          className={styles.summaryExtraPayment}
          link={showChangeLinks && renderEditLink('CheckoutSummary_ChangeExtraPaymenInfo', Steps.ExtraPayment)}
        />
      }
      {showEmailAddress && isB2C && !!email &&
        <StepSummary
          title={<SimpleText textKey="EmailAddress" />}
          value={email}
          className={styles.emailAddress}
          link={showChangeLinks && info.canEditEmail && onlineMode && (
            <Link to={editProfileRoute}>
              <SimpleText textKey="CheckoutSummary_ChangeEmailAddress" />
            </Link>
          )}
        />
      }
    </dl>
  );

  // eslint-disable-next-line react/no-multi-comp
  function renderEditLink(textKey, stepId) {
    return (
      <Link
        to={info.quote ? routesBuilder.forQuotePromotion(stepId) : routesBuilder.forCheckout(info.asQuote, stepId, info.isGuest)}
        url={toUrlHash(stepId)}
      >
        <SimpleText textKey={textKey} />
      </Link>
    );
  }
};

SummaryInfo.propTypes = {
  info: PropTypes.shape({
    asQuote: PropTypes.bool,
    billingAddress: PropTypes.shape({
      formatted: PropTypes.string.isRequired,
    }),
    shippingAddress: PropTypes.shape({
      address: PropTypes.shape({
        formatted: PropTypes.string.isRequired,
      }),
    }),
    paymentMethods: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired),
    paymentMethodId: PropTypes.string,
    canEditBilling: PropTypes.bool,
    canEditEmail: PropTypes.bool,
    shippingMethodId: PropTypes.string,
    shippingMethods: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })),
    extraPaymentStep: PropTypes.shape({
      titleKey: PropTypes.string.isRequired,
      summaryLine: PropTypes.string,
    }),
    pickupLocation: PropTypes.shape({
      address: PropTypes.string,
    }),
    isGuest: PropTypes.bool,
    quote: PropTypes.object,
  }).isRequired,
  showShippingAddress: PropTypes.bool,
  showBillingAddress: PropTypes.bool,
  showEmailAddress: PropTypes.bool,
  showChangeLinks: PropTypes.bool,
  showShipping: PropTypes.bool,
  showPayment: PropTypes.bool,
  showExtraPayment: PropTypes.bool,
};

SummaryInfo.formatAddressFromCheckoutInfo = info =>
  info.shippingAddress.address && renderHTML(info.shippingAddress.address.formatted);

export default SummaryInfo;
