import { resetPasswordPageQuery, userDataQuery } from './queries';
import { mergeMap, switchMap, pluck, first, map } from 'rxjs/operators';
import { RouteName, routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { getBackTo } from '../helpers';
import { StoreType } from 'behavior/settings';
import { initSystemPageContent } from '../system';
import { loadSystemPageQuery } from '../system/queries';
import { decapitalize } from 'utils/helpers';

export default (routeData, state$, { api }) => {
  return state$.pipe(
    pluck('settings'),
    first(settings => settings.loaded),
    switchMap(
      settings => settings.storeType === StoreType.Closed
        ? loadClosedStoreResetPasswordPage(routeData, state$, api)
        : loadSystemResetPasswordPage(routeData, api),
    ),
  );
};

function loadClosedStoreResetPasswordPage(routeData, state$, api) {
  const component = PageComponentNames.ClosedStoreResetPassword;

  if (routeData.params.previewToken)
    return loadResetPasswordPreviewPage(api, { emptyLayout: true, component });

  return api.graphApi(userDataQuery, { token: routeData.params.token }).pipe(
    mergeMap(({ profile }) => {
      if (!profile.userData)
        return of({ action$: of(navigateTo(routesBuilder.forForgotPassword(), undefined, { replaceHistory: true })) });

      return of({
        page: {
          emptyLayout: true,
          component,
          userData: profile.userData,
          token: routeData.params.token,
          backTo: getBackTo(state$, [RouteName.ResetPassword], routeData.params.language),
        },
      });
    }),
  );
}

function loadSystemResetPasswordPage(routeData, api) {
  const component = PageComponentNames.ResetPassword;

  if (routeData.params.previewToken)
    return loadResetPasswordPreviewPage(api, { component });

  return api.graphApi(resetPasswordPageQuery, { token: routeData.params.token }).pipe(
    mergeMap(({ profile: { userData }, pages }) => {
      if (!userData)
        return of({ action$: of(navigateTo(routesBuilder.forForgotPassword(), undefined, { replaceHistory: true })) });

      const page = pages.resetPassword;
      if (!page)
        return null;

      page.component = component;
      page.userData = userData;
      page.token = routeData.params.token;

      return of({ page });
    }),
    initSystemPageContent(),
  );
}

function loadResetPasswordPreviewPage(api, pageDefaults) {
  return api.graphApi(loadSystemPageQuery(decapitalize(RouteName.ResetPassword))).pipe(
    map(({ pages: { resetPassword: page } }) => !page ? null : ({
      page: { ...pageDefaults, ...page, userData: {}, token: '' },
    })),
    initSystemPageContent(),
  );
}
