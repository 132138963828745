import type { StyleModule, StyleOptions } from './types';
type StyleInfo = { usageCounter: number; stylesNumber: number };
const _inserted: Record<string, StyleInfo> = {};
const priorityAttr = 'data-priority';

// Base64 encoding and decoding - The "Unicode Problem"
// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
function b64EncodeUnicode(str: string) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_m, p1) =>
      String.fromCharCode(Number(`0x${p1}`)),
    ),
  );
}

/**
 * Remove style/link elements for specified node IDs
 * if they are no longer referenced by UI components.
 * @param {Array<string>} ids List of IDs to remove.
 * @param {Record<string, StyleInfo>} inserted Map between inserted IDs and information of their usage.
 */
function removeCss(ids: string[], inserted: Record<string, StyleInfo>) {
  ids.forEach(id => {
    const info = inserted[id];
    if (--info.usageCounter <= 0) {
      for (let i = 0; i < info.stylesNumber; i++) {
        const elem = document.getElementById(id + i);
        if (elem?.parentNode)
          elem.parentNode.removeChild(elem);
      }
    }
  });
}

function _insertElement(id: string, cssText: string, media: string | null, priority: number | null) {
  let elem = document.getElementById(id);
  let create = false;

  if (!elem) {
    create = true;
    elem = document.createElement('style');
    elem.id = id;
    if (media) {
      elem.setAttribute('media', media);
    }
  }

  elem.textContent = cssText;

  if (create) {
    if (priority == null) {
      document.head.appendChild(elem);
      return;
    }

    elem.setAttribute(priorityAttr, priority.toString());
    let referenceNode: HTMLStyleElement | null | undefined;

    for (const existingStyleNode of document.head.getElementsByTagName('style')) {
      const dataPriority = existingStyleNode.getAttribute(priorityAttr);
      if (dataPriority && +dataPriority > priority) {
        referenceNode = existingStyleNode;
        break;
      }
    }

    if (!referenceNode)
      referenceNode = document.head.querySelector<HTMLStyleElement>(`style:not([${priorityAttr}])`);

    if (referenceNode)
      document.head.insertBefore(elem, referenceNode);
    else
      document.head.appendChild(elem);
  }
}

/**
 * Insert CSS styles object generated by `css-loader` into DOM
 * Example:
 *   var removeCss = insertCss([[1, 'body { color: red; }']]);
 *   // Remove it from the DOM
 *   removeCss();
 * @param {Array<StyleModule>} styleModules Styles to insert.
 * @param {StyleOptions} options Options.
 * @param {CallableFunction} [insertElement] Function used to insert style element into DOM. You can specify own function for example to collect styles in the Map.
 * @param {Record<string, StyleInfo>} [inserted] Map-object between inserted IDs and information of their usage.
 * @returns {CallableFunction} function to remove inserted styles.
 */
function insertCss(styleModules: StyleModule[], options?: StyleOptions, insertElement = _insertElement, inserted = _inserted) {
  const ids = [];
  const { replace = false, prefix = 's', prepareCss, priorities } = options || {};

  for (const styleModule of styleModules) {
    const moduleId = styleModule._moduleId;
    const id = prefix + moduleId;

    ids.push(id);

    if (inserted[id] && inserted[id].usageCounter > 0) {
      if (!replace) {
        inserted[id].usageCounter++;
        continue;
      }
    }

    styleModule._insertionOptions = Object.assign({}, options);
    const styles = styleModule._content;
    inserted[id] = { usageCounter: 1, stylesNumber: styles.length };

    for (let i = 0; i < styles.length; i++) {
      const [, css, media, sourceMap] = styles[i];

      let cssText = css;
      if (prepareCss)
        cssText = prepareCss(css);
      if (sourceMap && typeof btoa === 'function') {
        // skip IE9 and below, see http://caniuse.com/atob-btoa
        cssText += `\n/*# sourceMappingURL=data:application/json;base64,${b64EncodeUnicode(
          JSON.stringify(sourceMap),
        )}*/`;
        cssText += `\n/*# sourceURL=${sourceMap.file}?${id}*/`;
      }

      const priority = getPriority(moduleId, priorities);
      insertElement(id + i, cssText, media, priority);
    }
  }

  return removeCss.bind(null, ids, inserted);
}

function getPriority(moduleId: string, priorities: string[] | undefined) {
  if (!priorities)
    return null;
  const result = priorities.indexOf(moduleId);
  return result === -1 ? null : result;
}

export default insertCss;
