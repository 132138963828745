exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductsGroup_wrapper{width:100%;margin:4.37em 0;line-height:1.3}.ProductsGroup_heading,.ProductsGroup_heading-placeholder{display:inline-block}.ProductsGroup_heading{cursor:text;text-transform:uppercase;color:var(--tabHeader_Active_FontColor,#333);font-family:var(--tabHeader_FontFamily,\"Pathway Gothic One\"),sans-serif;font-size:var(--tabHeader_FontSize,2.3em);margin:0}.ProductsGroup_heading-placeholder{width:14em;font-size:var(--normalText_FontSize,13px);height:var(--tabHeader_FontSize,2.3em);vertical-align:bottom}@media (min-width:var(--breakpoints_Small,600)px),print{.ProductsGroup_wrapper>header{padding-bottom:.62em}}", ""]);

// exports
exports.locals = {
	"wrapper": "ProductsGroup_wrapper",
	"heading": "ProductsGroup_heading",
	"heading-placeholder": "ProductsGroup_heading-placeholder",
	"headingPlaceholder": "ProductsGroup_heading-placeholder"
};