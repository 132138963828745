import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { LazyImage } from 'components/primitives/responsiveImages';

const ProductThumbnail = ({ src, noImageSrc, title, ...props }) => {
  const { texts: [productNoImageText] } = useSanaTexts(['Product_NoImage'], makeSimpleText);

  if (!src && !noImageSrc)
    return null;

  const imgTitle = src ? title : productNoImageText;

  return <LazyImage src={src || noImageSrc} title={imgTitle} alt={imgTitle} {...props} />;
};

ProductThumbnail.propTypes = {
  src: PropTypes.string,
  noImageSrc: PropTypes.string,
  title: PropTypes.string,
};

export default memo(ProductThumbnail);