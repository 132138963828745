import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo } from 'react';
import { useSanaTexts } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { Link } from 'components/primitives/links';
import { useCurrentRouteAsBackTo } from 'utils/hooks';

export default memo(() => {
  const [
    succeedTitle,
    succeedDescription,
    wishListLink,
  ] = useSanaTexts([
    'WishList_ProductsAddedSuccessfully',
    'WishList_ProductSuccessfullyAddedPopupDescription',
    'GoToWishList',
  ]).texts;

  const backTo = useCurrentRouteAsBackTo();

  return (
    <>
      <h2>{succeedTitle}</h2>
      {succeedDescription}
      <div>
        <Link
          to={routesBuilder.forWishList}
          options={{ backTo }}
          className={`${linkStyles.link} ${linkStyles.arrowed}`}
        >
          {wishListLink}
        </Link>
      </div>
    </>
  );
});