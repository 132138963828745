import createOffers, { ProductWithCalculatedInfo } from './offers';
import createProductImages, { ProductWithImages } from './productImages';
import createReview, { ProductWithReviews } from './review';
import { stripHtmlTagsAndFormatting } from '../helpers';
import { flatMap } from 'utils/helpers';
import { createUrl } from '../url';
import type { ProductTrackingOptions } from './types';

interface Product extends ProductWithCalculatedInfo, ProductWithImages, ProductWithReviews {
  categoriesPaths?: null | Array<{
    categories: Array<{ name: string }>;
  }>;
  relatedProductGroups?: Array<{ products: Product[] }>;
}

export default function createProduct(product: Product, options: ProductTrackingOptions) {
  const { origin } = options;
  const { id, title: name, url, description, categoriesPaths, relatedProductGroups } = product;

  const schema: Record<string, unknown> = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name,
    productID: id,
    sku: id,
    url: createUrl(url, origin),
    ...createProductImages(product, options),
    ...createOffers(product, options),
    ...createReview(product),
  };

  if (description)
    schema.description = stripHtmlTagsAndFormatting(description);

  if (categoriesPaths && categoriesPaths.length)
    schema.category = categoriesPaths.map(path => path.categories.map(c => c.name).join(' > '));

  if (!relatedProductGroups || !relatedProductGroups.length)
    return schema;

  schema.isRelatedTo = flatMap(
    relatedProductGroups,
    ({ products }) => products,
    product => {
      const schema = createProduct(product, options);
      delete schema['@context'];

      return schema;
    },
  );

  return schema;
}
