import PropTypes from 'prop-types';

const SanaTextContainer = props => props.children || null;

SanaTextContainer.propTypes = {
  textKey: PropTypes.string.isRequired,
  children: PropTypes.string,
};

export default SanaTextContainer;
