import PropTypes from 'prop-types';
import InnerLine from './InnerLine';
import { renderHTML } from 'utils/render';

const CommentLine = ({ comment, lineTexts }) => {
  if (!comment)
    return null;

  return (
    <InnerLine value={renderHTML(comment)} lineTexts={lineTexts} />
  );
};

CommentLine.propTypes = {
  comment: PropTypes.string,
  lineTexts: PropTypes.object.isRequired,
};

export default CommentLine;
