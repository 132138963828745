import { useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { connect } from 'react-redux';
import { CustomerTypes } from 'behavior/user';
import { loadLines } from 'behavior/pages/document';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { isDocumentPromotableQuote, DocumentType } from 'behavior/documents';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';
import { useIsMobileViewport, usePrintMode } from 'utils/hooks';
import MobileTable from './MobileTable/MobileTable';
import DesktopTable from './DesktopTable/DesktopTable';
import { documentTypePropType } from '../propTypes';

const LinesTable = ({
  document,
  documentType,
  isB2CCustomer,
  showShippingStatus,
  currencyInfo,
  cancelled,
  loadLines,
}) => {
  const isPrintMode = usePrintMode();
  const lineTexts = useLinesTexts();

  const [canViewPrices, showUOM, canPromoteQuote] = useHasAbilities(
    AbilityTo.ViewPrices,
    AbilityTo.ViewUnitOfMeasure,
    AbilityTo.PromoteQuote,
  );

  const showPrices = canViewPrices
    && documentType !== DocumentType.ReturnOrder
    && documentType !== DocumentType.ReturnReceipt
    && documentType !== DocumentType.Shipment;
  const { id: internalDocumentId, orderId, lines } = document;

  const showDiscounts = useMemo(() => {
    return showPrices
      && lines.itemLines.some(({ discountPercentage, sublines }) =>
        discountPercentage > 0 ||
        (sublines && sublines.some(subline => subline.discountPercentage > 0)));
  }, [showPrices, lines.itemLines]);

  const showExtraColumns = !isB2CCustomer && documentType === DocumentType.Order
    || documentType === DocumentType.Quote
    || documentType === DocumentType.ReturnOrder;
  const showStock = canPromoteQuote && isDocumentPromotableQuote(document, documentType);

  const [itemsFolded, setItemsFolded] = useState(true);
  const linesLoadingRef = useRef(false);
  const initialLinesLoadedRef = useRef(!!lines.itemLines.length);
  const isMobile = useIsMobileViewport();

  const loadDocumentLines = () => {
    if (linesLoadingRef.current)
      return;

    toggleOverflowAnchorState(true);

    setItemsFolded(!itemsFolded);

    if (lines.itemLines.length)
      return;

    linesLoadingRef.current = true;
    loadLines(internalDocumentId, documentType, orderId);
  };

  useEffect(() => {
    if (!lines.itemLines.length && lines.totalCount)
      return;

    linesLoadingRef.current = false;
    setItemsFolded(false);
  }, [lines.itemLines]);

  const options = useMemo(() => {
    let columnsCount = 3; // ID + title + quantity cells

    /* eslint-disable nonblock-statement-body-position */
    if (showShippingStatus) columnsCount++;
    if (!isB2CCustomer) columnsCount += 2; // shipment date + outstanding quantity cells
    if (showPrices) columnsCount += 2; // price + total cells
    if (showDiscounts) columnsCount++;
    if (showUOM) columnsCount++;
    if (showStock) columnsCount++;
    if (showExtraColumns) columnsCount += 2; // invoiced + shipped quantity cells
    /* eslint-enable nonblock-statement-body-position */

    return {
      showShippingStatus,
      isB2CCustomer,
      showPrices,
      showDiscounts,
      showUOM,
      showStock,
      showExtraColumns,
      currencyInfo,
      columnsCount,
    };
  }, [
    showShippingStatus,
    isB2CCustomer,
    showPrices,
    showDiscounts,
    showUOM,
    showStock,
    showExtraColumns,
    currencyInfo,
  ]);

  if (!isMobile || isPrintMode)
    return (
      <DesktopTable
        lines={lines}
        loadLines={loadDocumentLines}
        options={options}
        itemsFolded={itemsFolded}
        initialLinesLoaded={initialLinesLoadedRef.current}
        lineTexts={lineTexts}
        cancelled={cancelled}
      />
    );

  return (
    <MobileTable
      lines={lines}
      loadLines={loadDocumentLines}
      options={options}
      itemsFolded={itemsFolded}
      initialLinesLoaded={initialLinesLoadedRef.current}
      lineTexts={lineTexts}
      cancelled={cancelled}
    />
  );
};

LinesTable.propTypes = {
  documentType: documentTypePropType.isRequired,
  isB2CCustomer: PropTypes.bool.isRequired,
  showShippingStatus: PropTypes.bool.isRequired,
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lines: PropTypes.shape({
      itemLines: PropTypes.arrayOf(PropTypes.shape({
        discountPercentage: PropTypes.number,
        sublines: PropTypes.arrayOf(PropTypes.shape({
          discountPercentage: PropTypes.number,
        })),
      })).isRequired,
    }).isRequired,
  }).isRequired,
  currencyInfo: PropTypes.object.isRequired,
  cancelled: PropTypes.bool,
  loadLines: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  settings: {
    documents: {
      showShippingStatus,
    },
  },
  user: { customerType },
}) => ({
  showShippingStatus,
  isB2CCustomer: customerType === CustomerTypes.B2C,
});

export default connect(mapStateToProps, { loadLines })(LinesTable);

function useLinesTexts() {
  const lineTexts = useSanaTexts([
    'General_Product_Id',
    'General_Product_Title',
    'OrderLineShippingStatus',
    'ShipmentDate',
    'OrderDetail_LinesShipmentDate',
    'General_Product_Price',
    'Discount',
    'Quantity',
    'Qty',
    'UnitOfMeasure',
    'UOM',
    'Stock',
    'OrderDetail_QuantityShipped',
    'OrderDetail_LinesShippedQuantity',
    'OrderDetail_QuantityInvoiced',
    'OrderDetail_QuantityInvoicedTitle',
    'OrderDetail_OutstandingQuantityAltText',
    'OrderDetail_OutstandingQuantity',
    'Total',
    'ItemsInCart_Label',
    'ButtonText_LoadOrderLines',
    'ButtonText_HideOrderLines',
    'Aria_LineStatus',
    'Aria_LineStatus_Cancelled',
    'OrderDetails_InStock',
    'OrderDetails_NoStock',
  ], makeSimpleText).texts;

  const [
    id,
    title,
    lineShippingStatus,
    shipmentDateTitle,
    shipmentDate,
    price,
    discount,
    quantity,
    qty,
    unitOfMeasure,
    uom,
    stock,
    quantityShipped,
    linesShippedQuantity,
    quantityInvoiced,
    quantityInvoicedTitle,
    outstandingQuantityAlt,
    outstandingQuantity,
    totals,
    itemsInCart,
    showLinesText,
    hideLinesText,
    lineStatus,
    lineStatusCancelled,
    inStock,
    outStock,
  ] = lineTexts;

  return {
    id,
    title,
    lineShippingStatus,
    shipmentDateTitle,
    shipmentDate,
    price,
    discount,
    quantity,
    qty,
    unitOfMeasure,
    uom,
    stock,
    quantityShipped,
    linesShippedQuantity,
    quantityInvoiced,
    quantityInvoicedTitle,
    outstandingQuantityAlt,
    outstandingQuantity,
    totals,
    itemsInCart,
    showLinesText,
    hideLinesText,
    lineStatus,
    lineStatusCancelled,
    inStock,
    outStock,
  };
}
