import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { loadSystemPageQuery } from './queries';
import { initSystemPageContent } from './helpers';

export default (routeData, _state$, { api }) => api.graphApi(loadSystemPageQuery('pageNotFound')).pipe(
  map(({ pages: { pageNotFound } }) => ({
    page: {
      ...pageNotFound,
      component: PageComponentNames.NotFound,
      index: false,
      backTo: routeData.options ? routeData.options.backTo : undefined,
    },
    statusCode: 404,
    routeData,
  })),
  initSystemPageContent(),
);
