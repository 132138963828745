export const CUSTOMERS_REQUESTED = 'REPRESENTATION/CUSTOMERS/REQUESTED';
export const requestCustomers = (keywords = '', index = 0, size = 10) => ({
  type: CUSTOMERS_REQUESTED,
  payload: { keywords, page: { index, size } },
});

export const CUSTOMERS_RECEIVED = 'REPRESENTATION/CUSTOMERS/RECEIVED';
export const customersLoaded = (customers, keywords) => ({
  type: CUSTOMERS_RECEIVED,
  payload: { customers, keywords },
});