import styles from '../Calendar.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getYear, getMonth, getMonthStart } from '@wojtekmaj/date-utils';
import { getDayOfWeekIndex } from '../helpers';

const WeekDays = ({ calendarType, firstDayOfWeek, formatShortWeekday, formatWeekday, onMouseOver }) => {
  const weekDays = useMemo(() => {
    const date = new Date();
    const beginOfMonth = getMonthStart(date);
    const year = getYear(beginOfMonth);
    const monthIndex = getMonth(beginOfMonth);
    const firstDayOfWeekIndex = getDayOfWeekIndex(beginOfMonth, firstDayOfWeek, calendarType);

    const result = [];
    for (let i = 1; i <= 7; i++)
      result.push(new Date(year, monthIndex, i - firstDayOfWeekIndex));

    return result;
  }, [calendarType, firstDayOfWeek]);

  return (
    <section className={styles.weekDays} onFocus={onMouseOver} onMouseOver={onMouseOver}>
      {weekDays.map(weekDay => {
        const label = formatWeekday(weekDay);

        return (
          <div key={weekDay} className={styles.weekDay}>
            <abbr aria-label={label} title={label}>
              {formatShortWeekday(weekDay).replace('.', '')}
            </abbr>
          </div>
        );
      })}
    </section>
  );
};

WeekDays.propTypes = {
  calendarType: PropTypes.string.isRequired,
  firstDayOfWeek: PropTypes.number,
  formatShortWeekday: PropTypes.func.isRequired,
  formatWeekday: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func,
};

export default memo(WeekDays);
