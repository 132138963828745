import styles from './ProductSelector.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ProductTrackingContext, WithProductClickTracking } from 'components/objects/analytics';
import VariantDropdowns from './VariantDropdowns';
import QuantityBox from './QuantityBox';

const QUANTITY_INPUT_ID = 'productSelectorQuantity';

const ProductPanel = ({
  texts,
  product,
  onProductAdd,
  productAddDisabled,
  allowAddUnorderableProduct,
  productClickTrackingSource,
  searchInputId,
}) => {
  const {
    id,
    title,
    url,
    hasVariants,
    variantComponentGroups,
    isOrderable,
    uom,
  } = product;

  const { addBtn, productCannotBeOrderedMessage } = texts;
  const productUomId = uom && uom.id;
  const [quantity, setQuantity] = useState(null);
  const [uomId, setUomId] = useState(productUomId);
  const [variantId, setVariantId] = useState(null);

  useEffect(() => {
    setUomId(productUomId);

    if (!hasVariants)
      setVariantId(null);
  }, [id]);

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!quantity.isValid) {
      document.getElementById(QUANTITY_INPUT_ID).focus();
      return;
    }

    onProductAdd({
      productId: id,
      variantId,
      uomId,
      quantity: quantity.value,
    }, product);

    const searchInput = document.getElementById(searchInputId);
    searchInput.focus();
    searchInput.select();
  };

  return (
    <form
      onSubmit={onSubmit}
      className={`${styles.product} ${allowAddUnorderableProduct || isOrderable ? '' : styles.cannotOrder}`}
    >
      <div className={styles.productInfo}>
        <ProductTrackingContext.Provider value={{ product, trackingSource: productClickTrackingSource }}>
          <WithProductClickTracking>
            {handleClick => (
              <a onClickCapture={handleClick} href={url} target="_blank" rel="noopener noreferrer">{title}</a>
            )}
          </WithProductClickTracking>
        </ProductTrackingContext.Provider>
      </div>
      {!allowAddUnorderableProduct && !isOrderable
        ? <div className={styles.msg}>{productCannotBeOrderedMessage}</div>
        : <>
          {hasVariants && variantComponentGroups &&
            <VariantDropdowns
              productId={id}
              variantComponentGroups={variantComponentGroups}
              updateVariantId={setVariantId}
            />
          }
          <QuantityBox
            inputId={QUANTITY_INPUT_ID}
            product={product}
            value={quantity && quantity.value}
            updateQuantity={setQuantity}
            uomId={uomId}
            updateUom={setUomId}
            hideControlsOnBlur
          />
          <div className={styles.actions}>
            <button
              className={`${btnStyles.btn} ${btnStyles.btnSmall} ${btnStyles.btnAction} ${styles.btnAdd}`}
              type="submit"
              aria-disabled={productAddDisabled}
            >
              <span className="btn-cnt">{addBtn}</span>
            </button>
          </div>
        </>
      }
    </form>
  );
};

ProductPanel.propTypes = {
  texts: PropTypes.shape({
    addBtn: PropTypes.string,
    productCannotBeOrderedMessage: PropTypes.node,
  }).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    variantComponentGroups: PropTypes.array,
    isOrderable: PropTypes.bool,
    uom: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onProductAdd: PropTypes.func.isRequired,
  productAddDisabled: PropTypes.bool,
  allowAddUnorderableProduct: PropTypes.bool,
  productClickTrackingSource: PropTypes.string.isRequired,
  searchInputId: PropTypes.string.isRequired,
};

export default ProductPanel;
