import styles from './VariantsMatrix.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { SimpleText } from 'components/sanaText';
import { SanaButton } from 'components/primitives/buttons';
import { PropTypes } from 'prop-types';
import { Placeholder } from 'components/primitives/placeholders';
import { strip } from 'components/primitives/product';

const AddToBasketButton = ({ quantities }) => {
  let count = 0;

  for (const quantity of quantities.values())
    if (quantity.value)
      count += +quantity.value;

  const variantsCountPlaceholder = <Placeholder className={styles.variantsCountPlaceholder} />;

  return (
    <div className={styles.addBox}>
      <div className={styles.variantsCount}>
        <SimpleText
          textKey="Product_TotalVariants_Label"
          formatWith={[strip(count)]}
          placeholder={variantsCountPlaceholder}
        />
      </div>
      <SanaButton textKey="AddToBasket" type="submit" className={`${btnStyles.btnAction} ${btnStyles.btnBig}`} />
    </div>
  );
};

AddToBasketButton.propTypes = {
  quantities: PropTypes.instanceOf(Map).isRequired,
};

export default AddToBasketButton;