import styles from '../SanaForm.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../ErrorMessage';
import { useFormikContext } from 'formik';

const FormError = ({ className }) => {
  const { errors } = useFormikContext();
  const message = errors[''] || errors[undefined];

  if (!message)
    return null;

  return (
    <div className={`${styles.validation} ${className}`} aria-live="polite">
      <ErrorMessage>{message}</ErrorMessage>
    </div>
  );
};

FormError.propTypes = {
  className: PropTypes.string,
};

export default memo(FormError);
