import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { DecimalField as DecimalFieldCore } from '../../fields';
import validationFieldPropType from './validationFieldPropType';

const DecimalField = hoistStatics(
  ({
    fieldName,
    fieldTitle,
    validation,
    required,
    className,
    disabled,
    placeholder,
    maxLength,
  }) => (
      <DecimalFieldCore
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={validation}
        required={required}
        className={className}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
      />
    ),
  DecimalFieldCore,
);

DecimalField.propTypes = {
  ...DecimalFieldCore.propTypes,
  required: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  validation: validationFieldPropType,
  maxLength: PropTypes.number,
};

export default DecimalField;
