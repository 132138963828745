import { AGREEMENTS_RECEIVED, AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED } from './actions';

export default function salesAgreementsPageReducer(state, action) {
  switch (action.type) {
    case AGREEMENTS_RECEIVED:
      const newAgreements = action.payload.agreements;

      const agreements = action.payload.append && state.agreements
        ? state.agreements.concat(newAgreements)
        : newAgreements || [];

      return { ...state, agreements };
    case AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED:
      return { ...state, search: action.payload };
    default:
      return state;
  }
}