import styles from './LargeGallery.module.scss';
import { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/primitives/spinner/Spinner';

function SliderImage({ item, index, active, emitter }) {
  const [loaded, setLoaded] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(active);

  const unmounted = useRef(false);
  useEffect(() => () => void (unmounted.current = true), []);

  useEffect(() => {
    if (!shouldLoad) {
      const subscription = emitter && emitter.subscribe(next => {
        if (next === index && !shouldLoad) {
          setShouldLoad(true);
          subscription.unsubscribe();
        }
      });
    }
  }, [emitter]);

  const onLoad = useCallback(() => {
    if (!unmounted.current)
      setLoaded(true);
  }, []);

  return (
    <>
      {shouldLoad && (
        <img
          className={styles.slide}
          src={item.large}
          alt={item.title}
          title={item.title}
          onLoad={onLoad}
          draggable="false"
          onDragStart={preventDragEvent}
        />
      )}
      {!loaded &&
        <div className={`${styles.spinner} ${styles.slide}`}>
          <Spinner />
        </div>
      }
    </>);
}

SliderImage.propTypes = {
  item: PropTypes.shape({
    large: PropTypes.string,
    title: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  emitter: PropTypes.object,
};

export default SliderImage;

function preventDragEvent(e) {
  e.preventDefault();
}