export const getProductsTrackingDataFromLines = lines => {
  if (lines == null)
    return [];

  const trackingProducts = [];
  lines.forEach(line => {
    if (line.isSupplementary)
      return;

    trackingProducts.push(...getProductsDataFromLine(line));
  });
  return trackingProducts;
};

export const getConfigurationResultTrackingData = configurationResult => {
  const { masterProduct, products } = configurationResult;
  const productsData = products.map(product => ({
    id: masterProduct.id,
    title: masterProduct.title,
    uom: product.uom,
    categoriesPaths: masterProduct.categoriesPaths,
    variant: product.variantId ? `${product.id}_${product.variantId}` : product.id,
    price: product.price,
    quantity: product.quantity,
  }));

  return productsData;
};

export const getModifiedProductsTrackingData = (oldLines, modifiedLines, addedLines) => {
  const addedProducts = [];
  const removedProducts = [];
  if (modifiedLines == null)
    modifiedLines = [];

  modifiedLines.forEach(line => {
    const oldLine = oldLines.find(item => isSameLine(line, item));
    if (oldLine == null) {
      addedProducts.push(...getProductsDataFromLine(line));
      return;
    }

    if (line.subLines) {
      line.subLines.forEach(subLine => {
        const oldSubLine = oldLine.subLines && oldLine.subLines.find(item => isSameSubline(subLine, item));
        const oldQuantity = oldSubLine ? oldSubLine.quantity : 0;

        if (subLine.quantity > oldQuantity) {
          addedProducts.push(getProductData(line, subLine, subLine.quantity - oldSubLine.quantity));
        }
        if (subLine.quantity < oldQuantity) {
          removedProducts.push(getProductData(line, subLine, oldSubLine.quantity - subLine.quantity));
        }
      });

      return;
    }

    if (line.quantity > oldLine.quantity) {
      addedProducts.push(getProductData(line, null, line.quantity - oldLine.quantity));
    }

    if (line.quantity < oldLine.quantity) {
      removedProducts.push(getProductData(line, null, oldLine.quantity - line.quantity));
    }
  });

  if (addedLines && addedLines.length)
    addedProducts.push(...getProductsTrackingDataFromLines(addedLines));

  return {
    addedProducts,
    removedProducts,
  };
};

function isSameLine(line1, line2) {
  if (line1.configuration)
    return line2.configuration && line2.configuration.id === line1.configuration.id;

  if (!line1.uom && !line2.uom || line1.subLines)
    return line2.product && line2.product.id === line1.product.id;

  return line2.product && line2.product.id === line1.product.id
    && line1.uom && line2.uom && line1.uom.id === line2.uom.id;
}

function isSameSubline(subLine1, subLine2) {
  if (!subLine1.uom && !subLine2.uom)
    return subLine1.variationId === subLine2.variationId;

  return subLine1.variationId === subLine2.variationId
    && subLine1.uom && subLine2.uom && subLine1.uom.id === subLine2.uom.id;
}

function getProductsDataFromLine(line) {
  const products = [];
  if (line.subLines) {
    line.subLines.forEach(subLine => products.push(getProductData(line, subLine)));
  } else {
    products.push(getProductData(line));
  }

  return products;
}

function getProductData(line, subLine = null, quantity = null) {
  const productInfo = line.product || line.configuration.masterProduct;
  const lineInfo = subLine || line;
  const variant = line.configuration ? getConfigurationVariant(line.configuration) : lineInfo.variationId;

  return {
    id: productInfo.id,
    title: productInfo.title,
    uom: lineInfo.uom,
    categoriesPaths: productInfo.categoriesPaths,
    variant,
    price: lineInfo.price,
    quantity: quantity || lineInfo.quantity,
  };
}

function getConfigurationVariant(configuration) {
  const product = configuration.products[0];
  return product.variantId ? `${product.id}_${product.variantId}` : product.id;
}