import styles from './NewsOverview.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { renderHTML } from 'utils/render';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { toLocaleDate } from 'utils/format';

const NewsListItem = ({ item, culture }) => {
  const { id, title, url, shortDescription, date } = item;
  return (
    <li className={styles.item}>
      <Link url={url} to={routesBuilder.forNewsDetail(id)} className={styles.link}>
        {title}
      </Link>
      <span className={styles.date}>{toLocaleDate(date, culture)}</span>
      <div className="short fr-view">
        {shortDescription && renderHTML(shortDescription)}
      </div>
    </li>
  );
};

NewsListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
    date: PropTypes.string.isRequired,
  }).isRequired,
  culture: PropTypes.string,
};

export default memo(NewsListItem);