export {
  APP_INIT, initApp,
  APP_DESTROY, destroyApp,
  OFFLINE_MODE_CHANGED,
  APP_STORE_CONFIGURED, storeConfigured,
  APP_INIT_HYDRATE, initAppHydrate,
  APP_HYDRATED, notifyHydrated,
  APP_STATE_UPDATED, requestAppStateUpdate,
} from './actions';

export const OfflineModeSupport = Object.freeze({
  Full: 'FULL',
  Disabled: 'DISABLED',
  ViewOnly: 'VIEW_ONLY',
});
