import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';
import { toLocaleDate, formatAsPercentage } from 'utils/format';
import UnitOfMeasure from '../UnitOfMeasure';
import ShippingStatus from '../ShippingStatus';
import QuoteLineStock from '../../quote/QuoteLineStock';
import { useCultureName } from 'utils/hooks';
import { FormattedPrice } from 'components/primitives/price';

const SimpleLine = ({
  line: {
    productId,
    title,
    shippingStatus,
    shipmentDate,
    price,
    subtotal,
    discountPercentage,
    uom,
    quantity,
    quantityShipped,
    quantityInvoiced,
    quantityOutstanding,
    inStock,
  },
  showId = false,
  options: {
    isB2CCustomer,
    showUOM,
    showStock,
    showPrices,
    showDiscounts,
    showExtraColumns,
    showShippingStatus,
    currencyInfo,
  },
  className = '',
  cancelled,
  lineTexts,
}) => {
  const cultureName = useCultureName();
  const uomId = uom && uom.id;
  const uomDescription = uom && uom.description;

  return (
    <tr className={`${cancelled ? styles.cancelled : ''} ${className}`}>
      <td aria-hidden={!cancelled} className="visually-hidden">{cancelled && lineTexts.lineStatusCancelled}</td>
      <td className={styles.lineId} aria-hidden={!showId}>{showId ? productId : ''}</td>
      <td className={styles.lineTitle}>{title}</td>
      {showShippingStatus && <td className={styles.lineShippingStatus}><ShippingStatus status={shippingStatus} /></td>}
      {!isB2CCustomer &&
        <td className={styles.lineShipmentDate} aria-hidden={!shipmentDate}>
          {toLocaleDate(shipmentDate, cultureName)}
        </td>
      }
      {showPrices && <td className={styles.linePrice}><FormattedPrice price={price} currencyInfo={currencyInfo} /></td>}
      {showDiscounts && (
        <td className={styles.lineDiscount} aria-hidden={!discountPercentage}>
          {discountPercentage
            ? formatAsPercentage(discountPercentage, currencyInfo, true /* include sign*/)
            : ''
          }
        </td>
      )}
      <td className={styles.lineQty}>{quantity}</td>
      {showUOM && <td className={styles.lineUom}><UnitOfMeasure id={uomId} description={uomDescription} /></td>}
      {showStock &&
        <td aria-hidden={inStock == null} className={styles.lineStock}>
          <QuoteLineStock inStock={inStock} lineTexts={lineTexts} />
        </td>
      }
      {showExtraColumns && <td className={styles.lineQtyShipped}>{quantityShipped}</td>}
      {showExtraColumns && <td className={styles.lineQtyInvoiced}>{quantityInvoiced}</td>}
      {!isB2CCustomer && <td className={styles.lineQtyOutstanding}>{quantityOutstanding}</td>}
      {showPrices && <td className={styles.lineTotal}><FormattedPrice price={subtotal} currencyInfo={currencyInfo} /></td>}
    </tr>
  );
};

SimpleLine.propTypes = {
  line: PropTypes.shape({
    productId: PropTypes.string,
    title: PropTypes.string,
    shippingStatus: PropTypes.string,
    shipmentDate: PropTypes.string,
    price: PropTypes.number,
    subtotal: PropTypes.number,
    discountPercentage: PropTypes.number,
    uom: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
    quantity: PropTypes.number,
    quantityShipped: PropTypes.number,
    quantityInvoiced: PropTypes.number,
    quantityOutstanding: PropTypes.number,
    inStock: PropTypes.bool,
  }),
  showId: PropTypes.bool,
  options: PropTypes.shape({
    isB2CCustomer: PropTypes.bool,
    showUOM: PropTypes.bool,
    showStock: PropTypes.bool,
    showPrices: PropTypes.bool,
    showDiscounts: PropTypes.bool,
    showExtraColumns: PropTypes.bool,
    showShippingStatus: PropTypes.bool,
    currencyInfo: PropTypes.shape({
      symbol: PropTypes.string,
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  className: PropTypes.string,
  cancelled: PropTypes.bool.isRequired,
  lineTexts: PropTypes.shape({
    lineStatusCancelled: PropTypes.string,
  }),
};

export default SimpleLine;
