import 'core-js/stable';
import 'unfetch/polyfill';
import 'intersection-observer';
import './extensions';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import createRouter from 'behavior/routing/routers/browser';
import { createBrowserCookies } from 'utils/cookies';
import configureStore from './store';
import { createApi } from 'utils/api';
import { initApp, initAppHydrate, storeConfigured, notifyHydrated } from 'behavior/app';
import { unregister as unregisterServiceWorker } from 'utils/registerServiceWorker';
import { StyleProvider } from 'components/styles';
import { ServiceContext } from 'utils/services';
import { loadableReady } from '@loadable/component';
import { createBroadcast } from 'utils/broadcast/browser';
import { AddonProvider, browserAddonLoader, requestAddonsMetadata } from 'utils/addons';
import { createAddonsContainer } from 'utils/addons/container';
import { browserStorage as localStorage } from 'utils/localStorage';
import { createToolsStorage } from 'utils/tools';
import { insertCss } from 'isomorphic-style-loader';
import { logger } from 'utils/logs';
import { AppContext } from 'utils/app';
import { setPreviewToken } from 'behavior/pages/preview/helpers';
import { addPluginCookieToHeaders } from './helpers';

logger.init(console);

const serverError = parseScriptElement('serverError');
if (serverError) {
  const error = serverError.error;

  if (Array.isArray(error))
    error.forEach(error => logger.error(error));
  else
    logger.error(error);
}

let history;
try {
  history = createBrowserHistory();
} catch (e) {
  if (e instanceof URIError) {
    window.location = '/';
  } else {
    logger.error(e);

    throw e;
  }
}

const router = createRouter(history);
const toolsStorage = createToolsStorage(localStorage);
const origin = window.location.origin || `${window.location.protocol}//${window.location.host}`;

const cookies = createBrowserCookies();
const serverCookies = parseScriptElement('cookies');
const headers = {};

if (serverCookies && serverCookies.length) {
  for (const { name, value, options } of serverCookies) {
    if ('expires' in options && typeof options.expires === 'string')
      options.expires = new Date(options.expires);

    cookies.set(name, value, options);
  }
}

const cookieValues = cookies.getValues();

for (const key in cookieValues)
  addPluginCookieToHeaders(key, cookieValues[key], headers);

const api = createApi({
  path: '/api/graph',
  fetch,
  cookies,
  headers,
  multiTab: true,
  toolsStorage,
});
Object.defineProperty(window, '__sana_ajax_active', {
  get: () => api.isRunning(),
});

const serverInitialState = parseScriptElement('initialReduxState');
const loadedAddonIds = parseScriptElement('loadedAddonIds') || [];
const broadcast = createBroadcast();
const scope = 'CLIENT'; /*:Scope*/

const epicServices = {
  api,
  broadcast,
  logger,
  scope,
  localStorage,
  cookies,
  toolsStorage,
};
const componentServices = {
  api,
  context: new AppContext(scope, origin),
};

const addonsContainer = createAddonsContainer(
  browserAddonLoader(api.trackObservable),
  epicServices,
);

const initialState = serverInitialState ?? (document.documentElement.hasAttribute('data-error') ? { error: { initial: true } } : undefined);
const store = configureStore(router, epicServices, addonsContainer, initialState);

if (!serverInitialState) {
  store.dispatch(initApp());
  store.dispatch(requestAddonsMetadata());
} else {
  const { localization: { currentLanguage }, routing: { routeData } } = serverInitialState;
  if (currentLanguage.id > 0)
    api.setLanguage(currentLanguage.id);

  if (routeData && routeData.params && routeData.params.previewToken)
    setPreviewToken(api, routeData.params.previewToken);

  store.dispatch(initAppHydrate());
}

store.dispatch(storeConfigured());

const rootElement = document.getElementById('root');

function render(renderFunc) {
  const App = require('./App').default;
  renderFunc(
    <ServiceContext.Provider value={componentServices}>
      <Provider store={store}>
        <StyleProvider insertCss={insertCss}>
          <AddonProvider registry={addonsContainer.registry}>
            <App />
          </AddonProvider>
        </StyleProvider>
      </Provider>
    </ServiceContext.Provider>,
    rootElement);
}

if (module.hot) {
  module.hot.accept('./App', () => {
    render(ReactDOM.render);
  });
}

const ieResizeObserverPolyfill = 'ResizeObserver' in window
  ? new Promise(resolve => resolve())
  : import('resize-observer-polyfill').then(module => window.ResizeObserver = module.default);

if (serverInitialState) {
  Promise.all([
    new Promise(loadableReady),
    addonsContainer.preload(store, loadedAddonIds),
    ieResizeObserverPolyfill,
  ]).then(() => {
    render(ReactDOM.hydrate);
    document.getElementById('root').classList.remove('disable-forms-submitting');
    store.dispatch(notifyHydrated());
  });
}
else {
  ieResizeObserverPolyfill.then(() => render(ReactDOM.render));
}

unregisterServiceWorker();

function parseScriptElement(name) {
  const scriptElement = document.getElementById(name);
  if (!scriptElement)
    return;

  const scriptText = scriptElement.textContent;
  scriptElement.parentNode.removeChild(scriptElement);

  return JSON.parse(scriptText);
}
