import tableStyles from 'components/primitives/table/Table.module.scss';
import styles from './BasketFooter.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { taxFocusModePropType, taxAmountPositionPropType, taxDisplayModePropType } from 'behavior/settings/propTypes';
import TotalsLine, {
  PromotionLine, ShippingCostLine, TaxLine,
  TotalExclTax, TotalInclTax,
} from './totals';
import { TaxFocusMode, TaxAmountPosition, TaxDisplayMode } from 'behavior/settings/constants';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { canShowPrepayment as getCanShowPrepayment } from 'behavior/basket';

const BasketFooter = ({
  readOnly,
  className = '',
  totals,
  discount,
  shippingCost,
  paymentCost,
  canShowPrepayment,
  tax,
  taxSettings,
  subtotalLabel,
}) => {
  const [showPrices] = useHasAbilities(AbilityTo.ViewPrices);

  if (showPrices === false || !totals || totals.price == null)
    // There might be outdated abilities on client, but 'price' will be filtered on server side and will not be returned.
    return null;

  if (!taxSettings)
    taxSettings = {};

  const taxLine = taxSettings.mode !== TaxDisplayMode.Hide &&
    <TaxLine amount={tax.amount} taxes={tax.taxes} detailed={taxSettings.mode === TaxDisplayMode.Detailed} />;
  const paymentDiscount = !!discount.payment &&
    <TotalsLine textKey="PaymentDiscount" value={-discount.payment} className={styles.paymentDiscount} />;

  const taxAndPaymentDisc = paymentDiscount ? <>{taxLine}{paymentDiscount}</> : taxLine;
  const showPromotion = !readOnly || !!discount.promotion?.amount;

  return (
    <table className={`${tableStyles.table} ${styles.tableTotals} ${className}`}>
      <thead>
        <TotalsLine textKey="Subtotal" label={subtotalLabel} value={totals.sub} className={styles.subTotal} />
        {!!totals.roundOff && <TotalsLine textKey="RoundOff" value={totals.roundOff} className={styles.roundOff} />}
      </thead>
      <tbody>
        {showPromotion && <PromotionLine line={discount.promotion} readOnly={readOnly} />}
        {!!discount.invoice &&
          <TotalsLine
            textKey="InvoiceDiscount"
            value={-discount.invoice}
            className={styles.invoiceDiscount}
          />
        }
      </tbody>
      <tbody>
        <ShippingCostLine shippingCost={shippingCost} labelOnly={readOnly} />
        {paymentCost && !!paymentCost.price &&
          <TotalsLine
            textKey="PaymentCosts"
            value={paymentCost.price}
            className={styles.paymentCost}
          />
        }
      </tbody>
      <tfoot>
        {taxSettings.totalExclTax && (
          <TotalExclTax
            priceExcludingTax={totals.priceExcludingTax}
            focus={taxSettings.focus === TaxFocusMode.Excl}
          />
        )}
        {taxSettings.amountPosition === TaxAmountPosition.Above && taxAndPaymentDisc}
        <TotalInclTax
          price={totals.price}
          focus={taxSettings.focus === TaxFocusMode.Incl}
        />
        {taxSettings.amountPosition === TaxAmountPosition.Below && taxAndPaymentDisc}
        {canShowPrepayment && totals.prepayment > 0 && totals.prepayment < totals.price &&
          <TotalsLine textKey="PrepaymentTotal" value={totals.prepayment} className={styles.prepayment} />
        }
      </tfoot>
    </table>
  );
};

BasketFooter.propTypes = {
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  totals: PropTypes.shape({
    sub: PropTypes.number,
    roundOff: PropTypes.number.isRequired,
    priceExcludingTax: PropTypes.number,
    price: PropTypes.number,
  }),
  discount: PropTypes.shape({
    invoice: PropTypes.number.isRequired,
    payment: PropTypes.number.isRequired,
  }),
  shippingCost: PropTypes.object,
  paymentCost: PropTypes.shape({ price: PropTypes.number }),
  tax: PropTypes.object,
  canShowPrepayment: PropTypes.bool,
  taxSettings: PropTypes.shape({
    totalExclTax: PropTypes.bool.isRequired,
    focus: taxFocusModePropType.isRequired,
    amountPosition: taxAmountPositionPropType.isRequired,
    mode: taxDisplayModePropType.isRequired,
  }),
  subtotalLabel: PropTypes.string,
};

const connected = connect(state => ({
  canShowPrepayment: getCanShowPrepayment(state),
  taxSettings: state.settings.tax,
}))(BasketFooter);

connected.selectBasketProps = basket => {
  const { totals, tax, discount, paymentCost, shippingCost } = basket;
  return {
    totals,
    tax,
    discount,
    paymentCost,
    shippingCost,
  };
};

export default connected;
