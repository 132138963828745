import { useRef } from 'react';
import PropTypes from 'prop-types';
import SanaForm from '../../SanaForm';
import { initFormRef } from '../helpers';

const GenericForm = ({
  name,
  initialValues,
  className,
  formRef,
  onSubmit,
  onBlur,
  children,
}) => {
  const formikRef = useRef();

  if (formRef && !formRef.current)
    initFormRef(formRef, formikRef);

  return (
    <SanaForm
      name={name}
      initialValues={initialValues}
      className={className}
      onSubmit={onSubmit}
      onBlur={onBlur}
      formikRef={formikRef}
    >
      {children}
    </SanaForm>
  );
};

GenericForm.propTypes = {
  name: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  className: PropTypes.string,
  formRef: PropTypes.shape({ current: PropTypes.any }),
  onSubmit: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.node,
};

export default GenericForm;
