import { useMemo } from 'react';
import PropTypes from 'prop-types';
import SliderPopup from './SliderPopup';
import SliderImage from './SliderImage';

const Gallery = ({ items = [], index = 0, hide }) => {
  const { largeIndex, largeItems } = useMemo(() => getLargeItemsWithIndex(items, index), [items, index]);

  return <SliderPopup hide={hide} items={largeItems} index={largeIndex} />;
};

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    large: PropTypes.string,
  })),
  index: PropTypes.number,
  hide: PropTypes.func.isRequired,
};

export default Gallery;

/**
 *  Returns only large images from array and corresponding index of current image
 * @param {array} items Array of images
 * @param {number} index Index of media gallery current image
 * @returns {object} - object with array of large images and index of current image in this array
 */
function getLargeItemsWithIndex(items, index) {
  let largeIndex = 0;
  const largeItems = [];
  let indexInSlider = 0;

  for (let i = 0; i < items.length; i++) {
    if (!items[i].large)
      continue;

    const item = <SliderImage active={index === i} index={indexInSlider} item={items[i]} />;
    largeItems.push(item);

    if (index === i)
      largeIndex = indexInSlider;

    indexInSlider++;
  }

  return {
    largeIndex,
    largeItems,
  };
}