/**
 * Finds an item in the navigation tree by predicate and then calls an update function on it.
 * All ascendants of updated item are updated to new objects.
 * @param {Array} items - array of navigation items.
 * @param {Function} predicate - a predicate to find an item.
 * @param {Function} updateItem - an action to run on a found item.
 * @returns {Array} - initial / updated array of items.
 */
export function updateTreeItem(items, predicate, updateItem) {
  const newItems = traverseTree(items, predicate, updateItem);
  return newItems || items;
}

function traverseTree(items, predicate, updateItem) {
  if (!items || !items.length)
    return items;

  if (predicate == null)
    throw new Error('"predicate" argument cannot be null / undefined.');
  if (updateItem == null)
    throw new Error('"updateItem" argument cannot be null / undefined.');

  let modified;
  const result = items.map(item => {
    if (predicate(item)) {
      updateItem(item);
      modified = true;
      return { ...item };
    }

    const children = item.children;
    const updatedChildren = traverseTree(children, predicate, updateItem);

    if (updatedChildren?.length) {
      modified = true;
      return { ...item, children: updatedChildren };
    }
    return item;
  });

  return modified ? result : null;
}