exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "#content.offline{margin:auto 0;padding:30px 0}#content.offline .OfflineMode_logo{display:flex;justify-content:center;padding-bottom:2em}#content.offline .OfflineMode_logo>a{pointer-events:none}#content.offline .OfflineMode_logo img{width:100%}.OfflineMode_content{display:flex;flex-direction:column;align-items:center}.OfflineMode_message{margin:1.92em 0}.OfflineMode_message a{text-transform:lowercase}.OfflineMode_message,.OfflineMode_shop-name{font-size:var(--normalText_BiggerFontSize,14px);line-height:1.9}.OfflineMode_shop-name{color:var(--regularTitle_FontColor,#333);margin:0}.OfflineMode_navs{list-style:none;padding-left:0;margin:0 0 1em}", ""]);

// exports
exports.locals = {
	"logo": "OfflineMode_logo",
	"content": "OfflineMode_content",
	"message": "OfflineMode_message",
	"shop-name": "OfflineMode_shop-name",
	"shopName": "OfflineMode_shop-name",
	"navs": "OfflineMode_navs"
};