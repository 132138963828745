import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/primitives/grid';

// This section is extracted to separate functional component to ensure ModalNav
// and MobileTopMenu components receive menuOpenerRef on their mount
const OpenerContainer = forwardRef(({ className }, menuOpenerRef) => (
  <Col xs="auto" className={className} ref={menuOpenerRef} />
));

OpenerContainer.propTypes = {
  className: PropTypes.string,
};

export default OpenerContainer;