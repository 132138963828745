import PropTypes from 'prop-types';
import { ErrorMessage as FormikErrorMessage, useFormikContext } from 'formik';
import ErrorMessage from '../../ErrorMessage';
import { createErrorMsgId } from '../../helpers';
import ErrorMessageText from './ErrorMessageText';

const FieldError = ({ fieldMetadata }) => {
  const formik = useFormikContext();
  const fieldName = fieldMetadata.name;
  const id = createErrorMsgId(formik.status.formName, fieldName);

  return (
    <FormikErrorMessage
      id={id}
      fieldName={fieldName}
      name={fieldName}
      component={({ id, fieldName, children }) => (
        <ErrorMessage id={id} fieldName={fieldName}>
          <ErrorMessageText message={children} fieldMetadata={fieldMetadata} />
        </ErrorMessage>
      )}
    />
  );
};

FieldError.propTypes = {
  fieldMetadata: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default FieldError;
