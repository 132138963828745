import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import VariantsMatrixContext from './VariantsMatrixContext';

const VariantMatrixProvider = ({ children }) => {
  const [state, setState] = useState(() => ({
    quantities: new Map(),
    quantitiesDependency: {},
  }));

  const quantities = state.quantities;

  const resetQuantities = () => setState({
    quantities: new Map(),
    quantitiesDependency: {},
  });

  const updateQuantity = useCallback((variantId, quantity) => {
    setState(({ quantities }) => {
      if (quantity && quantity.value)
        quantities.set(variantId, quantity);
      else
        quantities.delete(variantId, quantity);

      return { quantities, quantitiesDependency: {} };
    });
  }, []);

  const value = {
    quantities,
    updateQuantity,
    resetQuantities,
  };

  return (
    <VariantsMatrixContext.Provider value={value}>
      {children}
    </VariantsMatrixContext.Provider>
  );
};

VariantMatrixProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VariantMatrixProvider;
