import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { normalizeAddonId } from './helpers';

export function nodeLoader(origin, headers, reactiveFetch, cache) {
  function materialize(src) {
    let result = undefined;
    const registerAddon = (addonId, hash, addonFactory) => {
      result = addonFactory;

      if (!isDevelopmentAddon(hash))
        cache.set(createAddonCacheKey(addonId), { factory: result, hash });
    };

    /* eslint-disable-next-line no-new-func */
    Function('global', 'process', 'registerAddon', src)(undefined, undefined, registerAddon);
    return result;
  }

  function isDevelopmentAddon(hash) {
    return !!hash;
  }

  function createAddonCacheKey(id) {
    return `addon_${normalizeAddonId(id)}`;
  }

  function getAddonFactoryFromCache(id, hash) {
    if (isDevelopmentAddon(hash))
      return;

    const addon = cache.get(createAddonCacheKey(id));
    if (addon?.hash !== hash)
      return;

    return addon.factory;
  }

  return (addonId, addonHash) => {
    const addonFactory = getAddonFactoryFromCache(addonId, addonHash);
    if (addonFactory)
      return of(addonFactory);

    const url = getAddonEntryUrl(addonId, addonHash, '');
    return reactiveFetch(origin + url, { headers }).pipe(
      map(materialize),
    );
  };
}

export function browserLoader(requestTracker) {
  const addonFactories = new Map();
  const head = document.getElementsByTagName('head')[0];

  Object.defineProperty(window, 'registerAddon', {
    value(addonId, hash, factory) {
      addonFactories.set(normalizeAddonId(addonId), { hash, factory });
    },
  });

  return (addonId, addonHash) => {
    return Observable.create(observer => {
      const script = document.createElement('script');
      script.timeout = 120;
      script.async = true;
      script.src = getAddonEntryUrl(addonId, addonHash);

      let timeout = undefined;
      const onComplete = event => {
        // avoid mem leaks in IE.
        script.onerror = script.onload = null;
        clearTimeout(timeout);

        const addonFactory = addonFactories.get(addonId);
        if (addonFactory
          && addonFactory.hash === addonHash
          && typeof (addonFactory.factory) === 'function') {
          observer.next(addonFactory.factory);
          observer.complete();
        }
        else {
          const errorType = event
            && (event.type === 'load' ? 'missing' : event.type);
          const realSrc = event && event.target && event.target.src;

          const error = new Error(`Loading addon ${addonId} failed.\n(${errorType} : ${realSrc})`);
          error.type = errorType;
          error.request = realSrc;

          observer.error(error);
        }
      };

      timeout = setTimeout(() => onComplete({ type: 'timeout', target: script }), 120000);
      script.onerror = script.onload = onComplete;

      head.appendChild(script);
    }).pipe(
      requestTracker,
    );
  };
}

function getAddonEntryUrl(addonId, addonHash) {
  const idSegment = addonId.toLowerCase();
  const filename = addonHash ? 'index.' + addonHash : 'index';
  return `/static/a/${idSegment}/js/${filename}.js`;
}