import styles from './VariantsMatrix.module.scss';
import { memo, Fragment } from 'react';
import { SimpleText } from 'components/sanaText';
import { useProductContext } from '../hooks';

const VariantsOverview = ({ backButton }) => {
  const { product } = useProductContext();
  const { variantComponentGroups: groups, variants } = product;
  if (!groups || !variants)
    return null;

  return (
    <>
      <h4 className={styles.variantsOverviewTitle}><SimpleText textKey="Product_VariantsOverviewHeader" /></h4>
      <dl className={styles.variantsOverview}>
        {!!groups.length && groups.map(({ id, name, components }, index) => (
          <Fragment key={index}>
            <dt className={styles.variantsRow}><SimpleText textKey={`Product_VariantComponents_${id}`}>{name}</SimpleText></dt>
            <dd>{components.map(({ name }) => name).join(', ')}</dd>
          </Fragment>
        ))}
        {!groups.length && (
          <>
            <dt className={styles.variantsRow}><SimpleText textKey="Variants" /></dt>
            <dd>{variants.map(({ title }) => title).join(', ')}</dd>
          </>
        )}
      </dl>
      {backButton}
    </>
  );
};

export default memo(VariantsOverview);