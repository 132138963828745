import { fromEvent, asyncScheduler, EMPTY, BehaviorSubject } from 'rxjs';
import { throttleTime, share, startWith, map } from 'rxjs/operators';
import { isBrowser } from '../detections';

export const resize$ = getEventObservable('resize').pipe(
  throttleTime(350, asyncScheduler, { leading: false, trailing: true }),
  share(),
);

export const scroll$ = getEventObservable('scroll').pipe(
  throttleTime(250, asyncScheduler, { leading: false, trailing: true }),
  share(),
);

export const orientationChange$ = getEventObservable('orientationchange').pipe(
  throttleTime(250, asyncScheduler, { leading: false, trailing: true }),
  share(),
);

export const windowMessage$ = getEventObservable('message', true).pipe(
  share(),
);

export const visibility$ = isBrowser && 'onvisibilitychange' in window.document
  ? getEventObservable('visibilitychange', false).pipe(
    startWith(0),
    map(() => !window.document.hidden),
    share(),
  )
  : new BehaviorSubject(true);

function getEventObservable(eventName, isWindowEvent = true) {
  return isBrowser
    ? fromEvent(isWindowEvent ? window : window.document, eventName)
    : EMPTY;
}