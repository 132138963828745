import Field, { fieldPropTypes } from '../Field';
import { Checkbox } from 'components/primitives/form';

const CheckboxField = ({ fieldName, fieldTitle, validation, ...attributes }) => (
  <Field
    fieldName={fieldName}
    fieldTitle={fieldTitle}
    type="checkbox"
    initialValue={false}
    validation={validation}
    as={Checkbox}
    {...attributes}
  />
);

CheckboxField.propTypes = fieldPropTypes;

export default CheckboxField;