import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';

const InnerLine = ({ value, columnsAmount, className = '' }) => {
  if (!value)
    return null;

  return (
    <tr className={`${styles.innerLine} ${className}`}>
      <td aria-hidden className="visually-hidden" />
      <td aria-hidden />{/* ID */}
      <td>{value}</td>
      <td aria-hidden colSpan={columnsAmount - 2} />{/* 2 for empty ID and title cells */}
    </tr>
  );
};

InnerLine.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  columnsAmount: PropTypes.number.isRequired,
};

export default InnerLine;
