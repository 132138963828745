import createSystemRenderer from 'components/objects/systemPages/createSystemRenderer';
import { CheckoutProcessNames } from 'behavior/pages/checkout';
import OrderFailed from './OrderFailed';

export default page => {
  const { transaction: { checkoutProcessName } } = page;

  const headerTextKey = checkoutProcessName === CheckoutProcessNames.Invoice
    ? 'InvoicePaymentFailed_Header'
    : 'OrderFailed_Header';

  return createSystemRenderer(headerTextKey, OrderFailed)(page);
};