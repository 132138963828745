export default {
  /**
   * Gets value indicating whether cookie policy acceptance should be renewed.
   * @returns {Boolean} Value indicating whether cookie policy acceptance should be renewed.
   */
  shouldBeShown(): boolean {
    if (typeof window === 'undefined' || window.localStorage == null)
      return false;

    const saved = window.localStorage.getItem('cookiePolicyAccepted');
    if (!saved)
      return true;

    return +(new Date()) > +saved;
  },
  /**
   * Remembers cookie policy acceptance till the specified date.
   * @param {Date} date The date when cookie policy acceptance should be renewed.
   */
  rememberAccepted(date?: Date): void {
    if (!date) {
      date = new Date();
      date.setMonth(date.getMonth() + 3);
    }
    window.localStorage.setItem('cookiePolicyAccepted', (+date).toString());
  },
};