import { of } from 'rxjs';
import { map, first, mergeMap } from 'rxjs/operators';
import { PageComponentNames } from 'behavior/pages';
import { initSystemPageContent } from 'behavior/pages/system';
import { RouteName, routesBuilder } from 'routes';
import { requestRoute } from 'behavior/route';
import { redirectTo } from 'behavior/routing';
import { DocumentType } from 'behavior/documents';
import { areLocationsEqual } from 'behavior/routing/helpers';
import { CheckoutPresets } from 'behavior/settings/constants';
import { CustomerTypes } from 'behavior/user';
import { requestAbility } from 'behavior/user/epic';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { toUrlHash } from 'utils/url';
import {
  filterImages,
  getStepByUrlHash,
  updateShippingAddressIfNeeded,
  adjustShippingMethodData,
  adjustPaymentMethodData,
  adjustGuestProfileData,
} from './helpers';
import { createCheckoutPageQuery, createPromotionPageQuery } from './queries';
import { Steps } from './constants';

export default function (routeData, state$, dependencies) {
  const state = state$.value;
  const canLoadPage = s => s.user.initialized
    && s.settings.loaded
    && !s.basket.updating;

  if (!canLoadPage(state)) {
    return state$.pipe(
      first(canLoadPage),
      mergeMap(_ => handle(routeData, state$, dependencies)),
    );
  }

  return handle(routeData, state$, dependencies);
}

function handle({ routeName, params, options }, state$, dependencies) {
  const state = state$.value;

  if (state.user.customer && !state.user.customer.isValid)
    return of(null);

  let currentStep = options && options.step;
  if (currentStep && areLocationsEqual(state.routing.location, state.routing.navigatingTo.location, true)) {
    const page = { ...state.page };
    page.info = { ...page.info, currentStep };
    return of({ page });
  }
  currentStep = currentStep || (
    dependencies.scope === 'CLIENT'
      ? getStepByUrlHash(state.routing.navigatingTo.location.hash)
      : Steps.None
  );

  const isPromotion = routeName === RouteName.QuotePromotion;

  const isGuest = !isPromotion && !state.user.isAuthenticated && params?.guest?.toString() === 'true';
  if (!state.user.isAuthenticated) {
    if (!isGuest)
      return of({ statusCode: 401 });

    return requestAbility(AbilityTo.CheckoutAsGuest, state$, dependencies).pipe(
      mergeMap(guestCheckoutAbility => guestCheckoutAbility !== AbilityState.Available
        ? of({ statusCode: 401 })
        : _handle(),
      ),
    );
  }

  return _handle();

  function _handle() {
    let asQuote = params && params.asQuote || false;
    if (typeof asQuote === 'string')
      asQuote = asQuote.iEquals('true');

    const settings = state.settings;

    const loadShortInfo = settings
      && settings.analytics
      && settings.analytics.trackers
      && settings.analytics.trackers.length > 0;

    const isMultiStep = settings.checkout.pagePreset === CheckoutPresets.MultiStep;

    return dependencies.api.graphApi(isPromotion ? createPromotionPageQuery(isMultiStep) : createCheckoutPageQuery(isGuest, isMultiStep), {
      maxLines: state.settings.checkout.maxOverviewLines + 1,
      loadShortInfo,
      asQuote,
    }).pipe(
      mergeMap(({
        pages,
        checkout,
        viewer,
        shippingFields,
        profileFields,
      }) => {
        if (!checkout || !pages.checkout)
          return of(null);

        if (!checkout.valid) {
          const routeToRedirect = isPromotion ? routesBuilder.forQuotes() : routesBuilder.forBasket();

          return requestRoute(routeToRedirect, state$, dependencies).pipe(
            map(path => ({
              action$: of(redirectTo(path, 302, routeToRedirect)),
            })),
          );
        }

        if (checkout.editDocumentType && (checkout.editDocumentType === DocumentType.Quote) !== asQuote) {
          const checkoutRoute = routesBuilder.forCheckout(!asQuote, currentStep);
          const urlHash = currentStep !== Steps.None ? toUrlHash(currentStep) : '';

          return requestRoute(checkoutRoute, state$, dependencies).pipe(
            map(checkoutPath => ({
              action$: of(redirectTo(checkoutPath + urlHash, 302, checkoutRoute)),
            })),
          );
        }

        if (loadShortInfo) {
          const analytics = { products: getProductsToTrack(checkout.productLines) };
          checkout.analytics = analytics;
        }

        const page = pages.checkout;
        page.component = PageComponentNames.Checkout;
        page.info = checkout;
        page.info.shippingTemplateFields = shippingFields;
        page.info.currentStep = currentStep;

        adjustPaymentMethodData(page.info);
        adjustShippingMethodData(page.info);

        if (!isGuest) {
          const customer = viewer && viewer.customer;

          page.authentication = { required: true };
          page.info.billingAddress = customer && customer.billingAddress;
          page.info.shippingAddresses = customer && customer.shippingAddresses;
          page.info.isQuote = asQuote;

          if (profileFields) {
            if (page.info.billingAddress?.isPrimary && profileFields.some(f => f.type === 'EditableTemplateField'))
              page.info.canEditBilling = true;

            if (state.user.customerType === CustomerTypes.B2C
              && profileFields.some(f => f.name.includes('Email') && f.type === 'EditableTemplateField'))
              page.info.canEditEmail = true;
          }
        } else {
          page.info.isGuest = true;
          page.info.profileTemplateFields = profileFields;
          adjustGuestProfileData(page.info);
        }

        filterImages(checkout);

        if (isGuest)
          return of({ page });

        return updateShippingAddressIfNeeded(page.info, state$, dependencies)
          .pipe(map(action => ({ page, action })));
      }),
      initSystemPageContent(),
    );
  }
}

function getProductsToTrack(productLines) {
  const products = [];

  productLines.shortInfoList.forEach(pl => {
    if (pl.subLines && pl.subLines.length > 0) {
      pl.subLines.forEach(sl => {
        products.push({
          id: pl.product.id,
          uom: sl.uom,
          title: pl.product.title,
          quantity: sl.quantity,
          price: sl.subTotal,
          categoriesPaths: pl.product.categoriesPaths,
          variant: sl.variationId,
        });
      });
    }
    else {
      products.push({
        id: pl.product.id,
        uom: pl.uom,
        title: pl.product.title,
        quantity: pl.quantity,
        price: pl.subTotal,
        categoriesPaths: pl.product.categoriesPaths,
      });
    }
  });

  return products;
}
