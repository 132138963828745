export const NEWSLETTER_SUBSCRIBE = 'NEWSLETTER/SUBSCRIBE';
export const subscribeToNewsletter = (input, updatedById) => ({
  type: NEWSLETTER_SUBSCRIBE,
  payload: { input, updatedById },
});

export const NEWSLETTER_UNSUBSCRIBE = 'NEWSLETTER/UNSUBSCRIBE';
export const unsubscribeFromNewsletter = (input, updatedById) => ({
  type: NEWSLETTER_UNSUBSCRIBE,
  payload: { input, updatedById },
});

export const NEWSLETTER_UPDATED = 'NEWSLETTER/UPDATED';
export const updateNewsletterSubscription = (updatedById, email) => ({
  type: NEWSLETTER_UPDATED,
  payload: { updatedById, email },
});