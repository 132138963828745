import { tap } from 'rxjs/operators';
import { createSystemPageHandler } from '../system';

const systemHandler = createSystemPageHandler();

export default function salesAgreementsHandler(routeData, $state, dependencies) {
  const search = routeData.params && routeData.params.search;
  const activeOnly = routeData.params && routeData.params.activeOnly;

  return systemHandler(routeData, $state, dependencies)
    .pipe(
      tap(data => {
        if (data.page) {
          data.page.search = search;
          data.page.activeOnly = activeOnly == null || activeOnly;
        }
      }),
    );
}