import { PASSWORD_CHANGE, notifyPasswordChanged } from './actions';
import { switchMap, mapTo } from 'rxjs/operators';
import { changePassword } from './queries';
import { of } from 'rxjs';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormLockKeys } from 'behavior/pages';

export default (action$, _state$, { api, logger }) => action$.ofType(PASSWORD_CHANGE).pipe(
  switchMap(action => api.graphApi(changePassword, { input: action.payload }, { retries: 0 }).pipe(
    mapTo(notifyPasswordChanged()),
    catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(FormLockKeys.ChangePassword))),
    retryWithToast(action$, logger, _ => of(unlockForm(FormLockKeys.ChangePassword))),
  )),
);