import './HeroRowEffect.module.scss';
import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useResponsiveBreakpoints, useLayoutShifter } from 'utils/layout';
import { useIsVisualDesigner } from 'utils/hooks';

const HeroEffectLayout = () => {
  const isVisualDesigner = useIsVisualDesigner();

  if (isVisualDesigner)
    return;

  const layoutHeroEffect = useSelector(state => state.page?.layoutHeroEffect);
  const { headerOnTop, headerTextColor } = layoutHeroEffect || {};
  const { topShiftBlockHeight, topFixedElementsHeight } = useLayoutShifter();
  const { md, lg, xl } = useResponsiveBreakpoints();
  const isDesktop = md || lg || xl;
  const isSticky = topFixedElementsHeight - topShiftBlockHeight > 0;

  useEffect(() => {
    if (headerOnTop)
      document.getElementById('layout').dataset.heroLayout = isDesktop;
  }, [isDesktop, headerOnTop]);

  useEffect(() => {
    if (headerOnTop)
      document.getElementById('header').dataset.heroColored = !isSticky && !!headerTextColor;
  }, [isSticky, !!headerTextColor, headerOnTop]);

  useEffect(() => {
    if (headerOnTop)
      document.getElementById('header').style.top = isSticky ? '' : topShiftBlockHeight + 'px';
  }, [isSticky, topShiftBlockHeight, headerOnTop]);

  useEffect(() => {
    !headerOnTop && clearLayout();
  }, [headerOnTop]);

  useEffect(() => () => clearLayout(), []);

  return (
    <Helmet>
      {headerTextColor && <style>{`#header[data-hero-colored="true"]{color:${headerTextColor};fill:${headerTextColor}};`}</style>}
    </Helmet>
  );
};

export default memo(HeroEffectLayout);

function clearLayout() {
  const header = document.getElementById('header');

  delete document.getElementById('layout').dataset.heroLayout;
  delete header.dataset.heroColored;
  header.style.top = '';
}