import styles from './LoginInfo.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { connect } from 'react-redux';
import { logout } from 'behavior/user';

let timeoutId = null;

const LogoutButton = ({ logout, logoutDelay = 0 }) => {
  const handleClick = () => timeoutId = setTimeout(logout, logoutDelay);

  useEffect(() => () => clearTimeout(timeoutId), []);

  return (
    <div className={styles.bodyFooter}>
      <button className={`${linkStyles.link} ${linkStyles.arrowed}`} onClick={handleClick}>
        <SimpleText textKey="Logout_LinkText" />
      </button>
    </div>
  );
};

LogoutButton.propTypes = {
  logout: PropTypes.func.isRequired,
  logoutDelay: PropTypes.number,
};

export default connect(undefined, { logout })(LogoutButton);