import { ORDER_AUTHORIZATIONS_RECEIVED, AUTHORIZATIONS_PENDING_COUNT_RECEIVED } from './actions';
import { createReducer } from 'utils/redux';
import { ORDER_AUTHORIZATION_RESULT_RECEIVED } from 'behavior/pages/document';

export default createReducer(undefined, {
  [ORDER_AUTHORIZATIONS_RECEIVED]: onOrderAuthorizationsReceived,
  [AUTHORIZATIONS_PENDING_COUNT_RECEIVED]: onPendingAuthorizationsReceived,
  [ORDER_AUTHORIZATION_RESULT_RECEIVED]: onPendingAuthorizationsCountReset,
});

function onOrderAuthorizationsReceived(state, action) {
  let {
    orderAuthorizations: {
      items,
      totalCount,
    },
    pageIndex,
  } = action.payload;

  if (pageIndex > 0 && state.orderAuthorizations)
    items = state.orderAuthorizations.items.concat(items);

  return {
    ...state,
    orderAuthorizations: {
      items,
      totalCount,
    },
  };
}

function onPendingAuthorizationsReceived(state, action) {
  return {
    ...state,
    pendingAuthorizationsCount: action.payload.count,
  };
}

function onPendingAuthorizationsCountReset(state, _action) {
  return {
    ...state,
    pendingAuthorizationsCount: null,
  };
}
