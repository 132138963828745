import styles from './ProductSelector.module.scss';
import PropTypes from 'prop-types';
import { Select } from 'components/primitives/form';

const VariantDropdownRow = ({ id, value, variantComponents, onChange }) => {
  const items = variantComponents.map(({ id, name }) => ({ value: id, name }));

  return (
    <Select
      items={items}
      onChange={onChange}
      value={value}
      id={id}
      optionClassName={styles.variantOption}
      className={styles.variantSelector}
    />
  );
};

VariantDropdownRow.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  variantComponents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
};

export default VariantDropdownRow;
