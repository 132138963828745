import styles from './Basket.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { VerticalSliding } from 'components/primitives/transitions';
import { InfoAlert, alertStyles } from 'components/primitives/alerts';
import { useUpdateContext, usePendingChangesContext } from './updateContext';
import { makeSimpleText, makeRichText } from 'utils/render';

const RecalculationSuggestion = ({
  recalcMessageTextKey = 'RecalculateBasketMessage',
  messageId = 'recalcMsg',
  isQuickOrderMode,
}) => {
  const pendingChanges = usePendingChangesContext();
  const { submit } = useUpdateContext();

  const { texts: [recalcMsg, recalcBtnText] }
    = useSanaTexts([recalcMessageTextKey, 'Recalculate']);

  const handleKeyDown = e => {
    const { key, which } = e;
    const isSpaceKey = key === ' ' || which === 32;
    const isEnterKey = key === 'Enter' || which === 13;

    if (!isSpaceKey && !isEnterKey)
      return;

    e.preventDefault();
    submit();
  };
  const recalcBtn = (
    <span
      role="button"
      className={`${alertStyles.link} ${styles.btnRecalculate}`}
      onClick={submit}
      onKeyDown={handleKeyDown}
      tabIndex="0"
    >
      {makeSimpleText(recalcBtnText)}
    </span>
  );

  return (
    <VerticalSliding
      expanded={pendingChanges || isQuickOrderMode}
      role="alertdialog"
      aria-labelledby={messageId}
      duration={350}
      timingFunction="ease"
      className={styles.recalcSuggestionWrapper}
    >
      <InfoAlert className={styles.recalcMsg} id={messageId}>
        {makeRichText(recalcMsg, [recalcBtn])}
      </InfoAlert>
    </VerticalSliding>
  );
};

RecalculationSuggestion.propTypes = {
  recalcMessageTextKey: PropTypes.string,
  messageId: PropTypes.string,
  isQuickOrderMode: PropTypes.bool,
};

export default memo(RecalculationSuggestion);
