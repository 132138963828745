import styles from '../Order.module.scss';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { SimpleText } from 'components/sanaText';
import { renderHTML } from 'utils/render';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';

const AddressColumn = ({ headerTextKey, address, className = '' }) => {
  const isPreview = useIsPreview();

  if (!address && !isPreview)
    return null;

  return (
    <div className={`${styles.column} ${styles.addressesColumn} ${className}`}>
      <h2 className={`${styles.addressTitle} h4`}><SimpleText textKey={headerTextKey} /></h2>
      <div className={styles.address}>
        {!isPreview ? renderHTML(address) : <Placeholder lineCount={5} />}
      </div>
    </div>
  );
};

AddressColumn.propTypes = {
  headerTextKey: PropTypes.string.isRequired,
  address: PropTypes.string,
  className: PropTypes.string,
};

export default memo(AddressColumn);
