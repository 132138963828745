import { switchMap, takeUntil, mergeMap, startWith, exhaustMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import {
  ORDER_AUTHORIZATIONS_REQUESTED,
  authorizationsReceived,
  AUTHORIZATIONS_PENDING_COUNT_REQUESTED,
  pendingAuthorizationsCountReceived,
} from './actions';
import { authorizationsQuery, pendingAuthorizationsCountQuery } from './queries';
import { of, merge } from 'rxjs';
import { ORDER_AUTHORIZATION_RESULT_RECEIVED } from 'behavior/pages/document';

export default function (action$, _, { api, logger }) {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const authorizationResultReceived$ = action$.pipe(ofType(ORDER_AUTHORIZATION_RESULT_RECEIVED));
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  const requestOrderAuthorizations$ = action$.pipe(
    ofType(ORDER_AUTHORIZATIONS_REQUESTED),
    switchMap(({ payload: { options } }) =>
      api.graphApi(authorizationsQuery, {
        input: {
          ...options,
          authorizationStatus: options.authorizationStatus,
        },
      }).pipe(
        mergeMap(data => of(
          authorizationsReceived(data.profile.orderAuthorizations, options.page.index),
          unsetLoading,
        )),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        takeUntil(locationChanged$),
        startWith(setLoading),
      ),
    ),
  );

  const requestPendingCount$ = action$.pipe(
    ofType(AUTHORIZATIONS_PENDING_COUNT_REQUESTED),
    exhaustMap(() => {
      return api.graphApi(pendingAuthorizationsCountQuery).pipe(
        map(({ profile: { orderAuthorizations } }) =>
          pendingAuthorizationsCountReceived(orderAuthorizations && orderAuthorizations.totalCount || 0),
        ),
        retryWithToast(action$, logger),
        takeUntil(merge(locationChanged$, authorizationResultReceived$)),
      );
    }),
  );

  return merge(requestOrderAuthorizations$, requestPendingCount$);
}
