import { CUSTOMERS_REQUESTED, customersLoaded } from './actions';
import { exhaustMap, filter, map, takeUntil, observeOn, share } from 'rxjs/operators';
import { getCustomersToRepresent } from './queries';
import { retryWithToast } from 'behavior/errorHandling';
import { asyncScheduler } from 'rxjs';
import { ofType } from 'redux-observable';

export default function representationEpic(action$, _state$, { api, logger }) {
  const requests$ = action$.pipe(ofType(CUSTOMERS_REQUESTED), share());
  return requests$.pipe(
    observeOn(asyncScheduler),
    exhaustMap(action => {
      const keywords = action.payload.keywords;
      const keywordsChanged$ = requests$.pipe(
        filter(a => a.payload.keywords !== keywords),
      );
      return api.graphApi(getCustomersToRepresent, action.payload).pipe(
        filter(r => r.profile.impersonation != null),
        map(({ profile }) => customersLoaded(profile.impersonation.customers, keywords)),
        retryWithToast(action$, logger),
        takeUntil(keywordsChanged$),
      );
    }),
  );
}
