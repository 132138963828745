import modalStyles from 'components/objects/modals/Modal.module.scss';
import styles from './FullScreenPopup.module.scss';
import { useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import scrollLock from 'scroll-lock';
import { useLayoutShifter } from 'utils/layout';
import { CrossBigIcon } from 'components/primitives/icons';
import { useSimpleTexts } from 'components/sanaText';

const Popup = ({ children, hide, onKeyDown, ...props }) => {
  const { topShiftBlockHeight, bottomShiftBlockHeight } = useLayoutShifter();
  const [closeBtnTitle] = useSimpleTexts(['ButtonAltText_Close']).texts;

  const handleKeyDown = useCallback(e => {
    // 27 - Escape button keycode
    if (e.key === 'Escape' || e.which === 27) {
      hide();
      return;
    }

    onKeyDown && onKeyDown(e);
  }, []);

  const modalContainer = document.getElementById('modals');
  if (!modalContainer)
    return null;

  const modalRef = useRef(null);

  useEffect(() => {
    scrollLock.disablePageScroll();
    return scrollLock.enablePageScroll;
  }, []);

  useEffect(() => {
    if (!modalRef.current)
      return;

    modalRef.current.style.top = topShiftBlockHeight + 'px';
    modalRef.current.style.bottom = bottomShiftBlockHeight + 'px';
  }, [!!modalRef.current, topShiftBlockHeight, bottomShiftBlockHeight]);

  const modalBody = (
    <FocusLock
      shards={[
        modalRef.current,
        document.querySelector('.react-toast-notifications__container'),
      ]}
      returnFocus
    >
      {/*eslint-disable-next-line jsx-a11y/no-static-element-interactions*/}
      <div
        className={`${modalStyles.modal} ${styles.popup}`}
        onKeyDown={handleKeyDown}
        ref={modalRef}
        tabIndex="-1"
        {...props}
      >
        <div className={modalStyles.body}>
          {children}
        </div>
        <button className={styles.close} onClick={hide} title={closeBtnTitle}>
          <CrossBigIcon aria-hidden />
        </button>
      </div>
    </FocusLock>
  );

  return ReactDOM.createPortal(modalBody, modalContainer);
};

Popup.propTypes = {
  children: PropTypes.node,
  hide: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
};

export default Popup;