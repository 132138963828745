import styles from './PasswordStrengthIndicator.module.scss';
import { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/primitives/tooltip';
import { renderHTML } from 'utils/render';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';

const _IndicatorStatus = ({ opened, lblText = null, warningKey, suggestionKeys, feedbackTexts, anchorRef }) => {
  const cacheRef = useRef();
  const cache = cacheRef.current || (cacheRef.current = new Map());

  const tooltipBody = (
    <div>
      {suggestionKeys
        ? suggestionKeys.map((suggestionKey, index) => <span key={index}>{getOrRender(cache, feedbackTexts[suggestionKey])}<br /></span>)
        : getOrRender(cache, feedbackTexts[0])
      }
    </div>
  );

  const title = feedbackTexts[warningKey];

  return (
    <Tooltip id="pswd" title={title ? stripHtmlTagsAndFormatting(title) : ''} body={tooltipBody} anchorRef={anchorRef} options={opened ? { opened: true } : null} showOnTop>
      <span className={styles.status}>{getOrRender(cache, lblText)}</span>
    </Tooltip>
  );
};

_IndicatorStatus.propTypes = {
  opened: PropTypes.any,
  lblText: PropTypes.string,
  warningKey: PropTypes.any,
  suggestionKeys: PropTypes.array,
  feedbackTexts: PropTypes.arrayOf(PropTypes.string),
  anchorRef: PropTypes.object,
};

export const IndicatorStatus = memo(_IndicatorStatus, indicatorStatusComparer);

function indicatorStatusComparer(a, b) {
  if (a.opened !== b.opened
    || a.lblText !== b.lblText
    || a.warningKey !== b.warningKey
    || a.feedbackTexts !== b.feedbackTexts
  )
    return false;

  if (a.suggestionKeys === b.suggestionKeys)
    return true;

  if (!a.suggestionKeys
    || !b.suggestionKeys
    || a.suggestionKeys.length !== b.suggestionKeys.length
  )
    return false;

  return a.suggestionKeys.every((k, i) => b.suggestionKeys[i] === k);
}

/**
 * @param {Map<string,any>} cacheMap The cache.
 * @param {string} html The text to format.
 * @returns {any} Formatted text.
 */
function getOrRender(cacheMap, html) {
  const cached = cacheMap.get(html);
  if (cached)
    return cached;

  const formatted = renderHTML(html);
  cacheMap.set(formatted);

  return formatted;
}
