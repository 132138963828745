import styles from '../../Order.module.scss';
import tableStyles from 'components/primitives/table/Table.module.scss';
import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { resize$, useEventObservable } from 'utils/rxjs';
import { fixIncorrectScrollAnchoring } from 'utils/helpers';
import { usePrintMode } from 'utils/hooks';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';
import LinesFooter from '../LinesFooter';
import ProductLine from './ProductLine';
import ServiceLine from './ServiceLine';
import { useIsPreview } from 'components/objects/preview';
import PreviewLine from './PreviewLine';

const DesktopTable = ({
  lines,
  loadLines,
  options,
  itemsFolded,
  initialLinesLoaded,
  lineTexts,
  cancelled,
}) => {
  const {
    showUOM,
    isB2CCustomer,
    showPrices,
    showDiscounts,
    showStock,
    showExtraColumns,
    showShippingStatus,
  } = options;

  const linesContainerRef = useRef();
  const tableRef = useRef();
  const overflowAnchorTimeoutRef = useRef();
  const [scrollable, setScrollable] = useState(false);
  const isPrintMode = usePrintMode();
  const isPreview = useIsPreview();

  useEventObservable(resize$, checkTableScrollPos);
  useEffect(() => {
    const handleTableScroll = throttle(checkTableScrollPos, 250);
    tableRef.current.addEventListener('scroll', handleTableScroll);

    return () => {
      tableRef.current.removeEventListener('scroll', handleTableScroll);
      toggleOverflowAnchorState();
      clearTimeout(overflowAnchorTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!lines.itemLines.length && !itemsFolded && !lineTexts.length)
      return;

    requestAnimationFrame(checkTableScrollPos);
    overflowAnchorTimeoutRef.current = setTimeout(toggleOverflowAnchorState, 50);
    fixIncorrectScrollAnchoring();
  }, [lines.itemLines, itemsFolded, lineTexts]);

  return (
    <div className={styles.noScroll} ref={linesContainerRef}>
      <div className={styles.linesTableWrapper} ref={tableRef} tabIndex={scrollable ? '0' : null}>
        <table className={`${tableStyles.table} ${styles.linesTable}`}>
          <thead>
            <tr>
              <th className="visually-hidden">{lineTexts.lineStatus}</th>
              <th>{lineTexts.id}</th>
              <th>{lineTexts.title}</th>
              {showShippingStatus && <th>{lineTexts.lineShippingStatus}</th>}
              {!isB2CCustomer && <th><span title={lineTexts.shipmentDateTitle || null}>{lineTexts.shipmentDate}</span></th>}
              {showPrices && <th className={styles.linePrice}>{lineTexts.price}</th>}
              {showDiscounts && <th className={styles.lineDiscount}>{lineTexts.discount}</th>}
              <th className={styles.lineQty}>{isB2CCustomer ? lineTexts.quantity : <span title={lineTexts.quantity || null}>{lineTexts.qty}</span>}</th>
              {showUOM && <th className={styles.lineUom}><span title={lineTexts.unitOfMeasure || null}>{lineTexts.uom}</span></th>}
              {showStock && <th className={styles.lineStock}>{lineTexts.stock}</th>}
              {showExtraColumns && (
                <th className={styles.lineQtyShipped}>
                  <span title={lineTexts.quantityShipped || null}>
                    {lineTexts.linesShippedQuantity}
                  </span>
                </th>
              )}
              {showExtraColumns && (
                <th className={styles.lineQtyInvoiced}>
                  <span title={lineTexts.quantityInvoiced || null}>
                    {lineTexts.quantityInvoicedTitle}
                  </span>
                </th>
              )}
              {!isB2CCustomer &&
                <th className={styles.lineQtyOutstanding}>
                  <span title={lineTexts.outstandingQuantityAlt || null}>
                    {lineTexts.outstandingQuantity}
                  </span>
                </th>
              }
              {showPrices && <th className={styles.lineTotal}>{lineTexts.totals}</th>}
            </tr>
          </thead>
          {!isPreview
            ? !itemsFolded && (
              <tbody>
                {lines.itemLines && lines.itemLines.map((item, index) => (
                  <ProductLine
                    key={index}
                    line={item}
                    options={options}
                    cancelled={cancelled || item.isCancelled}
                    lineTexts={lineTexts}
                  />
                ))}
                {lines.serviceLines && lines.serviceLines.map((item, index) => (
                  <ServiceLine key={index} line={item} options={options} />
                ))}
              </tbody>
            )
            : (
              <tbody>
                <PreviewLine options={options} />
                <PreviewLine options={options} />
                <PreviewLine options={options} />
              </tbody>
            )
          }
        </table>
      </div>
      {!initialLinesLoaded && !isPrintMode &&
        <LinesFooter
          lines={lines}
          itemsFolded={itemsFolded}
          loadLines={loadLines}
          lineTexts={lineTexts}
        />
      }
    </div>
  );

  function checkTableScrollPos() {
    const tableWrapper = tableRef.current;
    const table = tableWrapper && tableWrapper.querySelector('table');
    if (!table)
      return;

    if (tableWrapper.scrollWidth === tableWrapper.offsetWidth) {
      linesContainerRef.current.classList.add(styles.noScroll);
      setScrollable(false);
    } else {
      setScrollable(true);
      linesContainerRef.current.classList.remove(styles.noScroll);
    }
  }
};

DesktopTable.propTypes = {
  lines: PropTypes.shape({
    serviceLines: PropTypes.array,
    totalCount: PropTypes.number.isRequired,
    itemLines: PropTypes.arrayOf(PropTypes.shape({
      discountPercentage: PropTypes.number,
    })),
  }).isRequired,
  loadLines: PropTypes.func.isRequired,
  options: PropTypes.shape({
    showShippingStatus: PropTypes.bool.isRequired,
    isB2CCustomer: PropTypes.bool.isRequired,
    showPrices: PropTypes.bool.isRequired,
    showDiscounts: PropTypes.bool.isRequired,
    showUOM: PropTypes.bool.isRequired,
    showStock: PropTypes.bool.isRequired,
    showExtraColumns: PropTypes.bool.isRequired,
  }).isRequired,
  itemsFolded: PropTypes.bool.isRequired,
  initialLinesLoaded: PropTypes.bool.isRequired,
  lineTexts: PropTypes.object,
  cancelled: PropTypes.bool.isRequired,
};

export default DesktopTable;