import ReactHtmlParser, { Transform, DomElement } from 'react-html-parser';
import imageTransformer from './transformImage';
import linkTransformer from './transformLink';
import type { ReactNode } from 'react';

const renderHTML = (html: string, ...transformers: Transform[]) => {
  const transform: Transform = _transform.bind(undefined, [...transformers, linkTransformer, imageTransformer]);

  return ReactHtmlParser(html, { transform });

  function _transform(transformers: Transform[], node: DomElement, index: number): ReactNode | void {
    for (const transformer of transformers) {
      const transformResult = transformer(node, index, transform);

      if (transformResult)
        return transformResult;
    }
  }
};

export default renderHTML;
