import styles from '../Checkout.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import BackStepLink from './BackStepLink';

const StepButtons = ({ onStepSubmit, disableNext }) => (
  <div className={styles.submitStepWrapper}>
    <BackStepLink />
    <SanaButton
      textKey="Next"
      onClick={onStepSubmit}
      className={btnStyles.noIcon}
      disabled={disableNext}
    />
  </div>
);

StepButtons.propTypes = {
  onStepSubmit: PropTypes.func,
  disableNext: PropTypes.bool,
};

export default StepButtons;
