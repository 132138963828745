import basketStyles from './Basket.module.scss';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { SimpleText } from 'components/sanaText';

const SalesAgreementControl = () => {
  const [canUseAgreement] = useHasAbilities(AbilityTo.UseSalesAgreements);
  if (canUseAgreement) {
    return (
      <Link to={routesBuilder.forSalesAgreements} className={basketStyles.useAgreement}>
        <SimpleText textKey="UseSalesAgreement" />
      </Link>
    );
  }

  return null;
};

export default SalesAgreementControl;
