exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TextArea_tar textarea{height:5.77em;resize:none;border:0;border-radius:3px;display:block;box-sizing:border-box;width:100%;outline:none;padding:.3em 1em;background:var(--inputs_BackgroundColor,#f2f2f2);border-bottom:1px solid;border-color:var(--inputs_BottomBorderColor,#c2c2c2);color:var(--inputs_FontColor,#333)}.TextArea_tar textarea:focus{background-color:var(--inputs_Focus_BackgroundColor,#fff);border-color:var(--inputs_Focus_BottomBorderColor,#1f7bc9)}.TextArea_tar ::-webkit-input-placeholder{color:var(--inputs_Placeholder_FontColor,#b2b2b2);font-style:italic}.TextArea_tar :-ms-input-placeholder{color:var(--inputs_Placeholder_FontColor,#b2b2b2);font-style:italic}.TextArea_tar ::-ms-input-placeholder{color:var(--inputs_Placeholder_FontColor,#b2b2b2);font-style:italic}.TextArea_tar ::placeholder{color:var(--inputs_Placeholder_FontColor,#b2b2b2);font-style:italic}.row-error .TextArea_tar textarea{background:var(--inputs_Error_BackgroundColor,#fcf5f5);border-color:var(--inputs_Error_BottomBorderColor,#ce8d9b);color:var(--messages_Error_FontColor,#cb2245)}.row-error .TextArea_tar ::-webkit-input-placeholder{color:var(--inputs_Placeholder_FontColor,#b2b2b2);font-style:italic}.row-error .TextArea_tar :-ms-input-placeholder{color:var(--inputs_Placeholder_FontColor,#b2b2b2);font-style:italic}.row-error .TextArea_tar ::-ms-input-placeholder{color:var(--inputs_Placeholder_FontColor,#b2b2b2);font-style:italic}.row-error .TextArea_tar ::placeholder{color:var(--inputs_Placeholder_FontColor,#b2b2b2);font-style:italic}", ""]);

// exports
exports.locals = {
	"tar": "TextArea_tar"
};