export const PRODUCT_SET_REQUESTED = 'PRODUCT_SET_REQUESTED';
export const requestProductSet = (contentBlockId, productSetId, size, sorting, calculate) => ({
  type: PRODUCT_SET_REQUESTED,
  payload: { contentBlockId, productSetId, size, sorting, calculate },
});

export const PRODUCT_SET_RECEIVED = 'PRODUCT_SET_RECEIVED';
export const productSetReceived = (contentBlockId, products, calculated) => ({
  type: PRODUCT_SET_RECEIVED,
  payload: { contentBlockId, products, calculated },
});