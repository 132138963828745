//3.1.Econ – configuration functionality
import styles from '../Order.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useState, useEffect } from 'react';
import { SanaButton } from 'components/primitives/buttons';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Modal } from 'components/objects/modals';
import IFrame from './EconIFrame';
import { createUserToken } from 'behavior/pages/document/actions';

const ConfigureButton = ({ configurationId, Settings, Page, Localization }) => {
  let src = '';
  let sessionId = '';
  const dispatch = useDispatch();


  const [addFormUrl, setAddFormUrl] = useState('');
  const [isModalOpen, setModalStatus] = useState(false);
  const [shopLanguage, setShopLanguage] = useState('');

  //Setting the language of the webshop
  useEffect(() => {
    setShopLanguage(Localization.currentLanguage.cultureName);
    let hostUrl = encodeURIComponent(`${window.location.protocol}//${window.location.host}`);
    src = `${Settings.econUrl}?environment=${Settings.econEnvironment}&model=${Settings.econModel}&modelversion=${Settings.econModelVersion}&sessionid=${sessionId}&theme=${Settings.econTheme}&context=${Settings.econContext}&language=${Localization.currentLanguage.cultureName}&formatlanguage=${Localization.currentLanguage.cultureName}&parentdomain=${hostUrl}&configuration=${configurationId}`;

    setAddFormUrl(src);

  }, [Localization.currentLanguage]);

  //Getting the session id for the user
  useEffect(() => {
    dispatch(createUserToken());
  }, []);

  //Updating the session id for the user
  useEffect(() => {
    if (Page.userToken) {
      sessionId = JSON.stringify(Page.userToken).replace(/["{}"]/g, '');
      let hostUrl = encodeURIComponent(`${window.location.protocol}//${window.location.host}`);
      src = `${Settings.econUrl}?environment=${Settings.econEnvironment}&model=${Settings.econModel}&modelversion=${Settings.econModelVersion}&sessionid=${sessionId}&theme=${Settings.econTheme}&context=${Settings.econContext}&language=${shopLanguage}&formatlanguage=${shopLanguage}&parentdomain=${hostUrl}&configuration=${configurationId}`;

      setAddFormUrl(src);

    }

  }, [Page.userToken, src, Settings]);

  const openEcon = () => {
    setModalStatus(true);
  }

  const closeEcon = () => {
    setModalStatus(false);
  }

  useEffect(() => {
    function econComplete(e) {
      if (e.source !== "react-devtools-content-script" && (typeof e.data === 'string' || e.data.toString().substring(0, 6) === "close:")) {
        try {
          setModalStatus(false);
          window.location.reload();
        }
        catch (err) {
          setModalStatus(false);
        }

      } 
    }

    window.addEventListener('message', econComplete);

    return () => {
      window.removeEventListener('message', econComplete);
    };
  });

  return (
    <>
      <SanaButton
        className={`${btnStyles.btnSmall} ${styles.btnAction} ${styles.btnEdit}`}
        textKey="Configure"
        onClick={openEcon}
      />
      <Modal opened={isModalOpen} hide={closeEcon} fullscreen={true}>
        <IFrame source={addFormUrl} />
        </Modal>
    </>
  );
};

const mapStateToProps = ({ settings, page, localization }) => ({
  Settings: settings,
  Localization: localization,
  Page: page,
});

export default connect(mapStateToProps)(ConfigureButton);
