import Field, { fieldPropTypes } from '../Field';
import { Input } from 'components/primitives/form';

const PasswordField = ({ fieldName, fieldTitle, validation, ...attributes }) => (
  <Field
    type="password"
    fieldName={fieldName}
    fieldTitle={fieldTitle}
    validation={validation}
    autoComplete="off"
    as={Input}
    {...attributes}
  />
);

PasswordField.propTypes = fieldPropTypes;

export default PasswordField;