import { storageKey } from './constants';

export function createApiVariables(localStorage, count, skipFirst) {
  const products = localStorage.getItem(storageKey) || [];
  if (!products.length)
    return undefined;

  let filteredProducts = products, start = 0;

  if (skipFirst)
    start = 1;

  if (start || count)
    filteredProducts = filteredProducts.slice(start, count ? count + start : count);

  if (!filteredProducts.length)
    return undefined;

  return {
    options: {
      ids: filteredProducts,
      page: {
        index: 0,
        size: filteredProducts.length,
      },
    },
  };
}

export function sortProducts(products, variables) {
  if (!products || !products.length)
    return products;
  const ids = variables.options.ids;
  return products
    .sort((first, second) => ids.indexOf(first.id.toLowerCase()) - ids.indexOf(second.id.toLowerCase()));
}
