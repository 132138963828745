import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DomHead } from 'components/primitives/dom';

const Injection = ({ htmlInjections }) => {
  if (!htmlInjections || !htmlInjections.head)
    return null;

  return <DomHead content={htmlInjections.head} />;
};

Injection.propTypes = {
  htmlInjections: PropTypes.shape({
    head: PropTypes.string,
  }),
};

export default connect(({ settings: { htmlInjections } }) => ({ htmlInjections }))(Injection);