import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from './hooks';
import { makeSimpleText } from 'utils/render';

const SimpleText = ({
  textKey,
  children,
  formatWith,
  placeholder = null,
  formatAsHtml = false,
  disableInsiteEditor = false,
}) => {
  const options = [{ key: textKey, fallback: children }];
  options.disableInsiteEditor = disableInsiteEditor;

  const { texts: [text], loaded } = useSanaTexts(options);

  if (!loaded)
    return placeholder;

  return makeSimpleText(text, formatWith, formatAsHtml);
};

SimpleText.propTypes = {
  textKey: PropTypes.string.isRequired,
  formatWith: PropTypes.array,
  children: PropTypes.string,
  placeholder: PropTypes.node,
  formatAsHtml: PropTypes.bool,
  disableInsiteEditor: PropTypes.bool,
};

export default memo(SimpleText);