import styles from './Page.module.scss';
import { useSelector } from 'react-redux';
import { RowRails, WithSwipeButtons } from './swiper';
import StickyPanel from './StickyPanel';
import PreviewProductTile from './PreviewProductTile';

const PreviewComparisonRow = () => {
  const productsCount = useSelector(({ page }) => page.previewProductsCount);

  return (
    <StickyPanel>
      {isSticky => (
        <WithSwipeButtons className={`${styles.tilesRow} ${isSticky ? styles.compact : ''}`}>
          <RowRails items={Array.from(Array(productsCount))}>
            {() => <PreviewProductTile compact={isSticky} />}
          </RowRails>
        </WithSwipeButtons>
      )}
    </StickyPanel>
  );
};

export default PreviewComparisonRow;