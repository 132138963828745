exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RemoveButton_btn-remove-item{cursor:pointer;border:none;background:transparent;width:20px;height:20px;position:absolute;margin:auto 0;padding:1px;color:#828282}html.pointer-mouse .RemoveButton_btn-remove-item:hover{opacity:.75}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .RemoveButton_btn-remove-item:active{opacity:.75}}}.RemoveButton_remove-icon{max-width:18px;max-height:18px;fill:#828282}", ""]);

// exports
exports.locals = {
	"btn-remove-item": "RemoveButton_btn-remove-item",
	"btnRemoveItem": "RemoveButton_btn-remove-item",
	"remove-icon": "RemoveButton_remove-icon",
	"removeIcon": "RemoveButton_remove-icon"
};