import { createReducer } from 'utils/redux';
import {
  PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_FAILED,
  PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_RECEIVED,
  PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED,
  PRODUCT_CONFIGURATOR_FINISHED,
} from './actions';

const newConfigurationFailed = (_, action) => ({
  configuratorUrl: null,
  configurationId: null,
  updatedById: action.payload,
});

const newConfigurationReceived = (state, action) => ({
  ...state,
  configuratorUrl: action.payload.configuratorUrl,
  configurationId: action.payload.configurationId,
});

const messageReceived = (state, action) => ({
  ...state,
  message: action.payload.message,
});

const configuratorFinished = (state, _) => {
  if (!state.configuratorUrl && !state.configurationId) {
    return state;
  }

  return {
    ...state,
    configuratorUrl: null,
    configurationId: null,
  };
};

export default createReducer(null, {
  [PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_FAILED]: newConfigurationFailed,
  [PRODUCT_CONFIGURATOR_NEW_CONFIGURATION_RECEIVED]: newConfigurationReceived,
  [PRODUCT_CONFIGURATOR_MESSAGE_RECEIVED]: messageReceived,
  [PRODUCT_CONFIGURATOR_FINISHED]: configuratorFinished,
});