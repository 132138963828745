exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LayoutShiftProvider_bottom-shift-block,.LayoutShiftProvider_top-shift-block{z-index:9400000;position:fixed;left:0;right:0;transform:translateZ(0)}.LayoutShiftProvider_top-shift-block{top:0}.LayoutShiftProvider_bottom-shift-block{bottom:0}", ""]);

// exports
exports.locals = {
	"bottom-shift-block": "LayoutShiftProvider_bottom-shift-block",
	"bottomShiftBlock": "LayoutShiftProvider_bottom-shift-block",
	"top-shift-block": "LayoutShiftProvider_top-shift-block",
	"topShiftBlock": "LayoutShiftProvider_top-shift-block"
};