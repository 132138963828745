import styles from './Header.module.scss';
import { memo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'components/primitives/grid';
import { LoginInfo } from 'components/objects/loginInfo';
import { LanguageSelector } from 'components/objects/languageSelector';
import { Logo } from 'components/primitives/logo';
import { Search } from 'components/objects/search';
import { DesktopNav } from 'components/objects/navigation';
import CustomerServicePage from './CustomerServicePage';
import TopInfo from './TopInfo';
import { BasketSummary } from 'components/objects/basket';
import ShoppingCartSuggestions from './ShoppingCartSuggestions';
import { HEADER_SEARCH_BOX_ID } from './constants';
import { useSelector } from 'react-redux';
import { SimpleText } from 'components/sanaText';

const DesktopHeaderTemplate = ({ setDesktopNavHoverStatus }) => {

  //TICKET 126282 - [KWB] [New Requirement] - Show the representing customer's name for Sales agents in the header
  const isImpersonating = useSelector(state => state.user.isImpersonating);
  const customerName = useSelector(state => state.user.customer?.name) ||'';
  const [representedCustomerName, setRepresentedCustomerName] = useState('');

  useEffect(() => {
    if (isImpersonating) {
      setRepresentedCustomerName(customerName);
    }
  }, [isImpersonating,customerName]);

    return (
      <>
        <Container className={styles.desktopTopContainer}>
          <Row>
            <Col xs={6} className={styles.column}>
              <TopInfo />
            </Col>
            <Col xs={6} className={`${styles.column} ${styles.actionsBlock}`}>
              {/*TICKET 126282 - [KWB] [New Requirement] - Show the representing customer's name for Sales agents in the header*/}
              {isImpersonating &&
                <div className={styles.representInfoHeader}>
                  <SimpleText textKey="Representing_Header" /> <span className="customer-name"><b>{representedCustomerName}</b></span>
                </div>
              }
              <LanguageSelector />
              <CustomerServicePage />
              <LoginInfo showLoginLink />
            </Col>
          </Row>
        </Container>
        <div className={styles.desktopMiddleContainer}>
          <Container className={styles.contentWrapper}>
            <Row crossAxisAlign="center">
              <Col xs={3}>
                <Logo />
              </Col>
              <Col xs={6}>
                <Search id={HEADER_SEARCH_BOX_ID} />
              </Col>
              <Col xs={3} className={`${styles.column} ${styles.shoppingCartSection}`}>
                <ShoppingCartSuggestions className={styles.suggestions} hideLabel />
                <BasketSummary />
              </Col>
            </Row>
          </Container>
        </div>
        <div className={styles.mainNavContainer}>
          <DesktopNav setDesktopNavHoverStatus={setDesktopNavHoverStatus} />
        </div>
      </>
    );
};

DesktopHeaderTemplate.propTypes = {
  setDesktopNavHoverStatus: PropTypes.func,
};

export default memo(DesktopHeaderTemplate);