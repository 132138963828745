import styles from './Search.module.scss';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useOnChange } from 'utils/hooks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autosuggest, highlightStringBySpans } from 'components/primitives/autosuggest';
import { escapeRegexCharacters } from 'utils/helpers';
import { debounce } from 'lodash';
import { useHeaderContext } from 'components/sections/headerContext';
import SuggestionItem from './SuggestionItem';
import { SearchContainerContext } from './SearchContainerContext';
import SuggestionsContainer from './SuggestionsContainer';
import { searchProducts, searchProductsClear } from 'behavior/products/search/suggestions';
import { navigateTo } from 'behavior/events';
import { RouteName } from 'routes';
import { defaultSearchParams } from './constants';
import { trackProductClick, EVENT_SOURCES } from 'behavior/analytics';
import { SearchButtonIcon } from 'components/primitives/icons';

const defaultDependencies = [];

const SearchBox = ({
  placeholder,
  searchTitle,
  searchProducts,
  searchProductsClear,
  suggestions,
  navigateTo,
  trackProductClick,
  routePath,
  noImageUrl,
  viewMode,
  routeData,
  prevRouteData,
  className = '',
  onFocus,
  onBlur,
  id,
  designMode,
}) => {
  const headerContext = useHeaderContext();
  const formRef = useRef(null);
  const highlightedSuggestionRef = useRef();
  const [maxHeight, setMaxHeight] = useState('');
  const [value, setValue] = useState(headerContext.searchInputValue || '');

  if (headerContext.available)
    headerContext.searchInputValue = value;

  useOnChange(() => {
    if (!headerContext.available) {
      setValue('');
      return;
    }

    const routeName = routeData?.routeName;
    if (routeName === RouteName.Search && routeData.params.q) {
      setValue(routeData.params.q);
      return;
    }

    if (!value)
      return;

    const prevRouteName = prevRouteData?.routeName;

    if (
      prevRouteName
      && prevRouteName === routeName
      && prevRouteData.params.language !== routeData.params.language
    )
      return;

    if (
      prevRouteName === RouteName.Search
      && routeName === RouteName.ProductDetails
    )
      return;

    setValue('');
  }, [routeData, prevRouteData], false);

  useEffect(() => {
    const resizeHandler = debounce(() => {
      if (!formRef.current)
        return;

      setMaxHeight(calculateMaxHeight(formRef.current));
    }, 250);

    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [formRef.current]);

  const search = useMemo(
    () => debounce(value => !designMode && searchProducts(value), 250),
    [designMode],
  );

  const onFetchRequested = useCallback(({ value, reason }) => {
    setMaxHeight(calculateMaxHeight(formRef.current));
    if (reason === 'input-changed' && value.length > 1)
      search(value);
  }, defaultDependencies);

  const onSelected = useCallback((event, { suggestion }) => {
    event.preventDefault();
    searchProductsClear();
    setValue('');
    document.getElementById('layout').focus();
    trackProductClick(suggestion, EVENT_SOURCES.searchInput);
    navigateTo(suggestion.routeData, suggestion.url);
  }, defaultDependencies);

  const onSubmit = useCallback(event => {
    event.preventDefault();
    searchProductsClear();
    const encodedValue = encodeURIComponent(value);
    const searchURL = routePath.replace(defaultSearchParams, encodedValue);

    navigateTo({ routeName: RouteName.Search, params: { q: value, viewMode } }, searchURL);

    document.getElementById('layout').focus();
  }, [routePath, value]);

  const onHighlighted = ({ suggestion }) => {
    highlightedSuggestionRef.current = suggestion;
  };

  const renderItem = useCallback(suggestion => <SuggestionItem suggestion={suggestion} />, defaultDependencies);
  const onChange = useCallback((_event, { newValue }) => setValue(newValue), defaultDependencies);

  const onKeyDown = e => {
    if (highlightedSuggestionRef.current && (e.key === 'Tab' || e.which === 9)) {
      onSelected(e, { suggestion: highlightedSuggestionRef.current });
      e.preventDefault();
    }
  };

  const inputProps = {
    id,
    placeholder,
    value,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    name: 'q',
    type: 'search',
  };

  const memorizedSuggestions = useMemo(() => getSuggestions(value, suggestions, noImageUrl), [suggestions]);
  const searchPathWithoutParams = useMemo(() => routePath ? routePath.split('?')[0] : '', [routePath]);

  return (
    <div className={`${styles.searchBox} ${className}`}>
      <form role="search" method="get" action={searchPathWithoutParams} onSubmit={onSubmit} ref={formRef}>
        <SearchContainerContext.Provider value={{ maxHeight }}>
          <Autosuggest
            suggestions={memorizedSuggestions}
            onFetchRequested={onFetchRequested}
            onClearRequested={searchProductsClear}
            onSelected={onSelected}
            onHighlighted={onHighlighted}
            getItemValue={getItemValue}
            renderItem={renderItem}
            renderItemsContainer={SuggestionsContainer}
            inputProps={inputProps}
            theme={styles}
            id={id} // Suffix to be added to 'react-autowhatever-' to resolve ID duplicates conflict.
          />
        </SearchContainerContext.Provider>
        <button type="submit" className={styles.submit} title={searchTitle}>
          <SearchButtonIcon className={styles.searchIcon} aria-hidden />
        </button>
      </form>
    </div>
  );
};

const routeDataPropType = PropTypes.shape({
  routeName: PropTypes.string,
  params: PropTypes.shape({
    q: PropTypes.string,
    language: PropTypes.number,
  }),
});

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  searchTitle: PropTypes.string,
  searchProducts: PropTypes.func.isRequired,
  searchProductsClear: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  routePath: PropTypes.string,
  navigateTo: PropTypes.func.isRequired,
  trackProductClick: PropTypes.func.isRequired,
  noImageUrl: PropTypes.string,
  viewMode: PropTypes.string,
  routeData: routeDataPropType,
  prevRouteData: routeDataPropType,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string.isRequired,
  designMode: PropTypes.bool,
};

const mapStateToProps = ({
  suggestions,
  settings: { product, search },
  routing: { routeData, previous: { routeData: prevRouteData } },
}) => ({
  suggestions: suggestions.products,
  noImageUrl: product ? product.noImage.small : null,
  viewMode: search && search.defaultViewMode,
  routeData,
  prevRouteData,
});

export default connect(
  mapStateToProps,
  { searchProducts, searchProductsClear, navigateTo, trackProductClick },
)(SearchBox);

function getSuggestions(value, products, noImageUrl) {
  const escapedValue = escapeRegexCharacters(value.trim());
  const regex = new RegExp(`(${escapedValue})`, 'gi');

  return products
    .map(product => ({
      ...product,
      imageUrl: product.imageUrl ? product.imageUrl : noImageUrl,
      highlightedText: highlightStringBySpans(`${product.title} - ${product.id}`, regex, styles.highlight),
    }),
    );
}

function getItemValue(suggestion) {
  return suggestion.title;
}

function calculateMaxHeight(form) {
  const innerHeight = window.innerHeight;
  return innerHeight - form.getBoundingClientRect().bottom + 'px';
}
