import styles from '../Order.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { makeSimpleText } from 'utils/render';

const LinesFooter = ({ lines, itemsFolded, loadLines, lineTexts }) => {
  if (!lines.totalCount)
    return null;

  const totalCount = <span className={styles.totalCount}>{lines.totalCount}</span>;

  return (
    <div className={styles.linesFooter}>
      <span>{makeSimpleText(lineTexts.itemsInCart, [totalCount], true)}</span>
      <div>
        <button
          className={`${linkStyles.link} ${styles.toggler}`}
          onClick={loadLines}
        >
          {itemsFolded ? lineTexts.showLinesText : lineTexts.hideLinesText}
        </button>
      </div>
    </div>
  );
};

LinesFooter.propTypes = {
  lines: PropTypes.shape({
    totalCount: PropTypes.number.isRequired,
  }).isRequired,
  columnsCount: PropTypes.number,
  itemsFolded: PropTypes.bool.isRequired,
  loadLines: PropTypes.func.isRequired,
  lineTexts: PropTypes.shape({
    itemsInCart: PropTypes.string,
    showLines: PropTypes.string,
    hideLines: PropTypes.string,
  }).isRequired,
};

export default LinesFooter;
