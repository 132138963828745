import DateOnly from 'date-only';

export default context => value => {
  const { maxDate } = context.params;
  if (!maxDate)
    return;

  const parsedValue = DateOnly.parse(value);
  if (!parsedValue)
    return;

  if (DateOnly.compare(maxDate, parsedValue) < 0)
    return context.errorMessage;
};