export const ImageBehaviors = {
  None: 'None',
  OpenInOverlay: 'OpenInOverlay',
  UseLink: 'UseLink',
};

export const ImageScalings = {
  ByWidth: 'ByWidth',
  ByHeight: 'ByHeight',
  DoNotScale: 'DoNotScale',
};
