import styles from './Grid.module.scss';
import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { asPropTypes } from './PropTypes.js';

const Container = forwardRef(({ as: Tag = 'div', className = '', fluid, children, ...attributes }, ref) => (
  <Tag className={getContainerClass(fluid, className)} {...attributes} ref={ref}>
    {children}
  </Tag>
));

Container.displayName = 'Container';

Container.propTypes = {
  as: asPropTypes,
  className: PropTypes.string,
  fluid: PropTypes.bool,
  children: PropTypes.node,
};

export default memo(Container);

function getContainerClass(fluid, className) {
  const classList = [];
  !fluid && classList.push(styles.container);
  !!className && classList.push(className);
  return classList.join(' ');
}