exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";.NewsOverview_side-header{text-transform:uppercase;padding-top:.25em;margin-bottom:-.5em}.NewsOverview_side-list{margin:0;padding:0}.NewsOverview_side-item{list-style:none;margin:.5em 0 .5em .7em;line-height:1.38}.NewsOverview_side-item:before{content:\"\\203A\";float:left;margin-left:-.7em}.NewsOverview_side-item a{color:var(--sideLinks_News_FontColor,#4c4c4c)}html.pointer-mouse .NewsOverview_side-item a:hover{color:var(--sideLinks_News_FontColor,#4c4c4c)}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .NewsOverview_side-item a:active{color:var(--sideLinks_News_FontColor,#4c4c4c)}}}.NewsOverview_link-more{font-weight:700}.NewsOverview_overviewList{margin-bottom:2em}.NewsOverview_overviewList ul{padding:0;margin:0;list-style:none}.NewsOverview_header{text-transform:uppercase;margin-bottom:-.5em}.NewsOverview_item{margin:.7em 0;padding:0 0 .7em;border-bottom:1px solid #e6e6e6}.NewsOverview_item:after{content:\"\";display:table;clear:both}.NewsOverview_item:last-of-type{border-bottom:none}.NewsOverview_link{color:#333;font-weight:700}html.pointer-mouse .NewsOverview_link:hover{color:#333}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .NewsOverview_link:active{color:#333}}}.NewsOverview_date{white-space:nowrap;margin-left:.7em}@media (min-width:var(--breakpoints_Medium,960)px),print{.NewsOverview_headlines{margin-bottom:2em}}", ""]);

// exports
exports.locals = {
	"side-header": "NewsOverview_side-header",
	"sideHeader": "NewsOverview_side-header",
	"side-list": "NewsOverview_side-list",
	"sideList": "NewsOverview_side-list",
	"side-item": "NewsOverview_side-item",
	"sideItem": "NewsOverview_side-item",
	"link-more": "NewsOverview_link-more",
	"linkMore": "NewsOverview_link-more",
	"overviewList": "NewsOverview_overviewList",
	"header": "NewsOverview_header",
	"item": "NewsOverview_item",
	"link": "NewsOverview_link",
	"date": "NewsOverview_date",
	"headlines": "NewsOverview_headlines"
};