import styles from '../Order.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { connect } from 'react-redux';
import { reorder } from 'behavior/pages/document';
import { Placeholder } from 'components/primitives/placeholders';
import useReorderFeedback from './useReorderFeedback';
import { ReorderResult } from 'behavior/documents';
import useConfirmation from 'components/objects/confirmation';
import { RichText } from 'components/sanaText';

const ReorderButton = ({
  document: {
    id: internalDocumentId,
    orderId,
    canReorder,
    lines: {
      totalCount,
    },
  },
  reorder,
  reorderResult,
  documentNotifications,
}) => {
  const { showConfirmation, clearConfirmation, confirmationModal } = useConfirmation();
  const initialCanReorderRef = useRef(canReorder);
  const [reorderPending, setReorderPending] = useState(false);

  const handleClick = forceClear => {
    documentNotifications.hideOrderNotifications();
    setReorderPending(true);
    reorder(internalDocumentId, orderId, forceClear);
  };

  const confirmationControl = {
    hide: clearConfirmation,
    show() {
      showConfirmation(
        handleClick.bind(null, true),
        'Info',
        <RichText
          textKey="ProductsFromCurrentBasketWillBeRemoved"
          placeholder={<Placeholder className={styles.confirmationTextPlaceholder} />}
        />,
        {
          okText: 'ButtonText_Continue',
        },
        undefined,
        styles.basketNotEmptyPopup,
      );
    },
  };
  useEffect(() => {
    if (reorderResult == null || reorderResult === ReorderResult.Success)
      return;

    setReorderPending(false);
  }, [reorderResult]);

  useReorderFeedback(confirmationControl, reorderResult, documentNotifications);

  if (initialCanReorderRef.current === false || totalCount === 0)
    return null;

  const btnPlaceholder = <Placeholder className={styles.btnActionPlaceholder} />;

  return (
    <>
      <SanaButton
        className={`${btnStyles.btnAction} ${btnStyles.btnSmall} ${styles.btnAction} ${styles.btnReorder}`}
        textKey="Reorder"
        placeholder={btnPlaceholder}
        onClick={reorderPending ? null : handleClick.bind(null, false)}
        aria-disabled={reorderPending}
        tabIndex={reorderPending ? '-1' : null}
      />
      {confirmationModal}
    </>
  );
};

ReorderButton.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
    orderId: PropTypes.string,
    canReorder: PropTypes.bool,
    lines: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  reorder: PropTypes.func.isRequired,
  documentNotifications: PropTypes.shape({
    orderNotFound: PropTypes.func.isRequired,
    orderHasNoProducts: PropTypes.func.isRequired,
    hideOrderNotifications: PropTypes.func.isRequired,
  }).isRequired,
  reorderResult: PropTypes.string,
};

const mapStateToProps = ({ page: { reorderResult } }) => ({ reorderResult });

export default connect(mapStateToProps, { reorder })(ReorderButton);
