import { redirectTo } from '../actions';
import { createLocation } from '../helpers';
import { destroyApp } from 'behavior/app';
import { isAbsoluteUrl } from 'utils/url';

export function redirect(url, location, route, dependencies) {
  const urlLocation = createLocation(url);
  if (urlLocation.pathname === location.pathname)
    return;

  if (dependencies.scope === 'CLIENT' && isAbsoluteUrl(url)) {
    window.location = url;
    return destroyApp();
  }

  return redirectTo(url, 301, route);
}
