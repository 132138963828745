import styles from './TermsAndAgreements.module.scss';
import formStyles from 'components/objects/forms/SanaForm.module.scss';
import PropTypes from 'prop-types';
import {
  ErrorMessage,
  createLabelId,
  createFieldId,
  createErrorMsgId,
} from 'components/objects/forms';
import { CheckboxField } from 'components/objects/forms/fields';
import { getIn, useFormikContext } from 'formik';
import InfoButton, { btnId } from './Info';

const CheckboxFormGroup = ({ fieldName, errorText, title, infoTexts }) => {
  const formik = useFormikContext();

  const fieldError = getIn(formik.touched, fieldName) && getIn(formik.errors, fieldName);

  const formName = formik.status.formName;
  const labelId = createLabelId(formName, fieldName),
    fieldId = createFieldId(formName, fieldName),
    errorId = createErrorMsgId(formName, fieldName);

  return (
    <div aria-labelledby={labelId} className={styles.termsContainer}>
      <label id={labelId} htmlFor={fieldId}>{title} </label>
      <InfoButton {...infoTexts} />
      <CheckboxField
        className={styles.chb}
        fieldName={fieldName}
        validation={{ required: { message: errorText, validateAgainstFalse: true } }}
        aria-labelledby={labelId + ' ' + btnId}
      />
      <div aria-live="polite" className={formStyles.validation}>
        <ErrorMessage fieldName={fieldName} id={errorId}>{fieldError}</ErrorMessage>
      </div>
    </div>
  );
};

CheckboxFormGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  title: PropTypes.string.isRequired,
  infoTexts: PropTypes.object.isRequired,
};

export default CheckboxFormGroup;
