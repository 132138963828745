export const ARIA_STATUS_SET = 'ARIA_STATUS_SET' as const;
export const setStatusTextKey = (key: string) => ({
  type: ARIA_STATUS_SET,
  payload: { key },
});
export const setStatusText = (text: string) => ({
  type: ARIA_STATUS_SET,
  payload: { text },
});

export type AriaStatusAction = ReturnType<
  | typeof setStatusTextKey
  | typeof setStatusText
>
