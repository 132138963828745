import { useState, useMemo } from 'react';
import { noop } from 'rxjs';
import { Modal, useModal } from 'components/objects/modals';
import Confirmation, { HideReason } from './Confirmation';
export { HideReason } from './Confirmation';

export default function useConfirmation() {
  const [confirmationState, setConfirmationState] = useState({
    action: noop,
  });

  const { opened, show, hide } = useModal();
  const confirmationModal = useMemo(() => {
    if (!opened)
      return null;

    const {
      onClose,
      action,
      titleTextKey,
      body,
      buttonsTextKeys,
      className,
    } = confirmationState;

    const handleClose = reason => {
      onClose && onClose(reason);
      hide();
    };

    return (
      <Modal hide={handleClose.bind(null, HideReason.Close)} opened={opened} className={className}>
        <Confirmation
          close={handleClose}
          action={action}
          titleTextKey={titleTextKey}
          body={body}
          buttonsTextKeys={buttonsTextKeys}
        />
      </Modal>
    );
  }, [confirmationState, opened]);

  const showConfirmation = (action, titleTextKey, body, buttonsTextKeys, onClose, className) => {
    show();
    setConfirmationState({
      action,
      titleTextKey,
      body,
      buttonsTextKeys,
      onClose,
      className,
    });
  };

  return { clearConfirmation: hide, showConfirmation, confirmationModal };
}