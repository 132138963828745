import PropTypes from 'prop-types';
import FieldLabelCore from '../FieldLabel';

const FieldLabel = ({
  fieldName,
  children,
  required,
  focusToFieldOnClick,
  className,
}) => (
    <FieldLabelCore
      fieldName={fieldName}
      required={required}
      notNative={focusToFieldOnClick}
      className={className}
    >
      {children}
    </FieldLabelCore>
  );

FieldLabel.propTypes = {
  fieldName: PropTypes.string.isRequired,
  children: PropTypes.node,
  required: PropTypes.bool,
  focusToFieldOnClick: PropTypes.bool,
  className: PropTypes.string,
};

export default FieldLabel;
