import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, mergeMap } from 'rxjs/operators';
import { FEEDBACK_SUBMITTED, feedbackReceived } from './actions';
import { feedbackMutation } from './queries';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { resetCaptcha } from 'behavior/captcha';
import { unlockForm, FormLockKeys } from 'behavior/pages';

const feedbackEpic = (action$, _, { api, logger }) => action$.pipe(
  ofType(FEEDBACK_SUBMITTED),
  switchMap(action => api.graphApi(feedbackMutation, { data: action.payload }, { retries: 0 }).pipe(
    mergeMap(result => [feedbackReceived(result.contactUs), resetCaptcha(), unlockForm(FormLockKeys.ContactUs)]),
    catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha(), unlockForm(FormLockKeys.ContactUs))),
    retryWithToast(action$, logger, _ => of(resetCaptcha(), unlockForm(FormLockKeys.ContactUs))),
  )),
);

export default feedbackEpic;