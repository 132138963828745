import { Subject, EMPTY, of } from 'rxjs';
import { delay, catchError, takeUntil, mergeMap } from 'rxjs/operators';

export default function (value, errorMessage, formikContext, key, validationHandler) {
  const prevRunKey = key + '_prev';

  const prev = formikContext.status[prevRunKey] || (formikContext.status[prevRunKey] = {});
  if (prev.value === value) {
    if (typeof prev.result === 'string' && prev.result !== errorMessage) {
      // Translated text arrived.
      return prev.result = errorMessage;
    }
    return prev.result;
  }

  prev.value = value;

  const subj = formikContext.status[key] || (formikContext.status[key] = new Subject());

  return prev.result = new Promise(resolve => {
    subj.next(value);

    const stream$ = of(value).pipe(
      delay(400),
      mergeMap(validationHandler),
      takeUntil(subj),
      catchError(_ => EMPTY),
    );

    let resolved = false;
    stream$.subscribe({
      next: valid => {
        resolved = true;
        prev.result = valid ? undefined : errorMessage;
        resolve(prev.result);
      },
      complete: () => !resolved && resolve(),
    });
  });
}