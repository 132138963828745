import styles from '../SubAccount.module.scss';
import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SanaForm, useFormLockerOnSubmit } from 'components/objects/forms';
import { useDispatch } from 'react-redux';
import { submitSubAccount } from 'behavior/pages/subAccounts';
import Limits from './Limits';
import GeneralErrorMessage from './GeneralErrorMessage';
import { FormLockKeys } from 'behavior/pages';
import SubAccountFields from './SubAccountFields';
import SubAccountFormSubmitRow from './SubAccountFormSubmitRow';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const FORM_NAME = 'SubAccount';

const SubAccountForm = ({ account, options = {}, authorizers }) => {
  const dispatch = useDispatch();
  const onSubmit = useFormLockerOnSubmit(data => dispatch(submitSubAccount(data)), FormLockKeys.SubAccount, []);
  const initialValues = useMemo(() => {
    if (account) {
      const result = { ...account };
      delete result.dependentAccounts;
      return result;
    }
    return {
      active: true,
      limits: [],
      canAuthorizeOrders: false,
      canOrder: true,
      canSeePrices: true,
      canSeeStock: true,
    };
  }, [account]);

  const validateAuthorization = useCallback(values => {
    if (!account)
      return;

    if (values.canAuthorizeOrders && values.active)
      return;

    if (account.dependentAccounts.length)
      return { general: 'DEPEND' };
  }, [account]);

  return (
    <SanaForm
      key={account ? account.id : FORM_NAME}
      name={FORM_NAME}
      initialValues={initialValues}
      onSubmit={useHandlerLockerInPreview(onSubmit)}
      validate={validateAuthorization}
      className={styles.form}
    >
      <GeneralErrorMessage account={account} />
      <SubAccountFields {...options} />
      <Limits authorizers={authorizers} />
      <SubAccountFormSubmitRow />
    </SanaForm>
  );
};

SubAccountForm.propTypes = {
  account: PropTypes.shape({
    active: PropTypes.bool,
    limits: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number.isRequired,
      authorizerId: PropTypes.string.isRequired,
    }).isRequired),
    dependentAccounts: PropTypes.array.isRequired,
    canAuthorizeOrders: PropTypes.bool,
    canOrder: PropTypes.bool,
    canSeePrices: PropTypes.bool,
    canSeeStock: PropTypes.bool,
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  options: PropTypes.object,
  authorizers: PropTypes.object.isRequired,
};

export default SubAccountForm;