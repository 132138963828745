const expRegEx = /[+-]\d+/;

export default context => value => {
  if (value == null || value === '')
    return;

  const number = +value;
  if (isNaN(number))
    return context.errorMessage;

  const { precision } = context.params;
  if (!isMultiple(number, precision))
    return context.errorMessage;
};

function isMultiple(x, y) {
  if (x === 0)
    return true;

  if (!x || !y)
    return false;

  x = Math.abs(x);
  const normalizedQuotient = normalizeNumber(x / y);

  return normalizedQuotient % 1 === 0;
}

function normalizeNumber(number, accuracy = 10) {
  number = number.toPrecision();

  let result = expRegEx.test(number)
    ? number.replace(expRegEx, match => +match + accuracy)
    : number + 'e' + accuracy;
  result = Math.round(result).toPrecision();

  return expRegEx.test(result)
    ? +(result.replace(expRegEx, match => +match - accuracy))
    : +(result + 'e-' + accuracy);
}