import { combineEpics } from 'redux-observable';
import basketEpic from './epic';
import basketSyncEpic from './epic.sync';
import quickOrderEpic from './epic.quickOrder';

export default combineEpics(
  basketEpic,
  basketSyncEpic,
  quickOrderEpic,
);
