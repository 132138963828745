import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

let VdColumnWrapper;

const VdColumnContainer = ({ children, column, visualDesignerInitialized, index }) => {
  if (!visualDesignerInitialized)
    return children;

  const { id, contentBlocks } = column;

  if (!VdColumnWrapper)
    VdColumnWrapper = loadable(() => import(/*webpackChunkName:"vd"*/'./column/VdColumnWrapper'));

  return (
    <VdColumnWrapper index={index} id={id} contentBlocks={contentBlocks}>
      {children}
    </VdColumnWrapper>
  );
};

VdColumnContainer.propTypes = {
  children: PropTypes.node,
  visualDesignerInitialized: PropTypes.bool.isRequired,
  index: PropTypes.number,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
    contentBlocks: PropTypes.array,
  }),
};

export default connect(({ visualDesigner }) => ({
  visualDesignerInitialized: !!visualDesigner.initialized,
}))(VdColumnContainer);