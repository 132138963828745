import type { ZXCVBNResult } from 'zxcvbn';

type ZXCVBNFunc = (password: string, userInputs?: string[]) => ZXCVBNResult;

export const loadPasswordStrengthValidator = (() => {
  let loadPromise: Promise<ZXCVBNFunc>;

  return () => loadPromise || (loadPromise = new Promise(resolve => {
    import(/* webpackMode: "lazy", webpackChunkName: "zxcvbn" */ 'zxcvbn/dist/zxcvbn').then(library => {
      resolve(library.default);
    });
  }));
})();