import styles from '../Order.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { OrderProp } from './OrderProp';
import ShippingTracking from '../../documents/ShippingTracking';

const TrackingProp = ({ name, tracking }) => {
  const { link, number } = tracking;
  if (!link && !number)
    return null;

  return (
    <OrderProp
      name={name}
      value={<ShippingTracking tracking={tracking} />}
      className={styles.shippingTrackingLink}
    />
  );
};

TrackingProp.propTypes = {
  name: PropTypes.string,
  tracking: PropTypes.object.isRequired,
};

export default memo(TrackingProp);