import styles from '../Order.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { AttachmentType } from 'behavior/attachments';

const Attachments = ({ attachments }) => {
  const sortedAttachments = useMemo(
    () => [...attachments].sort(compareAttachments),
    [attachments],
  );

  return (
    <article className={styles.attachments}>
      <h2><SimpleText textKey="OrderDetail_Attachments" /></h2>
      <ul>
        {sortedAttachments.map(({ value, type, title }, index) => {
          const text = title || value;
          switch (type) {
            case AttachmentType.Note:
              return (
                <li key={index}>
                  <span title={text === value ? null : value}>{text}</span>
                </li>
              );
            default:
              const target = type === AttachmentType.Url ? '_blank' : null;
              return (
                <li key={index}>
                  <a href={value} target={target} className={linkStyles.arrowed} rel="noopener noreferrer nofollow">{text}</a>
                </li>
              );
          }
        })}
      </ul>
    </article>
  );
};

export default Attachments;

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      type: PropTypes.oneOf(Object.values(AttachmentType)),
      title: PropTypes.string,
    }).isRequired,
  ).isRequired,
};

function compareAttachments(a, b) {
  const textA = a.title || a.value;
  const textB = b.title || b.value;

  return textA == null
    ? 0
    : textA.localeCompare(textB);
}
