export function findCurrentPath(url, navigation, currentLanguage) {
  const parentUrls = url.split('/').filter(Boolean);
  if (!parentUrls.length)
    return findNodePath(navigation, trimSlashes(url));
  for (let i = 1; i < parentUrls.length; i++)
    parentUrls[i] = parentUrls[i - 1] + '/' + parentUrls[i];

  // Do not search navigation tree in case we have language segment.
  const endIndex = parentUrls[0].iEquals(currentLanguage) ? 1 : 0;

  for (let j = parentUrls.length - 1; j >= endIndex; j--) {
    const result = findNodePath(navigation, parentUrls[j]);
    if (result)
      return result;
  }
}

function findNodePath(items, url) {
  for (const item of items) {
    if (item.link && trimSlashes(item.link.url) === url)
      return [item];
    if (item.children && item.children.length > 0) {
      const returned = findNodePath(item.children, url);
      if (returned) {
        returned.unshift(item);
        return returned;
      }
    }
  }
}

export function findNode(items, url) {
  for (const item of items) {
    if (item.link && trimSlashes(item.link.url) === url)
      return item;
    if (item.children && item.children.length > 0) {
      const returned = findNode(item.children, url);
      if (returned)
        return returned;
    }
  }
}

function trimSlashes(string) {
  if (string == null)
    return string;

  let start = 0, end;
  if (string.charAt() === '/')
    start = 1;
  const lastIndex = string.length - 1;
  if (string.charAt(lastIndex) === '/')
    end = lastIndex;
  if (start || end)
    return string.slice(start, end);
  return string;
}
