import { mergeMap, startWith, pluck } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { PAYMENT_METHOD_EXTRA_DATA_REQUESTED, receivePaymentMethodExtraData } from '../actions';
import { paymentMethodExtraDataQuery } from '../queries';

export default (action$, _, { api }) => action$.pipe(
  ofType(PAYMENT_METHOD_EXTRA_DATA_REQUESTED),
  pluck('payload'),
  mergeMap(({ orderId, paymentMethodId }) => api.graphApi(paymentMethodExtraDataQuery, { orderId, paymentMethodId }).pipe(
    pluck('invoicePayment', 'paymentMethods', 'byId'),
    mergeMap(({ additionalCustomerDataStep, extraPaymentCheckoutStep }) => of(receivePaymentMethodExtraData(paymentMethodId, additionalCustomerDataStep, extraPaymentCheckoutStep), unsetLoadingIndicator())),
    startWith(setLoadingIndicator()),
  )),
);