import styles from '../Checkout.module.scss';
import Spinner from 'components/primitives/spinner/Spinner';
import { SimpleText } from 'components/sanaText';

const LoadingIndicator = () => (
  <div className={styles.loaderWrapper}>
    <Spinner className={styles.indicator} />
    <div className={styles.loaderTitle}>
      <SimpleText textKey="Loading" />
    </div>
  </div>
);

export default LoadingIndicator;
