import styles from './MediaGallery.module.scss';
import PropTypes from 'prop-types';
import { UseSanaTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { makeSimpleText } from 'utils/render';

const GalleryControls = ({ visibility, openLargeGallery }) => (
  <div className={styles.previewControls} hidden={!visibility}>
    <UseSanaTexts
      options={['ImageZoom_Message']}
      placeholder={<Placeholder className={styles.linkPlaceholder} />}
    >
      {([ImageZoom_Message]) => (
        <button className={styles.link} onClick={openLargeGallery}>
          {makeSimpleText(ImageZoom_Message)}
        </button>
      )}
    </UseSanaTexts>
  </div>
);

GalleryControls.propTypes = {
  visibility: PropTypes.bool,
  openLargeGallery: PropTypes.func.isRequired,
};

export default GalleryControls;