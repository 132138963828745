import styles from '../Checkout.module.scss';
import { StatusSuccessIcon } from 'components/primitives/icons';
import { useSimpleTexts } from 'components/sanaText';

const StepDoneMark = () => {
  const [completedText] = useSimpleTexts(['Checkout_StepCompleted']).texts;

  return (
    <StatusSuccessIcon
      className={styles.completeIcon}
      alt={completedText}
      title={completedText}
    />
  );
};

export default StepDoneMark;
