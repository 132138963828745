import { stripHtmlTagsAndFormatting } from '../helpers';

type ProductReview = {
  title: string;
  description: string | null;
  date: string;
  rating: number;
}
export interface ProductWithReviews {
  reviews?: null | {
    list: Array<ProductReview>;
    total: number;
    avg: number;
  };
}

export default function (product: ProductWithReviews) {
  const { reviews } = product;
  if (!reviews || !reviews.list.length)
    return null;

  return {
    review: reviews.list.map(({ title, description, date, rating }) => {
      const name = stripHtmlTagsAndFormatting(title);

      return {
        '@type': 'Review',
        name,
        headline: name,
        reviewBody: stripHtmlTagsAndFormatting(description),
        datePublished: new Date(date).toLocaleDateString('en-US'),
        reviewRating: {
          '@type': 'Rating',
          ratingValue: rating,
          worstRating: 1,
          bestRating: 5,
        },
        /*The author node is required by Google; otherwise it leads to error in testing tool and Search console.*/
        author: {
          '@type': 'Person',
        },
      };
    }),
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: reviews.avg,
      reviewCount: reviews.total,
      worstRating: 1,
      bestRating: 5,
    },
  };
}
