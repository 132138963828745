import styles from './ShoppingCartSuggestions.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useSanaButton } from 'components/primitives/buttons';
import { Link } from 'components/primitives/links';
import { withAbilitiesAndRouteChecker } from 'components/primitives/checkers';
import { AbilityTo } from 'behavior/user/constants';
import { RouteName } from 'routes';
import { CartSuggestionIcon } from 'components/primitives/icons';

const ShoppingCartSuggestions = ({ shoppingCartSuggestionPage, hideLabel, className = '' }) => {
  const { text, title } = useSanaButton('GetProductSuggestions', undefined, undefined, 'GetProductSuggestions');

  if (shoppingCartSuggestionPage == null)
    return null;

  const hideLabelClassName = hideLabel ? `${styles.hideLabel} ` : '';

  return (
    <Link
      title={hideLabel ? title : undefined}
      to={shoppingCartSuggestionPage.to}
      className={`${hideLabelClassName}${styles.suggestionsBtn} ${btnStyles.btnWhite} ${className}`}
      role="button"
    >
      <CartSuggestionIcon className={styles.icon} aria-hidden />
      {!hideLabel && <span className="btn-cnt">{text}</span>}
    </Link>
  );
};

ShoppingCartSuggestions.propTypes = {
  shoppingCartSuggestionPage: PropTypes.shape({
    to: PropTypes.shape({
      routeName: PropTypes.string,
      params: PropTypes.object,
    }),
  }),
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
};

const ignoredRoutes = [
  RouteName.BasketPage,
  RouteName.Checkout,
];

const wrappedShoppingCartSuggestions = withAbilitiesAndRouteChecker(
  connect(({ settings: { shoppingCartSuggestionPage } }) => ({ shoppingCartSuggestionPage }))(ShoppingCartSuggestions),
  ignoredRoutes,
  [AbilityTo.ViewProductSuggestions],
);

export default wrappedShoppingCartSuggestions;
