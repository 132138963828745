import styles from './Toast.module.scss';
import { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import { SuccessIcon, ErrorIcon, WarningIcon, InfoIcon, CrossBigIcon } from 'components/primitives/icons';
import { noop } from 'rxjs';
import ReactResizeDetector from 'react-resize-detector';
import { RichText } from 'components/sanaText';

const Icons = {
  success: <SuccessIcon className={styles.icon} />,
  error: <ErrorIcon className={styles.icon} />,
  errorDialog: <ErrorIcon className={styles.icon} />,
  warning: <WarningIcon className={styles.icon} />,
  info: <InfoIcon className={styles.icon} />,
};

const DefaultToast = ({
  appearance = 'info',
  autoDismiss,
  autoDismissTimeout,
  children,
  isRunning,
  onDismiss = noop,
  transitionDuration,
  transitionState,
  onMouseEnter = noop,
  onMouseLeave = noop,
  className,
  textKey,
}) => {
  const elementRef = useRef(null);
  const [height, setHeight] = useState('auto');
  const containerClass = joinClasses(
    styles.toast,
    appearance,
    !isRunning && 'pause',
    transitionState === 'entered' && 'show',
    className,
  );
  const containerStyle = { transitionDuration: transitionDuration + 'ms', height };
  const frameStyle = { transitionDuration: transitionDuration + 'ms' };
  const counterStyle = { animationDuration: autoDismissTimeout + 'ms' };

  const handleEscape = e => {
    if (e.key === 'Escape' || e.key === 'Esc')
      onDismiss(e);
  };

  useEffect(() => {
    switch (transitionState) {
      case 'entering':
        elementRef.current.getClientRects();
        elementRef.current.classList.add('show');
        autoDismiss && elementRef.current.classList.add('countdown');
        setParentElementMinHeight(elementRef.current);
        return;

      case 'entered':
        setHeight(elementRef.current.scrollHeight + 'px');
        setParentElementMinHeight(elementRef.current);
        return;

      case 'exiting':
        elementRef.current.classList.remove('show');
        setHeight(0);
        return;

      default:
        return;
    }
  }, [transitionState]);

  useEffect(() => () => {
    const { innerHeight } = window;
    const { style, children, dataset } = elementRef.current.parentElement;
    const newActualHeight = dataset.actualHeight - elementRef.current.scrollHeight;
    if (newActualHeight > innerHeight) {
      dataset.actualHeight = newActualHeight;
      return;
    }

    if (children.length > 1) {
      style.minHeight = newActualHeight + 'px';
      dataset.actualHeight = newActualHeight;
    } else {
      style.minHeight = '';
      dataset.actualHeight = 0;
    }
  }, []);

  const handleBottomBlockResize = useCallback(() => {
    if (transitionState !== 'entered')
      return;

    elementRef.current.style.height = '';
    setHeight(elementRef.current.scrollHeight + 'px');
    setParentElementMinHeight(elementRef.current);
  }, [elementRef, transitionState]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onKeyDown={handleEscape}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={containerClass}
      style={containerStyle}
      ref={elementRef}
      role={appearance === 'errorDialog' ? 'alertdialog' : 'alert'}
      aria-labelledby={appearance === 'errorDialog' ? 'errorIdContent' : null}
    >
      <div className={styles.body}>
        <div className={styles.frame} style={frameStyle}>
          <div className={styles.side}>
            {autoDismiss && <div className={styles.counter} style={counterStyle} />}
            {Icons[appearance]}
          </div>
          <div className={styles.close}>
            <button onClick={onDismiss}>
              <CrossBigIcon />
            </button>
          </div>
          <div className={styles.content} id={appearance === 'errorDialog' ? 'errorIdContent' : null}>
            {children || textKey && <RichText textKey={textKey} />}
            <ReactResizeDetector handleHeight onResize={handleBottomBlockResize} />
          </div>
        </div>
      </div>
    </div>
  );
};

DefaultToast.propTypes = {
  appearance: PropTypes.oneOf(['success', 'error', 'errorDialog', 'warning', 'info']),
  autoDismiss: PropTypes.bool,
  autoDismissTimeout: PropTypes.number,
  children: PropTypes.node.isRequired,
  isRunning: PropTypes.bool,
  onDismiss: PropTypes.func,
  transitionDuration: PropTypes.number,
  transitionState: PropTypes.oneOf(['entering', 'entered', 'exiting', 'exited']),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  className: PropTypes.string,
  textKey: PropTypes.string,
};

export default DefaultToast;

function setParentElementMinHeight(element) {
  const { innerHeight } = window;
  const { parentElement } = element;
  let {
    'padding-top': paddingTop,
    'padding-bottom': paddingBottom,
    'border-top-width': borderTopWidth,
    'border-bottom-width': borderBottomWidth,
  } = window.getComputedStyle(parentElement);
  paddingTop = parseInt(paddingTop || 0, 10);
  paddingBottom = parseInt(paddingBottom || 0, 10);
  borderTopWidth = parseInt(borderTopWidth || 0, 10);
  borderBottomWidth = parseInt(borderBottomWidth || 0, 10);
  let actualHeight = paddingTop + paddingBottom + borderTopWidth + borderBottomWidth;

  for (const { scrollHeight } of parentElement.children)
    actualHeight += scrollHeight;

  parentElement.dataset.actualHeight = actualHeight;
  parentElement.style.minHeight = (actualHeight > innerHeight ? innerHeight : actualHeight) + 'px';
}