import styles from '../SanaForm.module.scss';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import FormGroupTemplate from './FormGroupTemplate';
import FieldError from '../FieldError.js';
import { CaptchaTypes } from 'behavior/settings';
import { CaptchaField } from '../fields';

const CaptchaFormGroup = forwardRef(({
  fieldName = 'captchaToken',
  ...attributes
}, fieldRef) => {
  const { showCaptcha, siteKey, captchaType, syncObj } = useSelector(
    ({ settings, captcha: { syncObj } }) => ({ ...settings.captcha, syncObj }),
    shallowEqual,
  );

  if (!showCaptcha)
    return null;

  const invisible = captchaType === CaptchaTypes.Invisible;

  let captchaError = null;
  if (!invisible)
    captchaError = <FieldError fieldName={fieldName} />;

  return (
    <FormGroupTemplate
      className={styles.captchaRow}
      field={(
        <CaptchaField
          ref={fieldRef}
          fieldName={fieldName}
          invisible={invisible}
          siteKey={siteKey}
          syncObj={syncObj}
          {...attributes}
        />
      )}
      error={captchaError}
    />
  );
});

CaptchaFormGroup.propTypes = {
  fieldName: PropTypes.string,
};

export default CaptchaFormGroup;