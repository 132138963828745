exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ChangePassword_form{margin-top:1em}@media (min-width:var(--breakpoints_Small,600)px),print{.ChangePassword_form{max-width:46.75em}}@media (min-width:var(--breakpoints_Medium,960)px),print{.ChangePassword_form{max-width:none}}.ChangePassword_toast{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"form": "ChangePassword_form",
	"toast": "ChangePassword_toast"
};