//SSO Integration
import { useSelector } from 'react-redux';

export default function AzureLoginRenderer(_page) {

  const insiteEditor = useSelector(state => state.visualDesigner.insiteEditor);

  if(insiteEditor) {
   return null;
  }

  if (typeof window !== 'undefined') {
     window.location.href = '/azure-login';
  }

  return null;
}