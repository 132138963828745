import styles from './Grid.module.scss';
import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { isObject } from 'lodash';
import { asPropTypes } from './PropTypes.js';

const colWidths = ['xs', 'sm', 'md', 'lg', 'xl'];

const getColumnSizeClass = (isXs, colWidth, colSize) => {
  if (colSize === true || colSize === '') {
    return isXs ? styles.col : styles[`col-${colWidth}`];
  } else if (colSize === 'auto') {
    return isXs ? styles.colAuto : styles[`col-${colWidth}-auto`];
  }

  return isXs ? styles[`col-${colSize}`] : styles[`col-${colWidth}-${colSize}`];
};

const Col = forwardRef((props, ref) => {
  const {
    as: Tag = 'div',
    className,
    crossAxisAlign,
    ...attributes
  } = props;
  const colClasses = [];

  colWidths.forEach((colWidth, index) => {
    const columnProp = props[colWidth];

    delete attributes[colWidth];

    if (!columnProp && columnProp !== '') {
      return;
    }

    const isXs = !index;

    if (!isXs && columnProp && !colClasses.length)
      colClasses.push(styles.col);

    if (isObject(columnProp)) {
      const colSizeInterfix = isXs ? '-' : `-${colWidth}-`;

      if (columnProp.size || columnProp.size === '')
        colClasses.push(getColumnSizeClass(isXs, colWidth, columnProp.size));
      if (columnProp.order || columnProp.order === 0)
        colClasses.push(styles[`order${colSizeInterfix}${columnProp.order}`]);
      if (columnProp.offset || columnProp.offset === 0)
        colClasses.push(styles[`offset${colSizeInterfix}${columnProp.offset}`]);
    } else {
      colClasses.push(getColumnSizeClass(isXs, colWidth, columnProp));
    }
  });

  if (!colClasses.length)
    colClasses.push(styles.col);

  colClasses.push(alignmentClassNames[crossAxisAlign] || alignmentClassNames.auto);

  if (className)
    colClasses.push(className);

  return <Tag {...attributes} className={colClasses.join(' ')} ref={ref} />;
});

Col.displayName = 'Col';

const stringOrNumberProp = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

export const columnProps = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.number,
  PropTypes.string,
  PropTypes.shape({
    size: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
    order: stringOrNumberProp,
    offset: stringOrNumberProp,
  }),
]);

Col.propTypes = {
  as: asPropTypes,
  xs: columnProps,
  sm: columnProps,
  md: columnProps,
  lg: columnProps,
  xl: columnProps,
  className: PropTypes.string,
};

export default memo(Col);

const alignmentClassNames = {
  auto: styles.alignSelfAuto,
  start: styles.alignSelfStart,
  center: styles.alignSelfCenter,
  end: styles.alignSelfEnd,
  baseline: styles.alignSelfBaseline,
  stretch: styles.alignSelfStretch,
};
