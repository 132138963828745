import styles from './Table.module.scss';
import { cloneElement, memo, useRef } from 'react';
import { useIsMobileViewport } from 'utils/hooks';
import { forEach, map } from 'utils/react';
import { useOnChange } from 'utils/hooks';
import PropTypes from 'prop-types';

const Table = ({ children, className, ...otherProps }) => {
  const isMobile = useIsMobileViewport();

  const thead = useRef(null);
  const tbodies = useRef([]);
  const mobile = useRef(null);

  useOnChange(() => {
    tbodies.current = [];

    forEach(children, child => {
      if (child.type === 'thead')
        thead.current = child;
      else if (child.type === 'tbody')
        tbodies.current.push(child);
    });
  }, [children]);

  useOnChange(() => {
    if (!isMobile || !tbodies.current) {
      mobile.current = null;
      return;
    }

    let theadRow;
    if (thead.current) {
      const { children: theadChildren } = thead.current.props;

      forEach(theadChildren, row => {
        theadRow = row;
      });
    }

    const headers = theadRow
      ? map(theadRow.props.children, th => th)
      : [];

    mobile.current = tbodies.current.map((tbody, index) => {
      const { children: tbodyChildren, ...tbodyProps } = tbody.props;
      const rows = map(tbodyChildren, (row, rowIndex) => {
        const { children: rowChildren, ...rowProps } = row.props;

        return (
          <tr key={rowIndex} {...rowProps}>
            {map(rowChildren, (cell, cellIndex) => {
              if (cell.props['data-empty'])
                return null;

              let className = cell.props.className || '';

              if (cell.props.colSpan === headers.length)
                return cloneElement(cell, { style: { width: '100%' }, className: `${styles.noTitle} ${className}` });

              const th = headers[cellIndex];
              const name = th && <div className={styles.name} role="presentation">{th.props.children}</div>;
              const cellChildren = <div className={styles.value}>{cell.props.children}</div>;

              if (!name)
                className = `${styles.noTitle} ${className}`;

              return cloneElement(
                cell,
                {
                  'aria-labelledby': null,
                  colSpan: null,
                  children: <>{name}{cellChildren}</>,
                  className,
                });
            })}
          </tr>
        );
      });

      return (
        <tbody key={index} {...tbodyProps}>
          {rows}
        </tbody>
      );
    });
  }, [thead.current, tbodies.current, isMobile]);

  return (
    <table {...otherProps} className={`${styles.table}${isMobile ? ' mobile' : ''} ${className || ''}`}>
      {isMobile ? null : thead.current}
      {isMobile ? mobile.current : tbodies.current}
    </table>
  );
};

Table.propTypes = {
  className: PropTypes.string,
};

export default memo(Table);