import PropTypes from 'prop-types';

const SelectedItem = ({ index, pageLbl }) => {
  const textIndex = index + 1;
  return (
    <li className="selected">
      {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a aria-disabled="true" aria-current="true" title={pageLbl + textIndex}>
          <span className="visually-hidden">{pageLbl}</span>
          <strong>{textIndex}</strong>
        </a>
      }
    </li>
  );
};

SelectedItem.propTypes = {
  index: PropTypes.number.isRequired,
  pageLbl: PropTypes.string.isRequired,
};

export default SelectedItem;