exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Faq_html-block{max-width:100%}.Faq_accordions{margin:20px 0;word-break:break-word}@supports (-ms-ime-align:auto){.Faq_accordions{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.Faq_accordions{word-wrap:break-word}}.Faq_item:not(:first-child) .Faq_header{padding-top:1em}.Faq_header{display:inline-flex;align-items:center;cursor:pointer}.Faq_header button.Faq_btn-show-hide{flex-shrink:0;width:1.35em;height:1.35em;min-width:auto;min-height:auto;margin-right:.85em}.Faq_header .Faq_header-item{flex-grow:0}.Faq_header .Faq_title{margin:0;text-transform:uppercase}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.Faq_header .Faq_title{min-width:1px}}@supports (-ms-ime-align:auto){.Faq_header{max-width:100%}.Faq_header .Faq_title{min-width:1px}}.Faq_body{padding-left:calc(var(--buttons_Normal_FontSize, 1.4em)*2.2)}.Faq_description{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"html-block": "Faq_html-block",
	"htmlBlock": "Faq_html-block",
	"accordions": "Faq_accordions",
	"item": "Faq_item",
	"header": "Faq_header",
	"btn-show-hide": "Faq_btn-show-hide",
	"btnShowHide": "Faq_btn-show-hide",
	"header-item": "Faq_header-item",
	"headerItem": "Faq_header-item",
	"title": "Faq_title",
	"body": "Faq_body",
	"description": "Faq_description"
};