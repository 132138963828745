import styles from './DateSelect.module.scss';
import { memo, useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCultureName } from 'utils/hooks';
import {
  getYear,
  getMonthHuman,
  getDate,
} from '@wojtekmaj/date-utils';
import DaySelect from './DaySelect';
import MonthSelect from './MonthSelect';
import YearSelect from './YearSelect';
import { useSimpleTexts } from 'components/sanaText';
import { toLocaleDate, getFormatNumber } from 'utils/format';
import { getMinMaxDay, getMinMaxMonth } from '../datePicker/helpers';
import DateOnly from 'date-only';
import { pad } from 'utils/helpers';

const defaultMinDate = new Date(1900, 0, 1);
const defaultMaxDate = new Date(2100, 11, 31);

const DateSelect = ({
  onChange,
  onKeyDown,
  onBlur,
  className = '',
  isValid = true,
  value,
  minDate = defaultMinDate,
  maxDate = defaultMaxDate,
  'aria-invalid': ariaInvalid,
  ...props
}) => {
  const [dayPlaceholderText, monthPlaceholderText, yearPlaceholderText]
    = useSimpleTexts(['Day', 'Month', 'Year']).texts;

  const setRerenderState = useState()[1];
  const date = useRef();
  if (value) {
    const parsedValue = DateOnly.parse(value);
    if (parsedValue) {
      date.current = {
        day: getDate(parsedValue),
        month: getMonthHuman(parsedValue),
        year: getYear(parsedValue),
      };
    }
  } else {
    date.current = { day: null, month: null, year: null };
  }

  const onDateChange = useCallback(() => {
    let { day, month, year } = date.current;
    let value;

    const { maxMonth, minMonth } = getMinMaxMonth(maxDate, minDate, year);
    const { maxDay, minDay } = getMinMaxDay(maxDate, minDate, month, year);

    if (month) {
      if (month > maxMonth)
        month = maxMonth;
      else if (month < minMonth)
        month = minMonth;

      date.current.month = month;
      setRerenderState(Date.now);
    }

    if (day) {
      if (day > maxDay)
        day = maxDay;
      else if (day < minDay)
        day = minDay;

      date.current.day = day;
      setRerenderState(Date.now);
    }

    if (day && month && year)
      value = new Date(year, month - 1, day);
    else if (!day && !month && !year)
      value = null;
    else
      value = new Date(NaN);

    onChange(DateOnly.toISOString(value));
  }, [onChange]);

  const onDayChange = useCallback(value => {
    date.current.day = value;
    onDateChange();
  }, [onDateChange]);

  const onMonthChange = useCallback(value => {
    date.current.month = value;
    onDateChange();
  }, [onDateChange]);

  const onYearChange = useCallback(value => {
    date.current.year = value;
    onDateChange();
  }, [onDateChange]);

  const format = useDateFormat();
  const { day, month, year } = date.current;

  const selects = {
    day: (
      <DaySelect
        key="day"
        value={day}
        className={styles.select}
        placeholderText={dayPlaceholderText}
        minDate={minDate}
        maxDate={maxDate}
        month={month}
        year={year}
        isInvalid={!isValid}
        onChange={onDayChange}
        {...props}
      />
    ),
    month: (
      <MonthSelect
        key="month"
        value={month}
        className={styles.select}
        placeholderText={monthPlaceholderText}
        minDate={minDate}
        maxDate={maxDate}
        year={year}
        isInvalid={!isValid}
        onChange={onMonthChange}
        {...props}
      />
    ),
    year: (
      <YearSelect
        key="year"
        value={year}
        className={styles.select}
        placeholderText={yearPlaceholderText}
        minDate={minDate}
        maxDate={maxDate}
        isInvalid={!isValid}
        onChange={onYearChange}
        {...props}
      />
    ),
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <input
        type="date"
        defaultValue={year && month && day ? `${year}-${pad(month, 2)}-${pad(day, 2)}` : ''}
        className="visually-hidden"
        aria-hidden
        name={props.name}
        id={props.id}
        onFocus={focusToFirstSelect}
      />
      {format.map(part => selects[part])}
    </div>
  );

  function focusToFirstSelect() {
    if (!props.id)
      return;

    const id = `${props.id}_${format[0]}`;
    document.getElementById(id).focus();
  }
};

DateSelect.propTypes = {
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  isValid: PropTypes.bool,
  value: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default memo(DateSelect);

function useDateFormat() {
  const culture = useCultureName();

  return useMemo(() => {
    const formatNumber = getFormatNumber(culture);
    const formatRegex = /year|month|day/g;

    // Value should be less than 1000 to prevent number grouping when formating number to string.
    const year = 120;
    const monthIndex = 11;
    const day = 11;

    return toLocaleDate(new Date(year, monthIndex, day), culture)
      .replace(formatNumber(year), 'year')
      .replace(formatNumber(monthIndex + 1), 'month')
      .replace(formatNumber(day), 'day')
      .match(formatRegex);
  }, [culture]);
}