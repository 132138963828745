export const SUB_ACCOUNT_SUBMITTED = 'SUB_ACCOUNT_SUBMITTED';
export const submitSubAccount = account => ({
  type: SUB_ACCOUNT_SUBMITTED,
  payload: account,
});

export const SUB_ACCOUNT_REMOVE = 'SUB_ACCOUNT_REMOVE';
export const removeSubAccount = id => ({
  type: SUB_ACCOUNT_REMOVE,
  payload: id,
});

export const SUB_ACCOUNT_REMOVAL_RESULT_RECEIVED = 'SUB_ACCOUNT_REMOVAL_RESULT_RECEIVED';
export const subAccountRemovalResultRecieved = result => ({
  type: SUB_ACCOUNT_REMOVAL_RESULT_RECEIVED,
  payload: result,
});
