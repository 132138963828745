exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Error_title{text-transform:uppercase}.Error_action-list,.Error_body p{margin:0}.Error_body .Error_action-btn{color:var(--toasts_ErrorFontColor,#600502);text-decoration:underline;font-weight:700}html.pointer-mouse .Error_body .Error_action-btn:hover{color:var(--toasts_ErrorFontColor,#600502)}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .Error_body .Error_action-btn:active{color:var(--toasts_ErrorFontColor,#600502)}}}.Error_block ul{padding-left:1.5em}.Error_block .Error_loading,.Error_body .Error_loading{margin:.5em 0;box-shadow:0 0 1px 0 rgba(0,0,0,.05)}.Error_block .Error_loading{width:100%}h1 .Error_loading{width:50%;height:.8em}", ""]);

// exports
exports.locals = {
	"title": "Error_title",
	"action-list": "Error_action-list",
	"actionList": "Error_action-list",
	"body": "Error_body",
	"action-btn": "Error_action-btn",
	"actionBtn": "Error_action-btn",
	"block": "Error_block",
	"loading": "Error_loading"
};