import type { ReactNode } from 'react';
import { Children, isValidElement } from 'react';

/**
 * Maps valid react children nodes within provided function selector.
 * @param {Array<ReactNode> | ReactNode} children array or single item of react children.
 * @param {Function} func map function.
 * @returns {Array} mapped array of react children.
 */
export function map<C extends ReactNode, R>(children: C | C[], func: (child: C, index: number) => R) {
  let index = 0;
  return Children.map<R | C, C>(children, child =>
    isValidElement(child) ? func(child, index++) : child);
}

/**
 * Iterates through valid react children nodes executing provided function for each node.
 * @param {Array<ReactNode> | ReactNode} children array or single item of react children.
 * @param {Function} func function to be executed for child node.
 */
export function forEach<C extends ReactNode>(children: C | C[], func: (child: C, index: number) => void): void {
  let index = 0;
  Children.forEach(children, child =>
    isValidElement(child) && func(child, index++));
}

/**
 * Calculates number of valid react children nodes.
 * @param {Array<ReactNode> | ReactNode} children array or single item of react children.
 * @returns {PositiveInteger} count of react children.
 */
export function length(children: ReactNode | ReactNode[]): number {
  let index = 0;
  Children.forEach(children, child =>
    isValidElement(child) && index++);

  return index;
}
