import proxies from './proxies';

export default function addonCreatorFactory(libraries, sharedModules) {
  return (id, factory) => {
    try {
      const sana = {
        lib: getLibraries(libraries, id),
      };
      Object.entries(sharedModules).forEach(([key, value]) => {
        sana[key] = typeof (value) === 'function' ? value(id) : value;
      });

      return factory(sana);
    }
    catch (e) {
      console.debug(e);
      return null;
    }
  };
}

function getLibraries(libraries, addonId) {
  return Object.keys(libraries).reduce((acc, name) => {
    const proxy = proxies[name];

    acc[name] = proxy ? proxy(addonId) : libraries[name];
    return acc;
  }, {});
}