import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';
import { FormattedPrice } from 'components/primitives/price';

const ServiceLine = ({
  line: {
    chargeId,
    title,
    price,
    subtotal,
  },
  options: {
    currencyInfo,
    isB2CCustomer,
    showStock,
    showDiscounts,
    showUOM,
    showPrices,
    showShippingStatus,
    showExtraColumns,
  },
  className = '',
}) => (
    <tr className={`${styles.serviceLine} ${className}`}>
      <td aria-hidden className="visually-hidden" />
      <td className={styles.lineId} aria-hidden={!chargeId}>{chargeId}</td>
      <td className={styles.lineTitle}>{title}</td>
      {showShippingStatus && <td aria-hidden />}
      {!isB2CCustomer && <td aria-hidden />}
      {showPrices && <td className={styles.linePrice}><FormattedPrice price={price} currencyInfo={currencyInfo} /></td>}
      {showDiscounts && <td aria-hidden />}
      <td aria-hidden />{ /* quantity */}
      {showUOM && <td aria-hidden />}
      {showStock && <td aria-hidden />}
      {showExtraColumns && <td aria-hidden />}{ /* quantity shipped */}
      {showExtraColumns && <td aria-hidden />}{ /* quantity invoiced */}
      {!isB2CCustomer && <td aria-hidden />}{ /* outstanding quantity */}
      {showPrices && <td className={styles.lineTotal}><FormattedPrice price={subtotal} currencyInfo={currencyInfo} /></td>}
    </tr>
  );

ServiceLine.propTypes = {
  options: PropTypes.shape({
    isB2CCustomer: PropTypes.bool.isRequired,
    showStock: PropTypes.bool.isRequired,
    showDiscounts: PropTypes.bool.isRequired,
    showUOM: PropTypes.bool.isRequired,
    showPrices: PropTypes.bool.isRequired,
    showShippingStatus: PropTypes.bool.isRequired,
    showExtraColumns: PropTypes.bool.isRequired,
    currencyInfo: PropTypes.object,
  }).isRequired,
  line: PropTypes.shape({
    chargeId: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    subtotal: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
};

export default ServiceLine;
