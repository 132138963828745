import styles from './StickyPanel.module.scss';
import { useRef, useContext, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import { useResponsiveBreakpoints } from 'utils/layout';
import { useLayoutShifter } from 'utils/layout';
import RowRailsContext from './swiper/context';
import { Container } from 'components/primitives/grid';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';

/* Determine when sticky state should be applied, with current value it will be set when
   goes trough 72.5 percents of sticky item height */
const STICKY_SHIFT_COEFFICIENT = 0.725;

const StickyPanel = ({ children }) => {
  const { xs, sm, md, lg, xl } = useResponsiveBreakpoints();
  const { topFixedElementsHeight } = useLayoutShifter();
  const { swiperBodyRef } = useContext(RowRailsContext);
  const placeholderRef = useRef(null);
  const stickyItemRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  const resetSizes = useCallback(() => {
    stickyItemRef.current.classList.remove(styles.isFixed);
    stickyItemRef.current.removeAttribute('style');
    placeholderRef.current.removeAttribute('style');
  }, []);

  const handleScroll = useCallback(() => {
    if (!swiperBodyRef.current || !stickyItemRef.current || !placeholderRef.current)
      return;

    const linker = isSticky ? placeholderRef.current : stickyItemRef.current;
    const cliBounds = linker.getBoundingClientRect();
    const topLine = cliBounds.top - topFixedElementsHeight + cliBounds.height * STICKY_SHIFT_COEFFICIENT;

    if (topLine <= 0) {
      if (!isSticky) {
        placeholderRef.current.style.height = cliBounds.height + 'px';
        setIsSticky(true);
        toggleOverflowAnchorState(true);
      }

      const stickyItemHeight = stickyItemRef.current.getBoundingClientRect().height;
      const bottomLine = swiperBodyRef.current.getBoundingClientRect().bottom - stickyItemHeight - topFixedElementsHeight;

      stickyItemRef.current.style.top = topFixedElementsHeight + 'px';
      stickyItemRef.current.classList[bottomLine <= 0 ? 'add' : 'remove'](styles.isFixed);
    } else if (isSticky) {
      resetSizes();
      setIsSticky(false);
    }
  }, [isSticky, topFixedElementsHeight]);

  const handleResize = useCallback(() => {
    resetSizes();
    handleScroll();
  }, [isSticky, topFixedElementsHeight]);

  useEffect(() => {
    swiperBodyRef.current.style.position = 'relative';
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky, topFixedElementsHeight]);

  useEffect(
    handleResize,
    [topFixedElementsHeight, xs, sm || md, lg || xl],
  );

  useEffect(() => {
    if (!isSticky)
      return;

    toggleOverflowAnchorState();
  }, [isSticky]);

  return (
    <>
      <ReactResizeDetector handleWidth onResize={handleResize} />
      <div className={styles.placeholder} ref={placeholderRef} />
      <div ref={stickyItemRef} className={isSticky ? styles.isSticky : null}>
        {isSticky ? <Container>{children(isSticky)}</Container> : children(isSticky)}
      </div>
    </>
  );
};

StickyPanel.propTypes = {
  children: PropTypes.func.isRequired,
};

export default StickyPanel;