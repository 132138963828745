import styles from './NotValidCustomer.module.scss';
import { Container } from 'components/primitives/grid';
import { InfoAlert } from 'components/primitives/alerts';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';
import { ContactStoreLink } from 'components/primitives/links';

const NotValidCustomerWarningMessage = () => {
  const { texts: [msg], loaded } = useSanaTexts(['NotValidCustomerWarning', 'NotValidCustomerWarning_ContactLinkText']);

  if (!loaded)
    return null;

  const link = <ContactStoreLink linkTextKey="NotValidCustomerWarning_ContactLinkText" />;

  return (
    <div className={styles.info} data-scroll-lock-fill-gap> {/* data-scroll-lock-fill-gap - fills gap on element on right side with padding (default) when body scroll disabled */}
      <Container className={styles.noGutters}>
        <InfoAlert className={styles.noMargin} role="status">
          <div className={styles.contentWrapper}>
            {makeRichText(msg, [link])}
          </div>
        </InfoAlert>
      </Container>
    </div>
  );
};

export default NotValidCustomerWarningMessage;
