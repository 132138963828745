import naturalCompare from 'natural-compare';

/**
 * Makes facet values alphanumeric sorting
 * @param {Array} facetValues facet values
 * @param {Boolean} sortDescending sorting order
 * @returns {Array} sorted facet values
 */
export const alphanumSort = (facetValues, sortDescending) => {
  return sortDescending
    ? facetValues.sort((a, b) => compare(b, a))
    : facetValues.sort(compare);
};

function compare(a, b) {
  const aKey = selectKey(a);
  if (aKey == null)
    // return 0 to preserve default order (e.g. img instead of title)
    return 0;

  const bKey = selectKey(b);
  if (bKey == null)
    // return 0 to preserve default order (e.g. img instead of title)
    return 0;

  return naturalCompare(aKey, bKey);
}

function selectKey(facetValue) {
  const title = facetValue.textTitle || facetValue.title;
  return title ? title.toLowerCase() : null;
}