import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { CheckboxField as CheckboxFieldCore } from '../../fields';
import validationFieldPropType from './validationFieldPropType';

const CheckboxField = hoistStatics(
  ({
    fieldName,
    fieldTitle,
    validation,
    required,
    className,
    title,
    disabled,
  }) => (
      <CheckboxFieldCore
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={validation}
        required={required}
        className={className}
        title={title}
        disabled={disabled}
      />
    ),
  CheckboxFieldCore,
);

CheckboxField.propTypes = {
  ...CheckboxFieldCore.propTypes,
  required: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  validation: validationFieldPropType,
};

export default CheckboxField;
