import styles from './Summary.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import PriceNode from './PriceNode';
import { SimpleText } from 'components/sanaText';

const Total = ({ totalCount, calculated, currencyInfo, price }) => (
  <div className={`${styles.panelFooter} ${styles.total}`}>
    <div className={styles.totalItem}>
      <span className={styles.totalTitle}><SimpleText textKey="TotalProducts_Label" /> </span>
      <span className={`${styles.totalValue} ${styles.totalCount}`}>{totalCount}</span>
    </div>
    {calculated &&
      <div className={`${styles.totalItem} ${styles.totalPrice}`}>
        <span className={styles.totalTitle}><SimpleText textKey="TotalPrice_Label" /> </span>
        <PriceNode currencyInfo={currencyInfo} price={price} className={styles.totalValue} />
      </div>
    }
  </div>
);

Total.propTypes = {
  totalCount: PropTypes.number,
  calculated: PropTypes.bool,
  currencyInfo: PropTypes.object,
  price: PropTypes.number,
};

export default memo(Total);
