import { RichText } from 'components/sanaText';
import { DangerAlert } from 'components/primitives/alerts';

const ErrorMessage = () => (
  <DangerAlert role="alert">
    <RichText textKey="Product_AddVariantsError" />
  </DangerAlert>
);

export default ErrorMessage;
