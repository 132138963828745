import PropTypes from 'prop-types';
import { ImagePosition } from 'behavior/navigation';

const ImagePositionPropType = PropTypes.oneOf([
  ImagePosition.Inline,
  ImagePosition.RightBottom,
]);

export const LinkPropTypes = PropTypes.shape({
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  to: PropTypes.shape({
    routeName: PropTypes.string.isRequired,
    params: PropTypes.object,
  }),
});

export const NavigationItemPropTypes = {
  title: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  children: PropTypes.array,
  link: LinkPropTypes,
};

const GeneralMainNaigationItemPropTypes = {
  ...NavigationItemPropTypes,
  id: PropTypes.string.isRequired,
};

export const BaseNavigationItemPropTypes = PropTypes.shape(NavigationItemPropTypes);

export const MainNavItemPropTypes = PropTypes.shape(GeneralMainNaigationItemPropTypes).isRequired;

export const MainNavTopLevelItemPropTypes = PropTypes.shape({
  ...GeneralMainNaigationItemPropTypes,
  image: PropTypes.shape({
    path: PropTypes.string,
    keepOriginalSize: PropTypes.bool,
    position: ImagePositionPropType,
    link: LinkPropTypes,
  }),
}).isRequired;