import styles from '../Orders.module.scss';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSimpleTexts } from 'components/sanaText';
import InvoicesPaymentContext from './InvoicesPaymentContext';
import { Checkbox } from 'components/primitives/form';
import { paymentStatusPropType } from '../propTypes';
import { useIsTouchDevice } from 'utils/detections';
import { StatusFailedIcon, StatusPendingIcon, StatusSuccessIcon } from 'components/primitives/icons';
import dateOnly from 'date-only';

const PayInvoiceCheckbox = ({ invoice }) => {
  const isTouchDevice = useIsTouchDevice();
  const [
    alreadyPaidText,
    overdueText,
    inProgressText,
    currencyMismatchText,
  ] = useSimpleTexts([
    'AlreadyPaid',
    'DocumentIsOverdue',
    'DocumentPaymentInProgress',
    'YouCanSelectDocumentsOnlyWithSameCurrency',
  ]).texts;

  const {
    currency: currentCurrency,
    selectedInvoices,
    toggleInvoice,
  } = useContext(InvoicesPaymentContext);

  const {
    totals: { outstandingAmount },
    payment: { status: paymentStatus },
    dueDate,
    currency,
  } = invoice;

  if (outstandingAmount <= 0)
    return infoText(alreadyPaidText, styles.paid, StatusSuccessIcon);

  if (dueDate && new Date(dueDate) < dateOnly.today())
    return infoText(overdueText, styles.expired, StatusFailedIcon);

  // PaymentStatus from behavior/documents
  if (paymentStatus === 'inprogress')
    return infoText(inProgressText, styles.pending, StatusPendingIcon);

  const isCurrencyMismatched = currentCurrency && currentCurrency.id !== currency.id;

  return (
    <>
      <Checkbox
        className={styles.chb}
        title={isCurrencyMismatched ? currencyMismatchText : null}
        checked={selectedInvoices.includes(invoice)}
        onChange={!isCurrencyMismatched ? () => toggleInvoice(invoice) : null}
        disabled={isCurrencyMismatched}
      />
      {isCurrencyMismatched && <span className={getStatusTextClassName(isTouchDevice)}>{currencyMismatchText}</span>}
    </>
  );

  // eslint-disable-next-line react/no-multi-comp
  function infoText(text, className, Icon) {
    return (
      <span className={className} title={text}>
        <Icon aria-hidden />
        <span className={getStatusTextClassName(isTouchDevice)}>
          {text}
        </span>
      </span>
    );
  }
};

PayInvoiceCheckbox.propTypes = {
  invoice: PropTypes.shape({
    totals: PropTypes.shape({
      outstandingAmount: PropTypes.number.isRequired,
    }).isRequired,
    payment: PropTypes.shape({
      status: paymentStatusPropType,
    }).isRequired,
    dueDate: PropTypes.string,
    currency: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default PayInvoiceCheckbox;

function getStatusTextClassName(isTouchDevice) {
  return isTouchDevice ? styles.statusText : 'visually-hidden';
}
