import { map, first, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { createLoadOptions } from '../handler';
import { createSearchQuery } from './queries';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { updatePageProducts } from '../reducer';
import { sortOptionsAreEqual } from '../helpers';

export default ({ params, options }, state$, { api }) => {
  const handle = ({ settings }) => {
    const viewMode = params.viewMode || settings.productList.defaultViewMode;
    const loadOptions = createLoadOptions(params, { ...options, viewMode }, settings.productList);
    const productsOnly = options && options.productsOnly;

    if (!loadOptions.keywords)
      return of(createEmptyResult(settings));

    const state = state$.value;

    return api.graphApi(createSearchQuery(!productsOnly), {
      options: loadOptions,
      loadLargeImages: false,
      loadCategories: state.analytics && state.analytics.isTrackingEnabled,
    }).pipe(
      map(({ catalog: { products: foundProducts }, settings: { search: { preset } } }) => {
        if (!foundProducts)
          return null;

        if (productsOnly) {
          const size = loadOptions.page.size;
          const page = updatePageProducts(state.page, foundProducts.products, options.appendProducts, size);
          page.totalCount = foundProducts.totalCount;

          if (!sortOptionsAreEqual(params.sort, page.selectedSorting))
            page.selectedSorting = params.sort;

          return { page };
        }

        const { sort, viewMode } = params;
        const { showThumbnails } = settings.search;
        const { defaultViewMode, viewModeSwitchEnabled, pagingType } = settings.productList;
        const { products, totalCount, facets } = foundProducts;

        const page = {
          id: 'search',
          products,
          totalCount,
          facets,
          viewModeSwitchEnabled,
          showThumbnails,
          pagingType,
          selectedSorting: sort,
          defaultViewMode,
          selectedViewMode: viewMode || defaultViewMode,
          lastViewedEnabled: false,
          relevanceAvailable: true,
          component: PageComponentNames.Search,
          preset,
        };

        return { page };
      }),
    );
  };

  if (state$.value.settings.loaded)
    return handle(state$.value);

  return state$.pipe(
    first(({ settings }) => settings.loaded),
    switchMap(handle),
  );
};

export function createEmptyResult(settings) {
  const { showThumbnails, viewModeSwitchEnabled } = settings.search;

  return {
    page: {
      id: 'search',
      products: [],
      totalCount: 0,
      showThumbnails,
      viewModeSwitchEnabled,
      component: PageComponentNames.Search,
    },
  };
}