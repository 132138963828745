import styles from './ProductTiles.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useResponsiveBreakpoints } from 'utils/layout';
import ProductTile from './ProductTile';
import { Slider } from 'components/primitives/slider';

const ProductTilesSlider = ({ products, noImage, maxSlidesToShow = 4 }) => {
  const { xs, sm, md } = useResponsiveBreakpoints();
  const isMobile = xs;
  const isTablet = sm || md;

  const itemsPerSlide = isMobile ? 1 : isTablet ? 2 : maxSlidesToShow;
  const enableSlider = products.length / itemsPerSlide > 1;

  const tiles = useMemo(() => products.map(product => (
    <ProductTile
      key={product.id}
      product={product}
      noImage={noImage}
      itemsPerSlide={enableSlider ? null : itemsPerSlide}
    />
  )), [products, itemsPerSlide]);

  if (!tiles || !tiles.length)
    return null;

  return (
    <div className={styles.productTiles} role="presentation" onDragStart={preventDefault}>
      {enableSlider
        ? <Slider items={tiles} itemsPerSlide={itemsPerSlide} showArrows={false} />
        : <div className={styles.withoutSlider}>{tiles}</div>
      }
    </div>
  );
};

ProductTilesSlider.propTypes = {
  maxSlidesToShow: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
  noImage: PropTypes.string,
};

export default connect(({
  settings: { product: productSettings },
}) => ({
  noImage: productSettings && productSettings.noImage.small,
}))(ProductTilesSlider);

function preventDefault(e) {
  e.preventDefault();
}