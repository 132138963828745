import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

const UseSanaButton = ({
  textKey,
  titleTextKey,
  defaultText,
  placeholder,
  dontWait,
  children,
}) => {
  const { text, title, loaded } = useSanaButton(textKey, defaultText, undefined, titleTextKey);
  if (!dontWait && !loaded)
    return placeholder || null;

  return children(text, title);
};

UseSanaButton.propTypes = {
  textKey: PropTypes.string.isRequired,
  titleTextKey: PropTypes.string,
  defaultText: PropTypes.string,
  dontWait: PropTypes.bool,
  children: PropTypes.func.isRequired,
  placeholder: PropTypes.node,
};

export default memo(UseSanaButton);

export function useSanaButton(textKey, defaultText, formatWith, titleTextKey) {
  const { texts: [text, title], loaded } = useSanaTexts([
    {
      key: `ButtonText_${textKey}`,
      fallback: defaultText,
    },
    titleTextKey && `ButtonAltText_${titleTextKey}`,
  ], formatWith ? undefined : makeSimpleText);

  if (!formatWith || !loaded)
    return { text, title, loaded };

  return {
    text: makeSimpleText(text, formatWith),
    title: makeSimpleText(title),
    loaded,
  };
}
