(function () {
  const compare = new Intl.Collator(undefined, { sensitivity: 'accent' }).compare;

  //eslint-disable-next-line no-extend-native
  String.prototype.iEquals = function iEquals(otherString: string | null | undefined) {
    return compare(this as string, otherString as string) === 0;
  };
})();

// Polyfills for browser environment.
(function () {
  if (typeof window === 'undefined')
    return;

  // Element matches and closest polyfills.
  if (!Element.prototype.matches) {
    Element.prototype.matches = (Element.prototype as any).msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (selector: string) {
      //eslint-disable-next-line @typescript-eslint/no-this-alias 
      let el: Node | null = this;

      do {
        if (Element.prototype.matches.call(el, selector))
          return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }

  // IE11 fix, support forEach for NodeList
  // https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach as any;
  }

  // IE11 fix, support removing of nodes
  // https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
  (function (arr) {
    arr.forEach(function (item) {
      if (item.hasOwnProperty('remove')) {
        return;
      }
      Object.defineProperty(item, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
          this.parentNode.removeChild(this);
        },
      });
    });
  })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

  // IE11 polyfill for :scope support within the selector methods Element#querySelector, Element#querySelectorAll,
  // Element#matches, and Element#closest. Based on https://github.com/jonathantneal/element-qsa-scope
  (function () {
    try {
      document.querySelector(':scope *');
    } catch (error) {
      (function (ElementPrototype) {
        type FuncName = typeof functions[number];

        type Func<T extends FuncName, R extends ReturnType<Element[T]>> = (this: Element, ...args: Parameters<Element[T]>) => R;
        type ElementFunc<T extends FuncName> = Func<T, ReturnType<Element[T]>>;

        const functions = ['matches', 'querySelector', 'querySelectorAll', 'closest'] as const;

        functions.forEach((fn: FuncName) => {
          const proto: Record<FuncName, ElementFunc<FuncName>> = ElementPrototype;
          const originalFn = proto[fn];

          proto[fn] = function (this: Element, ...args: Parameters<Element[FuncName]>) {
            return scopePolyfill(this, originalFn, args);
          };
        });

        function scopePolyfill<T extends FuncName, R extends ReturnType<Element[T]>>(
          element: Element,
          originalFn: Func<T, R>,
          args: Parameters<typeof originalFn>,
        ): R {
          const scopeRegEx = /:scope(?![\w-])/gi;
          const [selectors] = args;
          const hasScope = selectors && scopeRegEx.test(selectors);

          if (hasScope) {
            const fallbackAttr = 'q' + Math.floor(Math.random() * 9000000) + 1000000;
            args[0] = selectors.replace(scopeRegEx, '[' + fallbackAttr + ']');
            element.setAttribute(fallbackAttr, '');

            const result = originalFn.apply(element, args);
            element.removeAttribute(fallbackAttr);
            return result;
          } else {
            return originalFn.apply(element, args);
          }
        }

      })(Element.prototype);
    }
  })();
})();

// Empty export to overcome TS1208:
// TypeScript(TS) cannot be compiled under because it is considered a global script file.Add an import, export, or an empty statement to make it a module.
export { };
