import { isEqual } from 'lodash';

export function modifyContentElementById(id, content, modifyAction) {
  const { row: oldRow, column: oldColumn, contentBlock: oldBlock } = findContentElementById(id, content);
  if (!oldRow)
    return content;
  if (id === oldRow.id) {
    return replaceById(id, content, modifyAction(oldRow));
  }
  if (id === oldColumn.id) {
    const newRow = {
      ...oldRow,
      columns: replaceById(id, oldRow.columns, modifyAction(oldColumn)),
    };
    return replaceById(oldRow.id, content, newRow);
  }
  if (id === oldBlock.id) {
    const newColumn = {
      ...oldColumn,
      contentBlocks: replaceById(id, oldColumn.contentBlocks, modifyAction(oldBlock)),
    };
    const newRow = {
      ...oldRow,
      columns: replaceById(oldColumn.id, oldRow.columns, newColumn),
    };
    return replaceById(oldRow.id, content, newRow);
  }
}

function replaceById(id, array, newItem) {
  return array.map(item => item.id === id ? newItem : item);
}

export function findContentElementById(id, content) {
  function find(array, func) {
    for (let i = 0; i < array.length; i++) {
      const result = func(array[i], i);
      if (result)
        return result;
    }
    return null;
  }

  const result = find(content, (row, rowIndex) => {
    if (row.id === id) {
      return { row, rowIndex };
    }
    return find(row.columns, (column, columnIndex) => {
      if (column.id === id) {
        return { row, rowIndex, column, columnIndex };
      }

      return find(column.contentBlocks, (contentBlock, contentBlockIndex) => {
        if (contentBlock.id === id) {
          return { row, rowIndex, column, columnIndex, contentBlock, contentBlockIndex };
        }

        return null;
      });
    });
  });

  return result || {};
}

export function mergePageContentData(oldData, newData) {
  if (oldData) {
    newData = newData.map(rowData => {
      rowData.columns = rowData.columns.map(columnData => {
        columnData.contentBlocks = columnData.contentBlocks.map(contentBlockData => {
          const oldContentBlockDataModel = findContentElementById(contentBlockData.id, oldData)?.contentBlock?.model;
          if (isEqual(oldContentBlockDataModel, contentBlockData.model))
            contentBlockData.model = oldContentBlockDataModel;

          return contentBlockData;
        });
        return columnData;
      });
      return rowData;
    });
  }
  return newData;
}