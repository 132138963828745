import styles from '../SubAccount.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SimpleText, useSimpleTexts } from 'components/sanaText';
import { createFieldId, createErrorMsgId } from 'components/objects/forms';
import { DecimalField, DropdownField } from 'components/objects/forms/fields';
import LimitFieldError from './LimitFieldError';
import { ErrorMessage } from 'formik';
import { useComputedValue } from 'utils/hooks';
import RemoveButton from '../RemoveButton';

const Limit = ({ authorizer, availableAuthorizers, name, remove, index, currency, formName, hasErrors }) => {
  const authorizers = useMemo(
    () => sortByName([authorizer, ...availableAuthorizers]),
    [authorizer, availableAuthorizers],
  );
  const memoizedAuthorizers = useComputedValue(
    () => authorizersToSelection(authorizers),
    authorizers,
  );

  const [amountTitle] = useSimpleTexts(['Amount']).texts;

  const amountFieldName = `${name}.amount`;
  const authorizerFieldName = `${name}.authorizerId`;
  const lessOrEqualLabelId = `${name}.lessOrEqual`;
  const currencyId = `${name}.currency`;
  const fieldId = createFieldId(formName, amountFieldName);
  const errorId = createErrorMsgId(formName, amountFieldName);

  return (
    <>
      <tr className={`${styles.limit}${hasErrors ? ' row-error' : ''}`}>
        <td id={lessOrEqualLabelId} className={`${styles.limitItem} ${styles.operator}`}>
          <SimpleText textKey="LessOrEqual" />
        </td>
        <td
          className={`${styles.limitItem} ${styles.value}`}
          aria-labelledby={`${lessOrEqualLabelId} ${fieldId} ${currencyId}`}
        >
          <DecimalField
            fieldName={amountFieldName}
            fieldTitle={amountTitle}
            asCurrency
            required
            validation={{
              required: true,
              range: { min: 0.01, max: 100000000000 },
              precise: { precision: 0.01 },
            }}
          />
        </td>
        <td id={currencyId} className={`${styles.limitItem} ${styles.currency}`}>{currency}</td>
        <td className={`${styles.limitItem} ${styles.authorizer}`}>
          <DropdownField
            fieldName={authorizerFieldName}
            items={memoizedAuthorizers}
            required
          />
        </td>
        <td className={`${styles.limitItem} ${styles.delete}`}>
          <RemoveButton onClick={() => remove(index)} />
        </td>
      </tr>
      <ErrorMessage
        name={amountFieldName}
        component={LimitFieldError}
        id={errorId}
        className={`field-validation-error ${amountFieldName}-error`}
      />
    </>
  );
};

Limit.propTypes = {
  authorizer: PropTypes.object.isRequired,
  availableAuthorizers: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  formName: PropTypes.string,
  hasErrors: PropTypes.bool,
};

export default Limit;

function authorizersToSelection(authorizers) {
  return authorizers.map(authorizer => ({
    name: authorizer.name,
    value: authorizer.id,
  }));
}

function sortByName(authorizers) {
  return authorizers.sort((first, second) => first.name.localeCompare(second.name));
}