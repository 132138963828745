import {
  sm, md, lg, xl,
  breakpoints_Small, breakpoints_Medium, breakpoints_Large, breakpoints_ExtraLarge,
} from 'css/exports/_breakpoints.scss';
import MEDIA_QUERIES from 'css/exports/_media_queries.scss';
import { useState, useMemo, useEffect } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import MatchMediaContext from './MatchMediaContext';
import type { MediaQueriesData } from 'css/exports/_media_queries.scss';
import type { MatchMediaContext as MatchMediaContextValue } from './types';

type Props = {
  sm: number;
  md: number;
  lg: number;
  xl: number;
  children: React.ReactNode;
}

type BreakpointsMediaQueriesList = {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

type AllMediaQueriesList = BreakpointsMediaQueriesList & MediaQueriesData;

type MediaQueriesDataList = Record<keyof AllMediaQueriesList, MediaQueryList>;

type QueryName = keyof MediaQueriesDataList;

const DEFAULT_QUERIES = getDefaultMediaQueries(MEDIA_QUERIES);
const QUERIES_DEBOUNCE_DELAY = 20;

const MatchMediaProvider = ({ sm, md, lg, xl, children }: Props) => {
  const mediaQueries = useMemo((): AllMediaQueriesList => {
    return { ...getBreakpointsQueries(sm, md, lg, xl), ...DEFAULT_QUERIES };
  }, [sm, md, lg, xl]);

  const [queriesStatus, setQueriesStatus] = useState(getQueriesStatus(mediaQueries));

  useEffect(() => {
    const queriesList = generateQueriesList(mediaQueries);
    const onMediaMatchChange = debounce(() => {
      setQueriesStatus(getQueriesStatus(queriesList));
    }, QUERIES_DEBOUNCE_DELAY);

    setQueriesStatus(getQueriesStatus(queriesList));
    addOrRemoveQueriesListeners(queriesList, onMediaMatchChange);

    return () => {
      addOrRemoveQueriesListeners(queriesList, onMediaMatchChange, false);
    };
  }, [mediaQueries]);

  return (
    <MatchMediaContext.Provider value={queriesStatus}>
      {children}
    </MatchMediaContext.Provider>
  );
};

export default connect(({ theme }) => {
  const themeValues = theme.values || {};
  return {
    sm: +(themeValues[breakpoints_Small] || sm),
    md: +(themeValues[breakpoints_Medium] || md),
    lg: +(themeValues[breakpoints_Large] || lg),
    xl: +(themeValues[breakpoints_ExtraLarge] || xl),
  };
})(MatchMediaProvider);

function getDefaultMediaQueries(mediaQueries: MediaQueriesData): MediaQueriesData {
  const queries = {} as MediaQueriesData;
  let key: keyof MediaQueriesData;
  for (key in mediaQueries) {
    // Skip style loader related private fields.
    if (!key.startsWith('_'))
      queries[key] = mediaQueries[key];
  }

  return queries;
}

function getBreakpointsQueries(sm: number, md: number, lg: number, xl: number): BreakpointsMediaQueriesList {
  return {
    xs: `screen and (max-width: ${sm - 1}px)`,
    sm: `screen and (min-width: ${sm}px) and (max-width: ${md - 1}px)`,
    md: `screen and (min-width: ${md}px) and (max-width: ${lg - 1}px)`,
    lg: `screen and (min-width: ${lg}px) and (max-width: ${xl - 1}px)`,
    xl: `screen and (min-width: ${xl}px)`,
  };
}

function generateQueriesList(mediaQueries: AllMediaQueriesList): MediaQueriesDataList {
  const queriesList = {} as MediaQueriesDataList;

  let name: QueryName;
  for (name in mediaQueries)
    queriesList[name] = window.matchMedia(mediaQueries[name]);

  return queriesList;
}

function getQueriesStatus(queriesList: MediaQueriesDataList | AllMediaQueriesList): MatchMediaContextValue {
  const queriesStatus = {} as MatchMediaContextValue;

  let name: QueryName;
  for (name in queriesList) {
    const query = queriesList[name];
    queriesStatus[name] = typeof query === 'string' ? false : query.matches;
  }

  return queriesStatus;
}

function addOrRemoveQueriesListeners(queriesList: MediaQueriesDataList, handler: () => void, add = true) {
  const methodName = add ? 'addListener' : 'removeListener';
  let name: QueryName;
  for (name in queriesList)
    queriesList[name][methodName](handler);
}