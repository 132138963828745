import { SANATEXTS_LOADED } from './actions';
import {
  INSITE_EDITOR_SAVE_SANA_TEXT,
  SANA_TEXT_CHANGED,
} from 'behavior/pages/visualDesigner/actions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SANATEXTS_LOADED:
      return { ...state, ...action.payload };
    case INSITE_EDITOR_SAVE_SANA_TEXT:
      const { sanaText: { key, text } } = action.payload;
      return {
        ...state,
        [key]: text,
      };
    case SANA_TEXT_CHANGED:
      const { key: changedKey, text: changedText } = action.payload;
      return {
        ...state,
        [changedKey]: changedText,
      };
    default:
      return state;
  }
}