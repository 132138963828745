exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EditProfile_form{padding-top:15px;max-width:46.75em}", ""]);

// exports
exports.locals = {
	"form": "EditProfile_form"
};