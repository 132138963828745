import styles from '../Order.module.scss';
import PropTypes from 'prop-types';
import { getCurrencyAndNumber } from 'utils/format';
import PriceNode from './PriceNode';
import { EmptyPriceSymbol } from 'utils/product';

export const TotalsLine = ({ heading, value, currencyInfo, className = null }) => {
  let priceNode = EmptyPriceSymbol;
  if (value != null) {
    const [symbol, price] = getCurrencyAndNumber(value, currencyInfo);
    priceNode = <PriceNode symbol={symbol} price={price} />;
  }

  return (
    <tr className={className}>
      <th scope="row" className={styles.totalsName}>{heading}</th>
      <td className={styles.totalsValue}>{priceNode}</td>
    </tr>
  );
};

TotalsLine.propTypes = {
  heading: PropTypes.any,
  value: PropTypes.number,
  currencyInfo: PropTypes.object.isRequired,
  className: PropTypes.string,
};
