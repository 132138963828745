import type { Action, Reducer, AnyAction } from 'redux';

type ActionType<TAction extends Action> = TAction['type'];

type Handler<TState, TAction> = (state: TState, action: TAction, initialState: TState) => TState;

type HandlersMap<TState, TAction extends Action> = {
  readonly [Type in ActionType<TAction>]?: Handler<TState, Extract<TAction, { type: Type }>>;
};

export default <
  TState,
  TAction extends Action<ActionType<TAction>> = AnyAction
>(initialState: TState, handlers: HandlersMap<TState, TAction>): Reducer<TState, TAction> => {
  return (state = initialState, action) => {
    const handler = handlers[action.type];
    if (handler === undefined)
      return state;

    return handler(
      state,
      action as any,
      initialState,
    );
  };
};
