import { createUrl } from '../helpers';
import { redirectTo } from '../actions';
import { redirect } from './helpers';

export default function (route, location, dependencies) {
  if (!route.canonicalUrl)
    return;

  const { params, canonicalUrl, language } = route;

  if (!language)
    return redirect(canonicalUrl, location, undefined, dependencies);

  const languageSegment = `/${language.name.toLowerCase()}`;

  let routeData;
  if (route.routeName)
    routeData = { routeName: route.routeName, params };

  if (canonicalUrl.startsWith(languageSegment)) {
    if (!location.pathname.startsWith(languageSegment)) {
      return redirectTo(createUrl({ ...location, pathname: languageSegment + location.pathname }), 302, routeData);
    }
  } else if (location.pathname.startsWith(languageSegment)) {
    const pathname = location.pathname.substring(languageSegment.length);
    return redirectTo(createUrl({ ...location, pathname }), 302, routeData);
  }
}