import styles from './Containers.module.scss';
import 'css/Animations.module.scss';
import { memo, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import createContentBlock from './createContentBlock';
import { Col } from 'components/primitives/grid';
import MediaBackground from 'components/primitives/grid/MediaBackground';
import { usePrintMode } from 'utils/hooks';
import { VerticalAlignmentsMap, AnimationsMap, NoBorderStyleValue } from './constants';
import { useCssAnimation } from './hooks';
import VdColumnContentWrapper from 'components/visualDesigner/column/VdColumnContentWrapper';
import { useResponsiveBreakpoints } from 'utils/layout';
import { getMinHeight } from './helpers';

const ContentBlockColumn = ({
  id: columnId,
  colspan: { sm, md, lg, xl },
  contentBlocks,
  columnAnimation,
  verticalAlignment,
  attributes,
  background,
  border,
  padding,
  minHeight,
  fullWidthRow,
}) => {
  const { id, className } = attributes;
  const normalizedSm = sm && sm !== 12 ? 6 : sm;
  const colRef = useRef(null);
  const animationClassName = useCssAnimation(colRef, columnAnimation);
  const alignmentClassName = styles[VerticalAlignmentsMap[verticalAlignment]];
  const showBorder = border.style !== NoBorderStyleValue && !!border.width;

  const { xs: viewXs, sm: viewSm, md: viewMd } = useResponsiveBreakpoints();
  const actualMinHeight = useMemo(() => getMinHeight(minHeight, viewXs, viewSm, viewMd), [viewXs, viewSm, viewMd, minHeight]);

  const wrapperStyles = {
    borderWidth: showBorder && border.width || null,
    padding: padding || null,
    minHeight: actualMinHeight,
  };
  const showBackground = showBorder
    || background.color
    || background.mobileImage
    || background.desktopImage
    || background.video;

  return (
    <Col
      ref={colRef}
      xs={12} sm={normalizedSm} md={md} lg={lg} xl={xl}
      id={id || null}
      className={`${animationClassName} ${className || ''}`}
    >
      {showBackground &&
        <MediaBackground
          parentType="column"
          {...background}
          borderWidth={border.width}
          borderStyle={border.style}
          borderColor={border.color}
          cornerRadius={border.radius}
          imageLoadVisibleByDefault={!!AnimationsMap[columnAnimation]}
          hideImageOnMobile={background.hideImageOnMobile}
          isMobile={viewXs}
          mutedSound
        />
      }
      <div className={`${styles.contentBox} ${alignmentClassName || ''}`} style={wrapperStyles}>
        <VdColumnContentWrapper columnId={columnId}>
          {renderContentBlocks(contentBlocks, { sm: normalizedSm, md, lg, xl }, fullWidthRow)}
        </VdColumnContentWrapper>
      </div>
    </Col>
  );
};

ContentBlockColumn.propTypes = {
  id: PropTypes.string.isRequired,
  colspan: PropTypes.object.isRequired,
  attributes: PropTypes.shape({
    className: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  contentBlocks: PropTypes.array,
  columnAnimation: PropTypes.string,
  verticalAlignment: PropTypes.string,
  background: PropTypes.shape({
    fullWidth: PropTypes.bool,
    desktopImage: PropTypes.string,
    mobileImage: PropTypes.string,
    hideImageOnMobile: PropTypes.bool,
    imageAltText: PropTypes.string,
    video: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  border: PropTypes.shape({
    width: PropTypes.string,
    style: PropTypes.string,
    color: PropTypes.string,
    radius: PropTypes.string,
  }).isRequired,
  padding: PropTypes.string,
  minHeight: PropTypes.shape({
    mobile: PropTypes.string,
    tablet: PropTypes.string,
    desktop: PropTypes.string,
  }).isRequired,
  fullWidthRow: PropTypes.bool,
};

export default memo(ContentBlockColumn);

function renderContentBlocks(contentBlocks, bounds, fullWidthRow) {
  const isPrintMode = usePrintMode();
  if (!contentBlocks || !contentBlocks.length)
    return null;

  return contentBlocks.map((contentBlock, index) => createContentBlock({ ...contentBlock, bounds, fullWidthRow, isPrintMode }, index));
}
