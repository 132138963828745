import { NAVIGATION_LOADED, MAIN_NAVIGATION_CHILDREN_LOADED } from './actions';
import { createReducer } from 'utils/redux';
import { updateTreeItem } from './helpers.js';
import { NavigationKeys } from './constants';

const initialState = {};

export default createReducer(initialState, {
  [NAVIGATION_LOADED]: onNavigationLoaded,
  [MAIN_NAVIGATION_CHILDREN_LOADED]: onMainNavigationChildrenLoaded,
});

function onNavigationLoaded(state, action) {
  const result = { ...state };
  const { groupCode, items } = action.payload;

  result[groupCode] = items;

  return result;
}

function onMainNavigationChildrenLoaded(state, action) {
  const { parentId, children: newChildren } = action.payload;
  const groupCode = NavigationKeys.Main;
  const rootItems = state[groupCode];

  if (!rootItems)
    return state;

  const newRootItems = updateTreeItem(rootItems,
    item => item.id === parentId,
    item => item.children = newChildren);

  if (rootItems === newRootItems)
    return state;

  const result = { ...state };
  result[groupCode] = newRootItems;
  return result;
}