import styles from './Facets.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText, makeSimpleText } from 'utils/render';
import { Link } from 'components/primitives/links';
import { CrossSmallIcon } from 'components/primitives/icons';
import useEditableFacets from './useEditableFacets';

const textsToLoad = [
  'SelectedFacets_Header',
  'RemoveFacetButton',
  'Delete',
  'FacetedSearch_ClearSelection',
];
textsToLoad.skipReplacingEmptyText = true;

const Selection = ({ facets, getUrl, getEmptyUrl }) => {
  const processedFacets = useEditableFacets(facets);
  const { texts: [selectedHeaderLbl, removeFacetButtonLbl, deleteLbl, clearSelection], loaded } = useSanaTexts(textsToLoad);
  if (!loaded)
    return null;

  const title = makeSimpleText(selectedHeaderLbl) + ':';
  const delTitle = makeSimpleText(deleteLbl);

  return (
    <section className={styles.selections} aria-label={title}>
      <div className={styles.header}>
        <h2 className="h4">{title}</h2>
      </div>
      {processedFacets.map((facet, index) => (
        <section key={index} className={styles.selection} aria-label={facet.title}>
          <h3 className={'h5 ' + styles.title}>{makeSimpleText(facet.title)}</h3>
          <ul className={styles.list}>
            {facet.values.map((value, index) => {
              const stringValueTitle = makeSimpleText(value.title);
              return (
                <li key={index} className={styles.item} aria-label={stringValueTitle}>
                  <Link
                    className={`${styles.clearBtn} ${styles.controlBtn}`} {...getUrl(facet, value)}
                    aria-label={delTitle + ' ' + stringValueTitle}
                    title={delTitle}
                  >
                    {removeFacetButtonLbl
                      ? makeRichText(removeFacetButtonLbl)
                      : <span className={styles.iconWrapper}><CrossSmallIcon alt={delTitle} /></span>
                    }
                  </Link>
                  <span className={styles.itemTitle} title={makeSimpleText(value.textTitle) || stringValueTitle}>
                    {makeRichText(value.title)}
                  </span>
                </li>
              );
            })}
          </ul>
        </section>
      ))}
      <Link className={`${linkStyles.link} ${styles.controlBtn} ${styles.clearFacetsSelection}`} {...getEmptyUrl()}>
        {makeSimpleText(clearSelection)}
      </Link>
    </section>
  );
};

Selection.propTypes = {
  facets: PropTypes.array,
  getUrl: PropTypes.func.isRequired,
  getEmptyUrl: PropTypes.func.isRequired,
};

export default memo(Selection);