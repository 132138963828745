import DateInput from 'react-date-picker/dist/DateInput';
import DayInput from './inputs/DayInput';
import MonthInput from './inputs/MonthInput';
import YearInput from './inputs/YearInput';
import MonthSelect from 'react-date-picker/dist/DateInput/MonthSelect';
import { isValidDate } from 'utils/helpers';

export default class ExtendedDateInput extends DateInput {
  static localeData = new Map();

  static getDerivedStateFromProps(nextProps, prevState) {
    const localeDataKey = `${nextProps.locale}_${nextProps.maxDetail}`;
    if (!ExtendedDateInput.localeData.get(localeDataKey)) {
      const placeholder = getPlaceholder(nextProps.locale, nextProps.maxDetail);
      const yearDiff = nextProps.locale.includes('th-TH') ? 543 : 0;
      ExtendedDateInput.localeData.set(localeDataKey, { placeholder, yearDiff });
    }

    if (nextProps.value && !isValidDate(nextProps.value))
      return { hasInvalidDate: true };

    if (!nextProps.value && prevState.hasInvalidDate)
      return { day: null, month: null, year: null, hasInvalidDate: false };

    let prevStateCore = prevState;
    if (nextProps.value && prevState.value && prevState.hasInvalidDate)
      prevStateCore = { ...prevStateCore, value: null };

    const nextState = DateInput.getDerivedStateFromProps(nextProps, prevStateCore);

    if (prevState.hasInvalidDate)
      nextState.hasInvalidDate = false;

    return nextState;
  }

  get localeDataKey() {
    return `${this.props.locale}_${this.props.maxDetail}`;
  }

  get placeholder() {
    if (this.props.format)
      return this.props.format;

    return ExtendedDateInput.localeData.get(this.localeDataKey).placeholder;
  }

  get yearDiff() {
    return ExtendedDateInput.localeData.get(this.localeDataKey).yearDiff;
  }

  componentWillUnmount() {
    clearTimeout(this.onChangeTimeout);
  }

  onKeyDown = event => {
    switch (event.key) {
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Left':
      case 'Right':
      case this.divider:
        if (event.key !== this.divider && event.shiftKey)
          return;

        event.preventDefault();

        const { target: input } = event;
        const isLeftArrowKey = event.key === 'ArrowLeft' || event.key === 'Left';
        const property = isLeftArrowKey ? 'previousElementSibling' : 'nextElementSibling';
        const nextInput = findInput(input, property);
        focus(nextInput);
        return;
      case ' ':
      case 'Spacebar':
        event.preventDefault();
        return;
      default:
        return;
    }
  };

  onKeyUp = event => {
    const { key, target: input } = event;

    const isNumberKey = !isNaN(parseInt(key !== 'Unidentified' ? key : input.value.slice(-1), 10));
    if (!isNumberKey)
      return;

    const maxLength = +input.getAttribute('maxLength');
    if (input.value.length === maxLength) {
      const property = 'nextElementSibling';
      const nextInput = findInput(input, property);
      focus(nextInput);
    }
  };

  onChange = (event, name, value) => {
    if (event) {
      name = event.target.name;
      value = event.target.value;
    }

    if (name == null)
      return;

    this.setState(() => {
      let newValue = value && !isNaN(value) ? parseInt(value, 10) : null;
      if (newValue && name === 'year')
        newValue -= this.yearDiff;

      return { [name]: newValue };
    });

    clearTimeout(this.onChangeTimeout);
    this.onChangeTimeout = setTimeout(this.onChangeExternal, 50);
  };

  onChangeExternal = () => {
    const { onChange } = this.props;

    if (!onChange)
      return;

    const formElements = [this.dayInput, this.monthInput, this.yearInput].filter(Boolean);
    const values = formElements.reduce((result, formElement) => {
      result[formElement.name] = formElement.value;
      return result;
    }, {});

    if (formElements.every(formElement => !formElement.value)) {
      onChange(null, false);
    } else if (formElements.every(formElement => formElement.value && formElement.checkValidity())) {
      const year = parseInt(values.year, 10);
      const month = parseInt(values.month || 1, 10);
      const day = parseInt(values.day || 1, 10);

      const proposedValue = new Date(year - this.yearDiff, month - 1, day);
      const processedValue = this.getProcessedValue(proposedValue);

      onChange(processedValue, false);
    } else {
      onChange(new Date(NaN), false);
    }
  };

  // eslint-disable-next-line react/no-multi-comp
  renderDay = (currentMatch, index) => {
    const {
      autoFocus,
      dayAriaLabel,
      dayPlaceholder,
      id,
    } = this.props;
    const { day, month, year } = this.state;

    if (currentMatch && currentMatch.length > 2)
      throw new Error(`Unsupported token: ${currentMatch} `);

    return (
      <DayInput
        key="day"
        id={index === 0 ? id : null}
        {...this.commonInputProps}
        ariaLabel={dayAriaLabel}
        autoFocus={index === 0 && autoFocus}
        month={month}
        placeholder={dayPlaceholder}
        value={day}
        year={year}
        allowEmpty={!month && !year}
      />
    );
  };

  // eslint-disable-next-line react/no-multi-comp
  renderMonth = (currentMatch, index) => {
    const {
      autoFocus,
      locale,
      monthAriaLabel,
      monthPlaceholder,
      id,
    } = this.props;
    const { day, month, year } = this.state;

    if (currentMatch && currentMatch.length > 4)
      throw new Error(`Unsupported token: ${currentMatch} `);

    if (currentMatch.length > 2) {
      return (
        <MonthSelect
          key="month"
          id={index === 0 ? id : null}
          {...this.commonInputProps}
          ariaLabel={monthAriaLabel}
          autoFocus={index === 0 && autoFocus}
          locale={locale}
          placeholder={monthPlaceholder}
          short={currentMatch.length === 3}
          value={month}
          year={year}
        />
      );
    }

    return (
      <MonthInput
        key="month"
        id={index === 0 ? id : null}
        {...this.commonInputProps}
        ariaLabel={monthAriaLabel}
        autoFocus={index === 0 && autoFocus}
        placeholder={monthPlaceholder}
        value={month}
        year={year}
        allowEmpty={!day && !year}
      />
    );
  };

  // eslint-disable-next-line react/no-multi-comp
  renderYear = (_currentMatch, index) => {
    const { autoFocus, yearAriaLabel, yearPlaceholder, id } = this.props;
    const { day, month, year } = this.state;

    return (
      <YearInput
        key="year"
        id={index === 0 ? id : null}
        {...this.commonInputProps}
        ariaLabel={yearAriaLabel}
        autoFocus={index === 0 && autoFocus}
        placeholder={yearPlaceholder}
        value={year}
        valueType={this.valueType}
        allowEmpty={!day && !month}
        yearDiff={this.yearDiff}
      />
    );
  };
}

function isValidInput(element) {
  return element.tagName === 'INPUT' && element.dataset.type === 'number';
}

function findInput(element, property) {
  let nextElement = element;
  do {
    nextElement = nextElement[property];
  } while (nextElement && !isValidInput(nextElement));
  return nextElement;
}

function focus(element) {
  if (element) {
    element.focus();
  }
}

function getPlaceholder(locale, maxDetail) {
  const year = 2017;
  const monthIndex = 11;
  const day = 18;
  const date = new Date(year, monthIndex, day);

  const dateFormatOptions = getFormatDateOptions(maxDetail);
  const formattedDate = date.toLocaleDateString(locale + '-u-ca-gregory-nu-latn', dateFormatOptions);

  return formattedDate
    .replace(/\u200F/g, '') // Remove unicode right-to-left marks.
    .replace(/\u200E/g, '') // Fix to support IE which adds unicode symbols.
    .replace(day, 'd')
    .replace(monthIndex + 1, 'M')
    .replace(/\d+/g, 'y');
}

function getFormatDateOptions(maxDetail) {
  const options = { year: 'numeric' };
  const level = ['century', 'decade', 'year', 'month'].indexOf(maxDetail);

  if (level >= 2)
    options.month = 'numeric';

  if (level >= 3)
    options.day = 'numeric';

  return options;
}
