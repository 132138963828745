import styles from '../../checkout/OrderResult.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useSimpleTexts } from 'components/sanaText';
import { logout } from 'behavior/user';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { Placeholder } from 'components/primitives/placeholders';
import { Helmet } from 'react-helmet';
import { usePageTitle } from 'components/objects/hooks';

const PaymentFailed = () => {
  const dispatch = useDispatch();
  const isAnonymousCustomer = useSelector(state => !state.user.isAuthenticated);
  const [texts, loaded] = usePaymentFailedTexts();
  const pageTitle = usePageTitle();

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <p className={styles.description}>
        {texts.description}
      </p>
      {loaded
        ? (
          <ul className={styles.actionsList}>
            <li>
              <Link className={`${linkStyles.arrowed} ${styles.homeLink}`} to={routesBuilder.forHome}>
                {texts.homePage}
              </Link>
            </li>
            {!isAnonymousCustomer && (
              <li>
                <button className={`${linkStyles.link} ${linkStyles.arrowed} ${styles.btnLogout}`} onClick={() => dispatch(logout())}>
                  {texts.logout}
                </button>
              </li>
            )}
          </ul>
        )
        : (
          <ul className={styles.actionsList}>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
          </ul>
        )
      }
    </>
  );
};

export default PaymentFailed;

function usePaymentFailedTexts() {
  const {
    texts: [
      description,
      homePage,
      logout,
    ],
    loaded,
  } = useSimpleTexts([
    'Payment_PaymentFailure',
    'GoToHomePage',
    'SubmitLogoutLinkText',
  ]);

  return [
    {
      description,
      homePage,
      logout,
    },
    loaded,
  ];
}
