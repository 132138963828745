exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InvoicePayment_custom-text{word-break:break-word}@supports (-ms-ime-align:auto){.InvoicePayment_custom-text{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.InvoicePayment_custom-text{word-wrap:break-word}}.InvoicePayment_invoice-pay-row{display:flex;justify-content:flex-end;align-items:center;padding-top:1em;margin:1em 0 2em}@media (min-width:var(--breakpoints_Medium,960)px),print{.InvoicePayment_invoice-pay-row{border-top:1px solid var(--borderColor,#e6e6e6)}}", ""]);

// exports
exports.locals = {
	"custom-text": "InvoicePayment_custom-text",
	"customText": "InvoicePayment_custom-text",
	"invoice-pay-row": "InvoicePayment_invoice-pay-row",
	"invoicePayRow": "InvoicePayment_invoice-pay-row"
};