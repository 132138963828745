exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ChildMenuItems_item{padding-top:1em;padding-bottom:1em}.ChildMenuItems_link{display:inline-block;width:100%;max-width:100%}html.pointer-mouse .ChildMenuItems_link:hover{color:inherit}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .ChildMenuItems_link:active{color:inherit}}}.ChildMenuItems_link>span{width:100%}.ChildMenuItems_link img{display:block;max-width:100%;margin:0 auto}.ChildMenuItems_caption{text-transform:uppercase;margin:0;text-align:center}@media (min-width:var(--breakpoints_Small,600)px),print{.ChildMenuItems_link{width:auto}.ChildMenuItems_link img{margin-left:0}.ChildMenuItems_caption{text-align:left}}", ""]);

// exports
exports.locals = {
	"item": "ChildMenuItems_item",
	"link": "ChildMenuItems_link",
	"caption": "ChildMenuItems_caption"
};