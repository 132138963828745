import { combineEpics } from 'redux-observable';
import {
  first,
  pluck,
  map,
  filter,
} from 'rxjs/operators';

import { initializeAnalyticsTrackers } from './actions';
import { default as ecommerce } from './epic.ecommerce';
import { viewerChanged, dataLayerInit } from './epic.user';

const init = (_, state$, __) => state$.pipe(
  pluck('settings'),
  first(settings => settings && settings.loaded),
  pluck('analytics'),
  filter(a => a),
  map(initializeAnalyticsTrackers),
);

export default combineEpics(
  init,
  ecommerce,
  viewerChanged,
  dataLayerInit,
);