/**
 * @typedef {Object} PagingInfo
 * @property {boolean} immediateLoad - indicates if immediate load of the page should be performed.
 * @property {number} pageToLoad - the next page index (zero based).
 * @property {number} nextPageCount - the next page count.
 * @property {number} itemsToLoad - the amount of items to load.
 */

/**
 * Gets paging info for the next page.
 * @param {Number} loadedItemsAmount - total amount of products already loaded.
 * @param {Number} totalCount - total amount of products.
 * @param {Number} pageSize - the page size.
 * @returns {PagingInfo} - the next page paging info.
 */

export function getNextPageInfo(loadedItemsAmount: number, pageSize: number, totalCount: number) {
  const immediateLoad: boolean = loadedItemsAmount !== totalCount && loadedItemsAmount % pageSize !== 0;
  const pageToLoad: number = Math.floor(loadedItemsAmount / pageSize);
  const nextPageCount: number = (pageToLoad + 1) * pageSize;
  const itemsToLoad: number = Math.min(nextPageCount, totalCount) - loadedItemsAmount;

  return {
    immediateLoad,
    pageToLoad,
    nextPageCount,
    itemsToLoad,
  };
}
