//3.1.Econ � configuration functionality
import React from 'react';
import styles from './EConIframe.module.scss';
import { func } from 'prop-types';

const Iframe = ({ source }) => {

  if (!source) {
    return <div>Loading...</div>;
  }

  setTimeout(function () {
    const econIframe = document.getElementById("econN");
    if (econIframe) {
      const econN = document.getElementById("econN").parentNode
      econN.parentElement.className = styles.iframeDwrapper;
    }
  }, 1500)

  const src = source;

  return (

    <div className={styles.iframeWrapper}>


      <iframe src={src}
        scrolling="yes"
        width="100%"
        id="econN"
        frameBorder="0"
        height="100%"></iframe>
    </div>
  );


};

export default Iframe;