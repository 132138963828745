import styles from '../Order.module.scss';
import PropTypes from 'prop-types';

const QuoteLineStock = ({ inStock, lineTexts }) => {
  if (inStock == null)
    return null;

  if (inStock)
    return <span className={styles.inStock}>{lineTexts.inStock}</span>;

  return <span className={styles.outStock}>{lineTexts.outStock}</span>;
};

QuoteLineStock.propTypes = {
  inStock: PropTypes.bool,
  lineTexts: PropTypes.shape({
    inStock: PropTypes.string,
    outStock: PropTypes.string,
  }).isRequired,
};

export default QuoteLineStock;