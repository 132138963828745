import styles from './Logo.module.scss';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { routesBuilder } from 'routes';
import { Link } from 'components/primitives/links';
import { UseSanaTexts } from 'components/sanaText';
import defaultLogo from './logo.svg';

const Logo = ({ small, className = '' }) => {
  const src = useSelector(({ theme: { version, values }, page: { layoutHeroEffect } }) => {
    if (layoutHeroEffect?.headerOnTop) {
      const { altSmallLogo, altLogo } = layoutHeroEffect;

      if (small && altSmallLogo)
        return altSmallLogo;

      if (altLogo && !small)
        return altLogo;
    }

    if (!version)
      return null;

    if (!values)
      return defaultLogo;

    if (small && values.smallLogo)
      return values.smallLogo;

    return values.logo || defaultLogo;
  });

  if (!src)
    return null;

  return (
    <div className={`${styles.logo} ${className}`}>
      <UseSanaTexts options={['LogoImageAltText']} dontWait>
        {([logoAlt]) => (
          <Link to={routesBuilder.forHome}>
            <img src={src} alt={logoAlt} />
          </Link>
        )}
      </UseSanaTexts>
    </div>
  );
};

Logo.propTypes = {
  small: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(Logo);
