exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NotValidCustomer_no-gutters{padding-left:0;padding-right:0}.NotValidCustomer_info{background-color:var(--alert_Info_BackgroundColor,#f3f6f9)}.NotValidCustomer_info .NotValidCustomer_no-margin,.NotValidCustomer_info p{margin:0}.NotValidCustomer_info p{line-height:normal}.NotValidCustomer_content-wrapper{display:inline-block;vertical-align:middle}", ""]);

// exports
exports.locals = {
	"no-gutters": "NotValidCustomer_no-gutters",
	"noGutters": "NotValidCustomer_no-gutters",
	"info": "NotValidCustomer_info",
	"no-margin": "NotValidCustomer_no-margin",
	"noMargin": "NotValidCustomer_no-margin",
	"content-wrapper": "NotValidCustomer_content-wrapper",
	"contentWrapper": "NotValidCustomer_content-wrapper"
};