import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { useSimpleTexts } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';

const ViewDetailsLink = ({ url, id, documentType, orderId }) => {
  const { texts: [viewDetails], loaded } = useSimpleTexts(['ViewDetails']);

  if (!loaded)
    return null;

  return (
    <Link
      to={() => routesBuilder.forDocument(id, documentType, orderId)}
      url={url}
      className={linkStyles.arrowed}
    >
      {viewDetails}
    </Link>
  );
};

ViewDetailsLink.propTypes = {
  url: PropTypes.string,
  id: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  orderId: PropTypes.string,
};

export default ViewDetailsLink;