import scrollIntoView from 'scroll-into-view';

export function scrollToInvalidElement(inputElement, errorElement) {
  const scrollTo = errorElement || inputElement;
  if (!scrollTo)
    return;

  scrollIntoView(
    scrollTo,
    { time: 300 },
    inputElement && inputElement.focus && (() => inputElement.focus()),
  );
}
