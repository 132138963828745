exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ActionButtons_btn-checkout-placeholder,.ActionButtons_btn-quote-placeholder{font-size:var(--buttons_Big_FontSize,22px);height:2em}.ActionButtons_btn-quote-placeholder{min-width:5.88em}.ActionButtons_btn-quote-placeholder.ActionButtons_small{height:1.7em;font-size:var(--buttons_Normal_FontSize,1.4em)}.ActionButtons_btn-checkout-placeholder{min-width:9.54em}.ActionButtons_btn-checkout,.ActionButtons_btn-quote{display:block}", ""]);

// exports
exports.locals = {
	"btn-checkout-placeholder": "ActionButtons_btn-checkout-placeholder",
	"btnCheckoutPlaceholder": "ActionButtons_btn-checkout-placeholder",
	"btn-quote-placeholder": "ActionButtons_btn-quote-placeholder",
	"btnQuotePlaceholder": "ActionButtons_btn-quote-placeholder",
	"small": "ActionButtons_small",
	"btn-checkout": "ActionButtons_btn-checkout",
	"btnCheckout": "ActionButtons_btn-checkout",
	"btn-quote": "ActionButtons_btn-quote",
	"btnQuote": "ActionButtons_btn-quote"
};