exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MyAccount_heading{display:flex;flex-wrap:wrap;align-items:center;margin-bottom:15px}.MyAccount_heading .MyAccount_spinner{width:20px;height:20px;margin-left:25px}.MyAccount_main-block{margin-top:10px}.MyAccount_main-block.MyAccount_no-order-products .MyAccount_top-actions{float:right}.MyAccount_main-block .MyAccount_top-actions{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center}.MyAccount_main-block .MyAccount_customer-service-link{display:inline-flex;align-items:center}.MyAccount_main-block .MyAccount_account-name{margin:13px 0 20px;color:var(--darkestText_FontColor,#000)}.MyAccount_account-management,.MyAccount_order-info{margin-top:65px}.MyAccount_order-info .MyAccount_title{display:inline-block;margin:0 25px 0 0}.MyAccount_order-info>a{margin-top:.5em}.MyAccount_account-management .MyAccount_title{margin:0}.MyAccount_content{padding:1em 0}.MyAccount_contact-info{margin-bottom:15px}.MyAccount_sub-title{font-weight:700;font-size:var(--normalText_BiggerFontSize,14px);margin-right:45px;color:var(--darkestText_FontColor,#000)}.MyAccount_account-info{border-collapse:collapse;border-spacing:0;width:100%;line-height:1.84}.MyAccount_account-info td{padding:0}.MyAccount_account-info td:first-of-type{width:36%;padding-right:3%;overflow:hidden;text-overflow:ellipsis}.MyAccount_newsletter{margin-top:15px}@media (min-width:var(--breakpoints_Small,600)px),print{.MyAccount_account-info{width:75%}}@media (min-width:var(--breakpoints_Medium,960)px),print{.MyAccount_account-info{width:50%}}.MyAccount_description{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"heading": "MyAccount_heading",
	"spinner": "MyAccount_spinner",
	"main-block": "MyAccount_main-block",
	"mainBlock": "MyAccount_main-block",
	"no-order-products": "MyAccount_no-order-products",
	"noOrderProducts": "MyAccount_no-order-products",
	"top-actions": "MyAccount_top-actions",
	"topActions": "MyAccount_top-actions",
	"customer-service-link": "MyAccount_customer-service-link",
	"customerServiceLink": "MyAccount_customer-service-link",
	"account-name": "MyAccount_account-name",
	"accountName": "MyAccount_account-name",
	"account-management": "MyAccount_account-management",
	"accountManagement": "MyAccount_account-management",
	"order-info": "MyAccount_order-info",
	"orderInfo": "MyAccount_order-info",
	"title": "MyAccount_title",
	"content": "MyAccount_content",
	"contact-info": "MyAccount_contact-info",
	"contactInfo": "MyAccount_contact-info",
	"sub-title": "MyAccount_sub-title",
	"subTitle": "MyAccount_sub-title",
	"account-info": "MyAccount_account-info",
	"accountInfo": "MyAccount_account-info",
	"newsletter": "MyAccount_newsletter",
	"description": "MyAccount_description"
};