import { memo } from 'react';
import PropTypes from 'prop-types';
import { HeadersMap } from '../../contentBlocks/constants';
import { renderHTML } from 'utils/render';

const HeaderBlock = ({ model, createInlineHtmlEditor }) => {
  if (!model)
    return null;

  const {
    content,
    color,
    headerStyle,
  } = model;
  if (!content || content === '')
    return null;

  const header = HeadersMap[headerStyle];
  const Tag = header.tag || 'div';
  const className = header.className;

  return (
    <Tag style={{ color }} className={className}>
      {createInlineHtmlEditor
        ? createInlineHtmlEditor(model, 'content', { textOnlyMode: true })
        : renderHTML(content)}
    </Tag>
  );
};

HeaderBlock.propTypes = {
  model: PropTypes.shape({
    content: PropTypes.string,
  }),
  createInlineHtmlEditor: PropTypes.func,
};

export default memo(HeaderBlock);