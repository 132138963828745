import { SimpleText, RichText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';

const formatWith = [
  <Link to={routesBuilder.forContactUs} className="contact-us-link"><SimpleText textKey="PaymentMethods_Contact" /></Link>,
];

const PaymentMethodsNotAvailable = () => <RichText textKey="PaymentMethods_NoMethodsAvailable" formatWith={formatWith} />;

export default PaymentMethodsNotAvailable;