exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media print,screen{html.print .d-print-none{display:none!important}}@media print{.d-print-none{display:none!important}}@media print,screen{html.print #layout,html.print #root,html.print .print-no-flex,html.print body{display:block!important}html.print #extras,html.print #footer,html.print #modals{display:none}html.print .gvi td,html.print .gvi th,html.print .lbl-price,html.print .list-price,html.print .tab-control,html.print a,html.print body,html.print div,html.print h1,html.print h2,html.print p,html.print span{color:#000!important}html.print #root{overflow:visible}html.print #header{padding-top:10px;padding-bottom:10px}}@page{margin:.4in}@media print{#layout{padding-top:0!important}.print #layout,.print #root{min-height:100%}@media not all and (min-resolution:.001dpcm){@media (pointer:coarse){#root{display:block}}}}@media print and (-ms-high-contrast:active),print and (-ms-high-contrast:none){#root{overflow:visible}}@media screen{.print body{background:#ebebe9;margin:0}.print #layout{width:850px!important;min-height:900px!important;border:1px solid #000;background-image:none;background-color:#fff;padding:.4in .15in;margin:70px auto 20px;position:relative;box-shadow:0 1px 8px #333}}", ""]);

// exports
exports.locals = {
	"printPageTopIndent": "70"
};