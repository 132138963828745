import PropTypes from 'prop-types';

export const basketModelPropTypes = {
  model: PropTypes.shape({
    productLines: PropTypes.shape({
      totalCount: PropTypes.number,
    }).isRequired,
    nonOrderableLines: PropTypes.array,
    page: PropTypes.object,
    isDisabled: PropTypes.bool,
  }),
};
