export const RETRY_FAILED_REQUESTS = 'APP/RETRY_FAILED_REQUESTS';
export const retryFailedRequests = () => ({ type: RETRY_FAILED_REQUESTS });

export const SHOW_ERROR_TOAST = 'APP/SHOW_ERROR_TOAST';
export const showErrorToast = (type, data) => ({
  type: SHOW_ERROR_TOAST,
  payload: { type, data },
});

export const CLOSE_ERROR_TOAST = 'APP/CLOSE_ERROR_TOAST';
export const closeErrorToast = type => ({
  type: CLOSE_ERROR_TOAST,
  payload: { type },
});
