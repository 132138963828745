exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ReturnOrders_btn-create{margin-top:10px}", ""]);

// exports
exports.locals = {
	"btn-create": "ReturnOrders_btn-create",
	"btnCreate": "ReturnOrders_btn-create"
};