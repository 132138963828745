import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

let VdLayoutWrapper;

const VdLayoutSwitcher = ({ children, visualDesigner, insiteEditor }) => {
  if (visualDesigner || insiteEditor) {
    if (!VdLayoutWrapper)
      VdLayoutWrapper = loadable(() => import(/*webpackChunkName:"vd"*/'./VdLayoutWrapper'));

    return <VdLayoutWrapper>{children}</VdLayoutWrapper>;
  }

  return children;
};

VdLayoutSwitcher.propTypes = {
  children: PropTypes.node,
  visualDesigner: PropTypes.bool,
  isVisualDesigner: PropTypes.bool,
};

export default connect(
  ({ visualDesigner }) => ({
    visualDesigner: visualDesigner.initialized,
    insiteEditor: visualDesigner.insiteEditor,
  }),
)(VdLayoutSwitcher);