import styles from './Swiper.module.scss';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import RowRailsContext from './context';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { BigArrowLeftIcon, BigArrowLeftPassiveIcon, BigArrowRightIcon, BigArrowRightPassiveIcon } from 'components/primitives/icons';

const WithSwipeButtons = ({ children, className = '' }) => {
  const { swipePrev, swipeNext, isFirstItem, isLastItem } = useContext(RowRailsContext);
  const [prevBtnTitle, nextBtnTitle] = useSanaTexts([
    'ButtonAltText_ProductCompare_SlidePrevious',
    'ButtonAltText_ProductCompare_SlideNext',
  ], makeSimpleText).texts;

  const PrevBtnIcon = isFirstItem ? BigArrowLeftPassiveIcon : BigArrowLeftIcon;
  const NextBtnIcon = isLastItem ? BigArrowRightPassiveIcon : BigArrowRightIcon;

  return (
    <div className={`${styles.buttonsWrapper} ${className}`}>
      {children}
      {!(isFirstItem && isLastItem) &&
        <>
          <button
            title={prevBtnTitle}
            type="button"
            disabled={isFirstItem}
            className={`${styles.button} swiper-button-prev`}
            onClick={swipePrev}
          >
            <PrevBtnIcon className={styles.icon} aria-hidden />
          </button>
          <button
            title={nextBtnTitle}
            type="button"
            disabled={isLastItem}
            className={`${styles.button} swiper-button-next`}
            onClick={swipeNext}
          >
            <NextBtnIcon className={styles.icon} aria-hidden />
          </button>
        </>
      }
    </div>
  );
};

WithSwipeButtons.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default WithSwipeButtons;