import { memo } from 'react';
import PropTypes from 'prop-types';
import RecentOrders from './RecentOrders';
import MainBlock from './MainBlock';
import AccountManagement from './AccountManagement';

const MyAccount = ({ orders }) => (
  <>
    <MainBlock />
    <RecentOrders orders={orders} />
    <AccountManagement />
  </>
);

MyAccount.propTypes = {
  orders: PropTypes.array,
};

const Memoized = memo(MyAccount);

Memoized.selectPropsFromPage = function ({ docs }) {
  return { orders: docs && docs.items };
};

export default Memoized;