import styles from './Swiper.module.scss';
import { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import RowRailsContext from './context';
import { useMatchMediaContext } from 'components/responsive';

const RowRails = ({ children, items, highlightColumns = false }) => {
  const { registerRow, unRegisterRow } = useContext(RowRailsContext);
  const rowRef = useRef(null);

  const itemsAmount = items.length;
  const maxItemsToShow = useMaxItemsToShow(itemsAmount);

  useEffect(() => {
    rowRef.current && registerRow(rowRef.current);

    return () => {
      unRegisterRow(rowRef.current);
    };
  }, [!!rowRef.current]);

  return (
    <div className={styles.rails}>
      <div className={styles.row} ref={rowRef} data-prod-amount={itemsAmount}>
        {items.map((item, i) => (
          <div key={i}
            className={`row-rails-col ${styles.item}${highlightColumns ? ` ${styles.highlightedCol}` : ''}`}
            style={{ width: `${100 / maxItemsToShow}%` }}
          >
            {children(item)}
          </div>
        ))}
      </div>
    </div>
  );
};

RowRails.propTypes = {
  children: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  highlightColumns: PropTypes.bool,
};

export default RowRails;

function useMaxItemsToShow(itemsAmount) {
  const { xs, lg, xl } = useMatchMediaContext();

  switch (itemsAmount) {
    case 1:
    case 2:
      return itemsAmount;
    case 3:
      return !xs ? 3 : 2;
    case 4:
      return xl || lg ? 4 : !xs ? 3 : 2;
    case 5:
      return xl ? 5 : lg ? 4 : !xs ? 3 : 2;
    default:
      return xl ? 6 : lg ? 4 : !xs ? 3 : 2;
  }
}