import { useBooleanOptions } from '../hooks';
import DropdownField from './DropdownField';
import { fieldPropTypes } from '../Field';

const BooleanDropdownField = ({ fieldName, ...additionalProps }) => {
  const items = useBooleanOptions(fieldName);

  return (
    <DropdownField
      fieldName={fieldName}
      items={items}
      {...additionalProps}
    />
  );
};

BooleanDropdownField.propTypes = fieldPropTypes;
BooleanDropdownField.notNative = DropdownField.notNative;

export default BooleanDropdownField;