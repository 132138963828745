import styles from './VariantsMatrix.module.scss';
import { useEffect, useCallback, useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { addProducts } from 'behavior/basket';
import { generateKey } from 'utils/helpers';
import { SimpleText } from 'components/sanaText';
import { useOnUpdate } from 'utils/hooks';
import { useProductContext } from '../hooks';
import AddToBasketButton from './AddToBasketButton';
import VariantsMatrix from './VariantsMatrix';
import { AbilityTo } from 'behavior/user/constants';
import { OfflinePriceWarning, UomSelector } from 'components/primitives/product';
import ErrorMessage from './ErrorMessage';
import VariantsMatrixContext from './VariantsMatrixContext';
import { Placeholder } from 'components/primitives/placeholders';
import { focusById } from 'utils/dom';

const VariantsMatrixForm = ({
  abilities,
  onProductsAdded,
  addProducts,
  backButton,
  allowUOMSelection,
  opened,
}) => {
  const [componentId] = useState(generateKey);
  const {
    quantities,
    resetQuantities,
    updateQuantity: updateContextQuantity,
  } = useContext(VariantsMatrixContext);
  const [valid, setValid] = useState(true);
  const canOrder = abilities[AbilityTo.OrderProducts];
  const { product, updateUomId, uomId } = useProductContext();
  const { title, id } = product;
  const productUomId = uomId || (product.uom && product.uom.id);

  const updateQuantity = (variantId, quantity) => {
    if (quantity && quantity.isValid)
      setValid(true);

    updateContextQuantity(variantId, quantity);
  };

  useEffect(() => () => {
    for (const [variantId, quantity] of quantities) {
      if (!quantity.isValid)
        updateQuantity(variantId, null);
    }
  }, []);

  useOnUpdate(() => {
    setValid(true);
    resetQuantities();
  }, [productUomId]);

  const addToBasket = useCallback(e => {
    e.preventDefault();
    const lines = [];

    for (const [variantId, quantity] of quantities) {
      if (!quantity.isValid) {
        const id = `${productId}_${variantId}`;
        document.getElementById(id).focus();
        return;
      }

      lines.push({
        productId,
        variantId,
        uomId: productUomId,
        quantity: quantity.value,
      });
    }

    if (!lines.length) {
      setValid(false);
      return;
    }

    addProducts(lines, componentId);
    setValid(true);
    resetQuantities();
    onProductsAdded();
  }, [product, quantities]);

  const addToBasketButton = canOrder && <AddToBasketButton quantities={quantities} />;
  const showUoms = abilities[AbilityTo.ViewUnitOfMeasure];
  const { uoms, id: productId } = product;
  const showUomSelector = showUoms && uomId && uoms && uoms.length;

  const uomTitlePlaceholder = <Placeholder className={styles.uomTitlePlaceholder} />;

  return (
    <form onSubmit={addToBasket}>
      <h2 className={styles.productTitle}>{title}</h2>
      <div className={styles.detailsWrapper}>
        <div className={styles.idWithUom}>
          <div className={styles.productId}>
            <span className={styles.idName}><SimpleText textKey="General_Product_Id" /></span>
            <span>{id}</span>
          </div>
          {showUomSelector && (
            <div className={styles.uomWrapper}>
              {/* onClick is used to simulate default label behavior. */}
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
              <label className={styles.uomTitle} onClick={focusById.bind(null, productId + '_UOM')}>
                <SimpleText textKey="UOM_Label" placeholder={uomTitlePlaceholder} />
              </label>
              <div className={styles.uomSelector}>
                <UomSelector
                  productId={productId}
                  allowUOMSelection={allowUOMSelection}
                  uomId={uomId}
                  uoms={uoms}
                  onUomChange={updateUomId}
                  isOrderable
                />
              </div>
            </div>
          )}
        </div>
        {addToBasketButton}
      </div>
      {!valid && <ErrorMessage />}
      <OfflinePriceWarning />
      {product.variantComponentGroups &&
        <VariantsMatrix
          opened={opened}
          product={product}
          initialQuantities={quantities}
          abilities={abilities}
          onQuantityChanged={updateQuantity}
        />
      }
      <div className={styles.footer}>
        {addToBasketButton}
        {backButton}
      </div>
    </form>
  );
};

VariantsMatrixForm.propTypes = {
  abilities: PropTypes.shape({
    [AbilityTo.OrderProducts]: PropTypes.bool.isRequired,
  }).isRequired,
  onProductsAdded: PropTypes.func.isRequired,
  addProducts: PropTypes.func.isRequired,
  backButton: PropTypes.node.isRequired,
  allowUOMSelection: PropTypes.bool.isRequired,
  opened: PropTypes.bool,
};

const mapStateToProps = ({ settings: { product } }) => ({
  allowUOMSelection: product && product.allowUOMSelection,
});

export default connect(mapStateToProps, { addProducts })(VariantsMatrixForm);