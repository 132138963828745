import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { mergeMap, map } from 'rxjs/operators';
import { orderCancelledQuery } from './queries';
import { initSystemPageContent, loadSystemPageQuery } from 'behavior/pages/system';
import { CheckoutProcessNames } from './constants';

export default (routeData, _state$, { api }) => {
  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi(loadSystemPageQuery('orderCancelled')).pipe(
      map(({ pages: { orderCancelled } }) => ({
        page: {
          ...orderCancelled,
          component: PageComponentNames.OrderCancelled,
          transaction: {
            id: '',
            documentId: '',
            checkoutProcessName: CheckoutProcessNames.Order,
          },
        },
      })),
      initSystemPageContent(),
    );
  }

  if (!transactionId)
    return of(null);

  return api.graphApi(orderCancelledQuery, { id: transactionId }).pipe(
    mergeMap(({ pages: { orderCancelled }, paymentTransaction: transaction }) => {
      if (transaction == null)
        return of(null);

      transaction.id = transactionId;

      return of({
        page: {
          ...orderCancelled,
          component: PageComponentNames.OrderCancelled,
          transaction,
        },
      });
    }),
    initSystemPageContent(),
  );
};
