export const ANALYTICS_TRACKERS_INITIALIZE = 'ANALYTICS_TRACKERS_INITIALIZE';
export const initializeAnalyticsTrackers = analyticsSettings => ({
  type: ANALYTICS_TRACKERS_INITIALIZE,
  payload: analyticsSettings,
});

export const ANALYTICS_PRODUCT_CLICKED = 'ANALYTICS_PRODUCT_CLICKED';
export const trackProductClick = (product, source = null) => ({
  type: ANALYTICS_PRODUCT_CLICKED,
  payload: { product, source },
});

export const ANALYTICS_PRODUCT_DETAILS_VIEWED = 'ANALYTICS_PRODUCT_DETAILS_VIEWED';
export const trackProductDetailsView = product => ({
  type: ANALYTICS_PRODUCT_DETAILS_VIEWED,
  payload: { product },
});

export const ANALYTICS_PRODUCT_LIST_VIEWED = 'ANALYTICS_PRODUCT_LIST_VIEWED';
export const trackProductListView = (products, source = null) => ({
  type: ANALYTICS_PRODUCT_LIST_VIEWED,
  payload: { products, source },
});

export const ANALYTICS_PRODUCTS_ADDED_TO_BASKET = 'ANALYTICS_PRODUCTS_ADDED_TO_BASKET';
export const trackAddToBasket = products => ({
  type: ANALYTICS_PRODUCTS_ADDED_TO_BASKET,
  payload: { products },
});

export const ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET = 'ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET';
export const trackRemoveFromBasket = products => ({
  type: ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET,
  payload: { products },
});

export const ANALYTICS_CHECKOUT = 'ANALYTICS_CHECKOUT';
export const trackCheckout = (step, products) => ({
  type: ANALYTICS_CHECKOUT,
  payload: { step, products },
});

export const ANALYTICS_CHECKOUT_OPTION = 'ANALYTICS_CHECKOUT_OPTION';
export const trackCheckoutOption = (step, option) => ({
  type: ANALYTICS_CHECKOUT_OPTION,
  payload: { step, option },
});

export const ANALYTICS_PURCHASE = 'ANALYTICS_PURCHASE';
export const trackPurchase = (products, transactionInfo) => ({
  type: ANALYTICS_PURCHASE,
  payload: { products, transactionInfo },
});