export const ContentHorizontalAlignment = Object.freeze({
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  JUSTIFY: 'justify',
});

export const HeadersMap = Object.freeze({
  DisplayHeader1: { tag: 'div', className: 'dh1' },
  DisplayHeader2: { tag: 'div', className: 'dh2' },
  DisplayHeader3: { tag: 'div', className: 'dh3' },
  Header1: { tag: 'h1', className: null },
  Header2: { tag: 'h2', className: null },
  Header3: { tag: 'h3', className: null },
  Header4: { tag: 'h4', className: null },
  Header5: { tag: 'h5', className: null },
});
