import { useInsiteEditorTexts } from 'components/visualDesigner/insiteEditor';
import { useSelector } from 'react-redux';

const useEditableTemplateFields = templateFields => {
  if (!useSelector(state => state.visualDesigner.insiteEditor))
    return templateFields;

  const getTemplateFieldKey = textKey => `EntityField_${textKey?.replace(/\./, '_')}`;

  return useInsiteEditorTexts(templateFields, (field, getText) => {
    const sanaTextKey = getTemplateFieldKey(field.name);
    return { ...field, title: getText(sanaTextKey, field.title) };
  }, { textOnly: true });
};

export default useEditableTemplateFields;