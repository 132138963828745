import { getTargetScrollBarWidth } from 'scroll-lock';

function setStickyColHeader(tableWrapperRef, stickyHeaderWrapperRef, tableRef) {
  return (styles, scrollWidth, hasRowHeaders, shouldClone) => {
    if (!document || !tableWrapperRef.current || !stickyHeaderWrapperRef.current)
      return;

    if (!hasRowHeaders)
      return;

    tableWrapperRef.current.classList.add(styles.hasStickyCol);

    const stickyHeaderCol = stickyHeaderWrapperRef.current.querySelector(`.${styles.stickyCol}`);
    const stickyHeaderColTable = stickyHeaderCol.querySelector('table');
    const rowHeaders = tableWrapperRef.current.querySelectorAll(`.${styles.rowHeader}`);

    if (shouldClone) {
      removeElementChildren(stickyHeaderColTable);
      for (const cell of rowHeaders) {
        const stickyHeaderColTableRow = document.createElement('tr');
        const headerCell = cell.cloneNode(true);
        headerCell.removeAttribute('id');
        stickyHeaderColTableRow.appendChild(headerCell);
        stickyHeaderColTable.appendChild(stickyHeaderColTableRow);
      }
    } else {
      const stickyRowHeaders = stickyHeaderColTable.querySelectorAll('th');
      for (let i = 0; i < rowHeaders.length; i++) {
        const stickyCellStyle = stickyRowHeaders[i].style;
        const cell = rowHeaders[i];
        const cellClientRect = cell.getBoundingClientRect();
        stickyCellStyle.height = cellClientRect.height + 'px';
        stickyCellStyle.width = cellClientRect.width + 'px';
      }
    }

    stickyHeaderColTable.style.top = tableWrapperRef.current.querySelector('th:first-child').offsetHeight + 'px';
    stickyHeaderCol.style.width = tableWrapperRef.current.querySelector('th:first-child').offsetWidth + 'px';
    if (tableWrapperRef.current.offsetWidth < tableRef.current.offsetWidth)
      stickyHeaderCol.style.bottom = scrollWidth + 'px';
    else
      stickyHeaderCol.style.bottom = '0';
  };
}

function useStickyRowHeader(tableRef, tableWrapperRef, stickyHeaderWrapperRef) {
  return (styles, scrollWidth, shouldClone) => {
    if (tableWrapperRef.current.offsetHeight >= tableRef.current.offsetHeight)
      return;

    //stickHeaders row
    tableWrapperRef.current.classList.add(styles.hasStickyRow);
    const stickyHeaderRow = stickyHeaderWrapperRef.current.querySelector(`.${styles.stickyRow}`);
    const stickyHeaderRowTable = stickyHeaderRow.querySelector('table');
    const colHeaders = tableWrapperRef.current.querySelectorAll('thead th');

    if (shouldClone) {
      removeElementChildren(stickyHeaderRowTable);
      const stickyHeaderRowTableRow = document.createElement('tr');
      for (const cell of colHeaders) {
        const headerCell = cell.cloneNode(true);
        headerCell.removeAttribute('id');
        stickyHeaderRowTableRow.appendChild(headerCell);
      }

      stickyHeaderRowTable.appendChild(stickyHeaderRowTableRow);

    } else {
      const stickyColHeaders = stickyHeaderRowTable.querySelectorAll('th');
      for (let i = 0; i < colHeaders.length; i++) {
        const cell = colHeaders[i];
        const stickyCellStyle = stickyColHeaders[i].style;
        const cellClientRect = cell.getBoundingClientRect();
        stickyCellStyle.height = cellClientRect.height + 'px';
        stickyCellStyle.width = cellClientRect.width + 'px';
      }
    }

    stickyHeaderRow.style.right = scrollWidth + 'px';
    stickyHeaderRow.style.height = tableWrapperRef.current.querySelector('th:first-child').offsetHeight + 'px';
  };
}

function setCornerCell(styles, stickyHeaderWrapperRef, tableWrapperRef) {
  const cornerCell = stickyHeaderWrapperRef.current.querySelector(`.${styles.cornerCell}`);
  const cornerCellRect = tableWrapperRef.current.querySelector('thead th').getBoundingClientRect();
  cornerCell.style.height = cornerCellRect.height + 'px';
  cornerCell.style.width = cornerCellRect.width + 'px';
}

function setOriginTableSizes(tableRef, resetClassName) {
  if (!document || !tableRef.current)
    return;

  const originTable = tableRef.current;
  const colHeaders = tableRef.current.querySelectorAll('thead th');
  let tableWidth = 0;

  originTable.classList.add(resetClassName);
  originTable.getClientRects();

  for (let i = 0; i < colHeaders.length; i++) {
    const cell = colHeaders[i];
    const cellClientRect = cell.getBoundingClientRect();
    cell.style.height = cellClientRect.height + 'px';
    cell.style.width = cellClientRect.width + 'px';
    tableWidth += cellClientRect.width;
  }

  const rowHeaders = tableRef.current.querySelectorAll('tbody th');

  for (let i = 0; i < rowHeaders.length; i++) {
    const cell = rowHeaders[i];
    const cellClientRect = cell.getBoundingClientRect();
    cell.style.height = cellClientRect.height + 'px';
    cell.style.width = cellClientRect.width + 'px';
  }

  originTable.style.width = tableWidth + 'px';
  originTable.classList.remove(resetClassName);
  originTable.getClientRects();
}

export function useSetStickyHeaders(tableRef, tableWrapperRef, stickyHeaderWrapperRef, resetClassName) {
  const createHorizontalHeader = setStickyColHeader(tableWrapperRef, stickyHeaderWrapperRef, tableRef);
  const createVerticalHeader = useStickyRowHeader(tableRef, tableWrapperRef, stickyHeaderWrapperRef);
  let previousVariantComponentGroups = null;
  let prevIsHorizontallyFit = false;
  let prevIsVerticallyFit = false;

  return (styles, hasRowHeaders, variantComponentGroups) => {
    if (!tableWrapperRef || !tableWrapperRef.current || !tableRef || !tableRef.current)
      return;

    const scrollWidth = getTargetScrollBarWidth(tableWrapperRef.current, true);
    const currentIsHorizontallyFit = tableWrapperRef.current.offsetWidth >= tableRef.current.offsetWidth;
    const currentsVerticallyFit = tableWrapperRef.current.offsetHeight >= tableRef.current.offsetHeight;

    const shouldClone = previousVariantComponentGroups !== variantComponentGroups
      || prevIsHorizontallyFit !== currentIsHorizontallyFit
      || prevIsVerticallyFit !== currentsVerticallyFit;

    previousVariantComponentGroups = variantComponentGroups;
    prevIsHorizontallyFit = currentIsHorizontallyFit;
    prevIsVerticallyFit = currentsVerticallyFit;

    setOriginTableSizes(tableRef, resetClassName);
    createHorizontalHeader(styles, scrollWidth, hasRowHeaders, shouldClone);
    createVerticalHeader(styles, scrollWidth, shouldClone);

    if (hasRowHeaders)
      setCornerCell(styles, stickyHeaderWrapperRef, tableWrapperRef);
  };
}

function removeElementChildren(element) {
  while (element.lastChild) {
    element.removeChild(element.lastChild);
  }
}