import { getIn } from 'formik';

export default context => (value, _fieldName, formikContext) => {
  if (!value)
    return;

  const { to } = context.params;
  const toValue = getIn(formikContext.values, to);

  if (value !== toValue)
    return context.errorMessage;
};