import { isRightArrowKeyPressed, isLeftArrowKeyPressed } from '../helpers';
import { isModifierKeyPressed } from 'utils/helpers';

export const hoveredListClass = 'nav-is-hovered';
export const hoveredItemClass = 'hover';
export const disableInteractionAttr = 'data-disable-interaction';
const pointerMoveAttr = 'data-pointer-move';

/**
 * When event fires on the element first child or its subtree and there was no swiping over the element performed,
 * adds hover classes to element and its parent element, handles aria visibility state of sublist, if present
 * and handles hover state of other list items located on the same level.
 * @param {MouseEvent|PointerEvent|TouchEvent} e - mouseover, pointerup or touchcancel event
 * @returns {void}
 */
export function addHoverState(e) {
  if (e.currentTarget.classList.contains(hoveredItemClass))
    return;

  // In case user performed swipe on laptop with touch screen do nothing.
  if (e.type === 'pointerup' && e.currentTarget.hasAttribute(pointerMoveAttr)) {
    e.currentTarget.removeAttribute(pointerMoveAttr);
    return;
  }

  if (!e.currentTarget.firstElementChild.contains(e.target))
    return;

  unsetListItemsHoverState(e.currentTarget.parentElement);
  e.currentTarget.classList.add(hoveredItemClass);
  e.currentTarget.parentElement.classList.add(hoveredListClass);
  handleAriaVisibilityState(e.currentTarget, true);
}

/**
 * When event fires on an element or its subtree, gets current document active element and if this element
 * is not descendant or parent of current element, removes hover class from current element and handles
 * aria visibility state of its sublist, if present. In case of blur event also removes hover class from element parent.
 * @param {MouseEvent|FocusEvent} e - mouseleave or blur event
 * @returns {void}
 */
export function removeItemHoverState(e) {
  const currentActiveElement = getCurrentActiveElement(e);
  if (e.currentTarget.contains(currentActiveElement))
    return;

  const { firstElementChild: linkElement } = e.currentTarget.firstElementChild;
  linkElement.removeAttribute(disableInteractionAttr);

  handleAriaVisibilityState(e.currentTarget);

  if (e.type === 'mouseleave' && currentActiveElement === e.currentTarget.parentElement)
    return;

  e.currentTarget.classList.remove(hoveredItemClass);

  if (e.currentTarget.parentElement.contains(currentActiveElement))
    return;

  e.currentTarget.parentElement.classList.remove(hoveredListClass);
}

/**
 * When event fires on an element or its subtree, gets current document active element and if this element
 * is not descendant of current element, removes hover class from it and all of its direct children.
 * @param {MouseEvent} e - mouseleave event
 * @returns {void}
 */
export function removeListHoverState(e) {
  const currentActiveElement = getCurrentActiveElement(e);

  if (e.currentTarget.contains(currentActiveElement))
    return;

  unsetListItemsHoverState(e.currentTarget);
  e.currentTarget.classList.remove(hoveredListClass);
}

/**
 * When event fired on an element, it has sublist present and Right Arrow key pressed, adds hover class
 * to the element and its parent, if Left Arrow key pressed - removes hover class from element.
 * In both cases handles aria visibility state of sublist, if present.
 * @param {KeyboardEvent} e - keydown event
 * @returns {void}
 */
export function toggleHoverState(e) {
  if (
    e.currentTarget.children.length <= 1
    || !e.currentTarget.firstElementChild.contains(e.target)
    || isModifierKeyPressed(e)
  )
    return;

  if (isRightArrowKeyPressed(e)) {
    if (e.currentTarget.classList.contains(hoveredItemClass))
      return;

    e.currentTarget.classList.add(hoveredItemClass);
    e.currentTarget.parentElement.classList.add(hoveredListClass);
    handleAriaVisibilityState(e.currentTarget, true);
  }

  if (isLeftArrowKeyPressed(e)) {
    if (!e.currentTarget.classList.contains(hoveredItemClass))
      return;

    e.currentTarget.classList.remove(hoveredItemClass);
    handleAriaVisibilityState(e.currentTarget);
  }
}

// Handler for laptops with touch screen.
/**
 * Adds attribute indicating that pointermove event occurred on element so it an be used later for swipe detection.
 * @param {PointerEvent} e - pointermove event.
 * @returns {void}
 */
export function handlePointerMove(e) {
  // Ignore randomly triggered by browsers event on mouse pointer, when context menu was called with touch screen.
  if (e.pointerType === 'mouse')
    return;

  if (!e.currentTarget.contains(e.target))
    return;

  e.currentTarget.setAttribute(pointerMoveAttr, 'true');
}

// Handler for laptops with touch screen.
/**
 * Removes, if present, attribute, indicating that pointermove event occurred on element, as pointerleave event firing indicates
 * that user did not performed swipe over the element.
 * @param {PointerEvent} e - pointerleave event.
 * @returns {void}
 */
export function handlePointerLeave(e) {
  if (!e.currentTarget.hasAttribute(pointerMoveAttr))
    return;

  e.currentTarget.removeAttribute(pointerMoveAttr);
}

/**
 * When event fires on an element, sets it in focus.
 * @param {MouseEvent} e - click event
 * @returns {void}
 */
export function setFocus(e) {
  e.currentTarget.focus();
}

// Handler for touch devices (iOS, Android) and laptops with touch screen.
/**
 * When click event fires on element if it has no hover class, adds this class to the element and its parent,
 * handles aria visibility state of sublist.
 * @param {MouseEvent} e - click event
 * @returns {void}
 */
export function addItemHoverState(e) {
  if (e.currentTarget.classList.contains(hoveredItemClass))
    return;

  e.currentTarget.classList.add(hoveredItemClass);
  e.currentTarget.parentElement.classList.add(hoveredListClass);
  handleAriaVisibilityState(e.currentTarget, true);
}

// Handler for touch devices (iOS, Android) and laptops with touch screen.
/**
 * Sets focus and adds disable link interaction attribute on element.
 * @param {MouseEvent|PointerEvent|TouchEvent} e - mouseover, contextmenu or touchcancel event.
 * @returns {void}
 */
export function disableLinkInteraction(e) {
  if (e.type === 'mouseover' && !e.currentTarget.parentElement.nextElementSibling)
    return;

  e.currentTarget.focus();
  e.currentTarget.setAttribute(disableInteractionAttr, 'true');
}

// Handler for touch devices (iOS, Android) and laptops with touch screen.
/**
 * Adds focus to current element, sets/removes disable interaction attribute depending on it presence before event been triggered.
 * If attribute is set, prevents default event action to disable navigation on links with external url.
 * @param {MouseEvent} e - click event.
 * @returns {void}
 */
export function handleLinkClickOnCapture(e) {
  e.currentTarget.focus();
  if (!e.currentTarget.hasAttribute(disableInteractionAttr)) {
    e.currentTarget.setAttribute(disableInteractionAttr, 'true');
    e.preventDefault();
    return;
  }

  e.currentTarget.removeAttribute(disableInteractionAttr);

}

// Handler for touch devices (iOS, Android) and laptops with touch screen.
/**
 * Prevents navigation on link element if disable interaction attribute present.
 * @param {MouseEvent} e - click event.
 * @returns {void}
 */
export function handleLinkClick(e) {
  if (e.currentTarget.hasAttribute(disableInteractionAttr))
    return false;
}

/**
 * Removes hover class for all HTMLElement direct children.
 * @param {HTMLElement} listElement - Valid HTML element.
 * @returns {void}
 */
function unsetListItemsHoverState(listElement) {
  if (!(listElement instanceof HTMLElement))
    return;

  for (const listItemElement of listElement.querySelectorAll(`:scope > .${hoveredItemClass}`))
    listItemElement.classList.remove(hoveredItemClass);
}

/**
 * Gets HTMLElement which became secondary target after event has been fired.
 * @param {MouseEvent|FocusEvent} e - mouseleave or blur event.
 * @returns {object} HTMLElement
 */
function getCurrentActiveElement(e) {
  // In Legacy MS Edge and IE11 relatedTarget property in some cases has a value of null or window,
  // so document.activeElement fallback should be used instead.
  return e.relatedTarget instanceof HTMLElement ? e.relatedTarget : document.activeElement;
}

/**
 * If element has data-should-handle-aria attribute with value "true", this function sets an appropriate aria attributes
 * on its second child element depending on passed value which represents status of expanded state.
 * @param {HTMLElement} listElement - Valid HTML element.
 * @param {boolean} isExpanded - indicates if element is in expanded state.
 * @returns {void}
 */
function handleAriaVisibilityState(listElement, isExpanded = false) {
  if (!(listElement instanceof HTMLElement) || listElement.dataset.shouldHandleAria !== 'true')
    return;

  const [, subItems] = listElement.children;
  subItems.setAttribute('aria-hidden', (!isExpanded).toString());
  subItems.setAttribute('aria-expanded', isExpanded.toString());
}