import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const AccordionStateContext = createContext();

export const AccordionStateProvider = ({ isExpanded, children }) => {
  const activeItemState = useState(null);
  const [, setActiveItemId] = activeItemState;
  const location = useSelector(state => state.routing.location);

  useEffect(() => {
    if (isExpanded)
      return;

    setActiveItemId(null);
  }, [isExpanded]);

  useEffect(() => {
    setActiveItemId(null);
  }, [location]);

  return (
    <AccordionStateContext.Provider value={activeItemState}>
      {children}
    </AccordionStateContext.Provider>
  );
};

AccordionStateProvider.propTypes = {
  isExpanded: PropTypes.bool,
  children: PropTypes.node,
};