import { memo, useContext } from 'react';
import { Selector } from 'components/objects/productComparison';
import ProductContext from './ProductContext';

const ProductComparisonSelector = props => {
  const product = useContext(ProductContext);
  return (
    <Selector product={product} {...props} />
  );
};

export default memo(ProductComparisonSelector);