import { SEARCH_PRODUCT_SUGGESTIONS, productsSearchSuggestionsCompleted } from './actions';
import { ofType } from 'redux-observable';
import { switchMap, map, pluck } from 'rxjs/operators';
import { searchSuggestionsQuery } from './queries';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';

const defaultProducts = [];

function epic(action$, _, { api, logger }) {
  return action$.pipe(
    ofType(SEARCH_PRODUCT_SUGGESTIONS),
    pluck('payload'),
    switchMap(options =>
      api.graphApi(searchSuggestionsQuery, { options }).pipe(
        map(mapSuggestions),
        retryWithToast(action$, logger),
      ),
    ),
  );
}

export default epic;

export function mapSuggestions(data) {
  const products = data.catalog.products.products || defaultProducts;

  for (const product of products) {
    product.routeData = routesBuilder.forProduct(product.id);

    product.imageUrl = product.image && product.image.small;

    if (product.categoriesPaths && product.categoriesPaths.length)
      product.categories = product.categoriesPaths[0].categories;
  }

  return productsSearchSuggestionsCompleted(products);
}