import { useDispatch } from 'react-redux';
import { buildRouteForProductComparison } from '../util';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { useRoutes } from 'components/primitives/route';
import { removeAllProductsFromComparison } from 'behavior/productComparison/actions';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const RemoveAllProductsLink = () => {
  const dispatch = useDispatch();
  const removeAllProductsRoute = buildRouteForProductComparison();
  const [removeAllProductsUrl] = useRoutes([removeAllProductsRoute]);

  return (
    <Link
      className="clean"
      url={removeAllProductsUrl}
      to={removeAllProductsRoute}
      options={{ keepPreviousState: true }}
      omitScroll
      replaceHistory
      onClick={useHandlerLockerInPreview(() => dispatch(removeAllProductsFromComparison()))}
    >
      <SimpleText textKey="ButtonText_ProductCompare_ClearSelection" />
    </Link>
  );
};

export default RemoveAllProductsLink;