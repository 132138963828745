import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from 'components/primitives/grid';
import ProductsGroup from 'components/objects/productsGroup';
import { requestOffers } from 'behavior/checkoutOffers';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { useMatchMediaContext } from 'components/responsive';
import { trackProductListView, EVENT_SOURCES } from 'behavior/analytics';
import { useLoadEffect } from 'utils/hooks';
import { ProductTrackingContext } from 'components/objects/analytics';

const CheckoutOffers = ({ products, expired, requestOffers, trackProductListView }) => {
  const { xl } = useMatchMediaContext();
  const notLoaded = !products;
  const shouldRequest = notLoaded || expired;

  useLoadEffect(() => {
    if (notLoaded || !products.length)
      return;

    trackProductListView(products, EVENT_SOURCES.checkoutOffers);
  }, [products]);

  useEffect(() => {
    if (shouldRequest)
      requestOffers();
  }, [shouldRequest]);

  if (notLoaded || !products.length)
    return null;

  return (
    <ProductTrackingContext.Provider value={{ trackingSource: EVENT_SOURCES.checkoutOffers }}>
      <Container>
        <ProductsGroup
          id="offers"
          headerTextKey="Basket_CheckoutOffersHeader"
          products={products}
          maxSlidesToShow={xl ? 5 : 4}
        />
      </Container>
    </ProductTrackingContext.Provider>
  );
};

CheckoutOffers.propTypes = {
  products: PropTypes.array,
  expired: PropTypes.bool,
  requestOffers: PropTypes.func.isRequired,
  trackProductListView: PropTypes.func.isRequired,
};

const ConnectedCheckoutOffers = connect(state => state.checkoutOffers, { requestOffers, trackProductListView })(CheckoutOffers);

// eslint-disable-next-line react/no-multi-comp
export default props => {
  const [canViewCatalog] = useHasAbilities(AbilityTo.ViewCatalog);
  return canViewCatalog && <ConnectedCheckoutOffers {...props} />;
};
