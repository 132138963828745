import { PRODUCT_SORTFIELDS_RECEIVED } from './actions';
import { VIEWER_CHANGED } from 'behavior/events';
import { NAVIGATED } from 'behavior/routing';
import { createReducer } from 'utils/redux';

const initialState = {
  fields: null,
};

export default createReducer(initialState, {
  [PRODUCT_SORTFIELDS_RECEIVED]: onSortableFieldsReceived,
  [VIEWER_CHANGED]: onViewerChanged,
  [NAVIGATED]: onNavigated,
});

function onSortableFieldsReceived(_state, action) {
  return { fields: action.payload };
}

function onViewerChanged(state, _action) {
  return { ...state, expired: true };
}

function onNavigated(state, _action) {
  if (state.expired)
    return { fields: null };

  return state;
}
