export const createProductClickPayload = ({ product, source }) => ({
  event: 'productClick',
  ecommerce: {
    click: {
      actionField: {
        list: source,
      },
      products: [
        toProductData({
          ...product,
          categories: getProductCategories(product.categoriesPaths),
        }),
      ],
    },
  },
});

export const createProductDetailsViewPayload = ({ product }) => ({
  event: 'detail',
  ecommerce: {
    detail: {
      products: [
        toProductData({
          ...product,
          categories: getProductCategories(product.categoriesPaths),
        }),
      ],
    },
  },
});

export const createProductListViewPayload = ({ products, currencyCode, source }) => ({
  event: 'impression',
  ecommerce: {
    currencyCode: currencyCode || '',
    impressions: {
      products: products.map(product => toProductData({
        ...product,
        categories: getProductCategories(product.categoriesPaths),
        list: source,
      })),
    },
  },
});

export const createProductsAddToBasketPayload = ({ products, currencyCode }) => ({
  event: 'addToCart',
  ecommerce: {
    currencyCode: currencyCode || '',
    add: {
      products: products.map(product => toProductData({
        ...product,
        categories: getProductCategories(product.categoriesPaths),
      })),
    },
  },
});

export const createProductsRemoveFromBasketPayload = ({ products }) => ({
  event: 'removeFromCart',
  ecommerce: {
    remove: {
      products: products.map(product => toProductData({
        ...product,
        categories: getProductCategories(product.categoriesPaths),
      })),
    },
  },
});

export const createCheckoutPayload = ({ step, products, customerType }) => ({
  event: 'checkout',
  ecommerce: {
    checkout: {
      actionField: {
        step,
      },
      products: products.map(product => toProductData({
        ...product,
        categories: getProductCategories(product.categoriesPaths),
      })),
    },
  },
  customerType,
});

export const createCheckoutOptionPayload = ({ step, option }) => ({
  event: 'checkoutOption',
  ecommerce: {
    checkout_option: {
      actionField: { step, option },
    },
  },
});

export const createPurchasePayload = ({ products, transactionInfo, customerType, currencyCode }) => ({
  event: 'purchase',
  ecommerce: {
    currencyCode: currencyCode || '',
    purchase: {
      actionField: transactionInfo,
      products: products.map(product => toProductData({
        ...product,
        categories: getProductCategories(product.categoriesPaths),
      })),
    },
  },
  customerType,
});

export const createUserIdPayload = ({ userId }) => ({ userId });

export const toProductData = ({
  id,
  title,
  uom = null,
  categories = null,
  variant = null,
  price = null,
  quantity = null,
  list = null,
}) => {
  const uomId = uom && uom.id;
  const data = {};
  addPropIfNotNull(data, 'id', (uomId && `${id}_${uomId}`) || id);
  addPropIfNotNull(data, 'name', title);
  addPropIfNotNull(data, 'variant', variant);
  addPropIfNotNull(data, 'price', price);
  addPropIfNotNull(data, 'quantity', roundDecimal(quantity));
  addPropIfNotNull(data, 'list', list);
  addPropIfNotNull(data, 'category', reduceCategories(categories));
  return data;
};

// A fix for GTM rounding issue. When qty is less than 1, GTM rounds it to 0
// and does not take into account such product anymore.
// We decided to round it manually to 1 in such scenario.
const roundDecimal = quantity => quantity && quantity < 1 ? 1 : quantity;

const addPropIfNotNull = (obj, prop, value) => {
  if (value !== undefined && value !== null)
    obj[prop] = value;
};

const reduceCategories = categories => {
  let category = null;
  if (!categories || typeof categories === 'string')
    category = categories;
  else if (categories.length) {
    if (typeof categories[0] === 'object')
      categories = categories.slice(0, 5).map(c => c.name.replace('/', '|'));
    category = categories.join('/');
  }
  return category;
};

const getProductCategories = categoriesPaths => {
  if (categoriesPaths && categoriesPaths.length > 0)
    return categoriesPaths[0].categories;

  return null;
};