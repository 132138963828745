import styles from './VolumePrices.module.scss';
import modalStyles from 'components/objects/modals/Modal.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SimpleText, RichText } from 'components/sanaText';
import { useIsMobileViewport } from 'utils/hooks';
import { UomTitle } from 'components/primitives/product';
import { formatAsPrice, formatAsPercentage } from 'utils/format';
import { Table } from 'components/primitives/table';
import { useFormatNumberWithCulture } from 'utils/hooks';

const VolumePrices = ({
  volumePrices,
  canViewUom,
  forAllVariants,
  uoms,
  defaultPrice,
  currencyInfo,
}) => {
  const formatNumber = useFormatNumberWithCulture();
  const isMobile = useIsMobileViewport();

  const infoSection = (
    <>
      <h2 className={`h3 ${modalStyles.title}`}><SimpleText textKey="VolumeDiscounts_Title" /></h2>
      <div><RichText textKey="VolumeDiscounts_Description" /></div>
    </>
  );
  const containerClassNames = `${styles.volumePrices} ${isMobile ? styles.mobile : ''}`;

  if (!volumePrices || !volumePrices.prices)
    return <div className={containerClassNames}>{infoSection}</div>;

  const hasPrices = !!volumePrices.prices.length;
  const prices = hasPrices
    ? volumePrices.prices.map(volumePrice => createVolumePriceModel(volumePrice, uoms))
    : createDefaultVolumePrices(defaultPrice);

  prices.sort(compareVolumePrices);

  const showVariant = forAllVariants || (hasPrices && prices.some(price => price.variantTitle));
  const showUom = canViewUom && hasPrices && prices.some(price => price.uom);

  return (
    <div className={containerClassNames}>
      {infoSection}
      <div className={styles.volumePricesTable}>
        <Table>
          <thead>
            <tr>
              {showVariant && <th><RichText textKey="Variant" /></th>}
              <th><RichText textKey="Quantity" /></th>
              {showUom && <th><RichText textKey="UOM" /></th>}
              <th><RichText textKey="General_Product_Price" /></th>
              <th><RichText textKey="Discount" /></th>
            </tr>
          </thead>
          <tbody>
            {prices.map(({ variantTitle, volumePrice: { quantity, price, discountPercent }, uom }, index) => (
              <tr key={index}>
                {showVariant &&
                  <td className={styles.cellTitle}>
                    {variantTitle ? variantTitle : <SimpleText textKey="AppliesToAllVariants" />}
                  </td>}
                <td className={styles.cellQty}>{formatNumber(quantity)}</td>
                {showUom &&
                  <td className={styles.cellUom}>
                    {uom &&
                      <UomTitle id={uom.id} description={uom.description} allowHtmlTags>
                        {title => <>{title}</>}
                      </UomTitle>}
                  </td>}
                <td className={styles.cellPrice}>{formatAsPrice(price, currencyInfo)}</td>
                <td className={styles.cellDiscount}>{formatAsPercentage(discountPercent, currencyInfo, true, 2)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

VolumePrices.propTypes = {
  volumePrices: PropTypes.shape({
    prices: PropTypes.arrayOf(PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      uomId: PropTypes.string,
      price: PropTypes.number.isRequired,
      discountPercent: PropTypes.number.isRequired,
      variant: PropTypes.shape({
        title: PropTypes.string,
      }),
    })),
  }),
  defaultPrice: PropTypes.number,
  canViewUom: PropTypes.bool,
  forAllVariants: PropTypes.bool,
  uoms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
  })),
  currencyInfo: PropTypes.object,
};

export default connect(({ settings }) => ({ currencyInfo: settings.currency }))(VolumePrices);

function createVolumePriceModel(volumePrice, uoms) {
  let uom = null;

  if (volumePrice.uomId)
    uom = uoms && uoms.find(u => u.id === volumePrice.uomId);

  const variantTitle = volumePrice.variant && volumePrice.variant.title;

  return { volumePrice, variantTitle, uom };
}

function createDefaultVolumePrices(defaultPrice) {
  return [createVolumePriceModel({ price: defaultPrice, quantity: 1, discountPercent: 0 })];
}

function compareVolumePrices(a, b) {
  if (a.variantTitle < b.variantTitle)
    return -1;

  if (a.variantTitle > b.variantTitle)
    return 1;

  if (a.volumePrice.quantity < b.volumePrice.quantity)
    return -1;

  if (a.volumePrice.quantity > b.volumePrice.quantity)
    return 1;

  if (a.uom && a.uom.description < b.uom && b.uom.description)
    return -1;

  if (a.uom && a.uom.description < b.uom && b.uom.description)
    return 1;

  return 0;
}
