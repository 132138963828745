import { useContext, useState, useEffect } from 'react';
import { LayoutShiftContext } from './LayoutShiftContext';
import { useMatchMediaContext } from 'components/responsive';

export const useResponsiveBreakpoints = () => {
  const { xs, sm, md, lg, xl } = useMatchMediaContext();
  return { xs, sm, md, lg, xl };
};

export const useLayoutShifter = () => useContext(LayoutShiftContext);

let extrasGlobal: HTMLElement | null = null;

export const useExtras = () => {
  const [extras, setExtras] = useState(extrasGlobal);

  useEffect(() => {
    extrasGlobal = document.getElementById('extras');

    if (extras !== extrasGlobal)
      setExtras(extrasGlobal);
  }, []);

  return extras;
};