import styles from '../Order.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { InfoAlert } from 'components/primitives/alerts';
import { QuoteStatus } from 'behavior/documents';
import { quoteStatusPropType } from '../propTypes';

const QuoteMessages = ({ document, expiredText, inReviewText }) => {
  const { status } = document;

  if (status === QuoteStatus.Cancelled)
    return null;

  const quoteExpired = status === QuoteStatus.Expired;

  let text = null;
  if (quoteExpired)
    text = expiredText;
  else if (status !== QuoteStatus.Orderable)
    text = inReviewText;

  if (!text)
    return null;

  return (
    <InfoAlert
      className={quoteExpired ? styles.quoteExpired : styles.quoteInReview}
    >
      {text}
    </InfoAlert>
  );
};

QuoteMessages.propTypes = {
  document: PropTypes.shape({
    status: quoteStatusPropType.isRequired,
  }).isRequired,
  expiredText: PropTypes.node,
  inReviewText: PropTypes.node,
};

export default memo(QuoteMessages);
