export default context => value => {
  value = value || '';

  const { min, max } = context.params;

  const length = value.length;
  if (min > length)
    return context.errorMessage;

  if (max && length > max)
    return context.errorMessage;
};