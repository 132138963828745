import { memo, useRef, useContext, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckoutContext from './CheckoutContext';
import StepButtons from './StepButtons';
import {
  ExtraPaymentStep as ExtraPaymentStepBase,
  ExtraPaymentStepBody,
} from '../base/payment';
import { useDispatch } from 'react-redux';
import { saveExtraPaymentData } from 'behavior/pages/checkout';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { scrollToInvalidElement } from './helpers';

const ExtraPaymentStep = ({ extraPaymentStep, asLink, isActive, className, isPromotion, isCompleted }) => {
  const dispatch = useDispatch();
  const { nextStep, setLoading } = useContext(CheckoutContext);
  const formRef = useRef();
  const savingExtraDataRef = useRef();
  const onBeforeSubmitRef = useRef();

  // Extra checkout step may disappear after update.
  useEffect(() => () => dispatch(unsetLoadingIndicator()), []);

  const onBeforeSubmit = async () => {
    if (onBeforeSubmitRef.current)
      await onBeforeSubmitRef.current();
  };

  const onStepSubmit = useCallback(async () => {
    if (!formRef.current) {
      await onBeforeSubmit();
      submitStep(saveExtraPaymentData());
      return;
    }

    if (!(await formRef.current.validate())) {
      scrollToInvalidElement();
      return;
    }

    await onBeforeSubmit();
    submitStep(saveExtraPaymentData(formRef.current.values));
  }, []);

  useEffect(() => {
    if (!savingExtraDataRef.current)
      return;

    savingExtraDataRef.current = false;
    if (extraPaymentStep.isDataValid) {
      nextStep(true);
      return;
    }

    dispatch(unsetLoadingIndicator());
    if (extraPaymentStep.errors && formRef.current)
      formRef.current.errors = extraPaymentStep.errors;
  }, [extraPaymentStep]);

  return (
    <ExtraPaymentStepBase
      asLink={asLink}
      headerText={extraPaymentStep.title}
      headerTextKey={extraPaymentStep.titleKey}
      className={className}
      isPromotion={isPromotion}
      isCompleted={isCompleted}
    >
      {isActive &&
        <>
          <ExtraPaymentStepBody
            extraPaymentStep={extraPaymentStep}
            formRef={formRef}
            onBeforeSubmitRef={onBeforeSubmitRef}
            onFormSubmit={onStepSubmit}
            setLoading={setLoading}
          />
          <StepButtons onStepSubmit={onStepSubmit} />
        </>
      }
    </ExtraPaymentStepBase>
  );

  function submitStep(action) {
    savingExtraDataRef.current = true;
    dispatch(action);
    dispatch(setLoadingIndicator());
  }
};

ExtraPaymentStep.propTypes = {
  extraPaymentStep: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleKey: PropTypes.string.isRequired,
    isDataValid: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.shape({
      fieldName: PropTypes.string,
      message: PropTypes.string.isRequired,
    })),
  }).isRequired,
  isActive: PropTypes.bool,
  asLink: PropTypes.bool,
  isPromotion: PropTypes.bool,
  isCompleted: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(ExtraPaymentStep);
