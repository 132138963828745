import { loadRegistrationPageQuery } from './queries';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';
import { initSystemPageContent } from '../system';

export default (routeData, state$, { api }) => api.graphApi(loadRegistrationPageQuery).pipe(
  map(({ pages, entityTemplateFields }) => {
    const page = pages.registration;
    if (!page)
      return null;

    page.templateFields = entityTemplateFields;

    page.component = PageComponentNames.Registration;
    page.backTo = getBackTo(state$, [
      RouteName.Login,
      RouteName.Registration,
    ], routeData.params && routeData.params.language);

    return { page };
  }),
  initSystemPageContent(),
);
