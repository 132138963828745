import { merge, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { takeUntil, switchMap, exhaustMap, mergeMap, map, startWith, catchError } from 'rxjs/operators';
import { navigateTo, LOCATION_CHANGED } from 'behavior/events';
import {
  quoteStockReceived,
  QUOTE_STOCK_REQUESTED,
  quotePromotionResultReceived,
  QUOTE_PROMOTION_REQUESTED,
} from '../actions';
import { quoteStockQuery, promoteQuoteMutation } from '../queries.quote';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { PromoteQuoteStatus, DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';

export default function (action$, _state$, { api, logger }) {
  const locationChanged$ = action$.pipe(
    ofType(LOCATION_CHANGED),
  );
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  const loadQuoteStock$ = action$.pipe(
    ofType(QUOTE_STOCK_REQUESTED),
    switchMap(({ payload }) =>
      api.graphApi(quoteStockQuery, payload).pipe(
        map(({ documents: { quotes } }) => quoteStockReceived(quotes?.byId)),
        takeUntil(locationChanged$),
      ),
    ),
  );

  const promoteQuote$ = action$.pipe(
    ofType(QUOTE_PROMOTION_REQUESTED),
    exhaustMap(({ payload }) =>
      api.graphApi(promoteQuoteMutation, { input: payload }, { retries: 0 }).pipe(
        mergeMap(({ documents }) => {
          const result = documents.promoteQuote;
          if (!result || result.status === PromoteQuoteStatus.QuoteNotFound)
            return of(navigateTo(routesBuilder.forQuotes()));

          if (result.status === PromoteQuoteStatus.Succeed) {
            return result.orderId
              ? of(navigateTo(routesBuilder.forDocument(result.orderId, DocumentType.Order)))
              : of(navigateTo(routesBuilder.forQuotePromotion()));
          }

          return of(quotePromotionResultReceived(result), unsetLoading);
        }),
        catchError(error => {
          logger.error(error);

          return of(quotePromotionResultReceived({ error: true }), unsetLoading);
        }),
        startWith(setLoading),
      ),
    ),
  );

  return merge(loadQuoteStock$, promoteQuote$);
}

