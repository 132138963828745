export const NAVIGATION_LOAD = 'NAVIGATION_LOAD';
export const loadNavigation = groupCode => ({
  type: NAVIGATION_LOAD,
  payload: { groupCode },
});

export const NAVIGATION_LOADED = 'NAVIGATION_LOADED';
export const navigationLoaded = (groupCode, items) => ({
  type: NAVIGATION_LOADED,
  payload: { groupCode, items },
});

export const MAIN_NAVIGATION_CHILDREN_LOAD = 'MAIN_NAVIGATION_CHILDREN_LOAD';
export const loadMainNavigationChildren = parentId => ({
  type: MAIN_NAVIGATION_CHILDREN_LOAD,
  payload: { parentId },
});

export const MAIN_NAVIGATION_CHILDREN_LOADED = 'MAIN_NAVIGATION_CHILDREN_LOADED';
export const mainNavigationChildrenLoaded = (parentId, children) => ({
  type: MAIN_NAVIGATION_CHILDREN_LOADED,
  payload: {
    parentId,
    children,
  },
});