exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SubscribeInlineColumn_caption{margin:1em 0}.SubscribeInlineColumn_form{width:100%;display:flex;flex-wrap:nowrap}.SubscribeInlineColumn_form-field-wrapper{position:relative;margin-right:.3em;flex-grow:1}.SubscribeInlineColumn_validation{padding-top:.4em;line-height:1.2em;position:absolute;font-size:var(--normalText_SmallerFontSize,12px);color:var(--messages_Error_FontColor,#cb2245)}.SubscribeInlineColumn_validation svg{margin-right:.5em}.SubscribeInlineColumn_btn-submit{flex-shrink:0;max-width:50%;word-break:break-word}@supports (-ms-ime-align:auto){.SubscribeInlineColumn_btn-submit{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.SubscribeInlineColumn_btn-submit{word-wrap:break-word}}.SubscribeInlineColumn_btn-submit .btn-cnt{max-width:100%}@media (min-width:var(--breakpoints_Small,600)px),print{.SubscribeInlineColumn_col:only-child form{max-width:28.3em}}@media (min-width:var(--breakpoints_Medium,960)px),print{.SubscribeInlineColumn_col:only-child form{max-width:none}}", ""]);

// exports
exports.locals = {
	"caption": "SubscribeInlineColumn_caption",
	"form": "SubscribeInlineColumn_form",
	"form-field-wrapper": "SubscribeInlineColumn_form-field-wrapper",
	"formFieldWrapper": "SubscribeInlineColumn_form-field-wrapper",
	"validation": "SubscribeInlineColumn_validation",
	"btn-submit": "SubscribeInlineColumn_btn-submit",
	"btnSubmit": "SubscribeInlineColumn_btn-submit",
	"col": "SubscribeInlineColumn_col"
};