import styles from './ProductsGroup.module.scss';
import PropTypes from 'prop-types';
import { ProductTilesSlider } from 'components/objects/productTile';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';

const ProductsGroup = ({
  id,
  headerTextKey,
  links = null,
  maxSlidesToShow,
  products,
}) => {
  const placeholder = <Placeholder className={styles.headingPlaceholder} />;
  const headingId = id ? id + 'head' : null;
  return (
    <section className={styles.wrapper}>
      <header role={links ? null : 'presentation'}>
        <h2 className={styles.heading} id={headingId}>
          <SimpleText textKey={headerTextKey} placeholder={placeholder} />
        </h2>
        {links}
      </header>
      <div id={id} aria-describedby={headingId}>
        <ProductTilesSlider products={products} maxSlidesToShow={maxSlidesToShow} />
      </div>
    </section>
  );
};

ProductsGroup.propTypes = {
  id: PropTypes.string,
  headerTextKey: PropTypes.string.isRequired,
  links: PropTypes.node,
  maxSlidesToShow: PropTypes.number,
  products: PropTypes.array.isRequired,
};

export default ProductsGroup;