import styles from './Icons.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { withStyles } from 'isomorphic-style-loader';
import iconPropTypes from './iconPropTypes';

export const createIcon = (type, defaultIcon) => {
  const Icon = ({ alt = '', className = '', ...props }) => {
    const src = useSelector(({ theme }) => theme.values && theme.values[type]);

    if (src)
      return <img src={src} alt={alt} className={`${styles.icon} ${className}`} {...props} />;

    return <FontAwesomeIcon icon={defaultIcon} className={`${styles.defaultIcon} ${className}`} {...props} />;
  };

  Icon.displayName = type;
  Icon.propTypes = iconPropTypes;

  return withStyles(styles)(Icon);
};

