import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { useCountryField } from '../hooks';
import { FormGroup } from '../../formGroups';
import { TextBoxField, DropdownField } from '../../fields';
import { useEffectOnChange } from 'utils/hooks';

const StateFormGroup = ({
  fieldName,
  states,
  allowCustomState,
  validation,
  ...additionalProps
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const countryField = useCountryField();
  const countryId = countryField &&
    (countryField.name && getIn(values, countryField.name) || countryField.displayValue || countryField.defaultValue);

  const statesToDisplay = useMemo(() => {
    if (!countryId)
      return null;

    let result = states || [];
    result = result.filter(s => s.countryId === countryId);

    result = result.map(({ name, code }) => ({ name, value: code }));
    if (result.length)
      return result;

    return null;
  }, [countryId, states]);

  const hadStatesToDisplayRef = useRef();

  useEffectOnChange(() => {
    const hadStatesToDisplay = hadStatesToDisplayRef.current;
    hadStatesToDisplayRef.current = !!statesToDisplay;

    if (hadStatesToDisplay === undefined)
      return;

    const selectedState = getIn(values, fieldName);
    if (!selectedState)
      return;

    if (statesToDisplay) {
      if (statesToDisplay.some(state => state.value === selectedState))
        return;
    } else if (allowCustomState && !hadStatesToDisplay) {
      return;
    }

    setFieldValue(fieldName, '', false);
    setFieldTouched(fieldName, false, false);
  }, [statesToDisplay]);

  if (statesToDisplay == null) {
    if (!allowCustomState)
      return null;

    return (
      <FormGroup
        fieldName={fieldName}
        fieldComponent={TextBoxField}
        validation={validation}
        {...additionalProps}
      />
    );
  }

  // omit max length validation for dropdown
  validation = { ...validation, maxLength: undefined };
  return (
    <FormGroup
      fieldName={fieldName}
      fieldComponent={DropdownField}
      items={statesToDisplay}
      placeholderTextKey="SelectStatePlaceholder"
      {...additionalProps}
      validation={validation}
    />
  );
};

StateFormGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  states: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    countryId: PropTypes.string.isRequired,
  })),
  allowCustomState: PropTypes.bool,
  validation: PropTypes.object,
};

export default StateFormGroup;

