import styles from './Lines.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { UomTitle } from 'components/primitives/product';

const UomLabel = ({ uom }) => {
  if (!uom)
    return null;

  return (
    <div className={styles.uomLabelWrapper}>
      <div className={styles.uomLabel}>
        {/* Additional element is used to fix issues with text wrapping in Legacy MS Edge and IE11. */}
        <div>
          <UomTitle id={uom.id} description={uom.description || ''} />
        </div>
      </div>
    </div>
  );
};

UomLabel.propTypes = {
  uom: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default memo(UomLabel);