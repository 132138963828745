import {
  VISUAL_DESIGNER_LOADED,
  SET_PAGE_ERRORS,
  VISUAL_DESIGNER_TEXTS_RECEIVED,
  SET_SETTINGS,
  SET_CONTENT_BLOCKS_INFO,
  INSITE_EDITOR_LOADED,
  SET_SANA_TEXTS_INFO,
  INSITE_EDITOR_SANA_TEXTS_REQUESTED,
  INSITE_EDITOR_SET_TEXT_ONLY_MODE,
  INSITE_EDITOR_ADD_CONSTRUCTED_TEXTS,
  INSITE_EDITOR_UPDATE_CONSTRUCTED_TEXT,
} from './actions';
import { NAVIGATING } from 'behavior/routing';
import { SANATEXTS_LOADED } from '../../sanaText/actions';
import { createReducer } from 'utils/redux';

const initialState = {
  initialized: false,
  insiteEditor: false,
  pageErrors: {},
  texts: {},
  settings: {},
  availableContentBlocksInfo: null,
  sanaTexts: { mapping: {} },
};

export default createReducer(initialState, {
  [VISUAL_DESIGNER_LOADED]: onLoaded,
  [INSITE_EDITOR_LOADED]: onInsiteEditorLoaded,
  [SET_PAGE_ERRORS]: onSetPageErrors,
  [VISUAL_DESIGNER_TEXTS_RECEIVED]: onReceiveTexts,
  [SET_SETTINGS]: onSetSettings,
  [SET_CONTENT_BLOCKS_INFO]: onSetContentBlocksInfo,
  [SANATEXTS_LOADED]: onSanaTextsLoaded,
  [SET_SANA_TEXTS_INFO]: onSetSanaTextsInfo,
  [INSITE_EDITOR_SANA_TEXTS_REQUESTED]: onSanaTextsRequested,
  [INSITE_EDITOR_SET_TEXT_ONLY_MODE]: onSetTextOnlyMode,
  [INSITE_EDITOR_ADD_CONSTRUCTED_TEXTS]: onAddConstructedTexts,
  [INSITE_EDITOR_UPDATE_CONSTRUCTED_TEXT]: onUpdateConstructedText,
  [NAVIGATING]: onNavigating,
});

function onLoaded(state) {
  return {
    ...state,
    initialized: true,
  };
}

function onInsiteEditorLoaded(state) {
  return {
    ...state,
    insiteEditor: true,
  };
}

function onSetPageErrors(state, action) {
  const { pageErrors } = action.payload;
  return {
    ...state,
    pageErrors,
  };
}

function onReceiveTexts(state, action) {
  const { texts } = action.payload;
  return {
    ...state,
    texts: {
      ...state.texts,
      ...texts,
    },
  };
}

function onSetSettings(state, action) {
  const { visualDesignerSettings } = action.payload;
  return {
    ...state,
    settings: {
      ...state.settings,
      ...visualDesignerSettings,
    },
  };
}

function onSetContentBlocksInfo(state, action) {
  return {
    ...state,
    availableContentBlocksInfo: action.payload.contentBlocksInfo,
  };
}

function onSanaTextsLoaded(state, action) {
  if (!state.insiteEditor)
    return state;

  let counter = state.sanaTexts && state.sanaTexts.counter || 0;
  const textsObj = Object.keys(action.payload).reduce(
    (acc, value) => {
      acc[counter++] = { textKey: value };
      return acc;
    },
    {},
  );

  return {
    ...state,
    sanaTexts: {
      ...state.sanaTexts,
      counter,
      mapping: { ...state.sanaTexts.mapping, ...textsObj },
    },
  };
}

function onSetSanaTextsInfo(state, action) {
  const { sanaTexts: sanaTextsInfo } = action.payload;

  return {
    ...state,
    sanaTexts: {
      ...state.sanaTexts,
      info: sanaTextsInfo,
    },
  };
}

function onSanaTextsRequested(state, action) {
  const { textKeys } = action.payload;
  const texts = new Set(state.sanaTexts && state.sanaTexts.requested);
  textKeys.forEach(text => texts.add(text));

  return {
    ...state,
    sanaTexts: {
      ...state.sanaTexts,
      requested: Array.from(texts),
    },
  };
}

function onSetTextOnlyMode(state, action) {
  const { textKeys } = action.payload;
  const texts = new Set(state.sanaTexts && state.sanaTexts.textOnlyModeKeys);
  textKeys.forEach(text => texts.add(text));

  return {
    ...state,
    sanaTexts: {
      ...state.sanaTexts,
      textOnlyModeKeys: Array.from(texts),
    },
  };
}

function onNavigating(state) {
  return {
    ...state,
    sanaTexts: {
      ...state.sanaTexts,
      requested: [],
    },
  };
}

function onAddConstructedTexts(state, action) {
  const { texts } = action.payload;
  const constructedTexts = state.sanaTexts.constructed ? Array.from(state.sanaTexts.constructed) : [];
  texts.forEach(text => constructedTexts.every(constructedText =>
    constructedText.key !== text.key) && constructedTexts.push(text));

  return {
    ...state,
    sanaTexts: {
      ...state.sanaTexts,
      constructed: constructedTexts,
    },
  };
}

function onUpdateConstructedText(state, action) {
  const { text } = action.payload;

  const updatedConstructed = state.sanaTexts.constructed?.map(constructedText =>
    constructedText.key === text.key ? text : constructedText);

  return {
    ...state,
    sanaTexts: {
      ...state.sanaTexts,
      constructed: updatedConstructed,
    },
  };
}