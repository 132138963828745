exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Subscribe_intro{margin:1em 0}@media (min-width:var(--breakpoints_Small,600)px),print{.Subscribe_form{max-width:46.75em}}@media (min-width:var(--breakpoints_Medium,960)px),print{.Subscribe_form{max-width:none}}.Subscribe_toast{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"intro": "Subscribe_intro",
	"form": "Subscribe_form",
	"toast": "Subscribe_toast"
};