import { memo } from 'react';
import { Col, Container, Row } from 'components/primitives/grid';
import { Logo } from 'components/primitives/logo';

const PrintHeaderTemplate = () => (
  <Container>
    <Row crossAxisAlign="center">
      <Col>
        <Logo />
      </Col>
    </Row>
  </Container>
);

export default memo(PrintHeaderTemplate);