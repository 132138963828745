import styles from '../../Checkout.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../StepLoadingIndicator';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { Steps } from 'behavior/pages/checkout';
import { PromotionalText } from 'components/objects/promotionalTexts';
import { WarningAlert } from 'components/primitives/alerts';
import PaymentMethodsList from './PaymentMethodsList';
import PrepaymentTooltip from './PrepaymentTooltip';
import { useCurrencyInfo } from 'utils/hooks';
import { toUrlHash } from 'utils/url';
import StepDoneMark from '../StepDoneMark';

const PaymentMethodStep = ({ className, isQuote, isPromotion, isCompleted, asLink, children }) => {
  const headerText = <SimpleText textKey={isQuote ? 'QuoteCheckoutStep_PaymentMethods' : 'CheckoutStep_PaymentMethods'} />;
  return (
    <section className={className}>
      <div className={styles.header}>
        <h2>
          {asLink
            ? (
              <Link
                to={isPromotion ? routesBuilder.forQuotePromotion(Steps.Payment) : routesBuilder.forCheckout(isQuote, Steps.Payment)}
                url={toUrlHash(Steps.Payment)}
              >
                {headerText}
              </Link>
            ) : headerText}
        </h2>
        {isCompleted && <StepDoneMark />}
      </div>
      {children &&
        <div className={styles.body}>
          {children}
        </div>
      }
    </section>
  );
};

PaymentMethodStep.propTypes = {
  className: PropTypes.string.isRequired,
  isQuote: PropTypes.bool,
  isPromotion: PropTypes.bool,
  isCompleted: PropTypes.bool,
  asLink: PropTypes.bool,
  children: PropTypes.node,
};

export default PaymentMethodStep;

// eslint-disable-next-line react/no-multi-comp
export const PaymentMethodStepBody = memo(({
  totals,
  paymentMethods,
  selectedMethodId,
  onSelect,
  onSubmit,
  isQuote,
  validationMsg,
}) => {
  const currency = useCurrencyInfo();

  let content, tooltip = null;
  if (paymentMethods.length === 0)
    content = <WarningAlert><PromotionalText textKey="PaymentMethodsNotAvailable" withPlaceholder /></WarningAlert>;
  else {
    content = (
      <PaymentMethodsList paymentMethods={paymentMethods}
        selectedMethodId={selectedMethodId}
        onSelect={onSelect}
        onSubmit={onSubmit}
        currency={currency}
        validationMsg={validationMsg}
      />
    );
  }

  if (totals.prepayment && totals.prepayment !== totals.price) {
    tooltip = <PrepaymentTooltip totals={totals} isQuote={isQuote} currency={currency} />;
  }

  return (
    <>
      {content}
      {tooltip}
      <LoadingIndicator />
    </>
  );
});

PaymentMethodStepBody.propTypes = {
  totals: PropTypes.shape({
    prepayment: PropTypes.number,
    totalPrice: PropTypes.number,
  }).isRequired,
  paymentMethods: PropTypes.array,
  selectedMethodId: PropTypes.any,
  onSelect: PropTypes.func,
  onSubmit: PropTypes.func,
  isQuote: PropTypes.bool,
  validationMsg: PropTypes.node,
};