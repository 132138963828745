import { merge, from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, startWith, exhaustMap, pluck } from 'rxjs/operators';
import { DOCUMENT_REORDER_REQUESTED, reorderReceived } from '../actions';
import { reorderMutation } from '../queries';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { ReorderResult } from 'behavior/documents';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';
import { basketChangeCompleted, navigateTo } from 'behavior/events';
import { trackAddToBasket, trackRemoveFromBasket } from 'behavior/analytics';
import { getProductsTrackingDataFromLines } from 'behavior/basket/util';
import { reloadLocation } from 'behavior/routing';

export default (action$, state$, dependencies) =>
  action$.pipe(
    ofType(DOCUMENT_REORDER_REQUESTED),
    exhaustMap(({ payload }) =>
      dependencies.api.graphApi(reorderMutation,
        {
          input: payload,
          requestModifiedLines: state$.value.analytics && state$.value.analytics.isTrackingEnabled,
        },
        { retries: 0 },
      ).pipe(
        pluck('documents', 'reorder'),
        mergeMap(reorder => {
          if (reorder == null)
            return of(reloadLocation());

          const { result, basket, deletedLines } = reorder;
          const linesUpdated = result === ReorderResult.Success
            ? basket.productLines.totalCount
            : 0;
          const resultActions = [
            unsetLoadingIndicator(),
            reorderReceived(result),
            basketChangeCompleted(linesUpdated),
          ];

          if (result !== ReorderResult.Success)
            return from(resultActions);

          const removedProducts = deletedLines ? getProductsTrackingDataFromLines(deletedLines.list) : [];
          if (removedProducts && removedProducts.length)
            resultActions.push(trackRemoveFromBasket(removedProducts));

          const addedProducts = basket.addedLines ? getProductsTrackingDataFromLines(basket.addedLines.list) : [];
          if (addedProducts && addedProducts.length) {
            resultActions.push(trackAddToBasket(addedProducts));
          }

          return merge(
            from(resultActions),
            of(navigateTo(routesBuilder.forBasket())),
          );
        }),
        retryWithToast(action$, dependencies.logger),
        startWith(setLoadingIndicator()),
      ),
    ),
  );
