import {
  getYear,
  getMonthHuman,
  getDate,
  getDaysInMonth,
} from '@wojtekmaj/date-utils';
import { min, max } from 'react-date-picker/dist/shared/utils';

export function getMinMaxDay(maxDate, minDate, month, year) {
  const currentMonthMaxDays = !month ? 31 : getDaysInMonth(new Date(year, month - 1, 1));

  const maxDay = min(currentMonthMaxDays, isSameMonth(maxDate, year, month) && getDate(maxDate));
  const minDay = max(1, isSameMonth(minDate, year, month) && getDate(minDate));

  return { maxDay, minDay };
}

export function getMinMaxMonth(maxDate, minDate, year) {
  const maxMonth = min(12, isSameYear(maxDate, year) && getMonthHuman(maxDate));
  const minMonth = max(1, isSameYear(minDate, year) && getMonthHuman(minDate));

  return { maxMonth, minMonth };
}

export function getMinMaxYear(maxDate, minDate) {
  const maxYear = min(275760, maxDate && getYear(maxDate));
  const minYear = max(1000, minDate && getYear(minDate));

  return { maxYear, minYear };
}

function isSameMonth(date, year, month) {
  return date && year === getYear(date) && month === getMonthHuman(date);
}

function isSameYear(date, year) {
  return date && year === getYear(date);
}