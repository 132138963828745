import { SUBMIT_INVOICE_ORDER, submitInvoiceOrderResultReceived } from '../actions';
import { submitInvoiceOrderMutation } from '../queries';
import { mergeMap, startWith, catchError, pluck, exhaustMap } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { postForm } from 'behavior/pages';
import { toasts } from 'behavior/toasts';
import { renderHTML } from 'utils/render';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';

export default (action$, _, { api, logger }) => action$.pipe(
  ofType(SUBMIT_INVOICE_ORDER),
  pluck('payload'),
  exhaustMap(({ orderId, paymentInput, additionalCustomerData, extraPaymentData }) =>
    api.graphApi(submitInvoiceOrderMutation, getSubmitParams(orderId, paymentInput, additionalCustomerData, extraPaymentData), { retries: 0 }).pipe(
      mergeMap(({ invoicePayment }) => {
        if (!invoicePayment)
          return of(submitInvoiceOrderResultReceived({ error: true }), unsetLoadingIndicator());

        return handleInvoicePaymentResult(invoicePayment.submit, { logger });
      }),
      catchError(error => {
        logger.error(error);

        return of(submitInvoiceOrderResultReceived({ error: true }), unsetLoadingIndicator());
      }),
      startWith(setLoadingIndicator()),
    ),
  ),
);

function getSubmitParams(orderId, paymentInput, additionalCustomerData, extraPaymentData) {
  const input = { orderId, ...paymentInput };

  if (additionalCustomerData)
    input.additionalCustomerData = { values: additionalCustomerData };

  if (extraPaymentData)
    input.extraPaymentData = { values: extraPaymentData };

  return { input };
}

function handleInvoicePaymentResult({ type, ...result }, dependencies) {
  switch (type) {
    case 'SuccessInvoicePaymentResult':
      return handleSuccessInvoicePaymentResult(result, dependencies);
    case 'FailureInvoicePaymentActionResult':
      return handleFailureInvoicePaymentResult(result, dependencies);
    default:
      dependencies.logger.error(`'${type}' invoice payment result is not handled.`, result);
      throw new Error('Unexpected invoice payment result.');  
    }
}

function handleSuccessInvoicePaymentResult(result, dependencies) {
  const { transaction, nextAction } = result;
  
  if (nextAction)
    return handleNextAction(nextAction, dependencies);

  if (transaction.cancelled || transaction.failed) {
    dependencies.logger.error('cancelled & failed transaction statuses are not handled.', result);
    throw new Error('Unexpected transaction status.');
  }

  return of(navigateTo(routesBuilder.forOrderSubmit(transaction.id)));
}

function handleFailureInvoicePaymentResult() {
  return of(submitInvoiceOrderResultReceived({ error: true }), unsetLoadingIndicator());
}

function handleNextAction({ type, ...nextAction }, dependencies) {
  switch (type) {
    case 'RedirectAction':
      return handleRedirectAction(nextAction);
    case 'PostAction':
      return handlePostAction(nextAction);
    default:
      dependencies.logger.error(`'${type}' next action is not handled.`, nextAction);
      throw new Error('Unexpected next action.');  }
}

function handlePostAction(postAction) {
  return of(postForm(postAction));
}

function handleRedirectAction({ message, isHtmlMessage, url }) {
  message && toasts.info(isHtmlMessage ? renderHTML(message) : message, { autoDismiss: false });
  window.location.href = url;

  return EMPTY;
}