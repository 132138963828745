import { cloneElement, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import FormGroupTemplate from '../../formGroups/FormGroupTemplate';
import ErrorMessage from '../../ErrorMessage';
import RadioFormGroup from './RadioFormGroup';
import FieldLabel from '../FieldLabel';
import FieldLabelText from './FieldLabelText';
import ErrorMessageText from './ErrorMessageText';
import { useFormikContext, getIn } from 'formik';
import { createValidator } from './validation';
import createFormField from './createFormField';
import { createLabelId, createErrorMsgId } from '../../helpers';

const GenericFormGroup = ({ field }) => {
  const formik = useFormikContext();
  const validation = useMemo(() => createValidation(field), [field]);

  const fieldComponent = createFormField(field, validation);
  if (!fieldComponent)
    return null;

  if (shouldUseRadioGroup(field))
    return (
      <RadioFormGroup
        field={fieldComponent}
        fieldMetadata={field}
        description={field.description}
      />
    );

  const focusToFieldOnClick = fieldComponent.type.notNative;
  const fieldName = field.name;

  let fieldError = getIn(formik.errors, fieldName);
  if (fieldError && !getIn(formik.touched, fieldName))
    fieldError = null;

  const formName = formik.status.formName;
  const labelId = createLabelId(formName, fieldName);
  const errorId = createErrorMsgId(formName, fieldName);

  return (
    <FormGroupTemplate
      className={fieldError ? 'row-error' : null}
      label={(
        <FieldLabel fieldName={fieldName} focusToFieldOnClick={focusToFieldOnClick}>
          <FieldLabelText fieldMetadata={field} />
        </FieldLabel>
      )}
      field={focusToFieldOnClick ? cloneElement(fieldComponent, { labelId }) : fieldComponent}
      error={(
        <ErrorMessage fieldName={fieldName} id={errorId}>
          {fieldError && <ErrorMessageText message={fieldError} fieldMetadata={field} />}
        </ErrorMessage>
      )}
      description={field.description}
      aria-labelledby={labelId}
    />
  );
};

GenericFormGroup.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    titleKey: PropTypes.string,
    isRequired: PropTypes.bool,
    validationRules: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
};

export default memo(GenericFormGroup);

function createValidation(field) {
  if (!field.validationRules.length)
    return undefined;

  return field.validationRules.reduce((validation, rule) =>
    ({ ...validation, ...createValidator(rule) }), {});
}

function shouldUseRadioGroup(field) {
  return field.type === 'BooleanRadio'
    || field.type === 'RadioButtons'
    || field.type === 'Gender';
}
