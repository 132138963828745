import { createElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from 'components/primitives/breadcrumb';
import { connect } from 'react-redux';
import { NavigationKeys } from 'behavior/navigation';
import { useIsMobileViewport } from 'utils/hooks';
import { findNode, findCurrentPath } from './findCurrentPath';
import { BackTo } from 'components/primitives/links';
import BackLinkWrapper from './BackLinkWrapper';

const PageBreadcrumb = ({
  navigation,
  url,
  showBreadcrumb,
  backLinkComponent,
  backTo,
  className,
  currentLanguage,
}) => {
  const backToLink = useMemo(() => {
    if (!backTo)
      return null;

    return <BackTo to={backTo.routeData} url={backTo.url} texts={backLinkComponent && backLinkComponent.texts} />;
  }, [backTo, backLinkComponent]);

  const isMobile = useIsMobileViewport();

  if (!navigation || !navigation.length || !url)
    return null;

  if (!showBreadcrumb || isMobile) {
    if (backToLink)
      return <BackLinkWrapper className={className}>{backToLink}</BackLinkWrapper>;

    if (!backLinkComponent)
      return null;

    const parentUrl = url.slice(1, url.lastIndexOf('/'));
    if (!parentUrl)
      return null;

    return (
      <BackLinkWrapper className={className}>
        {createElement(backLinkComponent, { node: findNode(navigation, parentUrl) })}
      </BackLinkWrapper>
    );
  }

  const items = findCurrentPath(url, navigation, currentLanguage);
  if (!items)
    return backToLink && <BackLinkWrapper className={className}>{backToLink}</BackLinkWrapper>;

  const back = backToLink || (
    backLinkComponent
      ? createElement(backLinkComponent, { node: getCurrentNode(items, url, navigation) })
      : null
  );

  return <Breadcrumb items={items} className={className} backLink={back} />;
};

PageBreadcrumb.propTypes = {
  navigation: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.shape({
      url: PropTypes.string,
    }),
    children: PropTypes.array,
  }).isRequired),
  url: PropTypes.string,
  showBreadcrumb: PropTypes.bool,
  backLinkComponent: PropTypes.any,
  backTo: PropTypes.shape({
    url: PropTypes.string,
    routeData: PropTypes.object.isRequired,
  }),
  className: PropTypes.string,
  currentLanguage: PropTypes.string,
};

export default connect(state => ({
  navigation: state.navigation[NavigationKeys.Main],
  url: state.routing.location && state.routing.location.pathname,
  showBreadcrumb: state.settings.navigation && state.settings.navigation.breadcrumb,
  backTo: state.page.backTo,
  currentLanguage: state.localization.currentLanguage.cultureName,
}))(PageBreadcrumb);

function getCurrentNode(items, url, fullNavigation) {
  const lastNode = items[items.length - 1];
  if (lastNode.link && lastNode.link.url !== url)
    return lastNode;

  if (items.length > 2)
    return items[items.length - 2];

  const parentUrl = url.slice(1, url.lastIndexOf('/'));
  if (parentUrl)
    return findNode(fullNavigation, parentUrl);
}