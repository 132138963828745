import styles from './Specifications.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import GridRow from './GridRow';

const Grid = ({ specifications, showOnlyDifference }) => {
  if (!specifications || specifications.length === 0)
    return null;

  const specificationsToShow = showOnlyDifference
    ? specifications.filter(s => s.hasDifferences)
    : specifications;

  return (
    <>
      <h2 className={styles.title}><SimpleText textKey="ProductCompare_Specifications" /></h2>
      {specificationsToShow.map((spec, i) => <GridRow key={i} data={spec} />)}
    </>
  );
};

Grid.propTypes = {
  specifications: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    hasDifferences: PropTypes.bool,
  })),
  showOnlyDifference: PropTypes.bool.isRequired,
};

export default Grid;