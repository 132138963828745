import styles from './VariantsMatrix.module.scss';
import { memo, useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { UseSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import {
  QuantityTextBox,
  ProductStock,
  ProductPrice,
  createQuantityModel,
} from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';
import { AbilityTo } from 'behavior/user/constants';
import { matrixAbilitiesProps } from './useMatrixAbilities';

const VariantCell = ({
  product,
  variant,
  getVariantQuantity,
  abilities,
  onQuantityChanged,
  onRendered,
  labelledBy,
  tooltipOptions,
  separator,
}) => {
  const viewPrices = abilities[AbilityTo.ViewPrices];
  const canOrder = abilities[AbilityTo.OrderProducts];

  const { isOrderable, inventory, price, id: variantId } = variant;
  const { stockLevels, uoms, id: productId } = product;
  const uomId = product.uom && product.uom.id;
  const showQuantityBox = canOrder && isOrderable === true;

  const quantityModel = useMemo(() => {
    if (!showQuantityBox)
      return null;

    const uom = uoms && uoms.find(u => u.id === uomId);
    return createQuantityModel(uom, true);
  }, [uomId, showQuantityBox]);

  const [, forceUpdate] = useState();
  const updateQuantity = useCallback(quantity => {
    forceUpdate(quantity);
    onQuantityChanged && onQuantityChanged(variantId, quantity);
  }, []);

  const notAvailablePlaceholder = <Placeholder className={styles.notAvailablePlaceholder} />;

  return (
    <td className={styles.cell} aria-labelledby={labelledBy}>
      {showQuantityBox &&
        <div>
          <QuantityTextBox
            tooltipOptions={tooltipOptions}
            id={`${productId}_${variantId}`}
            quantity={quantityModel}
            value={getVariantQuantity(variantId)}
            hideControlsOnBlur
            onChange={updateQuantity}
            separator={separator}
            allowResetOnStepDecrease
          />
        </div>
      }
      {canOrder && isOrderable === false && (
        <UseSanaTexts options={['Product_NotAvailable']} placeholder={notAvailablePlaceholder}>
          {([notAvailableLabel]) => {
            onRendered();
            return (
              <span className={styles.notAvailable}>
                {makeSimpleText(notAvailableLabel)}
              </span>
            );
          }}
        </UseSanaTexts>
      )}
      <div>
        {inventory !== null && stockLevels != null && isOrderable && (
          <div className={styles.stockIndicator}>
            <ProductStock inventory={inventory} stockLevels={stockLevels} onRendered={onRendered} forVariant />
          </div>
        )}
        {viewPrices && <ProductPrice salesPrice={price} />}
      </div>
    </td>
  );
};

VariantCell.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
    stockLevels: PropTypes.object,
    uoms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    }).isRequired),
  }).isRequired,
  variant: PropTypes.shape({
    isOrderable: PropTypes.bool,
    inventory: PropTypes.number,
    price: PropTypes.number,
  }).isRequired,
  getVariantQuantity: PropTypes.func.isRequired,
  abilities: matrixAbilitiesProps,
  onQuantityChanged: PropTypes.func,
  onRendered: PropTypes.func.isRequired,
  labelledBy: PropTypes.string.isRequired,
  tooltipOptions: PropTypes.object.isRequired,
  separator: PropTypes.string,
};

export default memo(VariantCell);