/* eslint-disable react/no-multi-comp */
import styles from './SubAccount.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import { EditButtonIcon } from 'components/primitives/icons';
import { Link } from 'components/primitives/links';

const EditLink = ({ url, to, title, linkText, showBtnText }) => (
  <Link
    url={url}
    to={to}
    title={showBtnText ? undefined : title}
    className={`${btnStyles.btnBase} ${btnStyles.btnSmall} ${styles.action}`}
  >
    <EditButtonIcon className={styles.icon} aria-hidden />
    {showBtnText &&
      <span className="btn-cnt">
        {linkText}
      </span>
    }
  </Link>
);

EditLink.propTypes = {
  url: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.string,
  linkText: PropTypes.string,
  showBtnText: PropTypes.bool,
};

export default EditLink;