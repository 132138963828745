import styles from '../Checkout.module.scss';
import { useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { AddressStep as AddressStepSection, GuestAddressStepBody } from '../base/address';
import CheckoutContext from './CheckoutContext';
import { extractEscapedTemplateFields, validateForm } from 'components/objects/forms';
import { Steps } from 'behavior/pages/checkout';
import { saveGuest } from 'behavior/pages/checkout';

const GuestAddressStep = ({ info, isCompleted }) => {
  const {
    profileFields,
    profileTemplateFields,
    shippingTemplateFields,
    shippingAddress,
  } = info;

  const profileFormikRef = useRef();
  const shippingFormikRef = useRef();
  shippingFormikRef.current = null;

  const { setLoading, registerStep } = useContext(CheckoutContext);

  useEffect(() => {
    const validateStep = async () => {
      const formValid = await validateForm(profileFormikRef);
      const withShippingAddress = !!shippingFormikRef.current;
      const shippingFormValid = !withShippingAddress || (await validateForm(shippingFormikRef));

      return formValid && shippingFormValid;
    };

    registerStep(Steps.Address, styles.address, [Steps.Address], validateStep);
  }, []);

  const dispatch = useDispatch();

  const onAddressFormSubmit = async () => {

    const formValid = await validateForm(profileFormikRef);
    const withShippingAddress = !!shippingFormikRef.current;

    const shippingFormValid = !withShippingAddress || (await validateForm(shippingFormikRef));

    if (!formValid || !shippingFormValid)
      return;

    setLoading(Steps.Address);
    const formData = extractEscapedTemplateFields(profileFormikRef.current.values, profileTemplateFields);
    const shippingFormData = withShippingAddress ? extractEscapedTemplateFields(shippingFormikRef.current.values, shippingTemplateFields) : undefined;
    dispatch(saveGuest(formData, shippingFormData));
  };

  return (
    <AddressStepSection
      className={styles.address}
      isGuest
      isCompleted={isCompleted}
    >
      <GuestAddressStepBody
        profileFields={profileFields}
        profileTemplateFields={profileTemplateFields}
        shippingFields={shippingAddress && shippingAddress.address?.fields}
        shippingTemplateFields={shippingTemplateFields}
        onAddressFormSubmit={onAddressFormSubmit}
        profileFormikRef={profileFormikRef}
        shippingFormikRef={shippingFormikRef}
        submitFormOnBlur
      />
    </AddressStepSection>
  );
};

GuestAddressStep.propTypes = {
  info: PropTypes.shape({
    profileFields: PropTypes.object,
    profileTemplateFields: PropTypes.array,
    shippingTemplateFields: PropTypes.array,
    shippingAddress: PropTypes.shape({
      address: PropTypes.object,
    }),
  }),
  isCompleted: PropTypes.bool,
};

export default GuestAddressStep;
