import styles from './Reviews.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import { HashRoutingContext } from 'components/hash';
import { SimpleText } from 'components/sanaText';

const ReviewsBtn = ({ total, navigateTo, className = styles.reviewsBtn }) => {
  const onClick = e => {
    e.preventDefault();
    navigateTo('#reviews', { extraTopOffset: 60 });
  };
  return (
    <a href="#reviews" onClick={onClick} className={`${linkStyles.link} ${className || ''}`}>
      <SimpleText textKey="ReviewsReadAmountReviewsLinkText" formatWith={[total]} />
    </a>
  );
};

ReviewsBtn.propTypes = {
  total: PropTypes.any.isRequired,
  navigateTo: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default connectToContext(
  [HashRoutingContext],
  ({ navigateTo }) => ({ navigateTo }),
)(memo(ReviewsBtn));