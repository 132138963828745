import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import { isMaxDate, isMinDate } from 'react-date-picker/dist/shared/propTypes';
import { getMinMaxDay } from '../datePicker/helpers';
import { pad } from 'utils/helpers';

const DaySelect = ({
  id,
  name,
  placeholderText,
  maxDate,
  minDate,
  month,
  year,
  ...selectProps
}) => {
  const { maxDay, minDay } = getMinMaxDay(maxDate, minDate, month, year);

  const items = useMemo(() => {
    const items = [{ name: placeholderText, value: null }];
    for (let i = minDay; i <= maxDay; i++)
      items.push({ name: pad(i, 2), value: i });

    return items;
  }, [maxDay, minDay, placeholderText]);

  return (
    <Select
      items={items}
      id={id && `${id}_day`}
      name={name && `${name}_day`}
      {...selectProps}
    />
  );
};

DaySelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholderText: PropTypes.string,
  maxDate: isMaxDate,
  minDate: isMinDate,
  month: PropTypes.number,
  year: PropTypes.number,
};

export default DaySelect;
