import { useEffect } from 'react';
import { toasts } from 'behavior/toasts';
import { SimpleText } from 'components/sanaText';
import { ReorderResult } from 'behavior/documents';

const useReorderFeedback = (confirmationControl, reorderResult, documentNotifications) => {

  useEffect(() => {
    if (reorderResult == null)
      return;

    confirmationControl.hide();

    // eslint-disable-next-line default-case
    switch (reorderResult) {
      case ReorderResult.Success:
        toasts.success(<SimpleText textKey="Order_SuccessfullyReordered" disableInsiteEditor />);
        break;

      case ReorderResult.NoProducts:
        documentNotifications.orderHasNoProducts();
        break;

      case ReorderResult.BasketNotEmpty:
        confirmationControl.show();
        break;

      case ReorderResult.OrderNotFound:
        documentNotifications.orderNotFound();
        break;
    }
  }, [reorderResult]);
};

export default useReorderFeedback;
