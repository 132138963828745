import styles from '../Button.module.scss';
import { memo } from 'react';
import ButtonCore from '../Button';
import PropTypes from 'prop-types';

const sizesStyleMap = new Map([
  ['xs', styles.btnExtraSmall],
  ['sm', styles.btnSmall],
  ['md', undefined],
  ['lg', styles.btnBig],
]);

const Button = ({ children, size = 'md', noIcon, className, ...attributes }) => {
  const sizeStyle = sizesStyleMap.get(size);
  let buttonClassName = sizeStyle ? `${styles.btn} ${sizeStyle}` : styles.btn;

  if (noIcon)
    buttonClassName += ` ${styles.noIcon}`;

  if (className)
    buttonClassName += ` ${className}`;

  return <ButtonCore className={buttonClassName} {...attributes}>{children}</ButtonCore>;
};

Button.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf([...sizesStyleMap.keys()]),
  noIcon: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(Button);

