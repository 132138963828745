import styles from './Basket.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useAbilities } from 'components/objects/user';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { SimpleText } from 'components/sanaText';
import { SanaLinkButton } from 'components/primitives/links';

const abilityKeys = [AbilityTo.ViewProductSuggestions];

const Empty = ({ shoppingCartSuggestionPage }) => {
  const [canViewProductSuggestions] = useAbilities(abilityKeys).abilities
    .map(ability => ability === AbilityState.Available);

  return (
    <div className={`msg-block ${styles.emptyBasketBlock}`}>
      <span className={styles.msgEmptyBasket}><SimpleText textKey="Basket_EmptyBasket" /></span>
      {canViewProductSuggestions && !!shoppingCartSuggestionPage && (
        <SanaLinkButton
          className={`${btnStyles.btnBig} ${styles.btnSuggestions}`}
          to={shoppingCartSuggestionPage.to}
          textKey="GetProductSuggestions"
          role="button"
        />
      )}
    </div>
  );
};

Empty.propTypes = {
  shoppingCartSuggestionPage: PropTypes.shape({
    to: PropTypes.shape({
      routeName: PropTypes.string,
      params: PropTypes.object,
    }),
  }),
};

export default connect(({ settings: { shoppingCartSuggestionPage } }) => ({ shoppingCartSuggestionPage }))(Empty);