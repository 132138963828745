import { createSelector } from 'reselect';

const abilitiesSelector = state => state.user.abilities;
const expiredAbilitiesSelector = state => state.user.expiredAbilities;

export function getAbilitiesSelector(abilityKeys) {
  return createSelector(
    abilitiesSelector,
    abilities => abilityKeys.map(k => abilities[k]),
  );
}

export function getExpAbilitiesSelector(abilityKeys) {
  return createSelector(
    expiredAbilitiesSelector,
    expired => abilityKeys.map(k => expired.includes(k)),
  );
}