export default context => value => {
  const { min, max } = context.params;
  if (value == null || value === '')
    return;

  const number = +value;
  if (isNaN(number))
    return context.errorMessage;

  if (number < min || number > max)
    return context.errorMessage;
};