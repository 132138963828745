import { memo } from 'react';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import Documents, { selectPropsFromPage } from '../Documents';
import ReturnReceiptsList from './ReturnReceiptsList';

const textKeys = {
  introduction: 'ReturnReceiptsHistory_IntroductionText',
  listHeader: 'RecentReturnReceipts',
  loadMore: 'ShowNextNOrders',
  noItems: 'Orders_NoOrderHistory',
};

const ordersRoute = routesBuilder.forReturnReceipts();

const ReturnReceipts = memo(props => (
  <Documents
    loadMoreRoute={ordersRoute}
    textKeys={textKeys}
    documentType={DocumentType.ReturnReceipt}
    {...props}
  >
    <ReturnReceiptsList documents={props.documents} />
  </Documents>
));

ReturnReceipts.selectPropsFromPage = selectPropsFromPage;

export default ReturnReceipts;
