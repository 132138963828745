import { Fragment, ReactNode } from 'react';

/**
 * Wraps substring(s) matching RegExp with span having specified CSS class.
 * @param {string} str - string which should be modified
 * @param {RegExp} regex - RegExp query
 * @param {string} highlightClass - span classes
 * @returns {ReactNode} - React fragment nodes with wrapped up matched substrings.
 */
export function highlightStringBySpans(str: string, regex: RegExp, highlightClass: string): ReactNode {

  const parts = str.split(regex);
  if (parts.length === 1)
    return str;

  const fragments: ReactNode[] = [];
  for (let i = 0; i < parts.length; i++) {
    if (parts[i])
      fragments.push(i % 2 ? <span className={highlightClass}>{parts[i]}</span> : parts[i]);
  }

  return fragments.map((fragment, index) => <Fragment key={index}>{fragment}</Fragment>);
}
