import { PASSWORD_RESET_RESULT } from './actions';

export default function resetPasswordReducer(state, action) {
  switch (action.type) {
    case PASSWORD_RESET_RESULT:
      return {
        ...state,
        result: action.payload,
      };
    default:
      return state;
  }
}