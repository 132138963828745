import styles from '../Checkout.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Button } from 'components/primitives/buttons';
import { MediumArrowUpIcon, MediumArrowDownIcon } from 'components/primitives/icons';

const OverviewLinesFooter = ({ totalCount, collapsed, onShowMoreClick }) => {
  const Icon = collapsed ? MediumArrowDownIcon : MediumArrowUpIcon;

  return (
    <div className={styles.footerWrapper}>
      <div className={styles.totalCount}>
        <span className={styles.productCount}>
          <SimpleText textKey="ItemsInCart" />
        </span>
        <b>{totalCount}</b>
      </div>
      <Button
        className={`${linkStyles.link} ${styles.showMoreBtn}`}
        onClick={onShowMoreClick}
        aria-expanded={!collapsed}
        aria-live="polite"
        aria-atomic
      >
        <Icon aria-hidden />
        <SimpleText textKey={collapsed ? 'ShowBasketContents' : 'HideBasketContents'} />
      </Button>
    </div>
  );
};

OverviewLinesFooter.propTypes = {
  totalCount: PropTypes.number.isRequired,
  collapsed: PropTypes.bool.isRequired,
  onShowMoreClick: PropTypes.func.isRequired,
};

export default OverviewLinesFooter;
