import styles from './ProductTiles.module.scss';
import { useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { useMatchMediaContext } from 'components/responsive';
import ProductTile from './ProductTile';

const ProductTiles = ({ products, noImage, id }) => {
  const { xs, isIE } = useMatchMediaContext();
  const ref = useRef();
  const styleRef = useRef();
  const blockId = `ps_${id}`;
  const shouldHandleResize = isIE && !xs;

  const handleResize = useMemo(() => {
    if (!shouldHandleResize)
      return null;

    return () => {
      const { fontSize, flexBasis } = window.getComputedStyle(ref.current.firstElementChild);
      const width = parseInt(flexBasis, 10) * parseInt(fontSize, 10);
      const offsetWidth = ref.current.offsetWidth;
      const maxWidth = `${offsetWidth / Math.floor(offsetWidth / width)}px`;
      const cssRule = styleRef.current.sheet.cssRules[0];
      if (cssRule && cssRule.style.maxWidth === maxWidth)
        return;

      styleRef.current.innerHTML = `#${blockId} .${styles.productTile} { max-width: ${maxWidth}; }`;
    };
  }, [shouldHandleResize, id]);

  useEffect(() => {
    if (!shouldHandleResize)
      return;

    styleRef.current = document.createElement('style');
    document.head.appendChild(styleRef.current);
    return () => {
      document.head.removeChild(styleRef.current);
      styleRef.current = undefined;
    };
  }, [shouldHandleResize, id]);

  return (
    <>
      <div id={blockId} ref={ref} className={styles.productTilesGrid}>
        {products.map(product => <ProductTile key={product.id} product={product} noImage={noImage} />)}
      </div>
      {shouldHandleResize && <ReactResizeDetector handleWidth onResize={handleResize} targetRef={ref} refreshMode="throttle" refreshRate={250} />}
    </>
  );
};

ProductTiles.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
  noImage: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default connect(({
  settings: { product: productSettings },
}) => ({
  noImage: productSettings && productSettings.noImage.small,
}))(ProductTiles);
