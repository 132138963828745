import PropTypes from 'prop-types';
import ErrorMessage from '../ErrorMessage';
import { getIn, useFormikContext } from 'formik';
import FormGroupTemplate from './FormGroupTemplate';
import { createLabelId, createErrorMsgId } from '../helpers';
import FieldLabel from '../FieldLabel';

const FormGroup = ({
  fieldName,
  fieldTitle,
  fieldComponent: FieldComponent,
  fieldDescriptionComponent: DescriptionComponent,
  className,
  ...fieldProps
}) => {
  const formik = useFormikContext();

  let fieldError = getIn(formik.errors, fieldName);
  if (fieldError && getIn(formik.touched, fieldName))
    className = className ? `${className} row-error` : 'row-error';
  else
    fieldError = null;

  const formName = formik.status.formName;
  const labelId = createLabelId(formName, fieldName);
  const errorId = createErrorMsgId(formName, fieldName);

  if (FieldComponent.notNative)
    fieldProps.labelId = labelId;

  return (
    <FormGroupTemplate
      className={className}
      label={<FieldLabel fieldName={fieldName} required={fieldProps.required} notNative={FieldComponent.notNative}>{fieldTitle}</FieldLabel>}
      field={<FieldComponent fieldName={fieldName} fieldTitle={fieldTitle} {...fieldProps} />}
      error={<ErrorMessage fieldName={fieldName} id={errorId}>{fieldError}</ErrorMessage>}
      description={DescriptionComponent && <DescriptionComponent />}
      aria-labelledby={labelId}
    />
  );
};

FormGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldTitle: PropTypes.string,
  fieldComponent: PropTypes.elementType.isRequired,
  fieldDescriptionComponent: PropTypes.elementType,
  className: PropTypes.string,
};

export default FormGroup;