export const DOC_FREE_RETURN_ORDER_SUBMITTED = 'DOC_FREE_RETURN_ORDER_SUBMITTED';
export const createReturnOrder = (input, files) => ({
  type: DOC_FREE_RETURN_ORDER_SUBMITTED,
  payload: { input, files },
});

export const DOC_FREE_RETURN_ORDER_FAILED = 'DOC_FREE_RETURN_ORDER_FAILED';
export const setReturnOrderFailed = () => ({
  type: DOC_FREE_RETURN_ORDER_FAILED,
});

export const DOC_FREE_RETURN_ORDER_PRODUCTS_REQUESTED = 'DOC_FREE_RETURN_ORDER_PRODUCTS_REQUESTED';
export const requestProducts = ids => ({
  type: DOC_FREE_RETURN_ORDER_PRODUCTS_REQUESTED,
  payload: { ids },
});

export const DOC_FREE_RETURN_ORDER_PRODUCTS_RECEIVED = 'DOC_FREE_RETURN_ORDER_PRODUCTS_RECEIVED';
export const receiveProducts = products => ({
  type: DOC_FREE_RETURN_ORDER_PRODUCTS_RECEIVED,
  payload: { products },
});