export const NavigationKeys = Object.freeze({
  Main: 'MAIN',
  Footer: 'FOOTER',
  Sitemap: 'SITEMAP',
  MyAccount: 'MY_ACCOUNT',
});

export const ImagePosition = Object.freeze({
  Inline: 'INLINE',
  RightBottom: 'RIGHT_BOTTOM',
});