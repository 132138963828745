import styles from './BasketB2B.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { PromotionalText } from 'components/objects/promotionalTexts';
import { QuoteButton, CheckoutButton } from '../actionButtons';
import Actions from './Actions';
import Totals from './Totals';
import SalesAgreementControl from '../SalesAgreementControl';
import PromotionControl from '../PromotionControl';
import { connect } from 'react-redux';

const Details = ({ isEmpty, isBlocked, enablePromotionCodes, canCreateQuote, canCreateOrder }) => {
  const detailsTitlePlaceholder = <Placeholder className={styles.detailsTitlePlaceholder} />;

  return (
    <>
      <h2 className={styles.detailsTitle}>
        <SimpleText textKey="BasketDetails" placeholder={detailsTitlePlaceholder} />
      </h2>
      <div className={styles.totalsWrapper}>
        {!isEmpty && enablePromotionCodes && <PromotionControl />}
        <SalesAgreementControl />
        {!isEmpty && <Totals />}
      </div>
      <div className={styles.actions}>
        <Actions isEmpty={isEmpty} />
        {canCreateQuote && <QuoteButton disabled={isEmpty} canCreateOrder={canCreateOrder} />}
        {canCreateOrder && <CheckoutButton disabled={isEmpty || isBlocked} />}
      </div>
      <div className={styles.promo}>
        <PromotionalText textKey="BasketPage_B2B_ColumnFooter" />
      </div>
    </>
  );
};

Details.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
  isBlocked: PropTypes.bool,
  enablePromotionCodes: PropTypes.bool.isRequired,
  editDocumentType: PropTypes.string,
};

const mapStateToProps = ({
  basket,
  settings: {
    checkout: {
      enablePromotionCodes,
    },
  },
}) => ({
  isEmpty: !basket.model || (!basket.model.totalCount && !basket.model.productLines.totalCount),
  isBlocked: basket.model?.isBlocked,
  enablePromotionCodes,
});

export default connect(mapStateToProps)(Details);
