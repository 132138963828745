import styles from './TermsAndAgreements.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TermsAndAgreementsVisibility } from 'behavior/pages/checkout';
import { SanaForm } from 'components/objects/forms';
import { HiddenSubmitButton } from 'components/primitives/form';
import CheckboxFormGroup from './CheckboxFormGroup';
import Info from './Info';
import { formatText } from 'utils/formatting';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText, makeRichText } from 'utils/render';

const TermsAndAgreements = ({ submitButtonTextKey, visibility, formikRef, onSubmit }) => {
  const hidden = visibility === TermsAndAgreementsVisibility.Invisible;
  const { termsError, loaded, title, ...infoTexts } = useTermsAndAgreementsTexts(hidden, submitButtonTextKey);

  const initialValues = useMemo(() => ({
    acceptTerms: visibility === TermsAndAgreementsVisibility.VisibleSelected,
  }), [visibility]);

  if (!loaded)
    return null;

  if (hidden)
    return <div className={styles.termsContainer}>{title} <Info {...infoTexts} /></div>;

  return (
    <SanaForm
      name="termsAndAgreements"
      formikRef={formikRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <CheckboxFormGroup fieldName="acceptTerms" errorText={termsError} title={title} infoTexts={infoTexts} />
      <HiddenSubmitButton />
    </SanaForm>
  );
};

TermsAndAgreements.propTypes = {
  submitButtonTextKey: PropTypes.string.isRequired,
  visibility: PropTypes.oneOf(Object.values(TermsAndAgreementsVisibility)),
  formikRef: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = ({ settings }) => ({
  visibility: settings.checkout &&
    settings.checkout.termsAndAgreementsVisibility,
});

export default connect(mapStateToProps)(TermsAndAgreements);

function useTermsAndAgreementsTexts(hidden, submitButtonTextKey) {
  const {
    texts: [
      termsErrorText,
      titleText,
      submitButtonText,
      termsMessageText,
      titleLinkText,
      popupHeaderText,
      popupBodyText,
    ],
    loaded,
  } = useSanaTexts([
    !hidden && 'OrderOverview_AgreeTermsError',
    !hidden && 'OrderOverview_Terms',
    hidden && `ButtonText_${submitButtonTextKey}`,
    hidden && 'OrderOverview_TermsMessage',
    'TermsAndConditions',
    'TermsAndConditions_Title',
    'TermsAndConditions_Body',
  ]);

  return {
    termsError: makeSimpleText(termsErrorText),
    title: hidden ? formatText(termsMessageText, [submitButtonText]) : makeSimpleText(titleText),
    linkText: makeSimpleText(titleLinkText),
    popupHeader: makeSimpleText(popupHeaderText),
    popupBody: makeRichText(popupBodyText),
    loaded,
  };
}
