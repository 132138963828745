exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Placeholder_placeholder-item{height:1em;background-color:var(--placeholderColor,#f7f7f7);background-clip:content-box;box-sizing:content-box;display:block}", ""]);

// exports
exports.locals = {
	"placeholder-item": "Placeholder_placeholder-item",
	"placeholderItem": "Placeholder_placeholder-item"
};