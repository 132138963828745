import styles from './Video.module.scss';
import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { generateKey } from 'utils/helpers';

const VIMEO_API_SRC = 'https://player.vimeo.com/api/player.js';

let asyncLoadVimeoAPI;

const VimeoPlayer = ({ videoId, className = '', events, ...playerVars }) => {
  const [playerId] = useState(() => `vimeo_player_${generateKey()}`);

  useEffect(() => {
    let player;
    if (!asyncLoadVimeoAPI) {
      asyncLoadVimeoAPI = new Promise(resolve => {
        const tag = document.createElement('script');
        tag.src = VIMEO_API_SRC;
        tag.onload = () => resolve(window.Vimeo);
        document.body.appendChild(tag);
      });
    }
    asyncLoadVimeoAPI.then(Vimeo => {
      player = new Vimeo.Player(playerId, {
        id: videoId,
        ...playerVars,
      });

      for (const event in events) {
        player.on(event, events[event]);
      }
    });

    return () => player?.destroy();
  }, [videoId, events, playerVars]);

  return (
    <div className={`${styles.frame} ${className}`} id={playerId} />
  );
};

VimeoPlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  className: PropTypes.string,
  events: PropTypes.shape({
    bufferend: PropTypes.func,
    bufferstart: PropTypes.func,
    ended: PropTypes.func,
    error: PropTypes.func,
    loaded: PropTypes.func,
    pause: PropTypes.func,
    play: PropTypes.func,
    playbackratechange: PropTypes.func,
    progress: PropTypes.func,
    seeked: PropTypes.func,
    timeupdate: PropTypes.func,
    volumechange: PropTypes.func,
  }),
};

export default memo(VimeoPlayer);