export const getDiscountAmount = (salesPrice: number, basePrice: number): number | null => {
  if (!basePrice || salesPrice >= basePrice)
    return null;

  return Math.round((basePrice - salesPrice) * 100) / 100;
};

export const getDiscountPercentage = (salesPrice: number, basePrice: number): number | null => {
  if (!basePrice || salesPrice >= basePrice)
    return null;

  if (!salesPrice)
    return 100;

  const actualPercent = (salesPrice * 100) / basePrice;
  return Math.round((100 - actualPercent) * 100) / 100;
};
