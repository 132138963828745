exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Confirmation_body p{margin:0;padding-top:8px}.Confirmation_confirmation footer{margin-top:20px;text-align:right}.Confirmation_confirmation footer button{margin-top:10px;max-width:100%}.Confirmation_confirmation footer button:last-child{margin-left:16px}", ""]);

// exports
exports.locals = {
	"body": "Confirmation_body",
	"confirmation": "Confirmation_confirmation"
};