import styles from './Faq.module.scss';
import { Container } from 'components/primitives/grid';
import { renderHTML } from 'utils/render';
import FaqPage from 'components/objects/faq/FaqPage';

function faqRenderer(page) {
  const { heading, headerText, footerText, faqItems } = page;
  const htmlBlockStyles = `fr-view ${styles.htmlBlock}`;

  return (
    <Container>
      <h1>{heading}</h1>
      {headerText && (
        <div className={htmlBlockStyles}>
          {renderHTML(page.headerText)}
        </div>
      )}
      <FaqPage faqItems={faqItems} />
      {footerText && (
        <div className={htmlBlockStyles}>
          {renderHTML(page.footerText)}
        </div>
      )}
    </Container>
  );
}

export default {
  faq: faqRenderer,
};