import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DangerAlert } from 'components/primitives/alerts';
import { RichText, SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';

const LoginFailedMessage = ({ loginFailed, onResetPasswordClick }) => {
  if (!loginFailed)
    return null;

  const resetPasswordLink = (
    <Link
      to={routesBuilder.forForgotPassword}
      options={{ email: '' }}
      onClick={onResetPasswordClick}
      data-prevent-validation
    >
      <SimpleText textKey="Login_FailureText_ResetLink" />
    </Link>

  );

  return (
    <DangerAlert role="alert" scrollOnAppear>
      <RichText textKey="Login_FailureText" formatWith={[resetPasswordLink]} />
    </DangerAlert>
  );
};

LoginFailedMessage.propTypes = {
  loginFailed: PropTypes.bool,
  onResetPasswordClick: PropTypes.func.isRequired,
};

export default connect(({ user: { loginFailed } }) => ({ loginFailed }))(LoginFailedMessage);
