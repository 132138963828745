exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MediaBackground_container{position:absolute;top:0;left:0;right:0;bottom:0;padding:0;margin:0 auto;overflow:hidden;pointer-events:none}.MediaBackground_column{left:var(--gutterWidth,16px);right:var(--gutterWidth,16px)}.row-no-gutters .MediaBackground_column{left:0;right:0}.MediaBackground_media-box,.MediaBackground_media-wrapper{height:100%}.MediaBackground_media-box{overflow:hidden;position:relative;display:flex;justify-content:center;align-items:center}.MediaBackground_media-box>.lazy-load-image-background{position:absolute;top:0;left:0;bottom:0;right:0;width:auto;height:auto;display:flex!important;justify-content:center;align-items:center}.MediaBackground_image{min-width:100%;min-height:100%;flex-shrink:0;width:100%;animation-play-state:paused}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.MediaBackground_image{-ms-interpolation-mode:bicubic;position:relative;left:50%;transform:translate3d(-50%,0,0)}}.MediaBackground_image.MediaBackground_full-height{height:100%;width:auto}.MediaBackground_ken-burn .MediaBackground_image{transform-origin:50% 50%;animation:MediaBackground_ken-burn-effect 62s linear infinite alternate}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.MediaBackground_ken-burn .MediaBackground_image{left:0}}.MediaBackground_black-and-white .MediaBackground_image{-webkit-filter:grayscale(1);filter:grayscale(1);animation:MediaBackground_black-and-white 20s ease forwards}.MediaBackground_play-animation .MediaBackground_image{animation-play-state:running}.animate-element:not(.animate-element-end) .MediaBackground_image{animation-play-state:paused}.MediaBackground_video{min-width:100%}@keyframes MediaBackground_ken-burn-effect{97%,to{transform:scale(1.3)}}@keyframes MediaBackground_black-and-white{to{-webkit-filter:grayscale(0);filter:grayscale(0)}}@media (prefers-reduced-motion:reduce),(print){.MediaBackground_image{animation-name:MediaBackground_none!important}}", ""]);

// exports
exports.locals = {
	"container": "MediaBackground_container",
	"column": "MediaBackground_column",
	"media-box": "MediaBackground_media-box",
	"mediaBox": "MediaBackground_media-box",
	"media-wrapper": "MediaBackground_media-wrapper",
	"mediaWrapper": "MediaBackground_media-wrapper",
	"image": "MediaBackground_image",
	"full-height": "MediaBackground_full-height",
	"fullHeight": "MediaBackground_full-height",
	"ken-burn": "MediaBackground_ken-burn",
	"kenBurn": "MediaBackground_ken-burn",
	"ken-burn-effect": "MediaBackground_ken-burn-effect",
	"kenBurnEffect": "MediaBackground_ken-burn-effect",
	"black-and-white": "MediaBackground_black-and-white",
	"blackAndWhite": "MediaBackground_black-and-white",
	"play-animation": "MediaBackground_play-animation",
	"playAnimation": "MediaBackground_play-animation",
	"video": "MediaBackground_video",
	"none": "MediaBackground_none"
};