import PropTypes from 'prop-types';
import Form from './Form';

const Page = ({ templateFields, createProspectResult }) => (
  <Form templateFields={templateFields} createProspectResult={createProspectResult} />
);

Page.propTypes = {
  templateFields: PropTypes.array.isRequired,
  createProspectResult: PropTypes.object,
};

Page.selectPropsFromPage = ({ templateFields, createProspectResult }) => ({ templateFields, createProspectResult });

export default Page;