import styles from '../../SanaForm.module.scss';
import PropTypes from 'prop-types';
import { createLabelId } from '../../helpers';
import { useFormikContext } from 'formik';
import FormGroupTemplate from '../../formGroups/FormGroupTemplate';
import FieldError from './FieldError';
import FieldLabelText from './FieldLabelText';

const RadioFormGroup = ({ field, fieldMetadata }) => {
  const formik = useFormikContext();
  const fieldName = fieldMetadata.name;
  const formName = formik.status.formName;
  const labelId = createLabelId(formName, fieldName);

  return (
    <FormGroupTemplate
      label={(
        <div id={labelId} className={styles.radioLabel}>
          <FieldLabelText fieldMetadata={fieldMetadata} />
        </div>
      )}
      field={field}
      error={<FieldError fieldMetadata={fieldMetadata} />}
      role="group"
      aria-labelledby={labelId}
    />
  );
};

RadioFormGroup.propTypes = {
  field: PropTypes.element.isRequired,
  fieldMetadata: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default RadioFormGroup;
