import styles from '../SubAccount.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FieldArray, getIn } from 'formik';
import { SanaButton } from 'components/primitives/buttons';
import Limit from './Limit';

const LimitsFieldArray = ({ limits, authorizers, labelledBy }) => {
  const available = { ...authorizers };
  const currency = useSelector(state => state.settings.currency.id);
  const limitsTableBodyRef = useRef();

  const lastIndex = useRef(-1);

  for (const limit of limits) {
    delete available[limit.authorizerId];
  }

  useEffect(() => {
    if (lastIndex.current === -1) {
      lastIndex.current = null;
      return;
    }

    if (limits.length === 0 || lastIndex.current === limits.length) {
      lastIndex.current = null;
      const addRuleButton = document.querySelector(`.${styles.addRule}`);

      if (addRuleButton)
        addRuleButton.focus();

      return;
    }

    if (lastIndex.current === null)
      return;

    if (lastIndex.current > limits.length) {
      const limitsInputs = limitsTableBodyRef.current.querySelectorAll('input');

      if (limitsInputs.length)
        limitsInputs[limitsInputs.length - 1].focus();

      lastIndex.current = null;
      return;
    }

  }, [limits]);

  const availableAuthorizers = Object.values(available);
  const identifiers = Object.keys(authorizers);
  const name = 'limits';

  return (
    <FieldArray name={name}>
      {({ push, remove, form: { errors, touched, status: { formName } } }) => {
        const removeLimit = index => {
          lastIndex.current = index;
          remove(index);
        };

        const addLimit = () => {
          lastIndex.current = limits.length + 2;
          push({
            amount: 0,
            authorizerId: availableAuthorizers[0].id,
          });
        };

        return (
          <>
            <table className={styles.limits} aria-labelledby={labelledBy}>
              <tbody ref={limitsTableBodyRef}>
                {limits.map((limit, index) => {
                  const fieldName = `${name}.${index}`;
                  const hasErrors = !!(getIn(errors, fieldName) && getIn(touched, fieldName));
                  return (
                    <Limit
                      key={identifiers[index]}
                      authorizer={authorizers[limit.authorizerId]}
                      availableAuthorizers={availableAuthorizers}
                      name={fieldName}
                      remove={removeLimit}
                      index={index}
                      currency={currency}
                      formName={formName}
                      hasErrors={hasErrors}
                    />
                  );
                })}
              </tbody>
            </table>
            {availableAuthorizers.length > 0 &&
              <div className={styles.control}>
                <SanaButton
                  type="button"
                  textKey="Add"
                  className={`${styles.addRule} ${btnStyles.btnSmall}`}
                  onClick={addLimit}
                />
              </div>
            }
          </>
        );
      }}
    </FieldArray>
  );
};

LimitsFieldArray.propTypes = {
  limits: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.any.isRequired,
    authorizerId: PropTypes.string.isRequired,
  })),
  authorizers: PropTypes.object.isRequired,
  labelledBy: PropTypes.string,
};

export default memo(LimitsFieldArray);
