import styles from './HtmlBlock.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { renderHTML } from 'utils/render';

const HtmlBlock = ({ model, createInlineHtmlEditor }) => {
  if (!model)
    return null;

  const { content } = model;
  if (!content || content === '')
    return null;

  if (createInlineHtmlEditor)
    return createInlineHtmlEditor(model, 'content');

  return (
    <div className={`fr-view ${styles.html}`}>{renderHTML(content)}</div>
  );
};

HtmlBlock.propTypes = {
  model: PropTypes.shape({
    content: PropTypes.string,
  }),
  createInlineHtmlEditor: PropTypes.func,
};

export default memo(HtmlBlock);