import styles from './Lines.module.scss';
import PropTypes from 'prop-types';

const ExtendedTexts = ({ texts }) => (
  texts != null && texts.map(({ title }, index) => <div key={index} className={styles.extendedText}>{title}</div>)
);

ExtendedTexts.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
  })),
};

export default ExtendedTexts;