import { SUB_ACCOUNT_REMOVAL_RESULT_RECEIVED } from './actions';
import { SubAccountRemovalResultTypes } from './constants';

export default function (state, action) {
  if (action.type === SUB_ACCOUNT_REMOVAL_RESULT_RECEIVED)
    return onSubAccountsRemovalResultReceived(state, action);

  return state;
}

function onSubAccountsRemovalResultReceived(state, action) {
  const { resultType, subAccountId } = action.payload || {};

  switch (resultType) {
    case SubAccountRemovalResultTypes.Success:
      return {
        ...state,
        subAccounts: {
          list: state.subAccounts.list.filter(a => a.id !== subAccountId),
        },
      };
    case SubAccountRemovalResultTypes.Failure.HasDependentAccounts:
    case SubAccountRemovalResultTypes.Failure.HasPendingAuthorizations:
      return {
        ...state,
        removalFailureInfo: {
          dependentAccounts: action.payload.dependentAccounts,
          authorizationsCount: action.payload.authorizationsCount,
        },
      };
    default:
      return state;
  }
}
