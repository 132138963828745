import { ofType } from 'redux-observable';
import { switchMap, map, pluck } from 'rxjs/operators';
import { loadNewsHeadlinesQuery } from './queries';
import { NEWS_HEADLINES_LOAD, newsHeadlinesLoaded } from './actions';

const newsHeadlinesEpic = (action$, _, { api }) => action$.pipe(
  ofType(NEWS_HEADLINES_LOAD),
  switchMap(action => api.graphApi(loadNewsHeadlinesQuery, action.payload).pipe(
    pluck('news', 'list', 'items'),
    map(newsHeadlinesLoaded),
  )),
);

export default newsHeadlinesEpic;