exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StyleWrapper_wrapper{flex:0 0 auto}.StyleWrapper_overflow{overflow:hidden}.StyleWrapper_stretch-height{height:100%;flex:0 1 auto}", ""]);

// exports
exports.locals = {
	"wrapper": "StyleWrapper_wrapper",
	"overflow": "StyleWrapper_overflow",
	"stretch-height": "StyleWrapper_stretch-height",
	"stretchHeight": "StyleWrapper_stretch-height"
};