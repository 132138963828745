import Field, { fieldPropTypes } from '../Field';
import { Input } from 'components/primitives/form';

const EmailAddressField = ({ fieldName, fieldTitle, validation, ...attributes }) => (
  <Field
    type="email"
    fieldName={fieldName}
    fieldTitle={fieldTitle}
    validation={{ ...validation, emailAddress: true }}
    as={Input}
    {...attributes}
  />
);

EmailAddressField.propTypes = fieldPropTypes;

export default EmailAddressField;