import { ADDONS_METADATA_REQUESTED, addonsMetadataLoaded } from './actions';
import { ofType } from 'redux-observable';
import { switchMap, map, pluck } from 'rxjs/operators';
import { addonsQuery } from './queries';

const addonsEpic = (action$, _, { api }) => action$.pipe(
  ofType(ADDONS_METADATA_REQUESTED),
  switchMap(_ => api.graphApi(addonsQuery).pipe(
    pluck('addons', 'packages'),
    map(addonsMetadataLoaded),
  )),
);

export default addonsEpic;