import { ExpensiveRequests, SlowThreshold } from './constants';

type TimingRoot = {
  name: string;
  duration: number;
  children?: Array<TimingRoot>;
  contentType: 'SQL' | 'XML' | '';
}

type Timing = {
  duration: number;
  root: TimingRoot;
}

export function getTimingHightLights(timing: Timing): [isSlow: boolean, hasERPRequest: boolean] {
  const timingIgnoringInfo = getTimingPerformanceInfo(timing.root);
  const isSlow = timingIgnoringInfo.isSlow
    ? true
    : timing.duration - timingIgnoringInfo.ignoredPerformanceTime > SlowThreshold;

  return [isSlow, timingIgnoringInfo.hasERPRequest];
}

function getTimingPerformanceInfo(
  timing: TimingRoot,
  info = { ignoredPerformanceTime: 0, isSlow: false, hasERPRequest: false },
) {
  if (timing.name in ExpensiveRequests) {
    info.ignoredPerformanceTime += timing.duration;
    if (timing.duration > ExpensiveRequests[timing.name as keyof typeof ExpensiveRequests])
      return {
        ...info,
        isSlow: true,
      };
  }

  if (timing.contentType === 'XML')
    info.hasERPRequest = true;

  if (!timing.children?.length)
    return info;

  for (const child of timing.children)
    info = getTimingPerformanceInfo(child, info);

  return info;
}
