import { FEEDBACK_RECEIVED_RESULT, FEEDBACK_SUBMITTED } from 'behavior/feedback';

const initialState = {
  successStatus: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FEEDBACK_RECEIVED_RESULT:
      return { ...state, successStatus: action.payload };
    case FEEDBACK_SUBMITTED:
      return { ...state, successStatus: null };
    default:
      return state;
  }
}