import { useEffect, useState } from 'react';

export default WrappedComponent => props => {
  const [hideOnClient, setHideOnClient] = useState(false);
  useEffect(() => setHideOnClient(true), []);

  if (!hideOnClient)
    return <WrappedComponent {...props} />;

  return null;
};