import styles from '../page/HeaderContent.module.scss';
import { Container } from 'components/primitives/grid';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { useSelectedFacetsTitle } from '../hooks';
import PageBreadcrumb from 'components/objects/pageBreadcrumb';
import { usePageTitle } from 'components/objects/hooks';

const HeaderContent = ({ keywords }) => {
  const facetsTitle = useSelectedFacetsTitle();
  keywords = keywords && keywords.trim();
  const title = usePageTitle(keywords ? `${keywords}${facetsTitle ? ' - ' + facetsTitle : ''}` : null);

  const keywordsValue = <span className={styles.fontTitle}>{keywords}</span>;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={`${styles.coloredHeaderDesktop} ${styles.header}`}>
        <Container className={styles.center}>
          <PageBreadcrumb />
          <div className={styles.combined}>
            <div className={styles.h1Holder}>
              <h1>
                <SimpleText textKey="Search_SearchResult" />
                {facetsTitle &&
                  <span className={styles.facetsTitle}>
                    {facetsTitle}
                  </span>
                }
              </h1>
            </div>
            {keywords &&
              <div className={`fr-view ${styles.combinedDescription}`}>
                <SimpleText textKey="Search_SearchedOn_Label" formatWith={[keywordsValue]} formatAsHtml />
              </div>
            }
          </div>
        </Container>
      </div>
    </>
  );
};

HeaderContent.propTypes = {
  keywords: PropTypes.string,
};

export default connect(({ routing: { routeData } }) => ({
  keywords: routeData.params && routeData.params.q,
}))(HeaderContent);