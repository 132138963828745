import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toLocaleDate, toLongLocaleDate } from 'utils/format';

const DateDisplay = ({ value, longFormat = false }) => {
  const culture = useSelector(getCulture);
  return longFormat
    ? toLongLocaleDate(value, culture)
    : toLocaleDate(value, culture);
};

function getCulture(state) {
  const { localization } = state;
  return localization.currentLanguage ? localization.currentLanguage.cultureName : null;
}

DateDisplay.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  longFormat: PropTypes.bool,
};

export default memo(DateDisplay);