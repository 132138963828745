import PropTypes from 'prop-types';
import Configure from './Configure';
import Placeholder from './Placeholder';
import ViewProduct from './ViewProduct';
import AddToBasket from './AddToBasket';
import SelectVariants from './SelectVariants';

const renderers = [
  { matches: product => product.isOrderable == null, renderer: Placeholder },
  { matches: product => product.isOrderable === false, renderer: ViewProduct },
  { matches: product => product.productConfiguratorInfo.isProductConfigurable, renderer: Configure },
  { matches: product => product.hasVariants, renderer: SelectVariants },
  { matches: _ => true, renderer: AddToBasket },
];

const ButtonRenderer = props => {
  const Renderer = renderers.find(r => r.matches(props.product)).renderer;
  return <Renderer {...props} />;
};

ButtonRenderer.propTypes = {
  product: PropTypes.shape({
    isOrderable: PropTypes.bool,
    productConfiguratorInfo: PropTypes.shape({
      isProductConfigurable: PropTypes.bool,
    }).isRequired,
    hasVariants: PropTypes.bool,
  }).isRequired,
};

export default ButtonRenderer;