import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import {
  checkoutInfoUpdated,
  CHECKOUT_ADDITIONAL_INFO_SAVE,
} from './actions';
import { getSaveAdditionalInfoMutation } from './queries';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {
    return action$.pipe(
      ofType(CHECKOUT_ADDITIONAL_INFO_SAVE),
      switchMap(action => waitForSubmit(() => {
        // 'Update' with empty object is used to trigger redux state update, so it will result in update of object reference in hook(s) dependencies.
        const emptyInfoUpdatedAction = checkoutInfoUpdated({});

        return api.graphApi(getSaveAdditionalInfoMutation(!!state$.value.page.info?.quote), { input: action.payload }).pipe(
          map(({ checkout }) => checkoutInfoUpdated(checkout.additionalInfo.save.info)),
          catchApiErrorWithToast(undefined, of(emptyInfoUpdatedAction)),
          retryWithToast(action$, logger),
        );
      })),
    );
  };
}
