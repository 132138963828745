export const ADDON_SCOPE_CLEARED = 'ADDON/SCOPE/CLEARED';
export const clearAddonScope = id => ({
  type: ADDON_SCOPE_CLEARED,
  payload: { id },
});

export const ADDON_REQUESTED = 'ADDON/REQUESTED';
export const requestAddon = (id, hash) => ({
  type: ADDON_REQUESTED,
  payload: { id, hash },
});

export const ADDON_LOADED = 'ADDON/LOADED';
export const addonLoaded = (id, hash, addon) => ({
  type: ADDON_LOADED,
  payload: { id, hash, addon },
});

export const ADDON_LOAD_FAILED = 'ADDON/LOAD_FAILED';
export const addonLoadFailed = (id, hash, error) => ({
  type: ADDON_LOAD_FAILED,
  payload: { id, hash, error },
});

export const ADDON_ACTION_PREFIX = '@/';
export const addonAction = (addonId, action) => ({
  type: `${ADDON_ACTION_PREFIX}${action.type} (${addonId})`,
  payload: { addonId, action },
});

export const EVENT_ACTION_PREFIX = 'EVENT/';

export const addonBroadcastAction = action => addonAction('*', action);