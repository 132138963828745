import { createRef } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'rxjs';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import PlaceholderWithTracking from './PlaceholderWithTracking';
import PlaceholderWithoutTracking from './PlaceholderWithoutTrackingWrapper';
import { isIntersectionObserverAvailable } from './helpers';

class LazyLoadComponentWrapper extends LazyLoadComponent {
  constructor(props) {
    super(props);

    this.useIntersectionObserver = isIntersectionObserverAvailable();
    this.placeholderComponentRef = createRef();
  }

  onVisible() {
    this.props.beforeLoad();
    if (
      !this.props.useCustomVisibilityHandling
      && this.placeholderComponentRef?.current?.placeholder
    ) {
      this.setState({ visible: true });
    }
  }

  render() {
    if (this.state.visible) {
      return this.props.children;
    }

    const {
      className,
      delayMethod,
      delayTime,
      height,
      placeholder,
      scrollPosition,
      style,
      threshold,
      useIntersectionObserver,
      width,
    } = this.props;
    const shouldUseIntersectionObserver = useIntersectionObserver && this.useIntersectionObserver;

    if (this.isScrollTracked || shouldUseIntersectionObserver) {
      return (
        <PlaceholderWithoutTracking
          ref={this.placeholderComponentRef}
          className={className}
          height={height}
          onVisible={this.onVisible}
          placeholder={placeholder}
          scrollPosition={scrollPosition}
          style={style}
          threshold={threshold}
          useIntersectionObserver={shouldUseIntersectionObserver}
          width={width}
        />
      );
    }

    return (
      <PlaceholderWithTracking
        ref={this.placeholderComponentRef}
        className={className}
        delayMethod={delayMethod}
        delayTime={delayTime}
        height={height}
        onVisible={this.onVisible}
        placeholder={placeholder}
        style={style}
        threshold={threshold}
        width={width}
      />
    );
  }
}

LazyLoadComponentWrapper.propTypes = {
  afterLoad: PropTypes.func,
  beforeLoad: PropTypes.func,
  useIntersectionObserver: PropTypes.bool,
  visibleByDefault: PropTypes.bool,
  useCustomVisibilityHandling: PropTypes.bool,
};

LazyLoadComponentWrapper.defaultProps = {
  afterLoad: noop,
  beforeLoad: noop,
  useIntersectionObserver: true,
  visibleByDefault: false,
  useCustomVisibilityHandling: false,
};

export default LazyLoadComponentWrapper;