import styles from './MyAccount.module.scss';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import AccountInfo from './AccountInfo';

const AccountManagement = ({ abilities: [editProfileAbility] }) => (
  <div className={styles.accountManagement}>
    <h3 className={styles.title}><SimpleText textKey="MyAccount_AccountManagement" /></h3>
    <div className={styles.content}>
      <div className={styles.contactInfo}>
        <span className={styles.subTitle}><SimpleText textKey="ContactInformation" /></span>
        {editProfileAbility === AbilityState.Available &&
          <Link to={routesBuilder.forEditProfile} rel="nofollow">
            <SimpleText textKey="YourDetails" />
          </Link>
        }
      </div>
      <AccountInfo />
    </div>
  </div>
);

AccountManagement.propTypes = {
  abilities: abilitiesPropType.isRequired,
};

export default withAbilities(AccountManagement, [AbilityTo.EditProfile, AbilityTo.SubscribeToNewsletter]);