import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import ProductDetailsButton from './ProductDetailsButton';

const ViewProduct = ({ product }) => (
  <ProductDetailsButton
    textKey="ViewProduct"
    className={`${btnStyles.btnSmall} btn-action`}
    product={product}
    titleTextKey="ProductComparePage_ViewProduct"
  />
);

ViewProduct.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ViewProduct;