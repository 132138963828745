exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EConIframe_iframe-wrapper{height:550px;overflow:hidden;height:100vh;border:none}.EConIframe_iframe-Dwrapper{padding:0}@media (max-width:var(--breakpoints_Medium,960)px){.EConIframe_iframe-wrapper{height:1100px}}@media (max-width:var(--breakpoints_Small,600)px){.EConIframe_iframe-wrapper{margin:0 -1.5em}.EConIframe_iframe-Dwrapper{padding:0 5px 5px}}", ""]);

// exports
exports.locals = {
	"iframe-wrapper": "EConIframe_iframe-wrapper",
	"iframeWrapper": "EConIframe_iframe-wrapper",
	"iframe-Dwrapper": "EConIframe_iframe-Dwrapper",
	"iframeDwrapper": "EConIframe_iframe-Dwrapper"
};