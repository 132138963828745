import styles from './Selector.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sanitizeId } from 'utils/helpers';
import { Checkbox } from 'components/primitives/form';
import { UseSanaTexts, SimpleText } from 'components/sanaText';
import { addProductToComparison, removeProductFromComparison } from 'behavior/productComparison/actions';
import Tooltip from 'components/primitives/tooltip';
import { ConditionalWrap } from 'components/primitives/conditionalWrap';
import { useIsPreview, useHandlerLockerInPreview } from 'components/objects/preview';

const Selector = ({
  selected,
  addProduct,
  removeProduct,
  product,
  className = '',
  maxProductsToCompare,
  productsToCompareCount,
}) => {
  const isPreview = useIsPreview();

  const isSelectorDisabled = () => {
    return !selected && productsToCompareCount >= maxProductsToCompare;
  };

  const handleChange = useHandlerLockerInPreview(() => {
    if (isSelectorDisabled())
      return;

    !selected
      ? addProduct(product)
      : removeProduct(product.id);
  });

  const checkId = sanitizeId('chb_' + product.id);

  return (
    <div className={`${styles.cnt} ${className}`}>
      <ConditionalWrap
        condition={isSelectorDisabled()}
        wrapWith={children => (
          <UseSanaTexts options={['ProductCompareMaxSelectedTooltip_Title', 'ProductCompareMaxSelectedTooltip_Body']}>
            {([title, body]) => (
              <Tooltip
                className={styles.configureProductBtnTooltip}
                title={title}
                body={body}
                sign={false}
                id={checkId + 'T'}
              >
                {children}
              </Tooltip>)}
          </UseSanaTexts>
        )}
      >
        <Checkbox
          className={styles.chb}
          id={checkId} 
          checked={selected && !isPreview}
          onChange={handleChange}
          disabled={isSelectorDisabled()}
        />
        <label htmlFor={checkId}>
          <SimpleText textKey="ProductCompareSelectorLabel" />
        </label>
      </ConditionalWrap>
    </div>
  );
};

Selector.propTypes = {
  selected: PropTypes.bool.isRequired,
  addProduct: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  className: PropTypes.string,
  maxProductsToCompare: PropTypes.number.isRequired,
  productsToCompareCount: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const productsToCompare = state.productComparison ? state.productComparison.products : [];

  return {
    selected: productsToCompare.some(item => item.id.toLocaleUpperCase() === ownProps.product.id.toLocaleUpperCase()),
    isEnabled: state.settings.product.productComparison.isEnabled,
    maxProductsToCompare: state.settings.product.productComparison.maxProductsToCompare,
    productsToCompareCount: productsToCompare.length,
  };
};

const mapDispatchToProps = {
  addProduct: addProductToComparison,
  removeProduct: removeProductFromComparison,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  ({ isEnabled, ...props }) => isEnabled ? <Selector {...props} /> : null,
);
