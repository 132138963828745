import styles from './Calendar.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getYear, getMonth, getDaysInMonth, getDate } from '@wojtekmaj/date-utils';
import { CalendarTypeLocalesMap } from './constants';
import { getDayOfWeekIndex } from './helpers';
import WeekNumbers from './monthView/WeekNumbers';
import WeekDays from './monthView/WeekDays';
import Days from './monthView/Days';

const calendarTypesWithLocales = Object.keys(CalendarTypeLocalesMap);

const MonthView = ({
  activeStartDate,
  calendarType,
  firstDayOfWeek,
  formatLongDate,
  formatShortWeekday,
  formatWeekday,
  isSecondView,
  locale,
  onClickWeekNumber,
  onMouseLeave,
  showFixedNumberOfWeeks,
  showWeekNumbers,
  ...daysProps
}) => {
  const currentCalendarType = useMemo(() => getCalendarType(calendarType, locale), [calendarType, locale]);

  const activeStartDateCalculatedData = useMemo(() => ({
    calendarType: currentCalendarType,
    date: activeStartDate,
    day: getDate(activeStartDate),
    daysInMonth: getDaysInMonth(activeStartDate),
    dayOfWeekIndex: getDayOfWeekIndex(activeStartDate, firstDayOfWeek, currentCalendarType),
    year: getYear(activeStartDate),
    monthIndex: getMonth(activeStartDate),
  }), [activeStartDate, firstDayOfWeek, currentCalendarType]);

  const weekNumbersSection = showWeekNumbers && (
    // onHover is handled by onMouseOver handler in child component.
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <WeekNumbers
      activeStartDateCalculatedData={activeStartDateCalculatedData}
      firstDayOfWeek={firstDayOfWeek}
      onClickWeekNumber={onClickWeekNumber}
      onMouseOver={onMouseLeave}
      showFixedNumberOfWeeks={showFixedNumberOfWeeks}
    />
  );

  return (
    <div className={styles.monthView}>
      {!isSecondView && weekNumbersSection}
      <div className={styles.mainView}>
        {/* onHover is handled by onMouseOver handler in child component. */}
        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
        <WeekDays
          calendarType={currentCalendarType}
          firstDayOfWeek={firstDayOfWeek}
          formatShortWeekday={formatShortWeekday}
          formatWeekday={formatWeekday}
          locale={locale}
          onMouseOver={onMouseLeave}
        />
        {/* onHover is handled by onMouseOver handler in child component. */}
        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
        <Days
          activeStartDateCalculatedData={activeStartDateCalculatedData}
          firstDayOfWeek={firstDayOfWeek}
          formatLongDate={formatLongDate}
          locale={locale}
          onMouseOver={onMouseLeave}
          showFixedNumberOfWeeks={showFixedNumberOfWeeks}
          {...daysProps}
        />
      </div>
      {isSecondView && weekNumbersSection}
    </div>
  );
};

MonthView.propTypes = {
  activeStartDate: PropTypes.instanceOf(Date).isRequired,
  calendarType: PropTypes.string,
  firstDayOfWeek: PropTypes.number,
  formatLongDate: PropTypes.func,
  formatShortWeekday: PropTypes.func,
  formatWeekday: PropTypes.func,
  isSecondView: PropTypes.bool,
  locale: PropTypes.string,
  onClickWeekNumber: PropTypes.func,
  onMouseLeave: PropTypes.func,
  showFixedNumberOfWeeks: PropTypes.bool,
  showWeekNumbers: PropTypes.bool,
};

export default MonthView;

function getCalendarType(calendarType, locale) {
  if (calendarType)
    return calendarType;

  const calendarTypeFromLocale = calendarTypesWithLocales.find(t => CalendarTypeLocalesMap[t].includes(locale));
  return calendarTypeFromLocale || 'ISO_8601';
}
