import { useEffect } from 'react';
import { TRANSITION_DURATION } from 'components/objects/toast/constants';

export const useFocusActionBtn = actionBtnRef => {
  let prevFocusedElement;

  useEffect(() => {
    if (!actionBtnRef || !actionBtnRef.current)
      return;

    if (document.activeElement !== actionBtnRef.current)
      prevFocusedElement = document.activeElement;

    setTimeout(() => actionBtnRef.current.focus(), TRANSITION_DURATION);

    return () => {
      if (prevFocusedElement)
        prevFocusedElement.focus();
    };
  }, [actionBtnRef]);
};