exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Spinner_spinner{display:block;width:2em;height:2em;position:relative;border-radius:50%;overflow:hidden}.Spinner_spinner:before{content:\"\";display:block;width:100%;height:100%;box-sizing:border-box;border:.3em solid var(--hyperlink_FontColor,#1f7bc9);border-radius:50%;opacity:.3}.Spinner_spinner:after{content:\"\";display:block;position:absolute;top:0;left:0;width:100%;height:100%;box-sizing:border-box;border-radius:50%;border:.3em solid transparent;border-top:.3em solid var(--hyperlink_FontColor,#1f7bc9);animation:Spinner_spinner 1.2s ease-in-out infinite}@keyframes Spinner_spinner{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", ""]);

// exports
exports.locals = {
	"spinner": "Spinner_spinner"
};