export default context => value => {
  if (!value)
    return;

  const { max } = context.params;

  if (value instanceof File) {
    if (value.size > max)
      return context.errorMessage;
  }
  else if (value instanceof FileList) {
    let size;
    for (const file of value)
      size += file.size;

    if (size > max)
      return context.errorMessage;
  }
};