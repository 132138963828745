import { useMemo } from 'react';
import PropTypes from 'prop-types';
import NavigationLink from './NavigationLink';
import { useRoutes } from 'components/primitives/route';
import { createRouteData, toPropTypes } from './helpers';

const RouteLink = ({
  createLink,
  to,
  onClick,
  omitScroll,
  replaceHistory,
  options,
  disabled,
}) => {
  const routes = useMemo(() => [createRouteData(to)], [to]);
  const [url] = useRoutes(routes);

  if (!url)
    return createLink('#', null);

  return (
    <NavigationLink
      createLink={createLink}
      url={url}
      to={routes[0]}
      onClick={onClick}
      omitScroll={omitScroll}
      replaceHistory={replaceHistory}
      options={options}
      disabled={disabled}
    />
  );
};

RouteLink.propTypes = {
  createLink: PropTypes.func.isRequired,
  to: toPropTypes.isRequired,
  onClick: PropTypes.func,
  omitScroll: PropTypes.bool,
  replaceHistory: PropTypes.bool,
  options: PropTypes.object,
  disabled: PropTypes.bool,
};

export default RouteLink;