import { useRef, useMemo } from 'react';

import { useOnChange } from 'utils/hooks';

export const useSliderControl = index => {
  const currentIndex = useRef(index);

  const _setIndex = index => {
    if (control && control.setIndex)
      control.setIndex(index);
    else
      currentIndex.current = index;
  };

  const control = useMemo(() => ({
    get currentIndex() {
      return currentIndex;
    },
    setIndex: undefined,
    prev() { _setIndex(currentIndex.current - 1); },
    next() { _setIndex(currentIndex.current + 1); },
  }), []);

  useOnChange(() => void (_setIndex(index)), [index]);

  return control;
};
