exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NumberSwitcher_num-switch{position:relative;display:inline-flex;flex-direction:column;height:1rem;line-height:1rem;overflow:hidden;text-align:center}.NumberSwitcher_num-switch-animation .NumberSwitcher_num-switch-next,.NumberSwitcher_num-switch-animation .NumberSwitcher_num-switch-prev{animation-name:NumberSwitcher_slideUp}.NumberSwitcher_final-state .NumberSwitcher_num-switch-next,.NumberSwitcher_final-state .NumberSwitcher_num-switch-prev{transform:translateY(-100%)}.NumberSwitcher_final-state[data-invert] .NumberSwitcher_num-switch-next,.NumberSwitcher_final-state[data-invert] .NumberSwitcher_num-switch-prev{transform:translateY(0)}.NumberSwitcher_num-switch-next,.NumberSwitcher_num-switch-prev{transform:translateY(0);animation:.25s ease-out 0s;animation-name:NumberSwitcher_none;animation-fill-mode:forwards}.NumberSwitcher_num-switch[data-invert]>span{animation-direction:alternate-reverse}.NumberSwitcher_num-switch[data-invert] .NumberSwitcher_num-switch-prev{order:2}.NumberSwitcher_num-switch[data-invert] .NumberSwitcher_num-switch-next{order:1}.NumberSwitcher_no-animation>span{animation-delay:-.25s;animation-duration:0s}@keyframes NumberSwitcher_slideUp{0%{transform:translateY(0)}to{transform:translateY(-100%)}}", ""]);

// exports
exports.locals = {
	"animationDuration": "250",
	"num-switch": "NumberSwitcher_num-switch",
	"numSwitch": "NumberSwitcher_num-switch",
	"num-switch-animation": "NumberSwitcher_num-switch-animation",
	"numSwitchAnimation": "NumberSwitcher_num-switch-animation",
	"num-switch-next": "NumberSwitcher_num-switch-next",
	"numSwitchNext": "NumberSwitcher_num-switch-next",
	"num-switch-prev": "NumberSwitcher_num-switch-prev",
	"numSwitchPrev": "NumberSwitcher_num-switch-prev",
	"slideUp": "NumberSwitcher_slideUp",
	"final-state": "NumberSwitcher_final-state",
	"finalState": "NumberSwitcher_final-state",
	"none": "NumberSwitcher_none",
	"no-animation": "NumberSwitcher_no-animation",
	"noAnimation": "NumberSwitcher_no-animation"
};