import { memo } from 'react';
import PropTypes from 'prop-types';
import Facet from './Facet';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import useEditableFacets from './useEditableFacets';

const FacetsList = ({ facets, isMobile, getUrl, multiSelect }) => {
  const filteredFacets = facets.filter(f => !f.anySelected || multiSelect);
  const processedFacets = useEditableFacets(filteredFacets);

  let [title] = useSanaTexts(['Filters'], makeSimpleText).texts;
  if (title)
    title += ':';

  return (
    <section aria-label={title}>
      <h2 id="filtersHeader" className="visually-hidden">{title}</h2>
      {processedFacets.map(facet =>
        <Facet facet={facet} key={facet.name} isMobile={isMobile} getUrl={getUrl} multiSelect={multiSelect} />)
      }
    </section>
  );
};

FacetsList.propTypes = {
  facets: PropTypes.arrayOf(
    PropTypes.shape({
      anySelected: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
  isMobile: PropTypes.bool,
  getUrl: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool.isRequired,
};

export default memo(FacetsList);
