import styles from './ClosedStore.module.scss';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { RouteName } from 'routes';
import Logo from 'components/primitives/logo/Logo';
import { Container, Row, Col } from 'components/primitives/grid';
import { useVerticalOverflowFix } from 'utils/hooks';
import PropTypes from 'prop-types';
import ClosedStoreFooter from './ClosedStoreFooter';

const ClosedStoreLayout = ({ children, pageTitle, routeName, backgroundImage }) => {
  useVerticalOverflowFix();

  const contentStyles = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div role="main" id="content" className={`${styles.content} page-${routeName}`} style={contentStyles}>
        <Container>
          <Row mainAxisAlign="center">
            <Col sm={9} md={8} lg={7} xl={6} className={styles.formColumn}>
              <div className={styles.form}>
                <Logo className={styles.logo} />
                {children}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ClosedStoreFooter />
    </>
  );
};

ClosedStoreLayout.propTypes = {
  children: PropTypes.node,
  pageTitle: PropTypes.string,
  routeName: PropTypes.string,
  backgroundImage: PropTypes.string,
};

const mapStateToProps = ({ theme, routing: { routeData } }) => ({
  routeName: routeData ? routeData.routeName : RouteName.NotFound,
  backgroundImage: theme.loaded && theme.values ? theme.values.closedStoreLayout_BackgroundImage : '',
});

export default connect(mapStateToProps)(ClosedStoreLayout);
