exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Bom_bom{margin:0 auto}.Bom_bom .Bom_table{word-break:break-word}@supports (-ms-ime-align:auto){.Bom_bom .Bom_table{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.Bom_bom .Bom_table{word-wrap:break-word}}.Bom_bom .Bom_table td,.Bom_bom .Bom_table th{border-width:0}.Bom_bom .Bom_table td{vertical-align:top;font-weight:700}.Bom_bom .Bom_id,.Bom_bom .Bom_variant{min-width:5.4em}.Bom_bom .Bom_title{min-width:8.7em;padding-left:15px;padding-right:15px}.Bom_bom .Bom_qty{width:5.4em;max-width:5.4em;text-align:right}.Bom_bom .Bom_nested{display:table;list-style:none;padding:0;margin:0;width:100%}.Bom_bom .Bom_nested li{display:table-row}.Bom_bom .Bom_nested li .Bom_lbl-variant{display:table-cell;padding-right:5px}.Bom_bom .Bom_nested li .Bom_lbl-qty{width:5.4em;max-width:5.4em;display:table-cell;text-align:right}.Bom_bom .Bom_nested li .Bom_lbl-variant+.Bom_lbl-qty{width:calc(5.4em - 5px);max-width:calc(5.4em - 5px);padding-left:5px}.Bom_bom.Bom_mobile{display:block}.Bom_bom.Bom_mobile,.Bom_bom.Bom_mobile .Bom_table{min-width:100%}.Bom_bom.Bom_mobile .Bom_table td,.Bom_bom.Bom_mobile .Bom_table th{font-weight:700}.Bom_bom.Bom_mobile .Bom_table td{text-align:left}.Bom_bom.Bom_mobile .Bom_table .Bom_title{padding-right:0}.Bom_table:not(.Bom_has-variants).mobile .Bom_nested{display:block}.Bom_table:not(.Bom_has-variants).mobile .Bom_nested .Bom_lbl-qty{width:auto;max-width:none}@media (min-width:var(--breakpoints_Medium,960)px),print{.Bom_table tbody td{width:40%}.Bom_table tbody td:first-child{width:20%}.Bom_table:not(.Bom_has-variants) .Bom_title{width:60%}.Bom_table:not(.Bom_has-variants) tbody tr td:last-child{width:20%}}", ""]);

// exports
exports.locals = {
	"bom": "Bom_bom",
	"table": "Bom_table",
	"id": "Bom_id",
	"variant": "Bom_variant",
	"title": "Bom_title",
	"qty": "Bom_qty",
	"nested": "Bom_nested",
	"lbl-variant": "Bom_lbl-variant",
	"lblVariant": "Bom_lbl-variant",
	"lbl-qty": "Bom_lbl-qty",
	"lblQty": "Bom_lbl-qty",
	"mobile": "Bom_mobile",
	"has-variants": "Bom_has-variants",
	"hasVariants": "Bom_has-variants"
};