import styles from './SalesAgreements.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { Link } from 'components/primitives/links';
import { RichText, SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { Table } from 'components/primitives/table';
import { getToLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';
import { applyAgreement, cancelAgreement } from 'behavior/salesAgreements/actions';
import { connect } from 'react-redux';
import { navigateTo } from 'behavior/events';

const Items = ({ items, selectedAgreementId, backTo, dispatch }) => {
  const culture = useMemoizedCulture(items);

  const apply = itemId => {
    dispatch(applyAgreement(itemId));
    dispatch(navigateTo(routesBuilder.forBasket()));
  };

  const cancel = itemId => dispatch(cancelAgreement(itemId));

  if (items.length === 0)
    return <p className="msg-block"><RichText textKey="NoSalesAgreementsFound" /></p>;

  const formatDate = getToLocaleDate(culture);

  let anyEffectiveDate, anyExpirationDate;

  const body = items.map(item => {
    if (!anyEffectiveDate)
      anyEffectiveDate = !!item.effectiveDate;

    if (!anyExpirationDate)
      anyExpirationDate = !!item.expirationDate;

    const isSelected = selectedAgreementId === item.id;

    return (
      <tr key={item.id}>
        <td aria-labelledby="headerId">
          {item.id}
          {item.title ? <span className={styles.title}> <br />{item.title}</span> : null}
        </td>
        <td aria-labelledby="headerEffective" data-empty={!item.effectiveDate}>{formatDate(item.effectiveDate)}</td>
        <td aria-labelledby="headerExpiration" data-empty={!item.expirationDate}>{formatDate(item.expirationDate)}</td>
        <td aria-labelledby="headerStatus"><SimpleText textKey={'Status_' + item.status}>{item.status}</SimpleText></td>
        <td className={styles.action}>
          {isSelected && item.status === 'Active'
            && <SanaButton textKey="Cancel" className={`${btnStyles.btnSmall} ${btnStyles.noIcon}`} onClick={() => cancel(item.id)} />
          }
          {!isSelected && item.status === 'Active'
            && <SanaButton textKey="Apply" className={`${btnStyles.btnSmall} ${btnStyles.btnAction} ${btnStyles.noIcon}`} onClick={() => apply(item.id)} />
          }
        </td>
        <td className={styles.link}>
          <Link
            url={item.url}
            to={routesBuilder.forSalesAgreement.bind(null, item.id)}
            options={{ backTo }}
            className={linkStyles.arrowed}
          >
            <SimpleText textKey="ViewDetails" />
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <Table className={styles.table}>
      <thead>
        <tr>
          <th id="headerId">
            <SimpleText textKey="ID" />
            /
            <span className={styles.title}><SimpleText textKey="Title" /></span>
          </th>
          <th id="headerEffective" data-empty={!anyEffectiveDate}>{anyEffectiveDate && <SimpleText textKey="EffectiveDate" />}</th>
          <th id="headerExpiration" data-empty={!anyEffectiveDate}>{anyExpirationDate && <SimpleText textKey="ExpirationDate" />}</th>
          <th id="headerStatus"><SimpleText textKey="Status" /></th>
          <th colSpan="2" />
        </tr>
      </thead>
      <tbody>
        {body}
      </tbody>
    </Table>
  );
};

Items.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    effectiveDate: PropTypes.any,
    expirationDate: PropTypes.any,
    status: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  selectedAgreementId: PropTypes.string,
  backTo: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
};

export default connect(({ salesAgreements }) => ({
  selectedAgreementId: salesAgreements.appliedSalesAgreementId,
}))(Items);

function useMemoizedCulture(items) {
  const culture = useCultureName();
  return useMemo(() => culture, [items]);
}
