import { memo } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import DomElement from './DomElement';
import { useExtras } from 'utils/layout';

const DomBody = ({ content }) => {
  const extras = useExtras();
  if (!extras)
    return null;

  return ReactDOM.createPortal(<DomElement content={content} />, extras);
};

DomBody.propTypes = {
  content: PropTypes.string.isRequired,
};

export default memo(DomBody);