import { ofType } from 'redux-observable';
import { exhaustMap, startWith, mergeMap } from 'rxjs/operators';
import { ORDER_AUTHORIZATION_ACTION_REQUESTED, orderAuthResultReceived } from '../actions';
import { orderAuthorizationMutations } from '../queries';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { of } from 'rxjs';
import { OrderAuthorizationActionResult, OrderAuthorizationAction } from '../constants';

export default (action$, _state$, dependencies) => action$.pipe(
  ofType(ORDER_AUTHORIZATION_ACTION_REQUESTED),
  exhaustMap(({ payload: { actionType, input } }) =>
    dependencies.api.graphApi(
      orderAuthorizationMutations[actionType],
      { input },
      { retries: 0 },
    ).pipe(
      mergeMap(result => {
        const { status, authorizationInfo } = result.documents.orderAuthorization[actionType];
        return of(
          orderAuthResultReceived(getDetailedAuthorizationResultStatus(actionType, status), authorizationInfo),
          unsetLoadingIndicator(),
        );
      }),
      retryWithToast(action$, dependencies.logger, () => of(unsetLoadingIndicator())),
      startWith(setLoadingIndicator())),
  ),
);

function getDetailedAuthorizationResultStatus(actionType, status) {
  const justApplied = status === OrderAuthorizationActionResult.Status.Applied;

  switch (actionType) {
    case OrderAuthorizationAction.Approve:
      return justApplied
        ? OrderAuthorizationActionResult.DetailedStatus.Approved
        : OrderAuthorizationActionResult.DetailedStatus.AlreadyApproved;
    case OrderAuthorizationAction.Decline:
      return justApplied
        ? OrderAuthorizationActionResult.DetailedStatus.Declined
        : OrderAuthorizationActionResult.DetailedStatus.AlreadyDeclined;
    case OrderAuthorizationAction.Cancel:
      return OrderAuthorizationActionResult.DetailedStatus.Cancelled;
    default:
      return null;
  }
}
