import { useMemo } from 'react';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { useAbilities } from 'components/objects/user';
import { PropTypes } from 'prop-types';

const abilityKeys = [
  AbilityTo.ViewPrices, AbilityTo.OrderProducts,
  AbilityTo.ViewStock, AbilityTo.ViewUnitOfMeasure,
];

export function useMatrixAbilities() {
  const { abilities } = useAbilities(abilityKeys);

  return useMemo(() => abilityKeys.reduce((seed, key, index) => {
    seed[key] = abilities[index] === AbilityState.Available;
    return seed;
  }, {}), abilities);
}

export const matrixAbilitiesProps = PropTypes.shape({
  [AbilityTo.ViewPrices]: PropTypes.bool.isRequired,
  [AbilityTo.OrderProducts]: PropTypes.bool.isRequired,
  [AbilityTo.ViewStock]: PropTypes.bool.isRequired,
}).isRequired;