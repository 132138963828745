import { Col, Container, Row } from 'components/primitives/grid';
import loadable from '@loadable/component';
import NewsTitle from './NewsTitle';
import NewsList from './NewsList';
import Sidebar from './SidebarNews';
import { PageComponentNames } from 'behavior/pages';
import { PageContent } from 'components/objects/pages';

function newsRenderer(page) {
  const header = page.content && page.content.header;
  const footer = page.content && page.content.footer;
  
  return (
    <>
      <PageContent content={header} position="header" />
      <Container>
        <Row>
          <Col xs={12} md={3}>
            <Sidebar />
          </Col>
          <Col as="section" xs={12} md={9}>
            <NewsTitle />
            <NewsList />
          </Col>
        </Row>
      </Container>
      <PageContent content={footer} position="footer" />
    </>
  );
}

const NewsDetail = loadable(() => import(/*webpackChunkName:"newsDetail"*/'./NewsDetailsObject'));
function newsItemRenderer() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={3}>
          <Sidebar />
        </Col>
        <Col as="article" xs={12} md={9}>
          <NewsDetail />
        </Col>
      </Row>
    </Container>
  );
}

export default {
  [PageComponentNames.News]: newsRenderer,
  [PageComponentNames.NewsDetails]: newsItemRenderer,
};