import { memo } from 'react';
import PropTypes from 'prop-types';
import LinkButton from './LinkButton';
import { useSanaButton } from 'components/primitives/buttons';
import { toPropTypes } from './helpers';

const SanaLinkButton = ({
  url,
  to,
  textKey,
  titleTextKey,
  defaultText,
  placeholder,
  className,
  ...attributes
}) => {
  const { text, title, loaded } = useSanaButton(textKey, defaultText, undefined, titleTextKey);
  if (!loaded)
    return placeholder || null;

  return <LinkButton url={url} to={to} className={className} {...attributes} title={title}>{text}</LinkButton>;
};

SanaLinkButton.propTypes = {
  url: PropTypes.string,
  to: toPropTypes,
  textKey: PropTypes.string.isRequired,
  titleTextKey: PropTypes.string,
  defaultText: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.node,
};

export default memo(SanaLinkButton);
