import styles from '../Order.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { OrderAuthorizationStatus } from 'behavior/pages/document';
import { useCultureName } from 'utils/hooks';
import { toLocaleDate, toLocaleTime } from 'utils/format';
import { SimpleText } from 'components/sanaText';
import { newLinesToHTMLBreaks } from '../helpers';
import CancelAuthorizationButton from './CancelAuthorizationButton';

const ProcessedAuthorization = ({ orderId, info }) => {
  const culture = useCultureName();

  const { status, authorizers, canAuthorize, feedbackMessage, authorizationDate } = info;
  const anyAuthorizers = !!authorizers?.length;
  const localeDate = toLocaleDate(authorizationDate, culture);
  const localeTime = toLocaleTime(authorizationDate, culture, true);
  const statusTextKey = status === OrderAuthorizationStatus.Approved
    ? 'OrderAuthorizationStatus_Approved'
    : 'OrderAuthorizationStatus_Declined';

  return (
    <>
      <span className={styles.authorizedByMsg}>
        <b><SimpleText textKey={statusTextKey} /></b>
        {!anyAuthorizers && <SimpleText textKey="OrderAuthorization_Authorized" formatWith={[localeDate, localeTime]} />}
        {anyAuthorizers && <SimpleText textKey="OrderAuthorization_AuthorizedBy" formatWith={[authorizers.join(', '), localeDate, localeTime]} />}
      </span>
      {canAuthorize && <CancelAuthorizationButton orderId={orderId} />}
      {feedbackMessage && (
        <>
          <div className={styles.authorizationFeedbackLabel}>
            <SimpleText textKey="OrderAuthorization_Feedback_Label" />
          </div>
          <div className={styles.authorizationFeedback}>
            {newLinesToHTMLBreaks(feedbackMessage)}
          </div>
        </>
      )}
    </>
  );
};

ProcessedAuthorization.propTypes = {
  orderId: PropTypes.string.isRequired,
  info: PropTypes.shape({
    canAuthorize: PropTypes.bool,
    authorizers: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.oneOf([OrderAuthorizationStatus.Approved, OrderAuthorizationStatus.Declined]),
    feedbackMessage: PropTypes.string,
    authorizationDate: PropTypes.string,
  }),
};

export default memo(ProcessedAuthorization);
