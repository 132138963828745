import { createContext } from 'react';

const defaultContext = {
  showImages: false,
  showPrices: false,
  showUom: false,
  currencyInfo: {},
  isMobile: false,
  lastModifiedLineId: null,
};

export default createContext(defaultContext);