exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Containers_content-box,.Containers_wrapper{position:relative}.Containers_content-box{height:100%;display:flex;flex-direction:column;border:0 solid transparent}.Containers_content-box.Containers_start{justify-content:flex-start!important}.Containers_content-box.Containers_center{justify-content:center!important}.Containers_content-box.Containers_end{justify-content:flex-end!important}.Containers_start .Containers_content-box{justify-content:flex-start}.Containers_center .Containers_content-box{justify-content:center}.Containers_end .Containers_content-box{justify-content:flex-end}.Containers_hero-row{order:-1}.Containers_hero-height{min-height:100vh}.vd-frame-auto-height .Containers_hero-height{min-height:360px!important}.Containers_auto{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"content-box": "Containers_content-box",
	"contentBox": "Containers_content-box",
	"wrapper": "Containers_wrapper",
	"start": "Containers_start",
	"center": "Containers_center",
	"end": "Containers_end",
	"hero-row": "Containers_hero-row",
	"heroRow": "Containers_hero-row",
	"hero-height": "Containers_hero-height",
	"heroHeight": "Containers_hero-height",
	"auto": "Containers_auto"
};