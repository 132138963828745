import PropTypes from 'prop-types';
import { ReturnReason } from '../../returnOrder';
import InnerLine from './InnerLine';

const ReturnReasonLine = ({ returnReason, lineTexts }) => {
  if (!returnReason)
    return null;

  return (
    <InnerLine
      value={<ReturnReason reason={returnReason} />}
      lineTexts={lineTexts}
    />
  );
};

ReturnReasonLine.propTypes = {
  returnReason: PropTypes.object,
  lineTexts: PropTypes.object.isRequired,
};

export default ReturnReasonLine;
