import styles from '../LoginInfo.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { MyAccountLink, Link } from 'components/primitives/links';
import { useCurrentRouteAsBackTo } from 'utils/hooks';
import { routesBuilder } from 'routes';
import { connect } from 'react-redux';
import LogoutButton from '../LogoutButton';

const LoginInfoSalesAgent = ({ name, logoutDelay }) => {
  const backTo = useCurrentRouteAsBackTo();

  return (
    <>
      <div className={styles.bodyHeader}>
        <span className="user-name"><SimpleText textKey="Login_Welcome" /> <b>{name}</b></span>
      </div>

      <Link to={routesBuilder.forRepresent} options={{ backTo }} className={styles.item}>
        <SimpleText textKey="ButtonText_RepresentCustomer" />
      </Link>
      <MyAccountLink className={styles.item} />
      <LogoutButton logoutDelay={logoutDelay} />
    </>
  );
};

LoginInfoSalesAgent.propTypes = {
  name: PropTypes.string.isRequired,
  logoutDelay: PropTypes.number,
};

export default connect(({ user }) => ({ name: user.name }))(LoginInfoSalesAgent);