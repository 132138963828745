import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useLoadEffect } from 'utils/hooks';
import { loadNavigation } from 'behavior/navigation';
import { shouldRenderNavigation } from './helpers';

export const useLoadNavigation = groupCode => {
  const items = useSelector(({ navigation }) => navigation[groupCode], shallowEqual);
  const dispatch = useDispatch();

  useLoadEffect(() => {
    if (!items)
      dispatch(loadNavigation(groupCode));
  }, [items]);

  return shouldRenderNavigation(items);
};