import { pageSize } from './queries';
import { filterExistingImages } from 'utils/helpers';

export const BASKET_ADD_PRODUCTS = 'BASKET_ADD_PRODUCTS';
export const addProducts = (lines, updatedById, agreementId = null) => ({
  type: BASKET_ADD_PRODUCTS,
  payload: { lines, updatedById, agreementId },
});

export const BASKET_UPDATED = 'BASKET_UPDATED';
export const basketUpdated = (updatedById, modifiedDate) => ({
  type: BASKET_UPDATED,
  payload: {
    modifiedDate,
    updatedById,
  },
});

export const BASKET_RECEIVED = 'BASKET_RECEIVED';
export const basketReceived = (basket, pageIndex = 0) => ({
  type: BASKET_RECEIVED,
  payload: {
    basket: filterImages(basket),
    page: {
      index: pageIndex,
      size: pageSize,
    },
  },
});

export const BASKET_PAGE_REQUESTED = 'BASKET_PAGE_REQUESTED';
export const reloadBasketPage = (shouldCountSubLines = false) => ({
  type: BASKET_PAGE_REQUESTED,
  payload: { countSubLines: shouldCountSubLines },
});
export const requestBasketPage = (pageIndex, shouldCountSubLines = false) => ({
  type: BASKET_PAGE_REQUESTED,
  payload: { index: pageIndex, countSubLines: shouldCountSubLines },
});

export const BASKET_SUMMARY_REQUESTED = 'BASKET_SUMMARY_REQUESTED';
export const requestBasketSummary = calculated => ({
  type: BASKET_SUMMARY_REQUESTED,
  payload: { calculated },
});

export const BASKET_SUMMARY_RECEIVED = 'BASKET_SUMMARY_RECEIVED';
export const basketSummaryReceived = basket => ({
  type: BASKET_SUMMARY_RECEIVED,
  payload: { basket: filterImages(basket) },
});

export const BASKET_UPDATE = 'BASKET_UPDATE';
export const modifyBasket = (modified = null, code = null, countSubLines = false, index) => ({
  type: BASKET_UPDATE,
  payload: { modified, code, countSubLines, index },
});
export const saveModifiedBasket = (modified, writeOnly = true) => ({
  type: BASKET_UPDATE,
  payload: { modified, writeOnly },
});

export const BASKET_CLEAR = 'BASKET_CLEAR';
export const clearBasket = (remove = false) => ({
  type: BASKET_CLEAR,
  payload: { remove },
});

/**
 * @typedef {Object} BroadcastPayload
 * @property {Date} modifiedDate
 * @property {number} language
 * @property {object} [basket]
 * @property {object} [summary]
 */

export const BASKET_BROADCAST = 'BASKET_BROADCAST';
/**
 * Creates action to notify another tabs about new basket data.
 * @param {BroadcastPayload} payload The payload.
 * @returns {object} The action.
 */
export const broadcastBasket = payload => ({ type: BASKET_BROADCAST, payload });

export const BASKET_ARRIVED = 'BASKET_ARRIVED';
export const basketArrived = basket => ({ type: BASKET_ARRIVED, payload: basket });

function filterImages(basket) {
  if (basket && basket.productLines && basket.productLines.list)
    for (const line of basket.productLines.list)
      if (line.product && line.product.images)
        line.product.images = filterExistingImages(line.product.images);

  return basket;
}
