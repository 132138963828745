exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductComparisonPage_actions-section{border-bottom:1px solid var(--tables_BorderColor,#e6e6e6);display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;padding:15px 0 2px}.ProductComparisonPage_breadcrumb-wrapper{display:none}.ProductComparisonPage_breadcrumb{margin-top:1em}@media (min-width:var(--breakpoints_Medium,960)px){html:not(.print) .ProductComparisonPage_breadcrumb-wrapper{display:flex}html:not(.print) .ProductComparisonPage_breadcrumb{margin-top:1.8em}}", ""]);

// exports
exports.locals = {
	"actions-section": "ProductComparisonPage_actions-section",
	"actionsSection": "ProductComparisonPage_actions-section",
	"breadcrumb-wrapper": "ProductComparisonPage_breadcrumb-wrapper",
	"breadcrumbWrapper": "ProductComparisonPage_breadcrumb-wrapper",
	"breadcrumb": "ProductComparisonPage_breadcrumb"
};