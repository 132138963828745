export const NEWS_HEADLINES_LOAD = 'NEWS_HEADLINES/LOAD';
export const loadNewsHeadlines = maxCount => ({
  type: NEWS_HEADLINES_LOAD,
  payload: { maxCount },
});

export const NEWS_HEADLINES_LOADED = 'NEWS_HEADLINES/LOADED';
export const newsHeadlinesLoaded = items => ({
  type: NEWS_HEADLINES_LOADED,
  payload: { items },
});