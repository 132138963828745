exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Rating_stars{font-size:.92em;white-space:nowrap}.Rating_stars svg{margin:0 2px;width:1em}.Rating_stars svg path{fill:#f7ad00}", ""]);

// exports
exports.locals = {
	"stars": "Rating_stars"
};