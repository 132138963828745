exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PageContent_footer,.PageContent_header,.PageContent_middle{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"footer": "PageContent_footer",
	"header": "PageContent_header",
	"middle": "PageContent_middle"
};