import styles from './OrderTemplates.module.scss';
import PropTypes from 'prop-types';
import { UomTitle } from 'components/primitives/product';

const LineTemplate = ({ idCell, titleCell, quantityCell, uom, ...attributes }) => (
  <tr {...attributes}>
    <td className={styles.idCell}>{idCell}</td>
    <td className={styles.titleCell}>{titleCell}</td>
    <td className={`${styles.quantityCell} text-center`}>{quantityCell}</td>
    {uom !== undefined && <td className={`${styles.uomCell} text-right`}>{uom && <UomTitle id={uom.id} description={(uom.description) || ''} />}</td>}
  </tr>
);

LineTemplate.propTypes = {
  idCell: PropTypes.node,
  titleCell: PropTypes.node,
  quantityCell: PropTypes.node,
  uom: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default LineTemplate;