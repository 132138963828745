import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from '../hooks';
import { makeSimpleText, makeRichText } from 'utils/render';

/**
 * Component which provides array of desired Sana texts to it's children function.
 * @returns {React.ReactNode} - children function with passed Sana texts array.
 */
const UseSanaTexts = ({
  children,
  textKeys,
  asHtml = false,
  dontWait,
  placeholder = null,
}) => {
  const { texts, loaded } = useSanaTexts(textKeys, asHtml ? makeRichText : makeSimpleText);

  return useMemo(() => {
    if (!dontWait && !loaded)
      return placeholder;

    if (!children)
      return null;

    return children(texts) || null;
  }, [children, texts, dontWait]);
};

UseSanaTexts.propTypes = {
  children: PropTypes.func,
  textKeys: PropTypes.arrayOf(PropTypes.string),
  asHtml: PropTypes.bool,
  dontWait: PropTypes.bool,
  placeholder: PropTypes.node,
};

export default UseSanaTexts;