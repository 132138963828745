import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import btnStyles from './Button.module.scss';
import SanaButton from './SanaButton';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';

const LoadMoreButton = ({ textKey, className = '', totalCount, loadedCount, size, loadNext }) => {
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
    return () => setTimeout(toggleOverflowAnchorState, 50);
  }, [loadedCount]);

  const nextIndex = loadedCount < totalCount &&
    Math.floor(loadedCount / size);

  if (!nextIndex)
    return null;

  return (
    <SanaButton textKey={textKey}
      className={`${btnStyles.btnLoadMore} ${isLoading ? btnStyles.loading : ''} ${className}`}
      formatWith={[size]}
      onClick={() => {
        setLoading(true);
        toggleOverflowAnchorState(true);
        loadNext(nextIndex);
      }}
    />
  );
};

LoadMoreButton.propTypes = {
  textKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  loadedCount: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  loadNext: PropTypes.func.isRequired,
};

export default memo(LoadMoreButton);