import { APP_STATE_UPDATED } from './actions';

export const initialState = {
  offlineMode: false,
  loaded: false,
};

export default function reducer(state = initialState, action) {
  if (action.type === APP_STATE_UPDATED)
    return { ...state, error: false, ...action.payload, loaded: true };

  return state;
}
