import payPalImage from './images/paypal.png';
import masterCardImage from './images/mastercard.png';
import visaImage from './images/visa.png';

const ColumnFooter = () => {
  return (
    <div>
      <span>Our payment methods</span>
      <div>
        <img src={payPalImage} alt="PayPal" />&nbsp;
        <img src={masterCardImage} alt="MasterCard" />&nbsp;
        <img src={visaImage} alt="Visa" />
      </div>
    </div>
  );
};

export default ColumnFooter;
