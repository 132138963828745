import { NEWS_HEADLINES_LOADED } from './actions';
import { LANGUAGE_CHANGED } from 'behavior/events';

const initialState = {
  items: undefined,
  loaded: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LANGUAGE_CHANGED:
      return { ...state, loaded: false };
    case NEWS_HEADLINES_LOADED:
      return { ...state, ...action.payload, loaded: true };
    default:
      return state;
  }
}