import { UseSanaTexts } from 'components/sanaText';
import PropTypes from 'prop-types';
import { makeRichText } from 'utils/render';

const TextRow = ({ textKey }) => (
  <UseSanaTexts options={[textKey]}>
    {([msg]) => msg && <div className="fr-view">{makeRichText(msg)}</div>}
  </UseSanaTexts>
);

TextRow.propTypes = {
  textKey: PropTypes.string.isRequired,
};

export default TextRow;