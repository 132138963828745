exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LastViewedProducts_clear-viewed,.LastViewedProducts_view-all{display:none}@media (min-width:var(--breakpoints_Small,600)px),print{.LastViewedProducts_clear-viewed,.LastViewedProducts_view-all{display:inline-block;font-weight:700;margin:.93em 0 0 2.81em}.LastViewedProducts_placeholder{display:inline-block;width:3.8em;vertical-align:bottom}.LastViewedProducts_clear-viewed{padding:.31em}}", ""]);

// exports
exports.locals = {
	"clear-viewed": "LastViewedProducts_clear-viewed",
	"clearViewed": "LastViewedProducts_clear-viewed",
	"view-all": "LastViewedProducts_view-all",
	"viewAll": "LastViewedProducts_view-all",
	"placeholder": "LastViewedProducts_placeholder"
};