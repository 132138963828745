import styles from './ImageBlock.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
import { Link } from 'components/primitives/links';

const ImageWithLink = ({ linkUrl, linkTarget, ...props }) => (
  <Link className={styles.link} url={linkUrl} target={linkTarget}>
    <Image {...props} />
  </Link>
);

ImageWithLink.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  target: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,
  linkTarget: PropTypes.string,
};

export default memo(ImageWithLink);