import styles from './LanguageSelector.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import Body from './Body';
import { Placeholder } from 'components/primitives/placeholders';

const LanguageSelectorContent = ({
  alternateUrls,
  sortedLanguages,
  selectedLanguage,
  isMobile,
}) => {
  return alternateUrls && alternateUrls.length
    ? (
      <Body
        languages={sortedLanguages}
        alternateUrls={alternateUrls}
        selectedLanguage={selectedLanguage}
        className={isMobile ? styles.mobileItem : ''}
      />
    )
    : (
      <div className={`${styles.placeholder}${isMobile ? ` ${styles.mobile}` : ''}`}>
        <Placeholder lineCount={sortedLanguages.length} className={styles.placeholderItem} />
      </div>
    );
};

LanguageSelectorContent.propTypes = {
  alternateUrls: PropTypes.array,
  sortedLanguages: PropTypes.array,
  selectedLanguage: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default memo(LanguageSelectorContent);
