import { mergeMap, startWith, catchError, pluck, exhaustMap } from 'rxjs/operators';
import { of, EMPTY, merge } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { postForm } from 'behavior/pages';
import { toasts } from 'behavior/toasts';
import { renderHTML } from 'utils/render';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import {
  PAY_ORDER,
  PAYMENT_METHOD_EXTRA_DATA_REQUESTED,
  receivePayOrderResult,
  receivePaymentMethodExtraData,
} from './actions';
import { submitOrderMutation, paymentMethodExtraDataQuery } from './queries';

export default (action$, _, { api, logger }) => {
  const payOrder$ = action$.pipe(
    ofType(PAY_ORDER),
    pluck('payload'),
    exhaustMap(({ transactionId, paymentInput, additionalCustomerData, extraPaymentData }) =>
      api.graphApi(submitOrderMutation, getSubmitParams(transactionId, paymentInput, additionalCustomerData, extraPaymentData), { retries: 0 }).pipe(
        mergeMap(({ orderPayment }) => {
          if (!orderPayment)
            return of(receivePayOrderResult({ error: true }), unsetLoadingIndicator());

          return handleOrderPaymentResult(orderPayment.submit, { logger });
        }),
        catchError(error => {
          logger.error(error);

          return of(receivePayOrderResult({ error: true }), unsetLoadingIndicator());
        }),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

  const paymentMethodExtraData$ = action$.pipe(
    ofType(PAYMENT_METHOD_EXTRA_DATA_REQUESTED),
    pluck('payload'),
    mergeMap(({ transactionId, paymentMethodId }) => api.graphApi(paymentMethodExtraDataQuery, { transactionId, paymentMethodId }).pipe(
      pluck('orderPayment', 'paymentMethods', 'byId'),
      mergeMap(({ additionalCustomerDataStep, extraPaymentCheckoutStep }) => of(receivePaymentMethodExtraData(paymentMethodId, additionalCustomerDataStep, extraPaymentCheckoutStep), unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
  );

  return merge(payOrder$, paymentMethodExtraData$);
};

function getSubmitParams(transactionId, paymentInput, additionalCustomerData, extraPaymentData) {
  const input = { transactionId, ...paymentInput };

  if (additionalCustomerData)
    input.additionalCustomerData = { values: additionalCustomerData };

  if (extraPaymentData)
    input.extraPaymentData = { values: extraPaymentData };

  return { input };
}

function handleOrderPaymentResult({ type, ...result }, dependencies) {
  switch (type) {
    case 'SuccessOrderPaymentResult':
      return handleSuccessOrderPaymentResult(result, dependencies);
    case 'FailureOrderPaymentActionResult':
      return handleFailureOrderPaymentActionResult(result, dependencies);
    default:
      dependencies.logger.error(`'${type}' order payment action result is not handled.`, result);
      throw new Error('Unexpected order payment result.');
  }
}

function handleSuccessOrderPaymentResult(result, dependencies) {
  const { transaction, nextAction } = result;

  if (nextAction)
    return handleNextAction(nextAction, dependencies);

  if (transaction.cancelled)
    return of(navigateTo(routesBuilder.forPaymentCancelled(transaction.id)));

  if (transaction.failed)
    return of(navigateTo(routesBuilder.forPaymentFailed(transaction.id)));

  if (transaction.isPaymentError)
    return of(navigateTo(routesBuilder.forPaymentError(transaction.id)));

  return of(navigateTo(routesBuilder.forPaymentSubmit(transaction.id)));
}

function handleFailureOrderPaymentActionResult(result) {
  const { reason, orderId } = result;

  return of(receivePayOrderResult({ error: { reason, orderId } }), unsetLoadingIndicator());
}

function handleNextAction({ type, ...nextAction }, dependencies) {
  switch (type) {
    case 'RedirectAction':
      return handleRedirectAction(nextAction);
    case 'PostAction':
      return handlePostAction(nextAction);
    default:
      dependencies.logger.error(`'${type}' next action is not handled.`, nextAction);
      throw new Error('Unexpected next action.');
  }
}

function handlePostAction(postAction) {
  return of(postForm(postAction));
}

function handleRedirectAction({ message, isHtmlMessage, url }) {
  message && toasts.info(isHtmlMessage ? renderHTML(message) : message, { autoDismiss: false });
  window.location.href = url;

  return EMPTY;
}