import styles from './BasketB2C.module.scss';
import { memo } from 'react';
import Basket from './Basket';
import PageTemplate from '../PageTemplate';

const Template = () => (
  <PageTemplate
    headerTextKey="BasketPage_B2C_Header"
    footerTextKey="BasketPage_B2C_Footer"
    className={styles.basket}
  >
    <Basket />
  </PageTemplate>
);

export default memo(Template);
