import styles from './ProductSelector.module.scss';
import { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { escapeRegexCharacters } from 'utils/helpers';
import { debounce } from 'lodash';
import { Autosuggest, highlightStringBySpans } from 'components/primitives/autosuggest';
import { useOnCustomerChanged, useOnLanguageChanged } from 'utils/hooks';
import SuggestionItem from './SuggestionItem';
import SuggestionsContainer from './SuggestionsContainer';
import { SearchIcon } from 'components/primitives/icons';

const SearchBox = ({
  texts,
  id,
  productLoadingState,
  suggestions,
  selectedProduct,
  onProductSearch,
  onProductSelect,
  onProductClear,
}) => {
  const [productLoading, setProductLoading] = productLoadingState;
  const { searchBoxPlaceholder, productNotFoundText } = texts;
  const [value, setValue] = useState('');
  const [productNotFound, setProductNotFound] = useState(false);

  const refsState = useRef({}).current;
  const [showSuggestions, setShowSuggestions] = useState(true);

  const highlightedSuggestions = useMemo(() => {
    refsState.suggestionsAreLoading = false;

    return getSuggestions(value, suggestions);
  }, [suggestions]);

  const search = useMemo(() => debounce(value => onProductSearch(value), 250), [onProductSearch]);

  const selectProduct = id => {
    refsState.isDirty = false;
    setShowSuggestions(false);

    setProductLoading(true);
    onProductSelect(id);
  };

  useOnCustomerChanged(() => {
    setValue('');
    setProductNotFound(false);
  });

  useOnLanguageChanged(() => {
    if (!selectedProduct)
      return;

    setProductLoading(true);
    onProductSelect(selectedProduct.id);
  });

  useEffect(() => {
    if (!refsState.waitingForResult || refsState.suggestionsAreLoading)
      return;

    refsState.waitingForResult = false;

    if (suggestions.length !== 0)
      selectProduct(suggestions[0].id);

    setProductNotFound(suggestions.length === 0 && value.length !== 0);

    if (suggestions.length === 0)
      setProductLoading(false);

  }, [suggestions, refsState.suggestionsAreLoading]);

  const onFetchRequested = ({ value, reason }) => {
    if (reason === 'input-changed' && value.trim().length > 0) {
      setShowSuggestions(true);
      refsState.suggestionsAreLoading = true;
      search(value);
    }
  };

  const onSelected = (e, { suggestion }) => {
    e.preventDefault();
    setProductNotFound(false);
    setValue(`${suggestion.id} - ${suggestion.title}`);
    selectProduct(suggestion.id);
  };

  const onHighlighted = ({ suggestion }) => {
    refsState.highlightedSuggestion = suggestion;
  };

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!refsState.isDirty)
      return;

    if (value.length && !value.trim().length)
      return;

    onProductClear();

    if (!value.length) {
      setProductNotFound(false);
      setShowSuggestions(false);
      return;
    }

    if (refsState.suggestionsAreLoading) {
      setProductLoading(true);
      refsState.waitingForResult = true;
      return;
    }

    refsState.waitingForResult = false;

    if (suggestions.length !== 0)
      selectProduct(suggestions[0].id);
    else
      setProductLoading(false);

    setProductNotFound(suggestions.length === 0);
  };

  const onChange = (_event, { newValue }) => {
    setValue(newValue);
    if (productLoading)
      setProductLoading(false);

    refsState.waitingForResult = false;
    refsState.isDirty = true;
  };

  const onBlur = () => {
    if (!value && productNotFound)
      setProductNotFound(false);
  };

  const onKeyDown = e => {
    if (e.key === 'Tab' || e.which === 9) {
      if (refsState.highlightedSuggestion) {
        onSelected(e, { suggestion: refsState.highlightedSuggestion });
        e.preventDefault();
        return;
      }

      const formInputsSelector = `.${styles.variantSelector} button,` +
        `.${styles.quantity} input,` +
        `.${styles.uomSelector} button,` +
        `.${styles.actions} button`;

      const firstFocusableElement = document.querySelector(formInputsSelector);
      if (firstFocusableElement) {
        firstFocusableElement.focus();
        e.preventDefault();
      }
    }
  };

  const inputProps = {
    id,
    placeholder: searchBoxPlaceholder,
    value,
    onChange,
    onBlur,
    onKeyDown,
    type: 'search',
  };

  return (
    <>
      <form onSubmit={onSubmit} className={styles.search}>
        <Autosuggest
          suggestions={showSuggestions ? highlightedSuggestions : []}
          onFetchRequested={onFetchRequested}
          onClearRequested={() => setShowSuggestions(false)}
          onSelected={onSelected}
          onHighlighted={onHighlighted}
          getItemValue={suggestion => suggestion.id}
          renderItem={suggestion => <SuggestionItem text={suggestion.highlightedText} />}
          renderItemsContainer={SuggestionsContainer}
          inputProps={inputProps}
          theme={styles}
        />
        <span className={styles.icon} aria-hidden>
          <SearchIcon />
        </span>
      </form>
      {productNotFound && !productLoading && (
        <div className="msg-block">{productNotFoundText}</div>
      )}
    </>
  );
};

SearchBox.propTypes = {
  texts: PropTypes.shape({
    searchBoxPlaceholder: PropTypes.string,
    productNotFoundText: PropTypes.node,
  }).isRequired,
  id: PropTypes.string.isRequired,
  productLoadingState: PropTypes.array,
  suggestions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  selectedProduct: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  onProductSearch: PropTypes.func.isRequired,
  onProductSelect: PropTypes.func.isRequired,
  onProductClear: PropTypes.func.isRequired,
};

export default SearchBox;

function getSuggestions(value, products) {
  const escapedValue = escapeRegexCharacters(value.trim());
  const regex = new RegExp(`(${escapedValue})`, 'gi');

  return products
    .map(product => ({
      ...product,
      highlightedText: highlightStringBySpans(`${product.id} - ${product.title}`, regex, styles.highlight),
    }));
}
