import styles from '../SubAccount.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { VerticalSliding } from 'components/primitives/transitions';
import { useSimpleTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { useFormikContext } from 'formik';
import LimitsFieldArray from './LimitsFieldArray';
import { MediumArrowUpIcon, MediumArrowDownIcon } from 'components/primitives/icons';

const textKeys = [
  'SpendingLimitsAndAuthorizer',
  'ButtonText_RemoveAll',
  /*preload texts*/
  'ButtonText_Add',
  'LessOrEqual',
];

const Limits = ({ authorizers }) => {
  const [expanded, setExpanded] = useState(true);
  const toggleExpand = () => setExpanded(!expanded);
  const {
    values,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    errors: { limits: limitErrors },
  } = useFormikContext();
  const [spendingLimitsAndAuthorizeText, buttonRemoveAllText] = useSimpleTexts(textKeys).texts;

  useEffect(() => {
    if (!isSubmitting)
      return;

    if (limitErrors)
      setExpanded(true);

  }, [isSubmitting]);

  const collapsible = values.limits.length > 1;
  if (!collapsible && !expanded)
    setExpanded(true);

  const limitsTogglerId = 'subaccountLimitsToggler';
  const limitsSectionId = 'subaccountLimitsSection';

  const handleRemoveAll = () => {
    setFieldValue('limits', [], false);
    setFieldTouched('limits', false);
  };

  const TogglerIcon = expanded ? MediumArrowUpIcon : MediumArrowDownIcon;

  return (
    <div className={styles.rules}>
      <div className={styles.header}>
        <span className={styles.label}>
          <button
            type="button"
            onClick={toggleExpand}
            className={styles.toggler}
            disabled={!collapsible}
            aria-controls={limitsSectionId}
            id={limitsTogglerId}
          >
            <span className={styles.title}>{spendingLimitsAndAuthorizeText}</span>
            {collapsible &&
              <span className={styles.expandIcon}>
                <TogglerIcon aria-hidden />
              </span>
            }
          </button>
        </span>
        {collapsible &&
          <button
            type="button"
            className={`${styles.removeAllBtn} ${linkStyles.link}`}
            onClick={handleRemoveAll}
          >
            {makeSimpleText(buttonRemoveAllText)}
          </button>
        }
      </div>
      <VerticalSliding expanded={expanded || !collapsible} id={limitsSectionId} containerClass={styles.limitsSlider}>
        <LimitsFieldArray limits={values.limits} authorizers={authorizers} labelledBy={limitsTogglerId} />
      </VerticalSliding>
    </div>
  );
};

Limits.propTypes = {
  authorizers: PropTypes.object.isRequired,
};

export default Limits;

