import styles from './ImageBlock.module.scss';
import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
import { usePrintMode } from 'utils/hooks';
import { FullScreenPopup } from 'components/objects/fullScreenPopup';

const ImageWithPreview = props => {
  const isPrintMode = usePrintMode();
  const [show, setShow] = useState(false);
  const { src, alt, title, className = '' } = props;

  return (<>
    <Image {...props} onClick={() => !isPrintMode && setShow(true)} className={`${styles.previewThumbnail} ${className}`} />
    {show &&
      <FullScreenPopup hide={() => setShow(false)}>
        <img src={src} alt={alt} title={title} className={styles.preview} />
      </FullScreenPopup>
    }
  </>);
};

ImageWithPreview.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
};

export default memo(ImageWithPreview);