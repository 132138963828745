import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { StyleContext } from 'isomorphic-style-loader';
import { primaryStyleSheets } from './constants';
import { noop } from 'rxjs';

const StyleProvider = ({ children, insertCss: _insertCss }) => {
  const { version, values: themeValues } = useSelector(state => state.theme);

  const context = useMemo(() => {
    if (!version)
      return { insertCss: noop };

    const prepareCss = replaceCss.bind(null, themeValues || {});
    return {
      insertCss: styles => _insertCss(styles, { prefix: version, prepareCss, priorities: primaryStyleSheets }),
    };
  }, [version, themeValues]);

  return (
    <StyleContext.Provider value={context}>
      {children}
    </StyleContext.Provider>
  );
};

StyleProvider.propTypes = {
  children: PropTypes.node.isRequired,
  insertCss: PropTypes.func.isRequired,
};

export default StyleProvider;

const variableRegex = /var\(--(?<name>[^,\s]+),\s*(?<defaultValue>[^)]+)\)/g;

function replaceCss(themeValues, css) {
  return css.replace(variableRegex, (_, name, defaultValue) => themeValues[name] || defaultValue);
}
