import { useRef } from 'react';
import PropTypes from 'prop-types';
import { PasswordField } from '../fields';
import PasswordStrengthIndicator, { textsToPreload } from '../passwordStrength';
import { useSelector, shallowEqual } from 'react-redux';
import FormGroup from './FormGroup';
import { useSimpleTexts } from 'components/sanaText';
import { getIn, useFormikContext } from 'formik';

const NewPasswordFormGroup = ({ fieldName, fieldTitle, additionalValidation, userInputs, userData, ...attributes }) => {
  const profileSettings = useSelector(state => state.settings.profile, shallowEqual);
  const { minPasswordLength, passwordStrengthScore: minimumScore } = profileSettings || {};

  useSimpleTexts(textsToPreload);

  const validation = {
    required: { allowWhitespace: true },
    stringLength: { min: minPasswordLength || 0, max: 30 },
    ...additionalValidation,
  };

  return (
    <FormGroup
      fieldName={fieldName}
      fieldTitle={fieldTitle}
      fieldComponent={PasswordFieldWrapper}
      validation={validation}
      minimumScore={minimumScore}
      userInputs={userInputs}
      userData={userData}
      autoComplete="off"
      maxLength={30}
      required
      {...attributes}
    />
  );
};

NewPasswordFormGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldTitle: PropTypes.string,
  additionalValidation: PropTypes.object,
  userInputs: PropTypes.arrayOf(PropTypes.string.isRequired),
  userData: PropTypes.arrayOf(PropTypes.string),
};

export default NewPasswordFormGroup;

function PasswordFieldWrapper({ minimumScore, userInputs, userData, validation, ...fieldProps }) {
  const passwordRef = useRef();
  const { values } = useFormikContext();
  const fromUserInputs = getUserInputs(userInputs, values).concat(userData).filter(Boolean);

  validation = {
    passwordStrength: { minimumScore, userInputs: fromUserInputs },
    ...validation,
  };

  return (
    <>
      <PasswordField {...fieldProps} validation={validation} innerRef={passwordRef} />
      <PasswordStrengthIndicator
        password={getIn(values, fieldProps.fieldName)}
        minimumScore={minimumScore}
        inputRef={passwordRef}
        userInputs={fromUserInputs}
      />
    </>
  );
}

function getUserInputs(userInputs, values) {
  const fromUserInputs = [];
  userInputs && userInputs.forEach(userInput => {
    const inputValue = getIn(values, userInput);
    if (!inputValue)
    return;
    
    fromUserInputs.push(inputValue);
    
    const splitInputValue = inputValue.includes(' ') && inputValue.split(' ');
    splitInputValue && splitInputValue.forEach(val => {
      fromUserInputs.push(val);
    });
  });
  return fromUserInputs;
}