import styles from './EditProfile.module.scss';
import { useMemo } from 'react';
import { SanaForm, EntityTemplateFields, useFormLockerOnSubmit, extractEscapedTemplateFields } from 'components/objects/forms';
import { FormLockKeys } from 'behavior/pages';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { editProfile } from 'behavior/pages/editProfile';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const Form = ({ templateFields, customerId }) => {
  const dispatch = useDispatch();
  const onSubmit = useFormLockerOnSubmit(data => {
    const editData = createEditData(customerId, data, templateFields);
    dispatch(editProfile(editData));
  }, FormLockKeys.EditProfile, [templateFields]);

  const initialStatus = useMemo(() => {
    const res = { defaultValues: {} };
    for (const field of templateFields)
      if (field.defaultValue !== undefined)
        res.defaultValues[field.name] = field.defaultValue;

    return res;
  }, [templateFields]);

  return (
    <SanaForm
      name="EditProfile"
      onSubmit={useHandlerLockerInPreview(onSubmit)}
      initialStatus={initialStatus}
      className={styles.form}
    >
      <EntityTemplateFields applyNamesEscaping fields={templateFields} />
      {hasEditableFields(templateFields) &&
        <SanaForm.SubmitRow textKey="Save" />
      }
    </SanaForm>
  );
};

Form.propTypes = {
  templateFields: PropTypes.array.isRequired,
  customerId: PropTypes.string,
};

export default Form;

const createEditData = (customerId, formData, templateFields) => {
  const customerData = extractEscapedTemplateFields(formData, templateFields);
  return { ...customerData, id: customerId };
};

const hasEditableFields = templateFields => {
  return templateFields.some(field => field.type === 'EditableTemplateField');
};
