import { PASSWORD_CHANGED } from './actions';

export default function (state, action) {
  if (action.type !== PASSWORD_CHANGED)
    return state;

  return {
    ...state,
    passwordChanged: Date.now(),
  };
}