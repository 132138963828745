import { from, Subject, zip } from 'rxjs';
import { tap, takeUntil, scan, filter } from 'rxjs/operators';
import { StateObservable } from 'redux-observable';
import { requestAddon, ADDON_LOADED, ADDON_LOAD_FAILED } from './actions';
import { logger } from 'utils/logs';

export default function preload(store, registry, epic, addonIds) {
  const state = store.getState();
  const metadata = state.addons.metadata;
  if (!metadata) {
    logger.error('Add-ons metadata must be available before preloading add-ons.');
    return Promise.resolve();
  }

  const bundleAddonIds = addonIds.filter(id => metadata[id].bundle);
  if (!bundleAddonIds.length)
    return Promise.resolve();

  return new Promise((resolve, reject) => {
    const actions = bundleAddonIds.map(id => requestAddon(id, metadata[id].bundle.hash));
    let loading = 0;
    const processedSubject = new Subject();
    const processed$ = processedSubject.pipe(scan(total => total + 1, 0));
    const loaded$ = registry.updates.pipe(scan(total => total + 1, 0));

    const action$ = from(actions);
    const state$ = new StateObservable(new Subject(), state);
    epic(action$, state$, {}).pipe(
      tap(action => {
        if (action.type === ADDON_LOADED) {
          const { id, hash, addon } = action.payload;
          loading++;
          processedSubject.next(id);
          registry.add(id, hash, addon);
        } else if (action.type === ADDON_LOAD_FAILED) {
          processedSubject.next(action.payload.id);
          store.dispatch(action);
        } else {
          store.dispatch(action);
        }
      }),
      takeUntil(zip(processed$, loaded$).pipe(
        filter(([processed, loaded]) => processed === bundleAddonIds.length && loaded === loading),
      )),
    ).subscribe({
      complete: resolve,
      error: reject,
    });
  });
}