import styles from './Represent.module.scss';
import inputStyles from 'components/primitives/form/Input.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { useSanaTexts } from 'components/sanaText';
import { BackTo } from 'components/primitives/links';
import { LoadMoreButton } from 'components/primitives/buttons';
import debounce from 'lodash/debounce';
import { useDispatch, connect } from 'react-redux';
import { requestCustomers } from 'behavior/pages/representation';
import { resetImpersonationFailed } from 'behavior/user';
import RepresentationTable from './Table';
import { useOnChange } from 'utils/hooks';
import { routesBuilder } from 'routes';
import { DangerAlert } from 'components/primitives/alerts';
import { Link } from 'components/primitives/links';
import { makeRichText, makeSimpleText } from 'utils/render';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import { SearchIcon } from 'components/primitives/icons';

const size = 10;

const Represent = ({ customers: { total, list, keywords }, backTo, impersonationFailed }) => {
  const {
    texts: [
      introText,
      placeholderText,
      representationFailureText,
      contactLinkText,
      contactLinkTitle,
    ],
    loaded: textsLoaded,
  } = useSanaTexts([
    'RepresentCustomer_Intro',
    'RepresentCustomer_Watermark',
    'Representation_FailureText',
    'Representation_FailureText_Contact',
    'ContactUs',
  ]);
  const dispatch = useDispatch();
  const searchRef = useRef();

  const onChange = useMemo(() => {
    const debounced = debounce(value => {
      dispatch(requestCustomers(value));
    }, 300, { trailing: true });

    return e => {
      const value = e.target.value;
      debounced(value);
    };
  }, []);

  useEffect(() => () => {
    dispatch(resetImpersonationFailed());
  }, []);
  useOnChange(() => void (searchRef.current.value = keywords), [keywords], false);

  const impersonationFailedAlert = useMemo(() => {
    if (!impersonationFailed)
      return null;

    const contactUsLink = (
      <Link
        to={routesBuilder.forContactUs}
        title={stripHtmlTagsAndFormatting(contactLinkTitle)}
      >
        {makeSimpleText(contactLinkText)}
      </Link>
    );

    return (
      <DangerAlert scrollOnAppear>
        {makeRichText(representationFailureText, [contactUsLink])}
      </DangerAlert>
    );
  }, [impersonationFailed, contactLinkText, contactLinkTitle, representationFailureText]);

  return (
    <>
      {textsLoaded && <div>{makeSimpleText(introText)}</div>}
      <div className={styles.panel}>
        {textsLoaded &&
          <Row className={styles.searchWrapper} noGutters>
            <Col xs={12} sm={8} xl={7}>
              <div className={inputStyles.tbx}>
                <input
                  ref={searchRef}
                  type="search"
                  placeholder={makeSimpleText(placeholderText)}
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  className={styles.search}
                />
                <span className={styles.icon} aria-hidden>
                  <SearchIcon />
                </span>
              </div>
            </Col>
          </Row>
        }
        {impersonationFailedAlert}
        {list.length > 0 &&
          <div>
            <RepresentationTable list={list} />
          </div>
        }
        <div className="panel-footer">
          <Row>
            <Col>
              <div className={styles.backToWrapper}>
                <BackTo
                  className={`${btnStyles.font} ${styles.arrowedBack} ${linkStyles.arrowedBack}`}
                  texts={['ButtonText_Back']}
                  to={backTo ? backTo.routeData : routesBuilder.forHome}
                  url={backTo && backTo.url}
                />
              </div>
            </Col>
            <Col className="text-right">
              <LoadMoreButton
                textKey="ShowNextNCustomers"
                className={`${styles.showNext} ${btnStyles.btnBig}`}
                totalCount={total}
                loadedCount={list.length}
                size={size}
                loadNext={nextIndex => dispatch(requestCustomers(keywords, nextIndex, size))}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

Represent.selectPropsFromPage = ({ customers, backTo }) => ({ customers, backTo });

Represent.propTypes = {
  customers: PropTypes.shape({
    total: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    keywords: PropTypes.string,
  }).isRequired,
  backTo: PropTypes.shape({
    url: PropTypes.string,
    routeData: PropTypes.object,
  }),
  impersonationFailed: PropTypes.bool,
};

export default connect(({ user: { impersonationFailed } }) => ({ impersonationFailed }))(Represent);

function onKeyDown(e) {
  if (e.key === 'Escape' || e.key === 'Esc')
    e.target.value = '';
}
