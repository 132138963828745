import { catchError, first, mergeMapTo, takeUntil, filter } from 'rxjs/operators';
import { RETRY_FAILED_REQUESTS, CLOSE_ERROR_TOAST, showErrorToast } from './actions';
import { concat, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { errorToasts } from './constants';

export default function retryWithToast(action$, logger, getContinueWith) {
  return catchError((error, observable) => {
    logger.error('The following error occurred ', error);

    const reset$ = action$.pipe(
      ofType(CLOSE_ERROR_TOAST),
      filter(({ payload: { type } }) => type === errorToasts.Retry),
    );

    const retry$ = action$.pipe(
      ofType(RETRY_FAILED_REQUESTS),
      first(),
      takeUntil(reset$),
      mergeMapTo(observable),
    );

    if (!getContinueWith)
      return concat(of(showErrorToast(errorToasts.Retry)), retry$);

    return concat(of(showErrorToast(errorToasts.Retry)), getContinueWith(error), retry$);
  });
}
