import PropTypes from 'prop-types';
import { useBooleanOptions } from '../fields/hooks';
import RadioFormGroup from './RadioFormGroup';

const BooleanRadioFormGroup = ({ fieldName, ...additionalProps }) => {
  const items = useBooleanOptions(fieldName);

  return (
    <RadioFormGroup
      fieldName={fieldName}
      items={items}
      {...additionalProps}
    />
  );
};

BooleanRadioFormGroup.propTypes = {
  fieldName: PropTypes.string,
};

export default BooleanRadioFormGroup;