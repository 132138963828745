export default context => value => {
  const { allowWhitespace, validateAgainstFalse } = context.params;

  if (validateAgainstFalse && value === false)
    return context.errorMessage;

  if (typeof value !== 'string' && value != null)
    return;

  if (value && value.length && (allowWhitespace || value.trim()))
    return;

  return context.errorMessage;
};
