export default function parseQuery(queryString: string): Record<string, string> {
  const query: Record<string, string> = {};
  const regex = /([^?=&]+)=([^&]*)/g;

  let match;
  while ((match = regex.exec(queryString)) !== null)
    query[match[1]] = match[2];

  return query;
}
