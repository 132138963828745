import styles from './Copyright.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';

const Copyright = ({ shopName }) => (
  <p className={styles.copyright}>
    <SimpleText textKey="Footer_Copyright" formatWith={[new Date().getFullYear(), shopName]} />
  </p>
);

Copyright.propTypes = {
  shopName: PropTypes.string,
};

export default memo(Copyright);