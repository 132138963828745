import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { map } from 'rxjs/operators';
import { paymentCancelledQuery } from './queries';

export default (routeData, _state$, { api }) => {
  const { params: { transactionId } } = routeData;

  if (!transactionId)
    return of(null);

  return api.graphApi(paymentCancelledQuery, { id: transactionId }).pipe(
    map(({ paymentTransaction: transaction }) => {
      if (transaction == null)
        return null;

      return ({
        page: {
          component: PageComponentNames.PaymentCancelled,
          transaction,
        },
      });
    }),
  );
};
