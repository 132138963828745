import styles from './NewsOverview.module.scss';
import { useLoadEffect } from 'utils/hooks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadNews } from 'behavior/pages/news';
import NewsListItem from './NewsListItem';
import { SimpleText } from 'components/sanaText';

const NewsList = ({ news, loadNews, culture }) => {
  useLoadEffect(() => {
    if (!news)
      loadNews(0, 0);
  });

  return (
    <div className={styles.overviewList}>
      <ul>
        {(news && news.length > 0) && news.map(item => <NewsListItem key={item.id} item={item} culture={culture} />)}
      </ul>
      {(news && news.length === 0) && <SimpleText textKey="NoNews" />}
    </div>
  );
};

NewsList.propTypes = {
  news: PropTypes.array,
  loadNews: PropTypes.func.isRequired,
  culture: PropTypes.string,
};

export default connect(({ page, localization }) => ({
  news: page.news,
  culture: localization.currentLanguage && localization.currentLanguage.cultureName,
}), { loadNews })(NewsList);