import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { mergeMap } from 'rxjs/operators';
import { paymentFailedQuery } from './queries';
import { routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';

export default (routeData, _state$, { api }) => {
  const { params: { transactionId } } = routeData;

  if (!transactionId)
    return of(null);

  return api.graphApi(paymentFailedQuery, { id: transactionId }).pipe(
    mergeMap(({ paymentTransaction: transaction }) => {
      if (transaction == null)
        return of(null);

      if (transaction.isPaymentError)
        return of({
          action$: of(navigateTo(routesBuilder.forPaymentError(transactionId))),
        });

      return of({
        page: {
          component: PageComponentNames.PaymentFailed,
          transaction,
        },
      });
    }),
  );
};
