import styles from '../Order.module.scss';
import tableStyles from 'components/primitives/table/Table.module.scss';
import PropTypes from 'prop-types';
import { formatAsPrice } from 'utils/format';
import { useSanaTexts, SimpleText } from 'components/sanaText';
import { OrderProp } from './OrderProp';
import OrderDateProp from './OrderDateProp';
import { paymentStatusPropType } from '../propTypes';
import { makeSimpleText } from 'utils/render';
import { ReturnReason } from '../returnOrder';

const RightColumn = ({ document, isB2BCustomer }) => {
  const [
    orderDateText,
    documentDateText,
    paymentStatusText,
    paymentMethodText,
    termsCodeText,
    termsDescriptionText,
    dueDateText,
    discountText,
    discountDateText,
    returnReasonText,
  ] = useRightColumnTexts();

  const {
    orderDate,
    documentDate,
    dueDate,
    currency: currencyInfo,
    payment: {
      isPaidOnAccount,
      status: paymentStatus,
      methodName: paymentMethodName,
      termsDescription,
      termsCode,
      discountDate,
    },
    totals,
    returnReason,
  } = document;
  const paymentDiscount = totals && totals.paymentDiscount;

  return (
    <div className={styles.column}>
      <table className={`${tableStyles.table} ${tableStyles.nameValue}`}>
        <tbody>
          <OrderDateProp name={orderDateText} date={orderDate} className={styles.orderDate} />
          {isB2BCustomer && <OrderDateProp name={documentDateText} date={documentDate} className={styles.documentDate} />}
          {!isPaidOnAccount &&
            <OrderProp
              name={paymentStatusText}
              value={<SimpleText textKey={`PaymentStatus_${paymentStatus}`}>{paymentStatus}</SimpleText>}
              className={styles.paymentStatus}
            />
          }
          <OrderProp name={paymentMethodText} value={paymentMethodName} className={styles.paymentMethod} />
          {isB2BCustomer &&
            <OrderProp
              name={termsDescription ? termsDescriptionText : termsCodeText}
              value={termsDescription || termsCode || null}
              className={styles.termsDescription}
            />
          }
          {isB2BCustomer && <OrderDateProp name={dueDateText} date={dueDate} className={styles.dueDate} />}
          {isB2BCustomer && !!paymentDiscount &&
            <OrderProp
              name={discountText}
              value={formatAsPrice(paymentDiscount, currencyInfo)}
              className={styles.paymentDiscount}
            />
          }
          {isB2BCustomer && discountDate != null &&
            <OrderDateProp name={discountDateText} date={discountDate} className={styles.paymentDiscountDate} />
          }
          {returnReason &&
            <OrderProp name={returnReasonText} value={<ReturnReason reason={returnReason} />} className={styles.returnReason} />
          }
        </tbody>
      </table>
    </div>
  );
};

export default RightColumn;

RightColumn.propTypes = {
  isB2BCustomer: PropTypes.bool.isRequired,
  document: PropTypes.shape({
    orderDate: PropTypes.string,
    documentDate: PropTypes.string,
    dueDate: PropTypes.string,
    currency: PropTypes.object,
    payment: PropTypes.shape({
      isPaidOnAccount: PropTypes.bool.isRequired,
      status: paymentStatusPropType,
      methodName: PropTypes.string,
      termsDescription: PropTypes.string,
      termsCode: PropTypes.string,
      discountDate: PropTypes.string,
    }).isRequired,
    totals: PropTypes.shape({
      paymentDiscount: PropTypes.number,
    }),
    returnReason: PropTypes.object,
  }).isRequired,
};

function useRightColumnTexts() {
  return useSanaTexts([
    'OrderDate',
    'OrderDetail_DocumentDate',
    'OrderDetail_PaymentStatus',
    'PaymentMethod',
    'OrderDetail_PaymentTermsCode',
    'OrderDetail_PaymentTermsDescription',
    'OrderDetail_DueDate',
    'OrderDetail_PaymentDiscount',
    'OrderDetail_PaymentDiscountDate',
    'ReturnRequest_Reason',
  ], makeSimpleText).texts;
}
