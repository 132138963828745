import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

let Profiler;

const ProfilerLoader = ({ timings }) => {
  if (!timings.length)
    return null;

  if (!Profiler)
    Profiler = loadable(() => import(/*webpackChunkName:"profiler"*/'./Profiler'));

  return <Profiler timings={timings} />;
};

ProfilerLoader.propTypes = {
  timings: PropTypes.array.isRequired,
};

const mapStateToProps = ({ profiler: { timings } }) => ({ timings });
export default connect(mapStateToProps)(ProfilerLoader);