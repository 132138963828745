import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { SimpleText } from 'components/sanaText';
import { SanaLinkButton } from 'components/primitives/links';
import AddProductButton from './AddProductButton';
import { Placeholder } from 'components/primitives/placeholders';

const ProductOrderButton = ({
  productId,
  url,
  to,
  uom,
  isOrderable,
  isConfigurable,
  hasVariants,
}) => {
  const [canOrder] = useHasAbilities(AbilityTo.OrderProducts);

  if (!canOrder)
    return null;

  const placeholder = <Placeholder className="placeholder" />;

  if (isOrderable == null)
    return placeholder;

  if (isOrderable === false)
    return (
      <span className="msg-not-available">
        <SimpleText textKey="Product_NotAvailable" placeholder={placeholder} />
      </span>
    );

  if (isConfigurable)
    return (
      <SanaLinkButton
        url={url}
        to={to}
        textKey="ConfigureProduct"
        titleTextKey="ConfigureProduct_ListPage"
        className={`${btnStyles.btnSmall} btn-configure`}
        placeholder={placeholder}
      />
    );

  if (hasVariants)
    return (
      <SanaLinkButton
        url={url}
        to={to}
        textKey="SelectVariants"
        className={`${btnStyles.btnSmall} btn-action`}
        placeholder={placeholder}
      />
    );

  return (
    <AddProductButton
      productId={productId}
      uom={uom}
      className={`${btnStyles.btnAction} ${btnStyles.btnSmall} btn-action`}
      placeholder={placeholder}
    />
  );
};

ProductOrderButton.propTypes = {
  productId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      routeName: PropTypes.string.isRequired,
      params: PropTypes.object,
    }),
  ]).isRequired,
  uom: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantityStep: PropTypes.number,
    minimumQuantity: PropTypes.number,
    defaultQuantity: PropTypes.number,
  }),
  isOrderable: PropTypes.bool,
  isConfigurable: PropTypes.bool,
  hasVariants: PropTypes.bool.isRequired,
};

export default memo(ProductOrderButton);
