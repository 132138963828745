exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OrderResult_actions-list,.OrderResult_description{word-break:break-word}@supports (-ms-ime-align:auto){.OrderResult_actions-list,.OrderResult_description{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.OrderResult_actions-list,.OrderResult_description{word-wrap:break-word}}.OrderResult_description{margin:0}.OrderResult_actions-list{margin:0;padding:.75em 0;list-style-type:none}@supports (-ms-ime-align:auto){.OrderResult_actions-list a,.OrderResult_actions-list button{max-width:100%}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.OrderResult_actions-list a,.OrderResult_actions-list button{max-width:100%}}.OrderResult_btn-logout{-webkit-appearance:none;line-height:inherit}.OrderResult_link-placeholder{margin-bottom:.7em;width:12em}.OrderResult_contact-us-link,.OrderResult_home-link,.OrderResult_my-account-link,.OrderResult_order-link{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"actions-list": "OrderResult_actions-list",
	"actionsList": "OrderResult_actions-list",
	"description": "OrderResult_description",
	"btn-logout": "OrderResult_btn-logout",
	"btnLogout": "OrderResult_btn-logout",
	"link-placeholder": "OrderResult_link-placeholder",
	"linkPlaceholder": "OrderResult_link-placeholder",
	"contact-us-link": "OrderResult_contact-us-link",
	"contactUsLink": "OrderResult_contact-us-link",
	"home-link": "OrderResult_home-link",
	"homeLink": "OrderResult_home-link",
	"my-account-link": "OrderResult_my-account-link",
	"myAccountLink": "OrderResult_my-account-link",
	"order-link": "OrderResult_order-link",
	"orderLink": "OrderResult_order-link"
};