import type { Action, Reducer } from 'redux';

type NeededUnionType<T extends any[]> = T[number];

type MainReducer<TState, TAction extends Action> = (
  state: TState | undefined,
  action: TAction
) => TState;

type ExtraReducer<TState, TAction extends Action> = (
  state: TState,
  action: TAction
) => TState;

type InferState<TReducer> = TReducer extends MainReducer<infer TState, any>
  ? TState
  : TReducer extends ExtraReducer<infer TState, any>
    ? TState
    : never;

type InferAction<TReducer> = TReducer extends MainReducer<any, infer TAction>
  ? TAction
  : TReducer extends ExtraReducer<any, infer TAction>
    ? TAction
    : never;

export default <
  TMainReducer extends MainReducer<InferState<TMainReducer>, any>,
  TExtraReducers extends ExtraReducer<any, any>[]
>(mainReducer: TMainReducer, ...extraReducers: TExtraReducers): Reducer<
  InferState<TMainReducer> | InferState<NeededUnionType<TExtraReducers>>,
  InferAction<TMainReducer> | InferAction<NeededUnionType<TExtraReducers>>
> => (state, action) => [mainReducer, ...extraReducers].reduce((s, r) => r(s, action), state) as any;
