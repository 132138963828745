import { getIn, useFormikContext } from 'formik';
import { createFieldId, createLabelId } from '../helpers';

export const useFormContext = () => {
  const formik = useFormikContext();
  return createFormContext(formik);
};

function createFormContext(formik) {
  return {
    getFieldValue: name => getIn(formik.values, name),
    getFieldError: name => getIn(formik.errors, name),
    isFieldTouched: name => !!getIn(formik.touched, name),
    resetField: (name, value) => {
      formik.setFieldValue(name, value, false);
      formik.setFieldTouched(name, false);
    },
    getFieldId: fieldName => createFieldId(formik.status.formName, fieldName),
    getFieldLabelId: fieldName => createLabelId(formik.status.formName, fieldName),
  };
}
