export const CaptchaTypes = Object.freeze({
  Default: 'DEFAULT',
  Invisible: 'INVISIBLE',
});

export const CookieBarModes = Object.freeze({
  Top: 'TOP',
  Bottom: 'BOTTOM',
  None: 'NONE',
});

export const TaxAmountPosition = Object.freeze({
  Above: 'ABOVE',
  Below: 'BELOW',
});

export const TaxDisplayMode = Object.freeze({
  Hide: 'DISABLED',
  Total: 'TOTAL',
  Detailed: 'DETAILED',
});

export const TaxFocusMode = Object.freeze({
  Excl: 'TOTAL_EXCL_TAX',
  Incl: 'TOTAL_INCL_TAX',
});

export const CheckoutPresets = Object.freeze({
  MultiStep: 'MULTI_STEP',
  OneStep: 'ONE_STEP',
});

export const StoreType = Object.freeze({
  Closed: 'CLOSED_STORE',
  Private: 'PRIVATE_STORE',
  Public: 'PUBLIC_STORE',
});
