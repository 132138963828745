import { getAgreementDetailPageQuery } from './queries';
import { pluckAsPage } from '../helpers';
import { PageComponentNames } from '../componentNames';
import { tap } from 'rxjs/operators';
import { createNotFoundPageResult } from '../helpers';

export default function salesAgreementHandler({ params }, state$, { api }) {
  return api.graphApi(getAgreementDetailPageQuery, { id: params.id }).pipe(
    tap(data => setSelectedAgreement(data, state$)),
    pluckAsPage('salesAgreement', PageComponentNames.SalesAgreement),
    tap(data => processAgreement(data, state$)),
  );
}

function setSelectedAgreement(data, state$) {
  if (data && data.basket)
    state$.value.salesAgreements.appliedSalesAgreementId = data.basket.salesAgreementId;
}

function processAgreement(data, state$) {
  if (!data || !data.page)
    return;

  if (!data.page.agreement) 
    return createNotFoundPageResult(data.page);

  for (const line of data.page.agreement.lines) {
    if (line.category) {
      if (line.category.type === 'NotExistingProductCategory') {
        delete line.category.type;
        line.category.exists = false;
      } else {
        line.category.exists = true;
      }
    }
  }

  const currentPageState = state$.value.page;
  if (currentPageState.agreement && currentPageState.agreement.id === data.page.agreement.id)
    data.page.search = currentPageState.search;
}
