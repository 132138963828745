exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductPrice_action-price{font-size:var(--listPrice_FontSize,11px);color:var(--listPrice_FontColor,#b2b2b2);text-decoration:line-through}.ProductPrice_action-price,.ProductPrice_actual-price,.ProductPrice_discount-amount{white-space:nowrap}.ProductPrice_discount-amount,.ProductPrice_discount-percentage{font-weight:700}.ProductPrice_discount-amount{font-size:var(--listPrice_DiscountAmount_FontSize,11px);color:var(--listPrice_DiscountAmount_FontColor,#cb2245)}.ProductPrice_discount-percentage{font-size:var(--listPrice_DiscountPercentage_FontSize,11px);color:var(--listPrice_DiscountPercentage_FontColor,#cb2245)}.ProductPrice_actual-price{font-size:var(--bulkPrice_FontSize,16px);color:var(--bulkPrice_FontColor,#333)}.ProductPrice_actual-price sup{top:-.7em;font-size:50%}", ""]);

// exports
exports.locals = {
	"action-price": "ProductPrice_action-price",
	"actionPrice": "ProductPrice_action-price",
	"actual-price": "ProductPrice_actual-price",
	"actualPrice": "ProductPrice_actual-price",
	"discount-amount": "ProductPrice_discount-amount",
	"discountAmount": "ProductPrice_discount-amount",
	"discount-percentage": "ProductPrice_discount-percentage",
	"discountPercentage": "ProductPrice_discount-percentage"
};