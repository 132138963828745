import type { ProductTrackingOptions } from './types';

export interface ProductWithUom {
  uom?: null | {
    id: string;
    description?: string | null;
    quantityStep: number;
  };
}

export default function (product: ProductWithUom, options: ProductTrackingOptions) {
  const { uomTitle } = options;
  const { uom } = product;

  if (!uom)
    return null;

  const { id, description, quantityStep } = uom;

  return {
    eligibleQuantity: {
      '@type': 'http://schema.org/QuantitativeValue',
      value: quantityStep,
      unitText: uomTitle || description || id,
    },
  };
}
