exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Heading_heading h1,.Heading_heading h2,.Heading_heading h3,.Heading_heading h4,.Heading_heading h5,.Heading_heading h6{font-size:inherit}", ""]);

// exports
exports.locals = {
	"heading": "Heading_heading"
};