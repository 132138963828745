import createItemList from './itemList';
import createProductImages, { ProductWithImages } from './productImages';
import { createUrl } from '../url';
import { ProductTrackingOptions } from './types';

interface Product extends ProductWithImages {
  url: string | null;
  title: string | null;
}

export default function (products: Product[], options: ProductTrackingOptions) {
  const { origin } = options;

  return createItemList(products.map(product => {
    const { url, title: name } = product;
    return {
      name,
      url: createUrl(url, origin),
      ...createProductImages(product, options),
    };
  }));
}
