import unicodeRegExp from 'unicoderegexp';

export default function toDashedUrl(str: string, maxLength = 50): string {
  if (!str)
    return str;

  const newChars = new Array(maxLength);

  let isPrevSep = true;
  let i = 0;
  for (const ch of str) {
    if (isWordCharacter(ch)) {
      newChars[i++] = ch;
      isPrevSep = false;
    } else if (!isPrevSep) {
      newChars[i++] = '-';
      isPrevSep = true;
    }
    if (i >= maxLength)
      break;
  }
  i--;
  if (newChars[i] === '-')
    newChars[i] = null;

  return newChars.join('');
}

function isWordCharacter(ch: string) {
  return unicodeRegExp.letter.test(ch)
    || unicodeRegExp.number.test(ch) || ch === '_';
}
