exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DateSelect_container .DateSelect_select{margin-bottom:.3em}.DateSelect_container .DateSelect_select:not(:last-child){margin-right:.3em}", ""]);

// exports
exports.locals = {
	"container": "DateSelect_container",
	"select": "DateSelect_select"
};