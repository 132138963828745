import { Fragment } from 'react';
import PropTypes from 'prop-types';
import SimpleLine from './SimpleLine';
import ServiceLine from './ServiceLine';
import ExtendedText from './ExtendedText';
import ProductHeaderRow from './ProductHeaderRow';
import CommentLine from './CommentLine';
import ReturnReasonLine from './ReturnReasonLine';

const ProductLine = ({
  line,
  options,
  lineTexts,
  cancelled,
}) => {
  const {
    productId,
    title,
    serviceLines,
    extendedTexts,
    sublines,
  } = line;

  const hasSublines = sublines && !!sublines.length;
  const allSublinesCancelled = hasSublines && sublines.every(line => line.isCancelled);

  const extendedTextsElement = <>
    {extendedTexts && extendedTexts.map(({ title }, index) => (
      <ExtendedText key={index} title={title} lineTexts={lineTexts} />
    ))}
  </>;

  return (
    <>
      {!hasSublines && (
        <SimpleLine
          line={line}
          showId
          options={options}
          lineTexts={lineTexts}
          cancelled={cancelled}
        />
      )}
      {hasSublines && (
        <>
          <ProductHeaderRow
            id={productId}
            title={title}
            lineTexts={lineTexts}
            cancelled={cancelled || allSublinesCancelled}
          />
          {extendedTextsElement}
          {sublines.map((line, index) => (
            <Fragment key={index}>
              <SimpleLine
                key={index}
                line={line}
                options={options}
                lineTexts={lineTexts}
                cancelled={cancelled || line.isCancelled}
              />
              <CommentLine comment={line.comment} lineTexts={lineTexts} />
              <ReturnReasonLine returnReason={line.returnReason} lineTexts={lineTexts} />
              {line.serviceLines && line.serviceLines.map((serviceLine, index) => (
                <ServiceLine key={index} line={serviceLine} options={options} lineTexts={lineTexts} />
              ))}
            </Fragment>
          ))}
        </>
      )}
      {!hasSublines &&
        <>
          {extendedTextsElement}
          <CommentLine comment={line.comment} lineTexts={lineTexts} />
          <ReturnReasonLine returnReason={line.returnReason} lineTexts={lineTexts} />
        </>
      }
      {!!serviceLines && serviceLines.map((serviceLine, index) => (
        <ServiceLine key={index} line={serviceLine} options={options} lineTexts={lineTexts} />
      ))}
    </>
  );
};

ProductLine.propTypes = {
  line: PropTypes.shape({
    productId: PropTypes.string,
    title: PropTypes.string,
    serviceLines: PropTypes.array,
    extendedTexts: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
    })),
    sublines: PropTypes.arrayOf(PropTypes.shape({
      serviceLines: PropTypes.array,
      comment: PropTypes.string,
      returnReason: PropTypes.object,
    })),
    comment: PropTypes.string,
    returnReason: PropTypes.object,
  }).isRequired,
  options: PropTypes.object,
  lineTexts: PropTypes.object,
  cancelled: PropTypes.bool,
};

export default ProductLine;
