import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RouteName } from 'routes';
import NotValidCustomerWarningMessage from './NotValidCustomerWarningMessage';
import { usePrintMode } from 'utils/hooks';

const ignoredRoutes = new Set([RouteName.Login, RouteName.Registration]);

const NotValidCustomerWarning = ({ isValid }) => {
  const isPrintMode = usePrintMode();

  if (isValid || isPrintMode)
    return null;

  return <NotValidCustomerWarningMessage />;
};

NotValidCustomerWarning.propTypes = {
  isValid: PropTypes.bool,
};

export default connect(state => {
  const isValid = state.user.customer ? state.user.customer.isValid : true;
  const result = { isValid };
  if (isValid)
    return result;

  const routeData = state.routing.routeData;
  if (routeData && ignoredRoutes.has(routeData.routeName))
    result.isValid = true;

  return result;
})(NotValidCustomerWarning);
