import styles from './LanguageSelector.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { getFlagImage } from './flags';

const Header = ({ selectedLanguage, isMobile }) => {
  const { name, displayName } = selectedLanguage;
  const imagePath = getFlagImage(selectedLanguage);
  return (
    <div className={styles.header}>
      {imagePath
        ? (<>
          <img src={imagePath} alt={name} title={displayName} className={styles.flag} />
          {isMobile && <SimpleText textKey="Language_Label" formatWith={[displayName]} />}
        </>)
        : <span className={styles.fakeFlag}>{isMobile ? displayName : name}</span>
      }
    </div>
  );
};

Header.propTypes = {
  selectedLanguage: PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    imagePath: PropTypes.string,
  }).isRequired,
  isMobile: PropTypes.bool,
};

export default memo(Header);