import { memo } from 'react';
import PropTypes from 'prop-types';
import { SanaTextContainer } from 'components/sanaText';

const FieldLabelText = ({ fieldMetadata }) => {
  const labelAsterisk = shouldShowAsterisk(fieldMetadata) ? '*' : null;

  if (fieldMetadata.titleKey)
    return (
      <>
        <SanaTextContainer textKey={fieldMetadata.titleKey}>
          {fieldMetadata.title}
        </SanaTextContainer>
        {labelAsterisk}
      </>
    );

  return labelAsterisk
    ? fieldMetadata.title + labelAsterisk
    : fieldMetadata.title;
};

FieldLabelText.propTypes = {
  fieldMetadata: PropTypes.shape({
    title: PropTypes.string,
    titleKey: PropTypes.string,
    type: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
  }).isRequired,
};

export default memo(FieldLabelText);

function shouldShowAsterisk(fieldMetadata) {
  if (!fieldMetadata.title)
    return false;

  if (fieldMetadata.type === 'Boolean')
    return false;

  return fieldMetadata.isRequired;
}
