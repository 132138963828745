import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';
import InnerLine from './InnerLine';
import { renderHTML } from 'utils/render';

const CommentLine = ({ comment, columnsAmount }) => {
  if (!comment)
    return null;

  return (
    <InnerLine
      value={renderHTML(comment)}
      columnsAmount={columnsAmount}
      className={styles.rowComment}
    />
  );
};

CommentLine.propTypes = {
  comment: PropTypes.string,
  columnsAmount: PropTypes.number.isRequired,
};

export default CommentLine;
