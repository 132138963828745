import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RouteName } from 'routes';
import { CookieBarModes } from 'behavior/settings/constants';
import { LoadingIndicator } from 'components/primitives/loadingIndicator';
import { Header, Footer, Extras, Modals } from 'components/sections';
import { HashRouter } from 'components/hash';
import { CookieBar } from 'components/objects/cookieBar';
import LayoutShiftProvider from './LayoutShiftProvider';
import PrintControls from './PrintControls';
import { ScrollToTopButton } from 'components/primitives/buttons';
import { OfflinePage } from 'components/objects/offlineMode';
import LimitedAccess from 'components/objects/limitedAccess';
import { OfflineModeSupport } from 'behavior/app';
import { Profiler } from 'components/tools/profiler';
import { AriaStatus } from 'components/objects/ariaStatus';
import { Widget as ProductComparisonWidget } from 'components/objects/productComparison';
import { NotValidCustomerWarning } from 'components/objects/notValidCustomer';
import { AccountImpersonationBar } from 'components/objects/adminImpersonation';
import SelfPostingForm from 'components/objects/SelfPostingForm';

const Layout = ({
  routeName,
  children,
  isVisualDesigner,
  isPrintMode,
  showScrollTop,
  cookieBarDisplayMode,
  limitedAccessMode,
  offline,
  appLoaded,
  emptyLayout,
  postForm,
}) => {
  const layoutRef = useRef();
  const footerRef = useRef();

  useEffect(() => {
    const className = 'mouse-click';

    const add = () => void (document.documentElement.classList.add(className));
    const remove = e => {
      if (e.key === 'Tab')
        document.documentElement.classList.remove(className);
    };

    window.addEventListener('mousedown', add);
    window.addEventListener('touchstart', add);
    window.addEventListener('keydown', remove);

    return () => {
      window.removeEventListener('mousedown', add);
      window.removeEventListener('touchstart', add);
      window.removeEventListener('keydown', remove);
    };
  }, []);

  if (!appLoaded)
    return null;

  if (limitedAccessMode)
    return (
      <div id="layout" tabIndex="-1">
        <div id="content" className="limited">
          <LimitedAccess />
        </div>
      </div>
    );

  if (emptyLayout)
    return (
      <div id="layout" tabIndex="-1">
        {children}
      </div>
    );

  if (offline)
    return (
      <div id="layout" tabIndex="-1">
        <div id="content" className="offline">
          <OfflinePage />
        </div>
      </div>
    );

  const topBlockContent = !isVisualDesigner && (
    <>
      <AccountImpersonationBar />
      <NotValidCustomerWarning />
      {!isPrintMode && cookieBarDisplayMode === CookieBarModes.Top && <CookieBar />}
    </>
  );

  const bottomBlockContent = !isVisualDesigner
    && !isPrintMode
    && cookieBarDisplayMode === CookieBarModes.Bottom
    && <CookieBar />;

  return (
    <div id="layout" tabIndex="-1" ref={layoutRef}>
      <LayoutShiftProvider
        topShiftElRef={layoutRef}
        bottomShiftElRef={footerRef}
        topBlockContent={topBlockContent}
        bottomBlockContent={bottomBlockContent}
      >
        <HashRouter>
          <div id="skipLinksContainer" />
          {isPrintMode && <PrintControls />}
          <div role="main" id="content" className={`page-${routeName}`}>
            {children}
          </div>
          {!isVisualDesigner
            ? (
              <>
                <LoadingIndicator />
                <Footer ref={footerRef} />
                <Header isPrintMode={isPrintMode} />
                <Extras>
                  <AriaStatus />
                  <ProductComparisonWidget />
                  {postForm && <SelfPostingForm formOptions={postForm} />}
                </Extras>
                <Profiler />
                {showScrollTop && <ScrollToTopButton isPrintMode />}
                <Modals />
              </>
            )
            : <Extras />
          }
        </HashRouter>
      </LayoutShiftProvider>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  routeName: PropTypes.string,
  isVisualDesigner: PropTypes.bool,
  isPrintMode: PropTypes.bool,
  postForm: PropTypes.object,
  showScrollTop: PropTypes.bool,
  cookieBarDisplayMode: PropTypes.string,
  limitedAccessMode: PropTypes.bool,
  offline: PropTypes.bool,
  appLoaded: PropTypes.bool,
  emptyLayout: PropTypes.bool,
};

export default connect(mapStateToProps)(Layout);

function mapStateToProps({
  routing: { routeData },
  visualDesigner,
  page: { isPrintMode, postForm },
  settings,
  app,
  localization,
  page,
}) {
  const routeName = routeData ? routeData.routeName : RouteName.NotFound;
  const showScrollTop = settings.navigation && settings.navigation.scrollTop;
  const cookieBarDisplayMode = settings.cookiebar && settings.cookiebar.mode;
  const { offlineMode, offlineModeSupport, loaded, error, limitedAccessMode } = app;
  const { currentLanguage } = localization;

  return {
    routeName,
    isVisualDesigner: visualDesigner.initialized,
    isPrintMode,
    postForm,
    showScrollTop,
    cookieBarDisplayMode,
    limitedAccessMode,
    offline: offlineMode && offlineModeSupport === OfflineModeSupport.Disabled,
    appLoaded: error || loaded && (!!currentLanguage.id || currentLanguage.id === undefined), // undefined will be set as language ID in case there was some error.
    emptyLayout: page && page.emptyLayout,
  };
}
