import Banner from './ErrorBanner';
import { PageComponentNames } from 'behavior/pages';

function errorRenderer() {
  return <Banner />;
}

export default {
  [PageComponentNames.Error]: errorRenderer,
};
