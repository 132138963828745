import { Subject, throwError, EMPTY, ObservableInput } from 'rxjs';
import { share, catchError } from 'rxjs/operators';

export function createErrorCodesTracker(handledCodes: number[] = []) {
  const subj = new Subject<any>();

  return {
    errors$: subj.pipe(share()),
    trackErrors: catchError<any, ObservableInput<never>>(e => {
      if (e.status && e.status >= 400) {
        subj.next(e);
        if (handledCodes.some(code => code === e.status))
          return EMPTY;
      }
      return throwError(e);
    }),
  };
}
