import styles from './WishList.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';

const ActionLinks = ({ productUrl, route, onDelete, isVariantLine }) => {
  const { texts: [viewText, deleteText], loaded }
    = useSanaTexts(['View', 'Delete'], makeSimpleText);

  if (!loaded)
    return null;
    
  return (
    <div className={styles.actionLinks}>
      {!isVariantLine && productUrl && route && (
          <WithProductClickTracking>
            {handleClick => {
              return (
                <>
                  <Link onClick={handleClick} url={productUrl} to={route} className={styles.hypView}>
                    {viewText}
                  </Link>
                  <span className={styles.separator} />
                </>
              );
            }}
          </WithProductClickTracking>
        )}
      <button type="button" className={linkStyles.link + ' ' + styles.hypRemove} onClick={onDelete}>
        {deleteText}
      </button>
    </div>
  );
};

ActionLinks.propTypes = {
  productUrl: PropTypes.string,
  route: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  onDelete: PropTypes.func.isRequired,
  isVariantLine: PropTypes.bool,
};

export default memo(ActionLinks);