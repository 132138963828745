export const UNLOCK_FORM_SUBMIT = 'UNLOCK_FORM_SUBMIT';
export const unlockForm = formKey => ({
  type: UNLOCK_FORM_SUBMIT,
  payload: formKey,
});

export const POST_FORM = 'POST_FORM';
export const postForm = options => ({
  type: POST_FORM,
  payload: options,
});
