import linkStyles from 'components/primitives/links/Link.module.scss';
import { AttachmentType } from 'behavior/attachments';
import { encodeSpacesInUrl } from 'utils/url';

export function linkable(attachment) {
  const isFile = attachment.type === AttachmentType.File || attachment.type === AttachmentType.LocalFile;
  const target = isFile ? null : '_blank';
  const download = isFile ? null : true;
  return (
    <a
      className={linkStyles.arrowed}
      rel="nofollow"
      href={encodeSpacesInUrl(attachment.url)}
      target={target}
      download={download}
    >
      {attachment.title}
    </a>
  );
}
