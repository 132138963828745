import { of, merge, throwError } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, mergeMap, catchError } from 'rxjs/operators';
import { NAVIGATED } from 'behavior/routing';
import { APP_STATE_UPDATE, APP_INIT, notifyAppStateUpdated, changeOfflineMode } from './actions';
import { appStateQuery } from './queries';

const appEpic = (action$, state$, { api }) => action$.pipe(
  ofType(NAVIGATED, APP_INIT, APP_STATE_UPDATE),
  switchMap(_ => api.graphApi(appStateQuery).pipe(
    mergeMap(({ appState }) => {
      const appStateUpdated = notifyAppStateUpdated(appState);
      if (state$.value.app.offlineMode === appState.offlineMode)
        return of(appStateUpdated);

      return of(appStateUpdated, changeOfflineMode(appState));
    }),
    catchError(e => merge(of(notifyAppStateUpdated({ error: true })), throwError(e))),
  )),
);

export default appEpic;
