import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';
import { ReturnReason } from '../../returnOrder';
import InnerLine from './InnerLine';

const ReturnReasonLine = ({ returnReason, columnsAmount }) => {
  if (!returnReason)
    return null;

  return (
    <InnerLine
      value={<ReturnReason reason={returnReason} />}
      columnsAmount={columnsAmount}
      className={styles.rowReturnReason}
    />
  );
};

ReturnReasonLine.propTypes = {
  returnReason: PropTypes.object,
  columnsAmount: PropTypes.number.isRequired,
};

export default ReturnReasonLine;
