import { createContext } from 'react';

export type ShiftType = 'TOP' | 'BOTTOM';

/**
 * Adds/updates/removes data related to element which affects layout shift in some way (in case of adding/updating - either adds/updates
 * element height added as additional shift to constantly fixed elements or adds/updates data necessary for tracking of fixed positioned
 * element that can add or can not add additional shift to constantly fixed elements but in any case overlaps viewport content)
 * @param {ShiftType} shiftType - type of shift (top/bottom)
 * @param {string} elementName - unique name for tracking element data
 * @param {number} height - element height
 * @param {boolean} shouldShift - should element height be added to constantly fixed elements which shift layout
 * @param {boolean} isFixed - is element overlaps viewport content
 */
export type UpdateElementsAffectingShiftData = (shiftType: ShiftType, elementName: string, height: number, shouldShift: boolean, isFixed: boolean) => void;

type Context = {
  // actual padding value applied to the top shift element (includes topShiftBlockHeight and the height of top positioned
  // temporary fixed elements which shift layout)
  topShift: number;
  // total height of constantly fixed elements inside top shift block
  topShiftBlockHeight: number;
  // total height of all fixed positioned elements on the top of the screen which overlap layout in the viewport
  topFixedElementsHeight: number;
  // actual padding value applied to the bottom shift element (includes bottomShiftBlockHeight and the height of bottom positioned
  // temporarily fixed elements which shift layout)
  bottomShift: number;
  // total height of constantly fixed elements inside bottom shift block
  bottomShiftBlockHeight: number;
  // total height of all fixed positioned elements on the bottom of the screen which overlap layout in the viewport
  bottomFixedElementsHeight: number;
  updateElementsAffectingShiftData: UpdateElementsAffectingShiftData;
};

export const LayoutShiftContext = createContext({} as Context);
