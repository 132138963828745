import type { Transform } from 'react-html-parser';
import { ResponsiveLazyImage } from 'components/primitives/responsiveImages';
import { stylesToJSON } from 'utils/helpers';

const transformImage: Transform = (node, index) => {
  if (node.type !== 'tag' || node.name !== 'img')
    return;

  const { alt = '', style, class: className, usemap, ...attributes } = node.attribs;
  return (
    <ResponsiveLazyImage
      key={index}
      alt={alt}
      wrapperClassName={className}
      style={style ? stylesToJSON(style) : undefined}
      useMap={usemap}
      {...attributes}
    />
  );
};

export default transformImage;
