import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';
import InnerLine from './InnerLine';

const ExtendedText = ({ title, columnsAmount }) => (
  <InnerLine value={title} columnsAmount={columnsAmount} className={styles.extendedText} />
);

ExtendedText.propTypes = {
  title: PropTypes.string,
  columnsAmount: PropTypes.number.isRequired,
};

export default ExtendedText;
