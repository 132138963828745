import styles from './Confirmation.module.scss';
import modalStyles from 'components/objects/modals/Modal.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSimpleTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { Button } from 'components/primitives/buttons';

export const HideReason = {
  Close: 'close',
  Cancel: 'cancel',
  Ok: 'ok',
  Escape: 'escape',
};

const Confirmation = ({
  close,
  action,
  titleTextKey,
  body = null,
  buttonsTextKeys,
}) => {
  const footerRef = useRef();

  useEffect(() => {
    if (!footerRef.current)
      return;

    footerRef.current.firstElementChild.focus();
  }, []);

  const textKeys = buttonsTextKeys
    ? [
      buttonsTextKeys.closeText || 'ButtonText_Cancel',
      buttonsTextKeys.okText || 'ButtonText_Ok',
      buttonsTextKeys.closeTextAlt,
      buttonsTextKeys.okTextAlt,
    ]
    : ['ButtonText_Cancel', 'ButtonText_Ok', '', '']; //cancelText, okText, cancelTitle, okTitle

  const [cancelText, okText, cancelTitle, okTitle, titleText]
    = useSimpleTexts(titleTextKey ? textKeys.concat(titleTextKey) : textKeys).texts;

  const handleKeyDown = e => {
    if (e.key === 'Escape' || e.which === 27)
      close(HideReason.Escape);
  };
  const handleOkClick = () => {
    close(HideReason.Ok);
    action && action();
  };

  return (
    <div className={styles.confirmation}>
      {titleText && <h2 className={`h3 ${modalStyles.title}`}>{makeSimpleText(titleText)}</h2>}
      {body &&
        <div className={styles.body}>
          {body}
        </div>
      }
      <footer role="presentation" ref={footerRef} onKeyDown={handleKeyDown}>
        <Button
          title={cancelTitle}
          onClick={close.bind(null, HideReason.Cancel)}
          className={`${btnStyles.btn} ${btnStyles.btnExtraSmall} ${btnStyles.noIcon}`}
          aria-keyshortcuts="Escape"
        >
          {cancelText}
        </Button>
        <Button
          title={okTitle}
          onClick={handleOkClick}
          className={`${btnStyles.btn} ${btnStyles.btnExtraSmall} ${btnStyles.noIcon} ${btnStyles.btnAction}`}
        >
          {okText}
        </Button>
      </footer>
    </div>
  );
};

Confirmation.propTypes = {
  close: PropTypes.func.isRequired,
  action: PropTypes.func,
  titleTextKey: PropTypes.string,
  body: PropTypes.node,
  declineText: PropTypes.string,
  confirmText: PropTypes.string,
  buttonsTextKeys: PropTypes.shape({
    closeText: PropTypes.string,
    closeTextAlt: PropTypes.string,
    okText: PropTypes.string,
    okTextAlt: PropTypes.string,
  }),
};

export default Confirmation;