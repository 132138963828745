import styles from './SalesAgreements.module.scss';
import { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'components/primitives/grid';
import { SimpleText, RichText, UseSanaTexts } from 'components/sanaText';
import Heading from 'components/primitives/headings/Heading';
import { Input } from 'components/primitives/form';
import { DangerAlert } from 'components/primitives/alerts';
import Paging from 'components/primitives/paging';
import debounce from 'lodash/debounce';
import { useOnChange } from 'utils/hooks';
import { searchForProductIds } from 'behavior/pages/salesAgreements';
import AgreementDetails from './AgreementDetails';
import Lines from './Lines';
import Messages from './Messages';
import { makeSimpleText } from 'utils/render';
import { EVENT_SOURCES, trackProductListView } from 'behavior/analytics';
import { filterLines } from './utils';
import { SearchIcon } from 'components/primitives/icons';

const PAGE_SIZE = 10;

const Details = ({ agreement, search, culture, userCurrency, dispatch }) => {
  const [pageIndex, setPageIndex] = useState(0);

  useOnChange(() => setPageIndex(0), [search && search.keywords], false);

  const filteredLines = useMemo(() => {
    const filteredLines = filterLines(agreement.lines, search, pageIndex, PAGE_SIZE);

    const products = [];
    filteredLines.forEach(l => {
      if (l.product)
        products.push(l.product);
    });

    if (products && products.length)
      dispatch(trackProductListView(products, EVENT_SOURCES.salesAgreement));

    return filteredLines;
  }, [search, pageIndex]);

  const lines = !(agreement.lines && agreement.lines.length)
    ? <DangerAlert className={styles.notAvailable}><RichText textKey="SalesAgreementLinesNotAvailable" /></DangerAlert>
    : (
      <div id="agreementLines" aria-live="polite">
        {filteredLines.length
          ? <Lines lines={filteredLines} culture={culture} currency={agreement.currency} key={agreement.id} />
          : (
            <div className={`msg-block ${styles.notFound}`}>
              <SimpleText textKey="SalesAgreementLinesNotFound" />
            </div>
          )
        }
      </div>
    );

  const handleChange = useMemo(() => {
    const debounced = debounce(value => {
      dispatch(searchForProductIds(value.trim()));
    }, 250);

    return e => debounced(e.target.value);
  }, []);

  const handleEscape = useCallback(e => {
    if (e.key === 'Escape' || e.which === 27) {
      e.target.value = '';
      dispatch(searchForProductIds(''));
    }
  }, []);

  return (
    <>
      <Heading>
        <h1>
          {(agreement.title || agreement.id) + ' '}
          <SimpleText textKey="SalesAgreements_DetailsPageHeader" />
        </h1>
      </Heading>
      <div className="fr-view">
        <RichText textKey="SalesAgreements_DetailsPageDescription" />
      </div>

      <Messages agreement={agreement} userCurrency={userCurrency} />
      <AgreementDetails agreement={agreement} culture={culture} />

      <Heading as="h3">
        <h2 id="linesHeader" className={styles.agreementsTitle}><SimpleText textKey="SalesAgreementLines" /></h2>
      </Heading>

      <UseSanaTexts options={['SalesAgreementSearchPlaceholderText']}>
        {([placeholder]) => (
          <Row>
            <Col xs={12} sm={8} xl={7}>
              <div className={styles.search}>
                <Input type="search"
                  name="keywords"
                  placeholder={makeSimpleText(placeholder)}
                  onChange={handleChange}
                  onKeyDown={handleEscape}
                  aria-controls="agreementLines"
                  aria-keyshortcuts="Escape"
                />
                <span className={styles.icon} aria-hidden>
                  <SearchIcon />
                </span>
              </div>
            </Col>
          </Row>
        )}
      </UseSanaTexts>

      {lines}

      <div className={styles.paging}>
        <Paging pageIndex={pageIndex} pageSize={PAGE_SIZE}
          totalCount={filteredLines.totalCount}
          onSelect={setPageIndex}
          scrollToId="linesHeader"
          withInput
        />
      </div>
    </>
  );
};

Details.propTypes = {
  agreement: PropTypes.shape({
    id: PropTypes.string.isRequired,
    currencyId: PropTypes.string,
    lines: PropTypes.array,
  }).isRequired,
  search: PropTypes.shape({
    keywords: PropTypes.string,
    ids: PropTypes.arrayOf(PropTypes.string.isRequired),
  }),
  culture: PropTypes.string,
  userCurrency: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

const connected = connect(s => ({
  culture: s.localization.currentLanguage && s.localization.currentLanguage.cultureName,
  userCurrency: s.user.currencyId,
}))(Details);

connected.selectPropsFromPage = function ({ agreement, search }) {
  return { agreement, search };
};

export default connected;
