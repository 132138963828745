exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductComparisonRating_label-placeholder{display:inline-block;vertical-align:middle}.ProductComparisonRating_goto-review{margin-left:.75em}.ProductComparisonRating_title{margin-bottom:0}.ProductComparisonRating_field-value{word-wrap:break-word;padding:.31em .75em}", ""]);

// exports
exports.locals = {
	"label-placeholder": "ProductComparisonRating_label-placeholder",
	"labelPlaceholder": "ProductComparisonRating_label-placeholder",
	"goto-review": "ProductComparisonRating_goto-review",
	"gotoReview": "ProductComparisonRating_goto-review",
	"title": "ProductComparisonRating_title",
	"field-value": "ProductComparisonRating_field-value",
	"fieldValue": "ProductComparisonRating_field-value"
};