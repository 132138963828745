import styles from './Reviews.module.scss';
import pdpStyles from '../Details.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/primitives/grid';
import { connectToContext } from 'utils/react';
import ProductContext from '../ProductContext';
import { StarsRating } from 'components/primitives/rating';
import ReviewsBtn from './ButtonReviews';
import AddButton from './ButtonAdd';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';

const ReviewsAvg = ({ reviews }) => {
  if (!reviews)
    return null;

  const reviewsLabelPlaceholder = <Placeholder className={pdpStyles.reviewsLabelPlaceholder} />;

  return (
    <Col as="article" xs={{ size: 12, order: 3 }} md={6}
      className={`${pdpStyles.customOffset} ${styles.total}`}
    >
      <StarsRating value={reviews.avg} />
      {reviews.total
        ? <ReviewsBtn total={reviews.total} />
        : (
          <span className={styles.reviewsBtn}>
            <SimpleText textKey="Product_ReviewsZeroReviewsLabel" placeholder={reviewsLabelPlaceholder} />
          </span>
        )
      }
      <AddButton />
    </Col>
  );
};

ReviewsAvg.propTypes = {
  reviews: PropTypes.shape({
    avg: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
};

export default connectToContext(
  [ProductContext],
  ({ product: { reviews } }) => ({ reviews }),
)(memo(ReviewsAvg));