import { useRef, DependencyList } from 'react';
import { shallowEqualArrays } from 'shallow-equal';

export type OnChangeCallback = (depsCache: DependencyList) => void;
export type ComparerFn = (oldDeps: DependencyList, newDeps: DependencyList) => boolean;

const initialCache: DependencyList = [];

export default function useOnChange(onChange: OnChangeCallback, dependencies: DependencyList, runOnInit = true, comparer: ComparerFn = shallowEqualArrays): void {
  const cache = useRef(initialCache);

  const initialRun = cache.current === initialCache;
  const shouldCallOnChange = (runOnInit || !initialRun) && !comparer(cache.current, dependencies);

  if (shouldCallOnChange)
    onChange(cache.current);

  if (initialRun || shouldCallOnChange)
    cache.current = dependencies;
}
