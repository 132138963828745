import type { Scope } from './Scope';

class AppContext {
  scope: Scope;
  origin: string;

  constructor(scope: Scope, origin: string) {
    /**
     * Current scope.
     * @type {Scope}
     */
    this.scope = scope;

    /**
     * Current URL origin.
     * @type {string}
     */
    this.origin = origin;
  }
}

export default AppContext;
