export const OrderAuthorizationStatus = Object.freeze({
  NotNeeded: 'NOT_NEEDED',
  Pending: 'PENDING',
  PendingNotSynced: 'PENDING_NOT_SYNCED',
  Approved: 'APPROVED',
  Declined: 'DECLINED',
});

export const OrderAuthorizationAction = Object.freeze({
  Approve: 'approve',
  Decline: 'decline',
  Cancel: 'cancel',
});

export const OrderAuthorizationActionResult = Object.freeze({
  Status: {
    Applied: 'APPLIED',
    AlreadyApplied: 'ALREADY_APPLIED',
  },
  DetailedStatus: {
    Approved: 'APPROVED',
    AlreadyApproved: 'ALREADY_APPROVED',
    Declined: 'DECLINED',
    AlreadyDeclined: 'ALREADY_DECLINED',
    Cancelled: 'CANCELLED',
  },
});
