import { createContext } from 'react';

const FacetsContext = createContext({
  multiSelect: false,
  hasFacets: false,
  selectedFacets: [],
  availableFacets: [],
  urlWithFacet: null,
  urlWithoutFacet: null,
  urlWithoutFacets: null,
});

export default FacetsContext;