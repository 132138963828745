exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Specifications_field-name{color:var(--darkestText_FontColor,#000);font-weight:700;font-size:var(--normalText_BiggerFontSize,14px);padding-top:.3em;padding-bottom:.3em}.Specifications_title{margin-bottom:0}.Specifications_field-value{word-wrap:break-word;padding:.31em .75em}", ""]);

// exports
exports.locals = {
	"field-name": "Specifications_field-name",
	"fieldName": "Specifications_field-name",
	"title": "Specifications_title",
	"field-value": "Specifications_field-value",
	"fieldValue": "Specifications_field-value"
};