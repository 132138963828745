exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RadioButtonGroup_item{display:block}.RadioButtonGroup_item:not(:last-of-type){margin-bottom:.5em}", ""]);

// exports
exports.locals = {
	"item": "RadioButtonGroup_item"
};