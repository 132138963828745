import styles from '../Order.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TotalsLine } from './TotalsLine';
import PromotionLine from './PromotionLine';
import {
  taxFocusModePropType,
  taxAmountPositionPropType,
  taxDisplayModePropType,
} from '../propTypes';
import { TaxFocusMode, TaxAmountPosition, TaxDisplayMode } from 'behavior/settings/constants';
import TaxLines from './TaxLines';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

const OrderTotals = ({ totals, taxSettings, currencyInfo }) => {
  const { texts: [
    totalText,
    exVatText,
    incVatText,
    totalVatText,
    paymentDiscountText,
    subtotalText,
    roundOffText,
    invoiceDiscountText,
    shippingCostsText,
    paymentCostsText,
  ] } = useTotalsTexts();

  if (!totals)
    return null;

  const {
    subtotal,
    totalPrice,
    roundOff,
    paymentDiscount,
    invoiceDiscount,
    promotion,
    shippingCost,
    paymentCost,
    taxAmount,
    taxLines,
    totalExcludingTax,
  } = totals;

  const {
    totalExclTax: displayTotalExclTax,
    focus,
    amountPosition,
    mode,
  } = taxSettings;

  const focusOnExclTax = focus === TaxFocusMode.Excl;
  const [totalExclText, totalInclText] = focusOnExclTax
    ? [totalText, incVatText]
    : [exVatText, totalText];

  const showTotalsAbove = amountPosition === TaxAmountPosition.Above;

  const taxBlock = mode === TaxDisplayMode.Detailed
    ? (
      <TaxLines
        lines={taxLines}
        currencyInfo={currencyInfo}
      />
    )
    : mode === TaxDisplayMode.Total
      ? (
        <TotalsLine
          heading={totalVatText}
          currencyInfo={currencyInfo}
          value={taxAmount}
        />
      )
      : null;

  const paymentDiscountLine = !!paymentDiscount && (
    <TotalsLine
      heading={paymentDiscountText}
      currencyInfo={currencyInfo}
      value={-Math.abs(paymentDiscount)}
    />
  );

  return (
    <div>
      <table className={styles.totalsTable}>
        <tbody>
          <TotalsLine
            heading={subtotalText}
            currencyInfo={currencyInfo}
            value={subtotal}
            className={styles.rowTotalExcludingDiscount}
          />
          {!!roundOff && (
            <TotalsLine
              heading={roundOffText}
              currencyInfo={currencyInfo}
              value={roundOff}
            />
          )}
        </tbody>
        <tbody className={styles.discount}>
          {!!promotion && (
            <PromotionLine
              promotion={promotion}
              currencyInfo={currencyInfo}
              className={styles.promotion}
            />
          )}
          {!!invoiceDiscount && (
            <TotalsLine
              heading={invoiceDiscountText}
              currencyInfo={currencyInfo}
              value={-Math.abs(invoiceDiscount)}
              className={styles.invoice}
            />
          )}
        </tbody>
        <tbody>
          {!!shippingCost && (
            <TotalsLine
              heading={shippingCostsText}
              currencyInfo={currencyInfo}
              value={shippingCost}
              className={styles.shippingCost}
            />
          )}
          {!!paymentCost && (
            <TotalsLine
              heading={paymentCostsText}
              currencyInfo={currencyInfo}
              value={paymentCost}
              className={styles.paymentCost}
            />
          )}
        </tbody>
        <tfoot>
          {displayTotalExclTax && (
            <TotalsLine
              heading={totalExclText}
              currencyInfo={currencyInfo}
              value={totalExcludingTax}
              className={focusOnExclTax ? styles.totalPrice : null}
            />
          )}
          {showTotalsAbove && (
            <>
              {taxBlock}
              {paymentDiscountLine}
            </>
          )}
          <TotalsLine
            heading={totalInclText}
            currencyInfo={currencyInfo}
            value={totalPrice}
            className={`${styles.rowTotal} ${focusOnExclTax ? '' : styles.totalPrice}`}
          />
          {!showTotalsAbove && (
            <>
              {taxBlock}
              {paymentDiscountLine}
            </>
          )}
        </tfoot>
      </table>
    </div>
  );
};

OrderTotals.propTypes = {
  totals: PropTypes.shape({
    totalPrice: PropTypes.number,
    subtotal: PropTypes.number,
    roundOff: PropTypes.number,
    paymentDiscount: PropTypes.number,
    invoiceDiscount: PropTypes.number,
    taxAmount: PropTypes.number, // defaults to 0 but can be omit in request
    promotion: PropTypes.object,
    shippingCost: PropTypes.number,
    paymentCost: PropTypes.number,
    taxLines: PropTypes.arrayOf(PropTypes.object),
  }),
  currencyInfo: PropTypes.object.isRequired,
  taxSettings: PropTypes.shape({
    totalExclTax: PropTypes.bool.isRequired,
    focus: taxFocusModePropType.isRequired,
    amountPosition: taxAmountPositionPropType.isRequired,
    mode: taxDisplayModePropType.isRequired,
  }),
};

const mapStateToProps = ({ settings: {
  tax: taxSettings,
} }) => ({ taxSettings });

export default connect(mapStateToProps)(OrderTotals);

function useTotalsTexts() {
  return useSanaTexts([
    'Total',
    'Basket_TotalExVat',
    'TotalIncVat',
    'TotalVat',
    'PaymentDiscount',
    'Subtotal',
    'RoundOff',
    'InvoiceDiscount',
    'ShippingCosts',
    'PaymentCosts',
  ], makeSimpleText);
}