import styles from './ScrollToTopButton.module.scss';
import { printPageTopIndent } from 'css/General.print.scss';
import { memo, useRef, useEffect } from 'react';
import { connectToContext } from 'utils/react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { scroll$, useEventObservable } from 'utils/rxjs';
import { HashRoutingContext } from 'components/hash';
import { useLayoutShifter } from 'utils/layout';
import { ScrollToTopIcon } from 'components/primitives/icons';

const ACTIVE_SCROLL_TO_TOP_CLASS_NAME = 'scroll-to-top-active';

const ScrollToTopButton = ({ navigateTo, isPrintMode }) => {
  const { bottomFixedElementsHeight } = useLayoutShifter();
  const wrapperRef = useRef();

  const scrollToTop = () => {
    document.getElementById('layout').focus();
    navigateTo('#root', { time: 500, extraTopOffset: isPrintMode ? +printPageTopIndent : 0 });
  };

  useEventObservable(scroll$, toggleButtonVisibility.bind(null, wrapperRef));

  useEffect(() => {
    if (!wrapperRef.current)
      return;

    wrapperRef.current.style.marginTop = bottomFixedElementsHeight ? `-${bottomFixedElementsHeight}px` : '';
  }, [bottomFixedElementsHeight]);

  const [buttonTitle] = useSanaTexts(['ButtonAltText_ScrollTop']).texts;

  return (
    <span ref={wrapperRef} className={styles.wrapper} data-scroll-lock-fill-gap aria-hidden>
      <button className={styles.scrollTop} onClick={scrollToTop} title={buttonTitle} id="scrollToTopButton" tabIndex="-1">
        <ScrollToTopIcon className={styles.icon} aria-hidden />
      </button>
    </span>
  );
};

ScrollToTopButton.propTypes = {
  navigateTo: PropTypes.func.isRequired,
  isPrintMode: PropTypes.bool,
};

export default connectToContext(
  [HashRoutingContext],
  ({ navigateTo }) => ({ navigateTo }),
)(memo(ScrollToTopButton));

function toggleButtonVisibility(buttonWrapperRef) {
  if (!buttonWrapperRef.current)
    return;

  const initialHeight = window.innerHeight / 2;
  const buttonElement = buttonWrapperRef.current.querySelector('button');

  if (window.pageYOffset <= initialHeight) {
    buttonWrapperRef.current.classList.remove(styles.visible);
    buttonElement.setAttribute('disabled', 'disabled');
    document.documentElement.classList.remove(ACTIVE_SCROLL_TO_TOP_CLASS_NAME);
    return;
  }

  buttonElement.removeAttribute('disabled');
  buttonWrapperRef.current.classList.add(styles.visible);
  document.documentElement.classList.add(ACTIVE_SCROLL_TO_TOP_CLASS_NAME);
}
