import { useAddon } from 'utils/addons';

export default function useExtraCheckoutStep(addonId, paymentModuleId) {
  const { exports } = useAddon(addonId);
  if (exports === undefined)
    return;

  if (!exports || !exports.paymentModules)
    return null;

  const paymentModule = exports.paymentModules[paymentModuleId];
  if (!paymentModule)
    return null;

  const Step = paymentModule.ExtraCustomStep;
  if (!Step)
    return null;

  return Step;
}
