import { useEffect, useRef } from 'react';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { useServices } from 'utils/services';

export default function useGraphApi<I, U, R>() {
  const { api } = useServices();
  const unmount$ = createUnmountObservable();

  return (query: string, input: I, resultsSelector: (result: U) => R) => api.graphApi(query, input).pipe(
    map(resultsSelector),
    takeUntil(unmount$),
  );
}

function createUnmountObservable(): Observable<boolean> {
    const subject = createReplaySubject();
    useEffect(() => () => subject.next(true), []);

    return subject;
}

function createReplaySubject(): ReplaySubject<boolean> {
    const subjectRef = useRef<ReplaySubject<boolean>>();
    if (!subjectRef.current)
        subjectRef.current = new ReplaySubject<boolean>(1);

    return subjectRef.current;
}