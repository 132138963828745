import { catchError } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import { showErrorToast } from './actions';
import { errorToasts } from './constants';

/**
 * Handle server error with a toast message.
 * @param {array} errorsToHandle - list of error codes to handle.
 * @param {object} observable - observable to return after processing.
 * @returns {function} - Operator object.
 */
export default function catchApiErrorWithToast(errorsToHandle = ['INVALID_INPUT'], observable) {
  return catchError(e => {
    if (e.response && e.response.errors && e.response.errors.length) {
      for (let i = 0, l = e.response.errors.length; i < l; i++) {
        const error = e.response.errors[i];

        if (error.extensions &&
          errorsToHandle.includes(error.extensions.code) &&
          error.message) {
          const showToast$ = of(showErrorToast(errorToasts.Message, error.message));

          if (observable)
            return concat(showToast$, observable);

          return showToast$;
        }
      }
    }

    throw e;
  });
}