exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Impersonate_bar{border-bottom:1px solid #a81937;padding:8px 0;background:#c91f42;color:#fff;line-height:1.15;font-size:13px}.Impersonate_cnt{display:flex;justify-content:space-between}.Impersonate_cnt button{background:no-repeat;border:none;color:inherit;cursor:pointer;text-decoration:underline}html.pointer-mouse .Impersonate_cnt button:hover{text-decoration:none}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .Impersonate_cnt button:active{text-decoration:none}}}.Impersonate_cnt button:focus{-webkit-text-decoration-style:double;text-decoration-style:double}", ""]);

// exports
exports.locals = {
	"bar": "Impersonate_bar",
	"cnt": "Impersonate_cnt"
};