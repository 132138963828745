exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DocFreeReturnOrder_table-wrapper{margin-bottom:2.7em}.DocFreeReturnOrder_upper-row{border:0!important}.DocFreeReturnOrder_upper-row td,.DocFreeReturnOrder_upper-row th{vertical-align:top;word-break:break-word;border:0}@supports (-ms-ime-align:auto){.DocFreeReturnOrder_upper-row td,.DocFreeReturnOrder_upper-row th{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.DocFreeReturnOrder_upper-row td,.DocFreeReturnOrder_upper-row th{word-wrap:break-word}}.DocFreeReturnOrder_inner-row td{padding:0 3.1em}.DocFreeReturnOrder_col-delete{width:2.15em}.DocFreeReturnOrder_product-title{font-weight:700;color:var(--regularTitle_FontColor,#333)}.DocFreeReturnOrder_product-to-return{border-top:2px dotted;border-color:var(--tables_BorderColor,#e6e6e6);padding-top:1.15em}.DocFreeReturnOrder_reason-field span[id$=_select]{height:2.15em}.DocFreeReturnOrder_reason-field .form-row-label{padding-top:.3em}.DocFreeReturnOrder_btn-delete{min-width:1.54em;height:1.54em;padding:0 .4em}.DocFreeReturnOrder_btn-delete .btn-cnt{display:flex;height:100%;padding:0;align-items:center}.DocFreeReturnOrder_btn-delete svg{display:block;margin-top:-1px}.DocFreeReturnOrder_btn-delete .DocFreeReturnOrder_btn-cnt{display:none;margin-left:.4em}@media not all and (min-width:var(--breakpoints_Medium,960)px){html:not(.print) .DocFreeReturnOrder_btn-delete .DocFreeReturnOrder_btn-cnt{display:block}}.DocFreeReturnOrder_icon{max-width:.16em;max-height:.2em}.DocFreeReturnOrder_variant-title{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"table-wrapper": "DocFreeReturnOrder_table-wrapper",
	"tableWrapper": "DocFreeReturnOrder_table-wrapper",
	"upper-row": "DocFreeReturnOrder_upper-row",
	"upperRow": "DocFreeReturnOrder_upper-row",
	"inner-row": "DocFreeReturnOrder_inner-row",
	"innerRow": "DocFreeReturnOrder_inner-row",
	"col-delete": "DocFreeReturnOrder_col-delete",
	"colDelete": "DocFreeReturnOrder_col-delete",
	"product-title": "DocFreeReturnOrder_product-title",
	"productTitle": "DocFreeReturnOrder_product-title",
	"product-to-return": "DocFreeReturnOrder_product-to-return",
	"productToReturn": "DocFreeReturnOrder_product-to-return",
	"reason-field": "DocFreeReturnOrder_reason-field",
	"reasonField": "DocFreeReturnOrder_reason-field",
	"btn-delete": "DocFreeReturnOrder_btn-delete",
	"btnDelete": "DocFreeReturnOrder_btn-delete",
	"btn-cnt": "DocFreeReturnOrder_btn-cnt",
	"btnCnt": "DocFreeReturnOrder_btn-cnt",
	"icon": "DocFreeReturnOrder_icon",
	"variant-title": "DocFreeReturnOrder_variant-title",
	"variantTitle": "DocFreeReturnOrder_variant-title"
};