import { merge, of, from } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, map, mergeMap } from 'rxjs/operators';
import {
  checkoutInfoUpdated,
  PAYMENT_SELECT,
  EXTRA_PAYMENT_DATA_SAVE,
  notifyExtraPaymentStepChanged,
  ADDITIONAL_CUSTOMER_DATA_SAVE,
  notifyCustomerDataStepChanged,
} from './actions';
import {
  getSelectPaymentMutation,
  getSaveExtraPaymentStepDataMutation,
  getSaveCustomerDataMutation,
  getRefreshCustomerDataQuery,
  getRefreshExtraPaymentStep,
} from './queries';
import { adjustPaymentMethodData, navigateOnIncorrect } from './helpers';
import { retryWithToast, catchApiValidationErrors } from 'behavior/errorHandling';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {
    const isQuote = () => state$.value.page.info?.isQuote || false;
    const isPromotion = () => !!state$.value.page.info?.quote;

    const selectPaymentMethod$ = action$.pipe(
      ofType(PAYMENT_SELECT),
      switchMap(({ payload }) => waitForSubmit(() => api.graphApi(getSelectPaymentMutation(isPromotion()), {
        id: payload.id,
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        map(({ checkout }) => {
          if (checkout) {
            const selectResult = checkout.paymentMethod.select;

            if (selectResult.success) {
              adjustPaymentMethodData(selectResult.info);
              selectResult.info.paymentMethodId = payload.id;

              return checkoutInfoUpdated(selectResult.info);
            }

            return checkoutInfoUpdated(selectResult.info);
          }

          return navigateOnIncorrect(state$.value.page.info);
        }),
        retryWithToast(action$, logger),
      ))),
    );

    const saveExtraPaymentData$ = action$.pipe(
      ofType(EXTRA_PAYMENT_DATA_SAVE),
      switchMap(({ payload }) => waitForSubmit(() => api.graphApi(getSaveExtraPaymentStepDataMutation(isPromotion()), {
        input: { values: payload.values },
        asQuote: isQuote(),
      }).pipe(
        mergeMap(({ checkout }) => {
          if (!checkout)
            return of(navigateOnIncorrect(state$.value.page.info));

          const checkoutInfo = checkout.paymentMethod.extraCheckoutStep?.saveData.info;
          const extraPaymentStep = checkoutInfo?.paymentMethod?.extraPaymentCheckoutStep;

          if (extraPaymentStep)
            extraPaymentStep.errors = null;

          const result = [notifyExtraPaymentStepChanged(extraPaymentStep)];

          if (checkoutInfo?.steps)
            result.push(checkoutInfoUpdated({ steps: checkoutInfo.steps }));

          return from(result);
        }),
        catchApiValidationErrors(errors =>
          api.graphApi(getRefreshExtraPaymentStep(isPromotion())).pipe(
            map(({ checkout }) =>
              notifyExtraPaymentStepChanged({
                errors,
                ...checkout?.paymentMethod?.extraPaymentCheckoutStep,
              }),
            ),
          ),
        ),
        retryWithToast(action$, logger),
      ))),
    );

    const saveCustomerData$ = action$.pipe(
      ofType(ADDITIONAL_CUSTOMER_DATA_SAVE),
      switchMap(({ payload }) => waitForSubmit(() => api.graphApi(getSaveCustomerDataMutation(isPromotion()), {
        input: { values: payload.values },
        asQuote: isQuote(),
      }).pipe(
        mergeMap(({ checkout }) => {
          const checkoutInfo = checkout?.paymentMethod.additionalCustomerDataStep?.save.info;
          const customerDataStep = checkoutInfo?.paymentMethod.additionalCustomerDataStep;

          if (!customerDataStep)
            return of(navigateOnIncorrect(state$.value.page.info));

          customerDataStep.errors = null;
          const result = [notifyCustomerDataStepChanged(customerDataStep)];

          if (checkoutInfo.steps)
            result.push(checkoutInfoUpdated({ steps: checkoutInfo.steps }));

          return from(result);
        }),
        catchApiValidationErrors(errors =>
          api.graphApi(getRefreshCustomerDataQuery(isPromotion())).pipe(
            map(({ checkout }) =>
              notifyCustomerDataStepChanged({ errors, ...checkout?.paymentMethod.additionalCustomerDataStep }),
            ),
          ),
        ),
        retryWithToast(action$, logger),
      ))),
    );

    return merge(selectPaymentMethod$, saveExtraPaymentData$, saveCustomerData$);
  };
}
