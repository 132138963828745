import { memo, useState, useRef, useEffect } from 'react';
import { ReplaySubject } from 'rxjs';
import PropTypes from 'prop-types';
import LazyLoadComponent from './LazyLoadComponentWrapper';
import { joinClasses } from 'utils/helpers';

const LazyLoadImage = props => {
  const { afterLoad, beforeLoad, delayMethod, delayTime, effect,
    placeholder, placeholderSrc, scrollPosition, threshold,
    visibleByDefault, wrapperClassName, ...imgProps } = props;
  const { className, height, style, width } = imgProps;

  const [{ visible, loaded }, setState] = useState({ visible: visibleByDefault });
  const subjectRef = useRef();
  const currentSrcRef = useRef();

  if (!subjectRef.current)
    subjectRef.current = new ReplaySubject(2);

  useEffect(() => () => void (subjectRef.current.complete()), []);

  useEffect(() => {
    subjectRef.current.subscribe(next => {
      setState(state => ({ ...state, ...next }));
    });
  }, []);

  useEffect(() => {
    if (currentSrcRef.current)
      setState({ visible: visibleByDefault, loaded: false });

    currentSrcRef.current = imgProps.src;
  }, [imgProps.src]);

  let lazyImage;
  if (loaded || visible) {
    const onImageLoad = loaded
      ? null
      : e => {
        afterLoad();
        e.type === 'error' && (e.target.dataset.brokenImage = true);
        subjectRef.current.next({ loaded: true });
      };

    // Disable next line for eslint check as 'alt' attribute is included in imgProps.
    // eslint-disable-next-line jsx-a11y/alt-text
    lazyImage = <img onLoad={onImageLoad} onError={onImageLoad} {...imgProps} />;
  } else {
    const onBeforeLoad = () => {
      beforeLoad();
      subjectRef.current.next({ visible: true });
    };

    lazyImage = (
      <LazyLoadComponent
        beforeLoad={onBeforeLoad}
        className={className}
        delayMethod={delayMethod}
        delayTime={delayTime}
        height={height}
        placeholder={placeholder}
        scrollPosition={scrollPosition}
        style={style}
        threshold={threshold}
        visibleByDefault={visibleByDefault}
        width={width}
        useCustomVisibilityHandling
      />
    );
  }

  if ((!effect && !placeholderSrc) || visibleByDefault)
    return <>{lazyImage}</>;

  return (
    <span
      className={joinClasses(
        wrapperClassName,
        'lazy-load-image-background',
        effect,
        loaded && 'lazy-load-image-loaded',
      )}
      style={{
        backgroundImage: loaded || !placeholderSrc ? 'none' : 'url( ' + placeholderSrc + ')',
        backgroundSize: loaded ? 'auto' : '100% 100%',
        color: loaded ? null : 'transparent',
        display: 'inline-block',
        height,
        width,
      }}
    >
      {lazyImage}
    </span>
  );
};

LazyLoadImage.propTypes = {
  afterLoad: PropTypes.func,
  beforeLoad: PropTypes.func,
  delayMethod: PropTypes.string,
  delayTime: PropTypes.number,
  effect: PropTypes.string,
  placeholderSrc: PropTypes.string,
  threshold: PropTypes.number,
  visibleByDefault: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

LazyLoadImage.defaultProps = {
  afterLoad: () => ({}),
  beforeLoad: () => ({}),
  delayMethod: 'throttle',
  delayTime: 300,
  effect: '',
  placeholderSrc: '',
  threshold: 100,
  visibleByDefault: false,
  wrapperClassName: '',
};

export default memo(LazyLoadImage);