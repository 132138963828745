import styles from './Search.module.scss';
import PropTypes from 'prop-types';

const SuggestionItem = ({ suggestion }) => {
  const cats = suggestion.categories;
  return (
    <span className={styles.suggestItem}>
      <span className={styles.img}>
        <img src={suggestion.imageUrl} alt={suggestion.title} />
      </span>
      <span className={styles.text}>
        <span className={styles.titleId}>
          {suggestion.highlightedText}
        </span>
        <span className={styles.category}>
          {cats && cats.length ? cats[cats.length - 1].name : null}
        </span>
      </span>
    </span>
  );
};

SuggestionItem.propTypes = {
  suggestion: PropTypes.shape({
    imageUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    highlightedText: PropTypes.node.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })),
  }).isRequired,
};

export default SuggestionItem;
