import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestProductSet } from 'behavior/content/productSets';
import { useLoadEffect, useOnChange } from 'utils/hooks';
import { parseSortOption } from 'behavior/pages/productList';
import { ProductTiles } from 'components/objects/productTile';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { trackProductListView, EVENT_SOURCES } from 'behavior/analytics';
import { ProductTrackingContext } from '../analytics';

const ProductSetBlock = ({
  model,
  id,
  products,
  calculated,
  requestProductSet,
  trackProductListView,
  pageTitle,
}) => {
  const [canViewCatalog] = useHasAbilities(AbilityTo.ViewCatalog);

  const trackingSource = pageTitle + ' | ' + EVENT_SOURCES.productSet;

  const requestProducts = ({ maximumProductsToShow, productSetId, sortOption }, calculate) => {
    const sorting = sortOption && [parseSortOption(sortOption)];
    requestProductSet(id, productSetId, maximumProductsToShow, sorting, calculate);
  };

  let modelExpired = false;
  useOnChange(() => void (modelExpired = true), [model], false);

  useLoadEffect(() => {
    if (!products || !products.length || !calculated)
      return;

    trackProductListView(products, trackingSource);
  }, [products]);

  useLoadEffect(() => {
    if ((products && !modelExpired) || !canViewCatalog)
      return;

    requestProducts(model, false);
  }, [id, products, modelExpired, model, canViewCatalog]);

  useEffect(() => {
    if (!products || !products.length || calculated)
      return;

    requestProducts(model, true);
  }, [products, calculated, model]);

  if (!products?.length)
    return null;

  return (
    <ProductTrackingContext.Provider value={{ trackingSource }}>
      <ProductTiles products={products} id={id} />
    </ProductTrackingContext.Provider>
  );
};

ProductSetBlock.propTypes = {
  model: PropTypes.shape({
    maximumProductsToShow: PropTypes.number.isRequired,
    productSetId: PropTypes.string.isRequired,
    sortOption: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  bounds: PropTypes.shape({
    sm: PropTypes.number.isRequired,
    md: PropTypes.number.isRequired,
    lg: PropTypes.number.isRequired,
    xl: PropTypes.number,
  }).isRequired,
  products: PropTypes.array,
  calculated: PropTypes.bool,
  requestProductSet: PropTypes.func.isRequired,
  trackProductListView: PropTypes.func.isRequired,
  pageTitle: PropTypes.string,
};

const mapStateToProps = ({ page: { productSets, metaTitle } }, { id }) => {
  const productSet = productSets && productSets[id];

  return {
    products: productSet && productSet.products,
    calculated: productSet && productSet.calculated,
    trackProductListView,
    pageTitle: metaTitle,
  };
};

export default connect(mapStateToProps, { requestProductSet, trackProductListView })(ProductSetBlock);
