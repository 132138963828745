import { SEARCH_PRODUCT_SUGGESTIONS_COMPLETED, SEARCH_PRODUCT_SUGGESTIONS_CLEARED } from './actions';

const initialState = {
  products: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_PRODUCT_SUGGESTIONS_COMPLETED:
      if (!action.payload.length)
        return initialState;

      return { products: action.payload };
    case SEARCH_PRODUCT_SUGGESTIONS_CLEARED:
      return initialState;
    default:
      return state;
  }
};
