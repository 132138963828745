/* eslint-disable react/no-multi-comp */
import styles from './SubAccount.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import { useSimpleTexts } from 'components/sanaText';
import { TrashCanButtonIcon } from 'components/primitives/icons';

const RemoveButton = ({ showBtnText, ...props }) => {
  const { texts: [altTextDelete, textDelete], loaded } = useSimpleTexts([
    'ButtonAltText_Delete',
    'ButtonText_Delete',
  ]);

  if (showBtnText && !loaded)
    return null;

  return (
    <button
      title={showBtnText ? undefined : altTextDelete}
      className={`${btnStyles.btnBase} ${btnStyles.btnSmall} ${styles.action}`}
      type="button"
      {...props}
    >
      <TrashCanButtonIcon className={styles.icon} aria-hidden />
      {showBtnText &&
        <span className="btn-cnt">
          {textDelete}
        </span>
      }
    </button>
  );
};

RemoveButton.propTypes = {
  showBtnText: PropTypes.bool,
};

export default RemoveButton;