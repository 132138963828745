export default context => value => {
  if (!value)
    return;

  const { max } = context.params;

  if (value instanceof File) {
    if (!isValid(value, max))
      return context.errorMessage;
  }
  else if (value instanceof FileList) {
    for (const file of value) {
      if (!isValid(file, max))
        return context.errorMessage;
    }
  }
};

function isValid(file, max) {
  return file.name.length <= max;
}