import styles from './Swiper.module.scss';
import { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import RowRailsContext from './context';
import { SwiperShape } from './utils';

const swiperInstance = new SwiperShape();
const EVENTS = [
  'mousedown',
  'mousemove',
  'mouseup',
  'mouseleave',
  'click',
  'touchstart',
  'touchmove',
  'touchend',
  'touchcancel',
];
Object.freeze(EVENTS);

const RailsSwiper = ({ children, ...rest }) => {
  const [isFirstItem, setFirstItem] = useState(true);
  const [isLastItem, setLastItem] = useState(true);
  const swiperBodyRef = useRef(null);

  const [handleDragging, swipeHandler, swipePrev, swipeNext, handleRefresh] = useMemo(() => {
    const toggleSwipingClass = shouldTo => swiperBodyRef.current.classList[shouldTo ? 'add' : 'remove'](styles.isSwiping);

    const refreshLimits = () => {
      const { currentSlide, maxSwipePosition } = swiperInstance;

      setFirstItem(currentSlide <= 0);
      setLastItem(currentSlide >= maxSwipePosition);
    };

    const handleDragging = event => {
      if (
        event.target.getAttribute('draggable') !== true
        || !event.target.closest('[draggable=true]')
      ) {
        event.stopImmediatePropagation();
        event.preventDefault();
      }
    };

    const swipeHandler = event => {
      if ('ontouchend' in document && event.type.indexOf('mouse') !== -1)
        return;

      if (!swiperInstance.isSwipingPossible)
        return;

      switch (event.type) {
        case 'mousedown':
        case 'touchstart':
          toggleSwipingClass(true);
          swiperInstance.swipeStart(event);
          break;
        case 'mousemove':
        case 'touchmove':
          swiperInstance.swipeMove(event, swiperBodyRef.current);
          break;
        case 'click':
          swiperInstance.swipeClick(event);
          swiperInstance.currentDeltaX = 0;
          break;
        case 'mouseleave':
        case 'touchcancel':
        case 'touchend':
          swiperInstance.currentDeltaX = 0;
        // eslint-disable-next-line no-fallthrough
        case 'mouseup':
          toggleSwipingClass(false);
          swiperInstance.swipeEnd();
          refreshLimits();
          break;
        default:
      }
    };

    const swipePrev = event => {
      event.stopPropagation();
      event.preventDefault();
      swiperInstance.swipePrevious();
      refreshLimits();
    };

    const swipeNext = event => {
      event.stopPropagation();
      event.preventDefault();
      swiperInstance.swipeNext();
      refreshLimits();
    };

    const handleRefresh = () => {
      swiperInstance.handleResizeRefresh();
      refreshLimits();
    };

    return [handleDragging, swipeHandler, swipePrev, swipeNext, handleRefresh];
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [children]);

  useEffect(() => {
    EVENTS.forEach(event => {
      swiperBodyRef.current.addEventListener(event, swipeHandler);
    });
    const handleResize = debounce(handleRefresh, 200);
    window.addEventListener('resize', handleResize);
    swiperBodyRef.current.addEventListener('dragstart', handleDragging);
    window.addEventListener('orientationchange', handleRefresh);

    return () => {
      EVENTS.forEach(event => {
        swiperBodyRef.current.removeEventListener(event, swipeHandler);
      });
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
      swiperBodyRef.current.removeEventListener('dragstart', handleDragging);
      window.removeEventListener('orientationchange', handleRefresh);
    };
  }, []);

  return (
    <div ref={swiperBodyRef} {...rest}>
      <RowRailsContext.Provider
        value={{
          registerRow: swiperInstance.registerRow,
          unRegisterRow: swiperInstance.unRegisterRow,
          swipeNext,
          swipePrev,
          isFirstItem,
          isLastItem,
          swiperBodyRef,
        }}
      >
        {children}
      </RowRailsContext.Provider>
    </div>
  );
};

RailsSwiper.propTypes = {
  children: PropTypes.any,
};

export default RailsSwiper;