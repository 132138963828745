import {
  ORDER_TEMPLATES_ADDED,
  ORDER_TEMPLATES_REMOVED,
  ORDER_TEMPLATES_RECEIVED,
  ORDER_TEMPLATE_LINES_RECEIVED,
  ORDER_TEMPLATE_SAVED,
} from 'behavior/pages/orderTemplates/actions';
import { createReducer } from 'utils/redux';
import { NAVIGATED } from 'behavior/routing';
import { UpdateType } from './constants';
import { PageComponentNames } from '../componentNames';

const initialState = {
  items: null,
  status: null,
  savingResult: null,
};

export default createReducer(initialState, {
  [ORDER_TEMPLATES_ADDED]: onOrderTemplatesAdded,
  [ORDER_TEMPLATES_REMOVED]: onOrderTemplatesRemoved,
  [ORDER_TEMPLATES_RECEIVED]: onOrderTemplatesReceived,
  [ORDER_TEMPLATE_LINES_RECEIVED]: onOrderTemplateLinesReceived,
  [ORDER_TEMPLATE_SAVED]: onOrderTemplateSavingResultReceived,
  [NAVIGATED]: onNavigated,
});

function onOrderTemplatesAdded(state, action) {
  return {
    ...state,
    status: {
      type: UpdateType.Added,
      countAdded: action.payload,
    },
  };
}

function onOrderTemplatesRemoved(state, action) {
  const deletedIds = action.payload;
  return {
    items: state.items.filter(t => !deletedIds.includes(t.id)),
    status: { type: UpdateType.Removed },
  };
}

function onOrderTemplatesReceived(state, action) {
  return {
    ...state,
    items: action.payload,
  };
}

function onOrderTemplateLinesReceived(state, action) {
  const { id: templateId, orderTemplateLines } = action.payload;

  return {
    ...state,
    items: state.items.map(template => {
      if (template.id !== templateId)
        return template;

      return { ...template, lines: orderTemplateLines };
    }),
  };
}

function onOrderTemplateSavingResultReceived(state, action) {
  const { savingResult } = action.payload;

  return { ...state, savingResult };
}

function onNavigated(state, action) {
  if (action.payload.page && action.payload.page.component === PageComponentNames.OrderTemplates)
    return state;

  return initialState;
}