import styles from './ImageBlock.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLazyImage } from 'components/primitives/responsiveImages';

const Image = ({ className, wrapperClassName, ...props }) => (
  <ResponsiveLazyImage
    className={`${styles.image} ${className || ''}`}
    wrapperClassName={`${styles.wrapper} ${wrapperClassName || ''}`}
    {...props}
  />
);

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default memo(Image);