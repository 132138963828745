import styles from '../page/PageTemplate.module.scss';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'components/primitives/grid';
import { connect } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { Facets, FacetsProvider, queryFacetUrlBuilder as facetUrlBuilder } from '../facets';
import { useIsMobileViewport } from 'utils/hooks';
import PageB2C from '../b2c/Page';
import CalculatedFieldsLoader from '../page/CalculatedFieldsLoader';
import HeaderContent from './HeaderContent';
import { presets } from '../page';
import { plpId } from '../constants';

const PageTemplate = ({ page, preset, id }) => {
  const isMobile = useIsMobileViewport();
  const { texts: [leftColumnProductList], loaded } = useSanaTexts(['LeftColumn_ProductList'], makeSimpleText);
  const { facets, categoryName, products } = page;

  const Page = preset in presets
    ? presets[preset]
    : PageB2C;

  if (!Page.selectProps)
    throw new Error(`Product list page component ${Page.displayName} should implement selectProps method to map page state to props.`);

  const shouldHideLeftColumn = !products.length && loaded && !leftColumnProductList;

  const content = (
    <Container className={`${styles.plp} ${shouldHideLeftColumn ? styles.hideLeftColumn : ''}`}>
      <Row>
        <Col xs={12} md={9} id={plpId} className={styles.productList}>
          <CalculatedFieldsLoader products={page.products}>
            <Page {...Page.selectProps(page)} />
          </CalculatedFieldsLoader>
        </Col>
        {isMobile
          ? <Col>{leftColumnProductList}</Col>
          : (
            <Col md={{ size: 3, order: 'first' }} className={styles.leftColumn}>
              <Facets />
              <div>{leftColumnProductList}</div>
            </Col>
          )}
      </Row>
    </Container>
  );
  const renderFacets = !!facets && facets.facets.length > 0;
  const header = <HeaderContent categoryName={categoryName} categoryId={id} backgroundColor={page.backgroundColor} backgroundImage={page.backgroundImage} />;
  return (
    <>
      {renderFacets
        ? <FacetsProvider facets={facets} urlBuilder={facetUrlBuilder}>{header}{content}</FacetsProvider>
        : <>{header}{content}</>
      }
    </>
  );
};

PageTemplate.propTypes = {
  page: PropTypes.shape({
    facets: PropTypes.object,
    categoryName: PropTypes.string,
  }).isRequired,
  preset: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default connect(({ settings: { productList: { preset } }, routing: { routeData } }) => ({
  preset,
  id: routeData.params && routeData.params.id,
}))(PageTemplate);