import { CHECKOUT_OFFERS_RECEIVED, CHECKOUT_OFFERS_EXPIRED } from './actions';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'behavior/events';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHECKOUT_OFFERS_RECEIVED:
      return { products: action.payload };
    case CHECKOUT_OFFERS_EXPIRED:
    case LANGUAGE_CHANGED:
      return { ...state, expired: true };
    case VIEWER_CHANGED:
      return initialState;

    default:
      return state;
  }
}