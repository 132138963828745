export function createPropsFromSettings(settings) {
  const validation = {};
  const props = { validation };

  for (const key in settings) {
    if (!settings.hasOwnProperty(key))
      continue;

    switch (key) {
      case 'required':
        const isRequired = settings[key];
        if (!isRequired) {
          props.required = false;
          break;
        }

        validation.required = true;
        props.required = true;
        break;
      case 'maxLength':
        validation.maxLength = { max: settings[key] };
        break;
      case 'countries':
        const countries = settings[key];

        props.items = countries.map(({ id, name }) => ({ name, value: id }));
        props.placeholderTextKey = 'SelectCountryPlaceholder';
        props.autoComplete = 'country';
        break;
      case 'states':
        props.states = settings[key];
        props.allowCustomState = settings.allowCustomState;
        break;
      case 'options':
        props.items = settings[key];
        break;
      case 'range':
        const range = settings[key];
        let { min, max } = range;
        min = normalizeNumber(min);
        max = normalizeNumber(max);

        validation.range = { min, max };
        break;
      case 'perCountryPatterns':
        props.perCountryPatterns = settings[key];
        break;
      case 'minAge':
        validation.minAge = { minAge: settings[key] };
        break;
      case 'uniqueEmail':
        if (settings[key])
          validation.uniqueEmail = 1;
        break;
      default:
        break;
    }
  }

  return props;
}

function normalizeNumber(number) {
  const minValue = Number.MIN_SAFE_INTEGER;
  const maxValue = Number.MAX_SAFE_INTEGER;

  if (number < minValue)
    return minValue;

  if (number > maxValue)
    return maxValue;

  return number;
}