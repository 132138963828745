import { merge, EMPTY, concat, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { showErrorToast, errorToasts } from 'behavior/errorHandling';
import { logger } from 'utils/logs';

export const ERROR_PRIORITIES = Object.freeze({
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
});

const getEmpty = _ => EMPTY;
export const handleError = (priority = ERROR_PRIORITIES.MEDIUM, epicName, continueWith = getEmpty) => catchError((e, o) => {
  const continueObservable = continueWith(e, o);

  if (priority === ERROR_PRIORITIES.LOW)
    return continueObservable;

  logger.error(`The following error occurred in "${epicName || '<anonymous>'}" epic:`, e);
  if (priority === ERROR_PRIORITIES.HIGH)
    return concat(of(showErrorToast(errorToasts.Reload)), continueObservable);

  return continueObservable;
});

export function combineWithErrorCatch(...epics) {
  function combineEpics(...args) {
    return merge(
      ...epics.map(epic => {
        let output$ = epic(...args);
        if (!output$) {
          throw new TypeError(`combineEpics: one of the provided Epics "${epic.name || '<anonymous>'}" does not return a stream.
Double check you're not missing a return statement!`);
        }

        output$ = output$.pipe(
          handleError(ERROR_PRIORITIES.HIGH, epic.displayName || epic.name, _ => output$),
        );

        return output$;
      }),
    );
  }

  return combineEpics;
}