const PROVIDER_REGEX = /(?:(?:https?:)?\/\/)?(?:[^.]+\.)?(\w+)\./i;
const YOUTUBE_VIDEOID_REGEX = /(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i;
const VIMEO_VIDEOID_REGEX = /(vimeo(?:cdn|pro)?)\.com\/(?:(?:channels\/[\w]+|(?:(?:album\/\d+|groups\/[\w]+|staff\/frame)\/)?videos?)\/)?(\d+)(?:_(\d+)(?:x(\d+))?)?(\.\w+)?/i;

export const VideoProviderTypes = Object.freeze({
  Youtube: 'youtube',
  Vimeo: 'vimeo',
});

export const parseVideoData = url => {
  if (!url)
    return null;

  const provider = getProvider(url);
  switch (provider) {
    case VideoProviderTypes.Youtube:
      const ytVideoId = getYoutubeVideoId(url);
      return ytVideoId ? { provider, videoId: ytVideoId } : null;
    case VideoProviderTypes.Vimeo:
      const vimeoVideoId = getVimeoVideoId(url);
      return vimeoVideoId ? { provider, videoId: vimeoVideoId } : null;
    default:
      return null;
  }
};

function getProvider(url) {
  const match = url.match(PROVIDER_REGEX);

  switch (match[1].toLowerCase()) {
    case 'youtube':
    case 'youtu':
      return VideoProviderTypes.Youtube;
    case 'vimeo':
    case 'vimeopro':
    case 'vimeocdn':
      return VideoProviderTypes.Vimeo;
    default:
      return null;
  }
}

function getYoutubeVideoId(url) {
  const match = url.match(YOUTUBE_VIDEOID_REGEX);
  return match ? match[1] : null;
}

function getVimeoVideoId(url) {
  const match = url.match(VIMEO_VIDEOID_REGEX);
  return match ? match[2] : null;
}