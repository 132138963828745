import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { switchMap, map, takeUntil } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import {
  checkoutInfoUpdated,
  pickupLocationsReceived,
  SHIPPING_METHOD_SELECT,
  PICKUP_LOCATIONS_REQUESTED,
} from './actions';
import { getSelectShippingMethodMutation, getPickupLocationsQuery } from './queries';
import { NAVIGATED } from 'behavior/routing';
import { adjustShippingMethodData, navigateOnIncorrect } from './helpers';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {
    const isQuote = () => state$.value.page.info?.isQuote || false;
    const isPromotion = () => !!state$.value.page.info?.quote;

    const selectShippingMethod$ = action$.pipe(
      ofType(SHIPPING_METHOD_SELECT),
      switchMap(({ payload: { id, locationId } }) => waitForSubmit(() => api.graphApi(getSelectShippingMethodMutation(isPromotion()), {
        id,
        locationId,
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        map(({ checkout }) => {
          if (checkout) {
            const { success, info } = checkout.shippingMethod.select;

            if (success) {
              adjustShippingMethodData(info);
              return checkoutInfoUpdated(info);
            }
          }

          return navigateOnIncorrect(state$.value.page.info);
        }),
        retryWithToast(action$, logger),
      ))),
    );

    const navigated$ = action$.pipe(ofType(NAVIGATED));
    const loadPickupLocations$ = action$.pipe(
      ofType(PICKUP_LOCATIONS_REQUESTED),
      switchMap(({ payload }) => api.graphApi(getPickupLocationsQuery(isPromotion()), payload).pipe(
        map(({ checkout }) => {
          if (!checkout)
            return navigateOnIncorrect(state$.value.page.info);

          return pickupLocationsReceived(payload.methodId, checkout.pickupLocations);
        }),
        retryWithToast(action$, logger),
        takeUntil(navigated$),
      )),
    );

    return merge(selectShippingMethod$, loadPickupLocations$);
  };
}
