exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FullScreenPopup_popup{bottom:0;padding:0;background:var(--popup_BackgroundColor,#fff);border:1px solid var(--popup_BorderColor,#e6e6e6);outline:0;height:auto}.FullScreenPopup_close{position:absolute;top:0;right:0;border:0;background:none;cursor:pointer;padding:3px 4px}.FullScreenPopup_close img{max-width:30px;max-height:30px;vertical-align:middle}.FullScreenPopup_close svg{width:30px;height:30px;fill:var(--lightestText_FontColor,#b2b2b2);vertical-align:middle}html.pointer-mouse .FullScreenPopup_close svg:hover{fill:var(--normalText_FontColor,grey)}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .FullScreenPopup_close svg:active{fill:var(--normalText_FontColor,grey)}}}@media not all and (min-resolution:.001dpcm){@media (pointer:coarse){.FullScreenPopup_close svg:hover{-webkit-tap-highlight-color:var(--normalText_FontColor,#808080)}}}", ""]);

// exports
exports.locals = {
	"popup": "FullScreenPopup_popup",
	"close": "FullScreenPopup_close"
};