import styles from '../Checkout.module.scss';
import { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'behavior/pages/checkout';
import CheckoutContext from './CheckoutContext';
import OverviewStepBase, { OverviewStepBody } from '../base/OverviewStep';
import { validateForm } from 'components/objects/forms';
import { CreditLimitMessage } from 'components/objects/basket';

const OverviewStep = ({ className, info, isCompleted }) => {
  const { registerStep, setStepCompletion } = useContext(CheckoutContext);
  const additionalInfoRef = useRef();
  const termsRef = useRef();

  useEffect(() => registerStep(Steps.Overview, className, [Steps.Address, Steps.Shipping, Steps.Payment, Steps.ExtraPayment], async () => {
    const [infoValid, termsValid] = await Promise.all([
      validateForm(additionalInfoRef),
      validateForm(termsRef),
    ]);

    return infoValid && termsValid;
  }), []);

  const onAdditionalInfoBlur = () =>
    setStepCompletion(Steps.Overview, additionalInfoRef.current.isValid);

  return (
    <OverviewStepBase
      className={className}
      isQuote={info.isQuote || !!info.quote}
      isCompleted={isCompleted}
    >
      <CreditLimitMessage className={styles.creditLimitMsg} creditLimit={info.creditLimit} />
      <OverviewStepBody
        info={info}
        context={CheckoutContext}
        additionalInfoFormikRef={additionalInfoRef}
        termsAndAgreementsFormikRef={termsRef}
        onAdditionalInfoBlur={onAdditionalInfoBlur}
      />
    </OverviewStepBase>
  );
};

OverviewStep.propTypes = {
  className: PropTypes.string.isRequired,
  info: PropTypes.shape({
    isQuote: PropTypes.bool,
    quote: PropTypes.object,
  }).isRequired,
  isCompleted: PropTypes.bool,
};

export default OverviewStep;
