import styles from './Header.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';

const CustomerServicePage = ({ customerServicePage }) => {
  if (customerServicePage == null)
    return null;

  return (
    <Link to={customerServicePage.to} className={styles.customerServiceLink}>
      <SimpleText textKey="CustomerService" />
    </Link>
  );
};

CustomerServicePage.propTypes = {
  customerServicePage: PropTypes.shape({
    to: PropTypes.shape({
      routeName: PropTypes.string,
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }),
};

export default connect(
  ({ settings: { customerServicePage } }) => ({ customerServicePage }),
)(CustomerServicePage);