export const NEWS_LOAD = 'NEWS/LOAD';
export const loadNews = (index, size) => ({
  type: NEWS_LOAD,
  payload: {
    page: { index, size },
  },
});

export const NEWS_LOADED = 'NEWS/LOADED';
export const newsLoaded = news => ({
  type: NEWS_LOADED,
  payload: news,
});
