import { useLoadEffect, useComputedValue } from 'utils/hooks';
import { getRouteKey, loadRoutes } from 'behavior/route';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';

export default function useRoutes(routes) {
  const dispatch = useDispatch();

  const selectRoutePaths = useComputedValue(() => makeRoutePathsSelector(routes), routes);
  const { paths, expirations } = useSelector(selectRoutePaths);

  useLoadEffect(() => {
    const routesToLoad = [];

    for (let length = routes.length, i = 0; i < length; i++) {
      if (paths[i] === undefined || expirations[i])
        routesToLoad.push(routes[i]);
    }

    if (routesToLoad.length)
      dispatch(loadRoutes(routesToLoad));
  }, [paths, expirations]);

  return paths;
}

const routesSelector = state => state.routes;

function makeRoutePathsSelector(routes) {
  return createSelector(
    routesSelector,
    routeEntries => ({
      paths: routes.map(route => {
        const key = getRouteKey(route);
        const routeEntry = routeEntries[key];

        return routeEntry ? routeEntry.path : undefined;
      }),
      expirations: routes.map(route => {
        const key = getRouteKey(route);
        const routeEntry = routeEntries[key];

        return routeEntry && routeEntry.expired;
      }),
    }),
  );
}