exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Page_page{margin-bottom:30px}.Page_actions-section{padding:15px 0 2px}.Page_line{border-bottom:1px solid var(--tables_BorderColor,#e6e6e6)}.Page_tiles-row:not(.Page_compact) .swiper-button-next,.Page_tiles-row:not(.Page_compact) .swiper-button-prev{top:105px;transform:none}.Page_tiles-row .row-rails-col:first-child:last-child{width:100%;max-width:475px;margin-left:auto;margin-right:auto}.Page_checkbox-label{margin-right:.5em}@media (min-width:var(--breakpoints_ExtraLarge,1920)px){html:not(.print) .Page_compact .swiper-button-prev{left:-22px}html:not(.print) .Page_compact .swiper-button-next{right:-22px}}", ""]);

// exports
exports.locals = {
	"page": "Page_page",
	"actions-section": "Page_actions-section",
	"actionsSection": "Page_actions-section",
	"line": "Page_line",
	"tiles-row": "Page_tiles-row",
	"tilesRow": "Page_tiles-row",
	"compact": "Page_compact",
	"checkbox-label": "Page_checkbox-label",
	"checkboxLabel": "Page_checkbox-label"
};