import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, pluck, map, mergeMap, startWith } from 'rxjs/operators';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormLockKeys } from 'behavior/pages';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { toasts } from 'behavior/toasts';
import { requestAbility } from 'behavior/user/epic';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import { DOC_BASED_RETURN_ORDER_SUBMITTED, setReturnOrderFailed } from './actions';
import { createReturnOrderMutation } from './queries';

export default (action$, state$, { api, logger }) => action$.pipe(
  ofType(DOC_BASED_RETURN_ORDER_SUBMITTED),
  pluck('payload'),
  switchMap(({ input, files }) => api.graphApi(createReturnOrderMutation, { input }, { files, retries: 0 }).pipe(
    pluck('documents', 'returnOrders', 'createDocBased', 'orderId'),
    mergeMap(orderId => {
      if (!orderId)
        return of(setReturnOrderFailed(), unsetLoadingIndicator());

      toasts.success('', { textKey: 'ReturnRequest_OrderCreated' });

      return requestAbility(AbilityTo.ViewReturnOrders, state$, { api }).pipe(
        map(abilityState => abilityState === AbilityState.Available),
        map(canViewReturnOrders => navigateTo(canViewReturnOrders
          ? routesBuilder.forDocument(orderId, DocumentType.ReturnOrder)
          : routesBuilder.forInvoices())),
      );
    }),
    catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(FormLockKeys.CreateDocBasedReturnOrder), unsetLoadingIndicator())),
    retryWithToast(action$, logger, () => of(unlockForm(FormLockKeys.CreateDocBasedReturnOrder), unsetLoadingIndicator())),
    startWith(setLoadingIndicator()),
  )),
);