import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function useFormLocker(formLockKey) {
  const isLocked = useRef(false);
  const lockReset = useSelector(({ page: { forms } }) => forms && forms[formLockKey]);

  useEffect(() => {
    if (!lockReset)
      return;

    isLocked.current = false;
  }, [lockReset]);

  return () => {
    if (!formLockKey)
      return false;

    if (isLocked.current)
      return true;

    isLocked.current = true;
    return false;
  };
}