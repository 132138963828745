import btnStyles from './Button.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import { useSanaButton } from './UseSanaButton';

const SanaButton = ({
  textKey,
  titleTextKey,
  defaultText,
  className,
  formatWith = null,
  component,
  placeholder = null,
  ...attributes
}) => {
  let buttonClass = btnStyles.btn;
  if (className)
    buttonClass += ` ${className}`;

  const Component = component || Button;
  const { text, title, loaded } = useSanaButton(textKey, defaultText, formatWith, titleTextKey);

  if (!loaded)
    return placeholder;

  return (
    <Component className={buttonClass} title={title} {...attributes}>
      {text}
    </Component>
  );
};

SanaButton.propTypes = {
  formatWith: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  textKey: PropTypes.string.isRequired,
  titleTextKey: PropTypes.string,
  defaultText: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.any,
  placeholder: PropTypes.node,
};

export default memo(SanaButton);