export const TRACKER_TYPES = Object.freeze({
  GoogleTagManager: 'GTM',
  PiwikPro: 'PWK',
});

export const EVENT_SOURCES = Object.freeze({
  searchInput: 'Search bar',
  checkoutOffers: 'Checkout offers',
  searchResult: 'Search results',
  lastViewedProducts: 'Last viewed products',
  lastViewedProductsPage: 'Last viewed products page',
  productSet: 'Product set content block',
  shoppingCartPage: 'Shopping cart page',
  shoppingCartMiniature: 'Shopping cart miniature',
  relatedProducts: 'Related products',
  compareProductsPage: 'Compare products page',
  compareProductsWidget: 'Compare products widget',
  orderTemplate: 'Order template',
  salesAgreement: 'Sales agreement',
  quickOrder: 'Quick order',
  returnOrder: 'Return order',
  wishList: 'Wish list',
});

export const DATALAYER_READY_EVENT = 'analyticsDataLayerReady';

export const trackerFactory = ({ trackers }) => {
  return (trackers && trackers.map(({ type, containerId }) => ({
    type,
    containerId,
    dataLayerName: 'dataLayer',
  }))) || [];
};