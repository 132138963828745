exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NonOrderable_closable{overflow:hidden;margin:20px 0}.NonOrderable_closable .NonOrderable_block{margin:0}.NonOrderable_closable.NonOrderable_closed{max-height:0;transition:max-height .25s}.NonOrderable_closable:not(:last-child)>*{margin-bottom:0}.NonOrderable_lines{padding:7px 1.2em;margin:0}", ""]);

// exports
exports.locals = {
	"closeTransition": "250",
	"closable": "NonOrderable_closable",
	"block": "NonOrderable_block",
	"closed": "NonOrderable_closed",
	"lines": "NonOrderable_lines"
};