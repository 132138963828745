import type { RouteData } from 'routes';

const PREFIX = 'EVENT/' as const;

type NavigationOptions = {
  omitScroll?: boolean;
  replaceHistory?: boolean;
}

export const LANGUAGE_CHANGED = `${PREFIX}LANGUAGE_CHANGED` as const;
export const languageChanged = () => ({ type: LANGUAGE_CHANGED });

export const VIEWER_CHANGED = `${PREFIX}VIEWER_CHANGED` as const;
export const viewerChanged = () => ({ type: VIEWER_CHANGED });

export const BASKET_CHANGE_STARTED = `${PREFIX}BASKET_CHANGE_STARTED` as const;
export const basketChangeStarted = () => ({ type: BASKET_CHANGE_STARTED });

export const BASKET_CHANGE_COMPLETED = `${PREFIX}BASKET_CHANGE_COMPLETED` as const;
export const basketChangeCompleted = (updatedLinesAmount: number) => ({
  type: BASKET_CHANGE_COMPLETED,
  payload: { updatedLinesAmount },
});

export const LOCATION_CHANGED = `${PREFIX}LOCATION_CHANGED` as const;
export const locationChanged = () => ({ type: LOCATION_CHANGED });

export const NAVIGATION_REQUESTED = `${PREFIX}NAVIGATION_REQUESTED` as const;
const emptyOptions = {};
export const navigateTo = (to: RouteData, url?: string, { omitScroll, replaceHistory }: NavigationOptions = emptyOptions) => ({
  type: NAVIGATION_REQUESTED,
  payload: { to, url, omitScroll, replaceHistory },
});
