import type { Tool } from './toolNames';
import type { LocalStorage } from '../localStorage';

export type ToolsStorage = ReturnType<typeof createToolsStorage>

export default function createToolsStorage(localStorage: LocalStorage) {
  const toolItemName = 'Tools';
  type ToolsInnerStorage = Partial<Record<Tool, boolean>>

  return {
    enableTool(toolName: Tool) {
      const tools = getTools();
      tools[toolName] = true;

      saveTools(tools);
    },
    disableTool(toolName: Tool) {
      const tools = getTools();
      delete tools[toolName];

      saveTools(tools);
    },
    toolEnabled(toolName: Tool) {
      const tools = getTools();
      return !!tools[toolName];
    },
    anyToolEnabled() {
      const tools = getTools();
      return !!Object.getOwnPropertyNames(tools).length;
    },
  };

  function getTools(): ToolsInnerStorage {
    const tools: ToolsInnerStorage = localStorage.getItem(toolItemName);
    if (tools)
      return tools;

    return {};
  }

  function saveTools(tools: ToolsInnerStorage) {
    localStorage.setItem(toolItemName, tools);
  }
}
