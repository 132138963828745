import { ofType } from 'redux-observable';
import { mergeMap, startWith, exhaustMap, pluck } from 'rxjs/operators';
import { DOCUMENT_EDIT_REQUESTED, editReceived } from '../actions';
import { editMutation } from '../queries';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { EditResult } from 'behavior/documents';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';
import { basketChangeCompleted, navigateTo } from 'behavior/events';

export default (action$, _state$, dependencies) =>
  action$.pipe(
    ofType(DOCUMENT_EDIT_REQUESTED),
    exhaustMap(({ payload }) =>
      dependencies.api.graphApi(editMutation,
        { input: payload },
        { retries: 0 },
      ).pipe(
        pluck('documents', 'edit'),
        mergeMap(edit => {
          const result = edit && edit.result;

          const actions = [
            unsetLoadingIndicator(),
          ];

          if (result === EditResult.Success) {
            // Non-zero linesUpdated signals that there was an actual basket change.
            actions.push(basketChangeCompleted(1));
            actions.push(navigateTo(routesBuilder.forBasket()));
          } else {
            actions.push(editReceived(result));
          }

          return actions;
        }),
        retryWithToast(action$, dependencies.logger),
        startWith(setLoadingIndicator()),
      ),
    ),
  );
