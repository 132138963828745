exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ForgotPassword_back-to{padding-top:12px}.ForgotPassword_form{margin-top:3em}@media (min-width:var(--breakpoints_Small,600)px),print{.ForgotPassword_forgot-password{max-width:46.75em}}@media (min-width:var(--breakpoints_Medium,960)px),print{.ForgotPassword_back-to{padding-top:22px}.ForgotPassword_forgot-password{max-width:none}}.ForgotPassword_toast{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"back-to": "ForgotPassword_back-to",
	"backTo": "ForgotPassword_back-to",
	"form": "ForgotPassword_form",
	"forgot-password": "ForgotPassword_forgot-password",
	"forgotPassword": "ForgotPassword_forgot-password",
	"toast": "ForgotPassword_toast"
};