import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useServices } from 'utils/services';
import { createUrl } from 'utils/url';

const CanonicalLink = () => {
  const { index, canonicalUrl } = useSelector(selector, _ => true);
  const { context: { origin } } = useServices();

  if (!index || !canonicalUrl)
    return null;

  const href = createUrl(canonicalUrl, origin);

  return (
    <Helmet>
      <link rel="canonical" href={href} />
    </Helmet>
  );
};

export default memo(CanonicalLink);

function selector({ routing: { canonicalUrl }, page }) {
  return {
    canonicalUrl,
    index: page.index,
  };
}