import styles from '../LoginInfo.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { connect } from 'react-redux';
import { WishlistLink, MyAccountLink, Link } from 'components/primitives/links';
import { useCurrentRouteAsBackTo } from 'utils/hooks';
import LogoutButton from '../LogoutButton';
import { stopRepresentingCustomer } from 'behavior/user';
import { routesBuilder } from 'routes';

const LoginInfoImpersonated = ({ name, customerName, stopRepresentingCustomer, logoutDelay }) => {
  const backTo = useCurrentRouteAsBackTo();

  return (
    <>
      <div className={styles.bodyHeader}>
        <div>
          <SimpleText textKey="Representing" /> <span className="customer-name"><b>{customerName}</b></span>
        </div>
        <div>
          <button className={`${linkStyles.link} ${linkStyles.arrowed} text-left`} onClick={stopRepresentingCustomer}>
            <SimpleText textKey="StopRepresentation" />
          </button>
        </div>
      </div>
      <div className={styles.bodyHeader}>
        <span className="user-name">
          <SimpleText textKey="LoggedInAs" /> <b>{name}</b>
        </span>
      </div>
      <Link to={routesBuilder.forRepresent} options={{ backTo }} className={styles.item}>
        <SimpleText textKey="RepresentAnotherCustomer" />
      </Link>
      <MyAccountLink className={styles.item} />
      <WishlistLink className={styles.item} />
      <LogoutButton logoutDelay={logoutDelay} />
    </>
  );
};

LoginInfoImpersonated.propTypes = {
  name: PropTypes.string.isRequired,
  customerName: PropTypes.string,
  stopRepresentingCustomer: PropTypes.func.isRequired,
  logoutDelay: PropTypes.number,
};

export default connect(
  ({ user }) => ({
    name: user.name,
    customerName: user.customer && user.customer.name,
  }),
  { stopRepresentingCustomer },
)(LoginInfoImpersonated);